import React, { useEffect, useRef } from "react";
import "./UserDefinedSBTinitiativeChart.scss";
import * as echarts from "echarts";
import { convertNegativeToZero, formatNumber } from "../../helper";

const UserDefinedSBTinitiativeChart = ({
  data = [],
  sbtiConfig,
  newData = [],
  selectedTarget,
  selectedScope,
}) => {
  const el = useRef(null);

  const filteredTarget =
    selectedTarget === "Near-Term Targets" ? "nearTeam" : "netZero";
  const filteredScope =
    selectedScope === "All Scopes"
      ? "allScopes"
      : selectedScope === "Scope 1+2"
      ? "scopeOneAndTwo"
      : selectedScope === "Scope 1"
      ? "scopeOne"
      : selectedScope === "Scope 2"
      ? "scopeTwo"
      : "scopeThree";
  const filteredData = newData?.[filteredScope]?.[filteredTarget];

  useEffect(() => {
    const result = {};

    filteredData?.forEach((subArray) => {
      const { year, emission_reduction } = subArray;
      if (!result[year]) {
        result[year] = 0;
      }
      result[year] += convertNegativeToZero(emission_reduction);
    });

    const _data = {
      year: Object.keys(result).map(Number),
      emission_reduction: Object.values(result),
    };

    const option = {
      title: {
        show: filteredData?.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: "15%",
        right: "10%",
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => formatNumber(value, 1),
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {},

      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: _data?.year,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        ...(sbtiConfig?.length
          ? [
              {
                name: "Emissions Targets",
                data: _data?.emission_reduction,
                type: "line",
                color: "#32CD32",
              },
            ]
          : []),
      ],
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    data,
    sbtiConfig,
    newData,
    selectedTarget,
    selectedScope,
    filteredData,
  ]);

  return (
    <div className="SBTinitiativeChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default UserDefinedSBTinitiativeChart;
