import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Row,
  Table,
  Alert,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

import { get } from "utils/DeApi";

import Header from "components/App/Header/Header";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import AuditReview from "./AuditReview";

import { AccountContext } from "contexts/AccountProvider";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";

import Select from "react-select";

import { getFormattedEmission } from "utils/StringUtils";
import UserCategories from "utils/userCategories";
import { getAllYearsBetweenDates } from "utils/dateUtils";

import "react-datepicker/dist/react-datepicker.css";
import "./AuditTrails.scss";

const AuditTrails = () => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const accountContext = useContext(AccountContext);

  const [auditTrails, setAuditTrails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "createdAt",
    updatedAt: true,
    sortAsc: false,
  });

  const date = new Date();

  const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 7));
  const [endDate, setEndDate] = useState(new Date());

  const events = ["Created", "Updated", "Deleted"];

  const changeTypeFilter = [
    "All Types",
    "Emission Factor",
    "Quantity",
    "Other",
  ];

  const [organizations, setOrganizations] = useState();

  const [filtersOrgLoader, setFiltersOrgLoader] = useState();

  const [sites, setSites] = useState();

  const [filterSitesLoader, setFilterSitesLoader] = useState();

  const [users, setUsers] = useState();

  const [filterUsersLoader, setFilterUsersLoader] = useState();

  const [selectedYear, setSelectedYear] = useState("2022");

  const [selectedType, setSelectedType] = useState("All Types");

  const [selectedEvent, setSelectedEvent] = useState("All Events");

  const [auditFilters, setAuditFilters] = useState({
    selectedOrg: { label: "All Organizations", value: "" },
    selectedSite: { label: "All Sites", value: "" },
    selectedUser: { label: "All Users", value: "" },
  });

  const navigate = useNavigate();

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const sortTable = (column) => {
    tableData.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  const fetchAuditTrails = useCallback(() => {
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");

    if (accountContext.member.role === "member") {
      navigate("/");
    } else {
      setError(null);
      setIsLoading(true);
      let getAuditTrails = get(`audit-trails/activities`, {
        params: {
          accountId: accountContext.id,
          "filter[createdAt]":
            startDate && endDate ? `${start},${end}` : startDate ? start : "",
          "filter[event]": selectedEvent,
          "filter[organizations]": auditFilters.selectedOrg.value,
          "filter[sites]": auditFilters.selectedSite.value,
          "filter[user]": auditFilters.selectedUser.value,
          "filter[yearEnded]": selectedYear,
          ...(selectedType !== "All Types" && {
            "filter[changeType]": selectedType,
          }),
        },
      });

      getAuditTrails.promise
        .then((response) => {
          setAuditTrails(response.data);

          setIsLoading(false);
          setError(null);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(getAuditTrails);
    }
  }, [
    accountContext,
    navigate,
    startDate,
    endDate,
    selectedEvent,
    auditFilters.selectedOrg,
    auditFilters.selectedSite,
    auditFilters.selectedUser,
    selectedYear,
    selectedType,
  ]);

  const fetchOrganizations = useCallback(() => {
    setFiltersOrgLoader(true);
    setError(null);

    let organizationsPromise = get("organizations/list", {
      params: {
        accountId: accountContext.id,
      },
    });

    organizationsPromise.promise
      .then((response) => {
        const allOrg = [{ label: "All Organizations", value: "" }];
        allOrg.push(
          response?.data.map((org) => ({
            label: org?.name,
            value: org?.id,
          }))
        );
        setOrganizations(allOrg?.flat());
        setFiltersOrgLoader(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setFiltersOrgLoader(false);
        }
      });

    subscribedPromises.current.push(organizationsPromise);
  }, [accountContext.id]);

  const fetchSites = useCallback(() => {
    setFilterSitesLoader(true);
    setError(null);

    let sitesPromise = get(`accounts/${accountContext.id}/sites`);

    sitesPromise.promise
      .then((response) => {
        const allSites = [{ label: "All Sites", value: "" }];
        allSites.push(
          response?.data
            ?.filter((filteredItem) =>
              auditFilters.selectedOrg.value
                ? filteredItem.organizationId === auditFilters.selectedOrg.value
                : filteredItem
            )
            .map((sites) => ({
              label: sites?.name,
              value: sites?.id,
            }))
        );
        setSites(allSites?.flat());
        setFilterSitesLoader(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setFilterSitesLoader(false);
        }
      });

    subscribedPromises.current.push(sitesPromise);
  }, [accountContext.id, auditFilters.selectedOrg.value]);

  const fetchUsers = useCallback(() => {
    setFilterUsersLoader(true);
    setError(null);

    let usersPromise = get(`accounts/${accountContext.id}/subscribers`);

    usersPromise.promise
      .then((response) => {
        const allUsers = [{ label: "All Users", value: "" }];
        allUsers.push(
          response?.data.map((users) => ({
            label: users?.name,
            value: users?.id,
          }))
        );
        setUsers(allUsers?.flat());
        setFilterUsersLoader(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setFilterUsersLoader(false);
        }
      });

    subscribedPromises.current.push(usersPromise);
  }, [accountContext.id]);

  useEffect(() => {
    fetchAuditTrails();
    fetchOrganizations();
    fetchSites();
    fetchUsers();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAuditTrails, fetchOrganizations, fetchSites, fetchUsers]);

  useEffect(() => {
    const data = auditTrails.map((item) => {
      return {
        id: item.activity.id,
        type: item.type,
        member: item.member?.email,
        name: item.member?.name,
        createdAt: item.createdAt,
        organizationId: item.activity?.site?.organization?.id,
        organizationName: item.activity?.site?.organization?.name,
        siteId: item.activity?.site?.id,
        siteName: item.activity?.site?.name,
        activityType: item.activity?.activityType?.title,
        scope: item.activity?.scope,
        activityId: item.activity?.id,
        activityTypeId: item.activity?.activityType?.id,
        reportedType: item?.activity?.isActivity,
        efType: item?.efType,
        year: item.activity?.yearEnded,
        emissions: item.activity?.emissions,
        label: item.activity?.activityType?.label,
        categoryNumber: item.activity?.activityType?.categoryNumber,
      };
    });

    data.sort((a, b) => (a["createdAt"] <= b["createdAt"] ? 1 : -1));
    setTableData(data);
  }, [auditTrails]);

  return (
    <>
      <Header />
      <Container id={userCategory} className="AuditTrails py-3">
        <Row className="my-3">
          <Col xs={12} md={6}>
            <h1>{accountContext.name}</h1>
            <h5>Your activities audit trail dashboard.</h5>
          </Col>
          <Col xs={12} md={6} className="text-end"></Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col xs={12} className="my-3">
            <div className="d-flex float-end">
              <div className="d-inline-flex mx-2">
                <Select
                  name="orgDropDown"
                  options={organizations}
                  value={auditFilters?.selectedOrg || organizations?.[0]}
                  onChange={(event) => {
                    let selectedOrg = event;
                    setAuditFilters({
                      ...auditFilters,
                      selectedOrg,
                      selectedSite: "",
                    });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#E9EFC0",
                      primary: "#007a5f",
                    },
                  })}
                  styles={{
                    control: (ownStyles) => ({
                      ...ownStyles,
                      borderColor: "#EEEEEE",
                      width: "10rem",
                    }),
                    dropdownIndicator: (ownStyles) => ({
                      ...ownStyles,
                      color: "#212121",
                    }),
                  }}
                  isDisabled={filtersOrgLoader}
                  isLoading={filtersOrgLoader}
                />
              </div>
              <div className="d-inline-flex mx-2">
                <Select
                  name="siteDropDown"
                  options={sites}
                  value={auditFilters?.selectedSite || sites?.[0]}
                  onChange={(event) => {
                    let selectedSite = event;
                    setAuditFilters({
                      ...auditFilters,
                      selectedSite,
                    });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#E9EFC0",
                      primary: "#007a5f",
                    },
                  })}
                  styles={{
                    control: (ownStyles) => ({
                      ...ownStyles,
                      borderColor: "#EEEEEE",
                      width: "10rem",
                    }),
                    dropdownIndicator: (ownStyles) => ({
                      ...ownStyles,
                      color: "#212121",
                    }),
                  }}
                  isDisabled={filterSitesLoader}
                  isLoading={filterSitesLoader}
                />
              </div>
              <div className="d-inline-flex mx-2">
                <Select
                  name="usersDropDown"
                  options={users}
                  value={auditFilters?.selectedUser || users?.[0]}
                  onChange={(event) => {
                    let selectedUser = event;
                    setAuditFilters({
                      ...auditFilters,
                      selectedUser,
                    });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#E9EFC0",
                      primary: "#007a5f",
                    },
                  })}
                  styles={{
                    control: (ownStyles) => ({
                      ...ownStyles,
                      borderColor: "#EEEEEE",
                      width: "10rem",
                    }),
                    dropdownIndicator: (ownStyles) => ({
                      ...ownStyles,
                      color: "#212121",
                    }),
                  }}
                  isDisabled={filterUsersLoader}
                  isLoading={filterUsersLoader}
                />
              </div>

              <div className="d-inline-flex ">
                <DropdownButton
                  aria-label="type-filter"
                  size="sm"
                  className="mx-1"
                  variant="outline-primary"
                  title={selectedType || "Select Type"}
                >
                  <Dropdown.Item disabled>Select Type</Dropdown.Item>
                  {changeTypeFilter.map((types) => (
                    <Dropdown.Item
                      onClick={() => setSelectedType(types)}
                      key={types}
                      value={types}
                      active={types === selectedType}
                    >
                      {types}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              <div className="d-inline-flex ">
                <DropdownButton
                  aria-label="year-filter"
                  size="sm"
                  className="mx-1"
                  variant="outline-primary"
                  title={selectedYear || "Select Year"}
                >
                  <Dropdown.Item disabled>Select Year</Dropdown.Item>
                  {getAllYearsBetweenDates().map((year) => (
                    <Dropdown.Item
                      onClick={() => setSelectedYear(year)}
                      key={year}
                      value={year}
                      active={year === selectedYear}
                    >
                      {year}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div className="d-inline-flex ">
                <DropdownButton
                  aria-label="event-filter"
                  size="sm"
                  className="mx-1"
                  variant="outline-primary"
                  title={selectedEvent}
                >
                  <Dropdown.Item disabled>Select Event</Dropdown.Item>
                  <Dropdown.Item
                    active={selectedEvent === "All Events"}
                    onClick={() => setSelectedEvent("All Events")}
                  >
                    All Events
                  </Dropdown.Item>
                  {events.map((events) => (
                    <Dropdown.Item
                      onClick={() => setSelectedEvent(events)}
                      key={events}
                      value={events}
                      active={events === selectedEvent}
                    >
                      {events}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              <div>
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={startDate}
                  onChange={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Select date/week"
                  monthsShown={2}
                >
                  <Button
                    size="sm"
                    className="mb-1"
                    onClick={() => {
                      setStartDate();
                      setEndDate();
                    }}
                  >
                    Clear
                  </Button>
                </DatePicker>
              </div>
            </div>
            <h3>Activity Edit History</h3>
            {error && <ErrorHandler error={error} />}

            {isLoading ? (
              <Loader />
            ) : (
              <div className="mt-3">
                {auditTrails.length ? (
                  <Table hover responsive size="sm">
                    <thead>
                      <tr>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("organizationName")}
                        >
                          Organization
                          <ColumnSorter
                            name={"organizationName"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("siteName")}
                        >
                          Site
                          <ColumnSorter
                            name={"siteName"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("year")}
                        >
                          Year
                          <ColumnSorter
                            name={"year"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("scope")}
                        >
                          Scope
                          <ColumnSorter
                            name={"scope"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("activityType")}
                        >
                          Activity Type
                          <ColumnSorter
                            name={"activityType"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("emissions")}
                        >
                          Emissions <small>(tCO2e)</small>
                          <ColumnSorter
                            name={"emissions"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("type")}
                        >
                          EF Type
                          <ColumnSorter
                            name={"type"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("member")}
                        >
                          Member
                          <ColumnSorter
                            name={"member"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th
                          className="table-sorter"
                          onClick={() => sortTable("createdAt")}
                        >
                          Time
                          <ColumnSorter
                            name={"createdAt"}
                            sortState={tableSortState}
                          />
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((log, index) => {
                        return (
                          <tr key={log.id + index}>
                            <td>{log.organizationName}</td>
                            <td>{log.siteName}</td>
                            <td>{new Date(log.year).getFullYear()}</td>
                            <td>{log.scope}</td>
                            <td>
                              {log.categoryNumber
                                ? `${log.categoryNumber}. `
                                : ""}
                              {log.activityType}
                            </td>
                            <td>{getFormattedEmission(log.emissions, 1)}</td>

                            <td>
                              <Badge className="bg-secondary text-white bg-rounded px-1 me-1">
                                {log?.efType}
                              </Badge>
                            </td>
                            <td className="text-capitalize">{log.name}</td>

                            <td>
                              <small>
                                {new Date(log.createdAt).toLocaleString([], {
                                  dateStyle: "short",
                                  timeStyle: "short",
                                })}
                              </small>
                            </td>
                            <td>
                              {log.activityType && (
                                <AuditReview
                                  activityId={log?.activityId}
                                  auditTrails={auditTrails}
                                  accountId={accountContext.id}
                                  tableData={tableData}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <Alert variant="info" className="my-5">
                    There is currently nothing to show here.
                  </Alert>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

let ColumnSorter = ({ name, sortState }) => {
  return (
    <>
      {sortState.sortColumn === name && (
        <span className="material-icons-outlined mx-1 md-20 text-muted">
          {sortState.sortAsc && "arrow_upward"}
          {!sortState.sortAsc && "arrow_downward"}
        </span>
      )}
    </>
  );
};

export default AuditTrails;
