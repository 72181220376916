import React, { useState, useEffect } from "react";
import { Form, Button, Collapse, Alert } from "react-bootstrap";

import { getFormula, UnitsDataSet } from "./UnitDataset";
import { filter } from "lodash";

import NumberFormat from "react-number-format";

const UnitConverter = ({ setFieldValue, emissionUnit, values }) => {
  const [show, setShow] = useState(false);
  const [unitList, setUnitList] = useState([]);

  const [amount, setAmount] = useState(values?.rawAmount);
  const [amountUnit, setAmountUnit] = useState(values?.rawUnit);

  function getUnitDatasetByEmissionUnit(unitsDataSet, emissionUnit) {
    const lowercaseKey = emissionUnit.toLowerCase();
    for (let i = 0; i < unitsDataSet.length; i++) {
      const objectKeys = Object.keys(unitsDataSet[i]);
      const foundKey = objectKeys.find((objKey) =>
        new RegExp(objKey, "i").test(lowercaseKey)
      );
      if (foundKey) {
        return unitsDataSet[i];
      }
    }
    return null;
  }

  useEffect(() => {
    let checkForEmissionUnit = ["kWh (Net CV)", "kWh (Gross CV)"].includes(
      emissionUnit
    )
      ? "kWh"
      : ["passenger.km", "person.km"].includes(emissionUnit)
      ? "km"
      : ["vehicle-mile", "ton-mile", "passenger-mile"].includes(emissionUnit)
      ? "mile"
      : ["litres"].includes(emissionUnit)
      ? "litre"
      : ["Tonne"].includes(emissionUnit)
      ? "t"
      : emissionUnit?.toLowerCase();
    let getDataSet = [
      getUnitDatasetByEmissionUnit(UnitsDataSet, checkForEmissionUnit),
    ];
    let newData = [];
    if (getDataSet?.length !== 0) {
      let key = emissionUnit;
      newData = getDataSet?.map(({ [key]: value, ...item }) => {
        return item;
      });
    }
    setUnitList(
      newData?.length !== 0
        ? Object.values(newData?.[0]).filter((unitData) =>
            ["kWh (Net CV)", "kWh (Gross CV)"].includes(emissionUnit)
              ? unitData !== "kWh"
              : ["passenger.km", "person.km"].includes(emissionUnit)
              ? unitData !== "km"
              : ["vehicle-mile", "ton-mile", "passenger-mile"].includes(
                  emissionUnit
                )
              ? unitData !== "mile"
              : ["Litre", "litres"].includes(emissionUnit)
              ? unitData !== "litre"
              : ["Tonne"].includes(emissionUnit)
              ? unitData !== "t"
              : unitData
          )
        : ["No Data Found"]
    );
  }, [emissionUnit]);

  return (
    <div className="pt-3">
      <Button
        variant="link"
        onClick={() => {
          setShow(!show);
        }}
        className="p-0 mt-n3"
      >
        <span className="material-icons-outlined">info</span> Need help
        converting quantity to {emissionUnit}
      </Button>
      <Collapse in={show}>
        <div>
          <Alert
            variant="dark"
            className="bg-light"
            dismissible
            onClose={() => {
              setAmount();
              setAmountUnit();
              setShow(!show);
            }}
          >
            <Form.Group controlId="unit" className="my-3">
              <Form.Label>Select the unit to convert from</Form.Label>

              <Form.Select
                name="amountUnit"
                value={amountUnit}
                onChange={(ev) => {
                  setAmountUnit(ev.target.value);
                  setFieldValue("rawUnit", ev.target.value);
                }}
              >
                <option>Select unit of measurement</option>
                {unitList?.map((item) => (
                  <option
                    key={item === "cubic metre" ? "m3" : item}
                    value={item === "cubic metre" ? "m3" : item}
                  >
                    {item === "cubic metre" ? "m3" : item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {amountUnit && (
              <Form.Group controlId="resource-amount">
                <Form.Label>
                  Provide the quantity of resource used in {amountUnit}
                </Form.Label>

                <NumberFormat
                  customInput={Form.Control}
                  name="amount"
                  value={amount}
                  thousandSeparator={true}
                  onValueChange={(numberItem) => {
                    setAmount(numberItem.value);
                    setFieldValue("rawAmount", numberItem.value);
                  }}
                  placeholder={"Enter amount of resource"}
                  onBlur={() => {}}
                />
              </Form.Group>
            )}
            {amount && (
              <p className="my-3">
                {amount} {amountUnit} is equivalent to{" "}
                <big>
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 10,
                    minimumFractionDigits: 2,
                  }).format(getFormula(amountUnit, emissionUnit, amount))}
                </big>{" "}
                {emissionUnit}
              </p>
            )}

            <div className="my-3 text-end">
              <>
                <Button
                  size="sm"
                  variant="link"
                  onClick={(e) => {
                    e.preventDefault();
                    setAmount();
                    setAmountUnit();
                    setShow(!show);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setFieldValue(
                      "amount",
                      getFormula(amountUnit, emissionUnit, amount)
                    );
                    setAmount();
                    setAmountUnit();
                    setShow(!show);
                  }}
                >
                  Use{" "}
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 10,
                    minimumFractionDigits: 2,
                  }).format(
                    amount ? getFormula(amountUnit, emissionUnit, amount) : 0
                  )}{" "}
                  {emissionUnit}
                </Button>
              </>
            </div>
          </Alert>
        </div>
      </Collapse>
    </div>
  );
};

export default UnitConverter;
