import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSearchParams, useLocation } from "react-router-dom";

import { Alert, Button, Collapse, Form } from "react-bootstrap";

import { get } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import ReportActivity from "../ReportActivity/ReportActivity";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import { getFormattedEmission } from "utils/StringUtils";
import ActivityList from "../../ActivityList/ActivityList";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import useContents from "components/hooks/useContents";

import UserCategories from "utils/userCategories";

import { isInvestments } from "utils/emissionFactors";

const ScopeThree = ({
  site,
  breadcrumbs,
  siteAccess,
  siteYearsUnderReview,
  organization,
}) => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const activityCategory = searchParams.get("activityCategory");
  const activityTypeId = searchParams.get("activityCategoryId");

  const [activityTypes, setActivityTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activityTypeFilter, setActivityTypeFilter] = useState();
  const [yearFilter, setYearFilter] = useState(
    location?.state?.year || undefined
  );

  const [helpContents, setHelpContents] = useState();
  const [openHelpContents, setOpenHelpContents] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const contentId = useContents();

  // Temporary hide if consumption unknown activity types.
  const hiddenActivityTypes = [
    "6262893dec289", // Upstream - Leased Assets (If consumption unknown)
    "6262893e129e5", // Downstream - Leased Assets (If consumption unknown)
    "6262893e1a763", // Franchises (If consumption unknown)
  ];

  const fetchActivities = useCallback(() => {
    setError(null);
    setIsLoading(true);
    setOpenHelpContents(false);

    const activitiesPromise = get(`sites/${site.id}/activities`, {
      params: {
        scope: [3],
        activityTypeId: activityTypeId,
        offset: 0,
        limit: 10000,
        precalc: true,
      },
    });
    const activityTypesPromise = get(`activity-types`, {
      params: {
        scope: 3,
      },
    });

    Promise.all([activitiesPromise.promise, activityTypesPromise.promise])
      .then((responses) => {
        setActivities(responses[0].data);
        setActivityTypes(responses[1].data);
        setIsLoading(false);
        setError(null);
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(activitiesPromise, activityTypesPromise);
  }, [activityTypeId, site.id]);

  useEffect(() => {
    setYearFilter(location?.state?.year || undefined);
    setActivityTypeFilter();
    fetchActivities();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActivities, activityTypeId]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!activities || !activityTypes) return <span />;

  return (
    <div id={userCategory}>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: `Scope 3 - ${activityCategory}`,
              link: location.pathname + location.search,
              active: true,
            },
          ]}
        />
      </div>
      <div className="d-flex float-end">
        {siteAccess && (
          <>
            <ReportActivity
              site={site}
              activityTypes={activityTypes.filter(
                (type) =>
                  type.label.includes(activityCategory) &&
                  !hiddenActivityTypes.includes(type.id)
              )}
              onActivityReported={fetchActivities}
              isInvestments={isInvestments(activityCategory)}
              scope={3}
              siteYearsUnderReview={siteYearsUnderReview}
              organization={organization}
            />{" "}
          </>
        )}
      </div>
      <h2>Scope 3 Emissions - {activityCategory}</h2>
      {helpContents && (
        <>
          <p className="my-3">{helpContents?.description}</p>
          <Button
            variant="link"
            onClick={() => {
              setOpenHelpContents(!openHelpContents);
            }}
            className="p-0 mt-n3"
          >
            <span className="material-icons-outlined">info</span>{" "}
            {helpContents.title}
          </Button>
        </>
      )}
      <Collapse in={openHelpContents}>
        <div>
          <Alert
            variant="dark"
            className="bg-light"
            dismissible
            onClose={() => setOpenHelpContents(!openHelpContents)}
          >
            <ContentDetails
              contentId={contentId}
              onContentFetched={setHelpContents}
            />
          </Alert>
        </div>
      </Collapse>
      <hr />

      {(() => {
        let filteredActivities = [
          ...activities
            .filter((activity) => {
              if (yearFilter)
                return activity.yearEnded.substring(0, 4) === yearFilter;
              else return true;
            })
            .filter((activity) => {
              if (activityTypeFilter)
                return activity.activityType.id === activityTypeFilter;
              else return true;
            }),
        ];

        return (
          <>
            <div className="text-end">
              {(() => {
                // For Market and Location Based
                let activityTypeOptions = activityTypes.filter(
                  (type) =>
                    type.label.includes(activityCategory) &&
                    !hiddenActivityTypes.includes(type.id)
                );

                if (activityTypeOptions.length > 1)
                  return (
                    <div className="my-3 ms-2 d-inline-flex">
                      <Form.Select
                        aria-label="category-filter"
                        size="sm"
                        value={activityTypeFilter || ""}
                        onChange={(ev) =>
                          setActivityTypeFilter(ev.target.value)
                        }
                      >
                        <option key={"All"} value={""}>
                          All Activity Types
                        </option>
                        {activityTypeOptions.map((type) => (
                          <option key={type.title} value={type.id}>
                            {type.title}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  );
                else return <span />;
              })()}
              <div className="my-3 ms-2 d-inline-flex">
                <Form.Select
                  aria-label="year-filter"
                  size="sm"
                  value={yearFilter || ""}
                  onChange={(ev) => setYearFilter(ev.target.value)}
                >
                  <option key={"All"} value={""}>
                    All Years
                  </option>
                  {(() => {
                    let startDate = new Date("2010-01-01");
                    let endDate = new Date();
                    let initialYear = startDate.getFullYear();
                    let currentYear = endDate.getFullYear();
                    const years = [];
                    while (currentYear >= initialYear) {
                      const year = currentYear--;
                      years.push(year.toString());
                    }

                    return years;
                  })().map((filter) => (
                    <option key={filter} value={filter}>
                      {filter}
                    </option>
                  ))}
                </Form.Select>
              </div>{" "}
              <div className="my-3 ms-2 d-inline-flex">
                <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10">
                  <span className="fs-1">
                    {getFormattedEmission(
                      filteredActivities.reduce(
                        (prev, curr) => prev + curr.emissions,
                        0
                      )
                    )}{" "}
                  </span>
                  <small>
                    tCO<sub>2</sub>e
                  </small>{" "}
                  Emissions
                </div>
              </div>
            </div>

            {filteredActivities.length ? (
              <ActivityList
                activities={filteredActivities}
                activityTypes={activityTypes}
                onActivityUpdated={fetchActivities}
                siteAccess={siteAccess}
                siteYearsUnderReview={siteYearsUnderReview}
                isException={location?.state?.year ? true : false}
                organization={organization}
              />
            ) : (
              <Alert variant="info">
                There is currently nothing to show here.
              </Alert>
            )}
          </>
        );
      })()}
    </div>
  );
};

ScopeThree.propTypes = {
  site: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  siteAccess: PropTypes.bool.isRequired,
  siteYearsUnderReview: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
};

export default ScopeThree;
