import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Field, FieldArray, Formik } from "formik";
import * as yup from "yup";
import { post, put } from "utils/DeApi";
import { useParams } from "react-router-dom";
import { isNegative } from "utils/numerals";
import SelectInputFormik from "components/SelectInput/SelectInput";

const MAX_YEAR = 2051;

const ManageReductions = ({
  emissions,
  scopeEmissions,
  onManageReductionsUpdated,
  baselineYear,
  type,
  memoizedConfig,
  emissionId,
  miscLeverName,
  onLeverNameUpdated,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [year, setYear] = useState(2021);

  const { organizationId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    leverName: yup.string().label("Decarb Lever Name").required(),
    scopePreference: yup
      .number()
      .label("Primary Impacted Scope")
      .oneOf([1, 2, 3], "Scope Preference is a required field.")
      .required(),
    scopePreferencePercentage: yup
      .number()
      .label("Impacted Emissions")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`)
      .required(),
    secondaryScopePreferencePercentage: yup
      .number()
      .label("Impacted Emissions Scope 3")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`)
      .when("scopePreference", {
        is: (value) => value === 1 || value === 2,
        then: yup.number().required(),
      }),
    reduction: yup.array().of(
      yup.object().shape({
        year: yup
          .number()
          .label("Year")
          .min(0)
          .max(
            9999999999999,
            `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
          ),
        reduction: yup
          .number()
          .label("Percentage Increase")
          .max(
            9999999999999,
            `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
          )
          .required(),
      })
    ),
  });

  const checkScopeName = (selectedScope, leverName) => {
    setError(null);
    setIsLoading(true);
    const config = memoizedConfig.findIndex((lever) => lever.type === type);
    if (config > -1) {
      memoizedConfig[config].scopeOne = selectedScope == 1;
      memoizedConfig[config].scopeTwo = selectedScope == 2;
      memoizedConfig[config].scopeThree = true;
      memoizedConfig[config].title = leverName;

      const {
        id,
        emissionsScopeOne,
        emissionsScopeTwo,
        emissionsScopeThree,
        baselineYear,
      } = scopeEmissions;

      const data = {
        organization_id: organizationId,
        emissions_scope_one: !isNaN(Number(emissionsScopeOne))
          ? Number(emissionsScopeOne)
          : "",
        emissions_scope_two: !isNaN(Number(emissionsScopeTwo))
          ? Number(emissionsScopeTwo)
          : "",
        emissions_scope_three: !isNaN(Number(emissionsScopeThree))
          ? Number(emissionsScopeThree)
          : "",
        baseline_year: baselineYear,
        forecastingConfig: JSON.stringify(memoizedConfig),
      };

      const forecastingPromise = emissionId
        ? put(`emissions/${id}`, data)
        : post(`organizations/${organizationId}/emissions`, data);

      forecastingPromise.promise
        .then((response) => {
          setIsLoading(false);
          onLeverNameUpdated(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(forecastingPromise);
    }
  };

  const updateS1Reduction = ({
    reduction,
    scopePreferencePercentage,
    scopePreference,
    secondaryScopePreferencePercentage,
    leverName,
  }) => {
    const data = {
      organization_id: organizationId,
      reduction,
      scopePreference,
      scopePreferencePercentage,
      secondaryScopePreferencePercentage:
        scopePreference === 1 || scopePreference === 2
          ? secondaryScopePreferencePercentage
          : 0,
      type,
    };

    checkScopeName(scopePreference, leverName);

    const organizationPromise = emissions?.id
      ? put(`miscellaneous-decarbonization/${emissions?.id}`, data)
      : post(
          `organizations/${organizationId}/miscellaneous-decarbonization`,
          data
        );
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onManageReductionsUpdated(response);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    setYear((prev) => {
      return !isNaN(Number(baselineYear)) ? baselineYear : prev;
    });

    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [baselineYear]);

  return (
    <>
      <Button size="sm" onClick={handleShow} className="float-end">
        Manage Projections
      </Button>
      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title className="fs-4">Manage Projections</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {error && <ErrorHandler error={error} />}
          <Formik
            validationSchema={schema}
            onSubmit={(values) => updateS1Reduction(values)}
            enableReinitialize={true}
            initialValues={{
              leverName: miscLeverName,
              scopePreference: emissions?.scopePreference || 0,
              scopePreferencePercentage: parseFloat(
                emissions?.scopePreferencePercentage || 0
              ),
              secondaryScopePreferencePercentage: parseFloat(
                emissions?.secondaryScopePreferencePercentage || 0
              ),
              reduction: [...Array(MAX_YEAR - parseInt(year) || 30)]
                .map((item, index) => ({
                  year: (parseInt(year) + index) % MAX_YEAR,
                  reduction: 0,
                }))
                .map(({ year, reduction }) => {
                  const data =
                    Array.isArray(emissions?.data) &&
                    emissions?.data.find(({ year: y }) => year === y);

                  return {
                    year: year,
                    reduction: data
                      ? parseFloat(data.reduction_percentage || 0)
                      : 0,
                  };
                })
                .filter((r) => r.year !== year),
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              warnings,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} className="mb-5 pb-2">
                <Row className="mb-1">
                  <Col xs={12}>Lever Name</Col>
                </Row>
                <Row>
                  <Form.Group controlId={`leverName`} className="mb-2">
                    <Field
                      name={`leverName`}
                      as={Form.Control}
                      type="text"
                      size="sm"
                      onChange={handleChange}
                      isValid={values.leverName}
                      isInvalid={errors.leverName && touched.leverName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.leverName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-1">
                  <Col xs={12}>Primary Impacted Scope</Col>
                </Row>
                <Row>
                  <Form.Group
                    controlId="scopePreference"
                    className="mb-2"
                    as={Col}
                    xs={12}
                  >
                    <Field
                      as={Form.Select}
                      size="sm"
                      name="scopePreference"
                      value={values.scopePreference}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={values.scopePreference}
                      isInvalid={
                        errors.scopePreference && touched.scopePreference
                      }
                    >
                      <option disabled value={0}>
                        Select Scope
                      </option>
                      {["Scope 1", "Scope 2", "Scope 3"].map((scope, index) => (
                        <option key={scope} value={index + 1}>
                          {scope}
                        </option>
                      ))}
                    </Field>
                    <Form.Control.Feedback type="invalid">
                      {errors.scopePreference}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-1">
                  <Col xs={12}>
                    % Impacted Emissions for Scope{" "}
                    {values?.scopePreference || emissions?.scopePreference}
                  </Col>
                </Row>
                <Row>
                  <Form.Group
                    controlId={`scopePreferencePercentage`}
                    className="mb-2"
                    as={Col}
                    xs={12}
                  >
                    <Field
                      name={`scopePreferencePercentage`}
                      as={Form.Control}
                      type="number"
                      size="sm"
                      onChange={(ev) => {
                        const reduction = ev.target.valueAsNumber;
                        setFieldValue(
                          `scopePreferencePercentage`,
                          !isNaN(reduction) ? reduction : ""
                        );
                      }}
                      isValid={values.scopePreferencePercentage}
                      isInvalid={
                        errors.scopePreferencePercentage &&
                        touched.scopePreferencePercentage
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.scopePreferencePercentage}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                {values.scopePreference == 1 || values.scopePreference == 2 ? (
                  <>
                    <Row className="mb-1">
                      <Col xs={12}>% Impacted Emissions for Scope 3</Col>
                    </Row>
                    <Row>
                      <Form.Group
                        controlId={`secondaryScopePreferencePercentage`}
                        className="mb-2"
                        as={Col}
                        xs={12}
                      >
                        <Field
                          name={`secondaryScopePreferencePercentage`}
                          as={Form.Control}
                          type="number"
                          size="sm"
                          onChange={(ev) => {
                            const reduction = ev.target.valueAsNumber;
                            setFieldValue(
                              `secondaryScopePreferencePercentage`,
                              !isNaN(reduction) ? reduction : ""
                            );
                          }}
                          isValid={values.secondaryScopePreferencePercentage}
                          isInvalid={
                            errors.secondaryScopePreferencePercentage &&
                            touched.secondaryScopePreferencePercentage
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.secondaryScopePreferencePercentage}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </>
                ) : null}
                <Row className="mb-1">
                  <Col xs={4}>Years</Col>
                  <Col xs={8}>% Reduction in Relevant Emission Factor</Col>
                </Row>
                {values.reduction.reduce((prev, curr, acc) => {
                  return curr?.reduction + (prev || 0);
                }, 0) > 100 ? (
                  <div className="invalid-feedback d-block mb-3 mt-2">
                    Note: Cumulative % reduction is capped to 100. You have
                    entered values exceeding the cap.
                  </div>
                ) : null}
                <FieldArray
                  name="reduction"
                  render={(arrayHelpers) => (
                    <>
                      {values.reduction.map((r, index) => (
                        <Row key={index}>
                          <Form.Group
                            controlId={`reduction[${index}].year`}
                            className="mb-2"
                            as={Col}
                            xs={4}
                          >
                            <Field
                              name={`reduction[${index}].year`}
                              as={Form.Control}
                              type="number"
                              size="sm"
                              disabled={true}
                            />
                          </Form.Group>
                          <Form.Group
                            controlId={`reduction[${index}].reduction`}
                            className="mb-2"
                            as={Col}
                            xs={8}
                          >
                            <Field
                              name={`reduction[${index}].reduction`}
                              as={Form.Control}
                              type="number"
                              size="sm"
                              onChange={(ev) => {
                                const reduction = ev.target.valueAsNumber;
                                setFieldValue(
                                  `reduction[${index}].reduction`,
                                  !isNaN(reduction) ? reduction : ""
                                );

                                values.reduction.forEach((item, ii) => {
                                  if (index < ii)
                                    setFieldValue(
                                      `reduction[${ii}].reduction`,
                                      !isNaN(reduction) ? reduction : ""
                                    );
                                });
                              }}
                            />
                            {values?.reduction?.length &&
                              isNegative(
                                values?.reduction[index]?.reduction
                              ) && (
                                <div className="invalid-feedback d-block">
                                  Note: A negative value represents an increase
                                  in emission factor.
                                </div>
                              )}
                          </Form.Group>
                        </Row>
                      ))}
                    </>
                  )}
                />
                <Col className="position-absolute fixed-bottom bg-light p-3">
                  <Button
                    size="sm"
                    type="submit"
                    className="float-end ms-2"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner animation="border" size="sm" className="me-2" />
                    )}
                    Save
                  </Button>
                  <Button
                    size="sm"
                    variant="link"
                    className="float-end"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageReductions.propTypes = {
  onManageReductionsUpdated: PropTypes.func.isRequired,
};

export default ManageReductions;
