import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { post, put } from "utils/DeApi";
import { Link, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { get } from "utils/DeApi";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";

const defaultbauProp = [
  {
    title: "Growth in Production/Turnover",
    link: "economic-growth",
    scopeOne: true,
    scopeTwo: true,
    scopeThree: true,
    isInForecasting: true,
    isInBau: true,
  },
];

const bauDropdownProp = [
  {
    title: "Decarb of Grid Electricity",
    link: "grid-decarbonization",
    scopeOne: false,
    scopeTwo: true,
    scopeThree: true,
    isInForecasting: true,
    isInBau: true,
  },
  {
    title: "Decarb of Heating Fuels",
    link: "heating-fuels-decarbonization",
    scopeOne: true,
    scopeTwo: false,
    scopeThree: true,
    isInForecasting: true,
    isInBau: true,
  },
  {
    title: "Decarb of Transport",
    link: "transport-decarbonization",
    scopeOne: true,
    scopeTwo: false,
    scopeThree: true,
    isInForecasting: true,
    isInBau: true,
  },
  {
    title: "Other External Measures",
    link: "miscellaneous-decarbonization-1",
    type: "miscellaneous-decarbonization-1",
    scopeOne: false,
    scopeTwo: false,
    scopeThree: false,
    isInForecasting: true,
    isInBau: true,
  },
];

const ManageEmissions = ({ emissions, onManageEmissionsUpdated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [sites, setSites] = useState([]);
  const [moduleError, setModuleError] = useState();
  const [step, setStep] = useState("step-1");
  const [bauModule, setBauModule] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showBauDropdown, setShowBauDropdown] = useState(false);
  const [scopeTwoPreference, setScopeTwoPreference] = useState(0); // market based is preference 1 or true and location based is preference 0 or false

  const [bauProps, setBauProps] = useState(
    emissions?.forecastingConfig
      ? [
          ...JSON.parse(emissions?.forecastingConfig).map((val) => {
            if (val.title === "Decard of Grid Electricity") {
              val.title = "Decarb of Grid Electricity";
            }

            return val;
          }),
        ]
      : [...defaultbauProp]
  );
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const { organizationId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    emissionsScopeOne: yup
      .number()
      .label("Emissions Scope 1")
      .min(0)
      .max(
        9999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999)}.`
      )
      .required(),
    emissionsScopeTwo: yup
      .number()
      .label("Emissions Scope 2")
      .min(0)
      .max(
        9999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999)}.`
      )
      .required(),
    emissionsScopeThree: yup
      .number()
      .label("Emissions Scope 3")
      .min(0)
      .max(
        9999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999)}.`
      ),
    year: yup
      .number("Most Recent Year is a required field")
      .label("Most Recent Year")
      .required(),
  });

  const updateEmissions = ({
    emissionsScopeOne,
    emissionsScopeTwo,
    emissionsScopeThree,
    limitReductionPercentage,
    year,
  }) => {
    setError(null);
    setIsLoading(true);
    const data = {
      organization_id: organizationId,
      emissions_scope_one: !isNaN(Number(emissionsScopeOne))
        ? Number(emissionsScopeOne)
        : "",
      emissions_scope_two: !isNaN(Number(emissionsScopeTwo))
        ? Number(emissionsScopeTwo)
        : "",
      emissions_scope_three: !isNaN(Number(emissionsScopeThree))
        ? Number(emissionsScopeThree)
        : "",
      limit_reduction_percentage: !isNaN(Number(limitReductionPercentage))
        ? Number(limitReductionPercentage)
        : "",
      baseline_year: year,
      forecastingConfig: JSON.stringify(bauProps),
    };
    const organizationPromise = emissions?.id
      ? put(`emissions/${emissions?.id}`, data)
      : post(`organizations/${organizationId}/emissions`, data);
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onManageEmissionsUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  const filterSitesByYear = (sites = [], year = "") =>
    sites.filter(({ yearEnded }) => parseInt(yearEnded) === parseInt(year));

  const sumOfScopes = (sites, year) => {
    return filterSitesByYear(sites, year).reduce(
      (prev, curr) => {
        return {
          scopeOneEmissions: prev.scopeOneEmissions + curr.scopeOneEmissions,
          scopeTwoEmissions: prev.scopeTwoEmissions + curr.scopeTwoEmissions,
          scopeThreeEmissions:
            prev.scopeThreeEmissions + curr.scopeThreeEmissions,
        };
      },
      {
        scopeOneEmissions: 0,
        scopeTwoEmissions: 0,
        scopeThreeEmissions: 0,
      }
    );
  };

  const deleteModule = (index) => {
    const data = [...bauProps];
    data.splice(index, 1);
    setBauProps([...data]);
  };

  const setModule = () => {
    setModuleError(null);
    const data = [...bauProps];
    if (!bauModule) {
      setModuleError({
        statusText: "Please Select Module First",
        data: { error: { message: "" } },
      });
      return;
    }
    if (bauModule.link?.includes("miscellaneous-decarbonization")) {
      const levers = data.filter((lever) =>
        lever.link?.includes("miscellaneous-decarbonization")
      );

      let module = { ...bauModule };

      if (levers.length) {
        const lever = levers.at(-1);
        module["link"] = `miscellaneous-decarbonization-${
          Number(lever.link.substring(30)) + 1
        }`;
        module["type"] = `miscellaneous-decarbonization-${
          Number(lever.link.substring(30)) + 1
        }`;
        module["title"] = `Other External Measures - ${levers.length + 1}`;
      }

      data.push(module);
      setBauProps([...data]);
      setBauModule("");
    } else {
      const index = data.findIndex((value) => value.link === bauModule.link);
      if (index > -1) {
        setModuleError({
          statusText: "Module Already Exist",
          data: { error: { message: "" } },
        });
      } else {
        data.push(bauModule);
        setBauProps([...data]);
        setBauModule("");
      }
    }
  };

  useEffect(() => {
    if (show) {
      setError(null);
      const bottomUpSummaryPromise = get(
        `organizations/${organizationId}/sites-summary`,
        {
          params: {
            "filter[preference]": scopeTwoPreference,
          },
        }
      );
      bottomUpSummaryPromise.promise
        .then((response) => {
          setError(null);
          setSites(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
          }
        });

      subscribedPromises.current.push(bottomUpSummaryPromise);
    }
  }, [scopeTwoPreference, organizationId, show]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    if (show) {
      setError(null);
      setModuleError(null);
      setBauModule("");
      setShowBauDropdown(false);
      setIsEditing(false);
      setBauProps(
        emissions?.forecastingConfig
          ? [
              ...JSON.parse(emissions?.forecastingConfig).map((val) => {
                if (val.title === "Decard of Grid Electricity") {
                  val.title = "Decarb of Grid Electricity";
                }

                return val;
              }),
            ]
          : [...defaultbauProp]
      );
    }
  }, [show, emissions?.forecastingConfig]);

  return (
    <>
      <Button
        size="sm"
        variant="outline-primary"
        onClick={handleShow}
        className="py-0"
      >
        Manage Forecasting
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom" closeButton>
          <Modal.Title className="fs-4">Manage Forecasting</Modal.Title>
        </Modal.Header>

        {error && <ErrorHandler error={error} />}
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateEmissions(values)}
          initialValues={{
            emissionsScopeOne: emissions?.emissionsScopeOne || "",
            emissionsScopeTwo: emissions?.emissionsScopeTwo || "",
            emissionsScopeThree: emissions?.emissionsScopeThree || "",
            limitReductionPercentage: emissions?.limitReductionPercentage || 50,
            year: emissions?.baselineYear || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={step}
                  onSelect={(k) => setStep(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="step-1"
                    title={
                      <>
                        Define Start Year{" "}
                        {(!values.year ||
                          (errors.year && touched.year) ||
                          !values.emissionsScopeOne ||
                          (errors.emissionsScopeOne &&
                            touched.emissionsScopeOne) ||
                          !values.emissionsScopeTwo ||
                          (errors.emissionsScopeTwo &&
                            touched.emissionsScopeTwo)) && (
                          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        )}
                      </>
                    }
                  >
                    <Form.Group controlId="activity-year" className="mb-3">
                      <Form.Label>
                        Start Year{" "}
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Field
                        as={Form.Select}
                        name="year"
                        value={values.year}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.year}
                        isInvalid={errors.year && touched.year}
                      >
                        <option>Select year</option>
                        {[
                          "2023",
                          "2022",
                          "2021",
                          "2020",
                          "2019",
                          "2018",
                          "2017",
                          "2016",
                          "2015",
                          "2014",
                          "2013",
                          "2012",
                          "2011",
                          "2010",
                        ].map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                      <Form.Control.Feedback type="invalid">
                        {errors.year}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {!isNaN(Number(values.year)) && !!sites && !!sites.length && (
                      <div className="bg-light px-2 py-2 border rounded-1 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">
                            Estimated emissions{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>{" "}
                            from Bottom-Up Analysis{" "}
                            <span className="text-muted">optional</span>
                          </p>
                          <div className="d-inline-flex align-items-center mx-1 align-center">
                            <ScopeTwoPreferenceToggler
                              scopeTwoPreference={scopeTwoPreference}
                              setScopeTwoPreference={setScopeTwoPreference}
                            />
                          </div>
                        </div>
                        <hr />
                        {sites.length > 0 &&
                        filterSitesByYear(sites, values.year).length > 0 ? (
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th scope="col">Year</th>
                                <th scope="col">Scope 1</th>
                                <th scope="col">Scope 2</th>
                                <th scope="col">Scope 3</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                <tr>
                                  <th scope="row">{values.year}</th>
                                  <td>
                                    {isNaN(
                                      Number(
                                        sumOfScopes(sites, values.year)
                                          .scopeOneEmissions
                                      )
                                    )
                                      ? "n/a"
                                      : new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 3,
                                        }).format(
                                          sumOfScopes(sites, values.year)
                                            .scopeOneEmissions
                                        )}
                                  </td>
                                  <td>
                                    {isNaN(
                                      Number(
                                        sumOfScopes(sites, values.year)
                                          .scopeTwoEmissions
                                      )
                                    )
                                      ? "n/a"
                                      : new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 3,
                                        }).format(
                                          sumOfScopes(sites, values.year)
                                            .scopeTwoEmissions
                                        )}
                                  </td>
                                  <td>
                                    {isNaN(
                                      Number(
                                        sumOfScopes(sites, values.year)
                                          .scopeThreeEmissions
                                      )
                                    )
                                      ? "n/a"
                                      : new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 3,
                                        }).format(
                                          sumOfScopes(sites, values.year)
                                            .scopeThreeEmissions
                                        )}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      variant="outline-dark"
                                      className="py-0 float-end"
                                      onClick={() => {
                                        setFieldValue(
                                          "emissionsScopeOne",
                                          sumOfScopes(sites, values.year)
                                            .scopeOneEmissions,
                                          true
                                        );

                                        setFieldValue(
                                          "emissionsScopeTwo",
                                          sumOfScopes(sites, values.year)
                                            .scopeTwoEmissions,
                                          true
                                        );

                                        setFieldValue(
                                          "emissionsScopeThree",
                                          sumOfScopes(sites, values.year)
                                            .scopeThreeEmissions,
                                          true
                                        );

                                        setTimeout(() => {
                                          setFieldTouched(
                                            "emissionsScopeOne",
                                            true
                                          );
                                          setFieldTouched(
                                            "emissionsScopeTwo",
                                            true
                                          );
                                          setFieldTouched(
                                            "emissionsScopeThree",
                                            true
                                          );
                                        }, 100);
                                      }}
                                    >
                                      use values
                                    </Button>
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        ) : (
                          <p>No scope data available</p>
                        )}
                      </div>
                    )}
                    <Form.Group controlId="emissionsScopeOne" className="mb-2">
                      <Form.Label>
                        Emissions scope 1{" "}
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="emissionsScopeOne"
                        size="sm"
                        value={values.emissionsScopeOne}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("emissionsScopeOne", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.emissionsScopeOne}
                        isInvalid={
                          errors.emissionsScopeOne && touched.emissionsScopeOne
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionsScopeOne}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="emissionsScopeTwo" className="my-2">
                      <Form.Label>
                        Emissions scope 2{" "}
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="emissionsScopeTwo"
                        size="sm"
                        value={values.emissionsScopeTwo}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("emissionsScopeTwo", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.emissionsScopeTwo}
                        isInvalid={
                          errors.emissionsScopeTwo && touched.emissionsScopeTwo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionsScopeTwo}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="emissionsScopeThree"
                      className="my-2"
                    >
                      <Form.Label>
                        Emissions scope 3{" "}
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                      </Form.Label>
                      <NumberFormat
                        name="emissionsScopeThree"
                        size="sm"
                        value={values.emissionsScopeThree}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue(
                            "emissionsScopeThree",
                            numberItem.value
                          );
                        }}
                        onBlur={handleBlur}
                        isValid={values.emissionsScopeThree}
                        isInvalid={
                          errors.emissionsScopeThree &&
                          touched.emissionsScopeThree
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionsScopeThree}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Tab>
                  <Tab eventKey="step-2" title="Define BAU">
                    <div className="d-flex flex-row pt-2 pb-4 px-1">
                      <h3 className="flex-fill pt-2 text-bold">
                        Define business-as-usual (BAU)
                      </h3>
                      <div>
                        <Button
                          className="rounded-0"
                          onClick={() => {
                            setShowBauDropdown((prev) => !prev);
                            setModuleError(false);
                          }}
                        >
                          Decarb Levers
                        </Button>
                      </div>
                    </div>
                    <div>
                      {showBauDropdown && (
                        <>
                          <InputGroup className="mb-3">
                            <Form.Select
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value !== "") {
                                  value = JSON.parse(value);
                                }
                                setBauModule(value);
                              }}
                              value={JSON.stringify(bauModule)}
                            >
                              <option value="">
                                Select external decarb lever
                              </option>
                              {bauDropdownProp.map((bauModule) => (
                                <option
                                  value={JSON.stringify(bauModule)}
                                  key={bauModule.link}
                                >
                                  {bauModule.title}
                                </option>
                              ))}
                            </Form.Select>
                            <Button onClick={setModule}>Add</Button>
                            <Button
                              variant="outline-primary"
                              onClick={() => {
                                setShowBauDropdown((prev) => !prev);
                                setModuleError(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </InputGroup>
                          {moduleError && <ErrorHandler error={moduleError} />}
                        </>
                      )}
                    </div>
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>Emissions Driver</th>
                          <th className="text-center">Scope 1</th>
                          <th className="text-center">Scope 2</th>
                          <th className="text-center">Scope 3</th>
                          {/* <th className="text-center">
                            Included in the Forecasting?
                          </th> */}
                          <th className="text-center">Part of BAU?</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {bauProps.map(
                          (
                            {
                              title,
                              link,
                              scopeOne,
                              scopeTwo,
                              scopeThree,
                              isInForecasting,
                              isInBau,
                            },
                            index
                          ) => (
                            <tr key={index}>
                              <td className="text-start text-nowrap fs-5">
                                {index !== isEditing && (
                                  <>
                                    <Link to={link} onClick={handleClose}>
                                      {title}
                                    </Link>
                                  </>
                                )}
                                {isEditing === index && (
                                  <div className="d-flex">
                                    <Form.Control
                                      size="sm"
                                      type="text"
                                      name="miscLeverName"
                                      defaultValue={title}
                                      placeholder="Enter lever name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={values.miscLeverName === ""}
                                    />
                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        if (values.miscLeverName !== "") {
                                          bauProps[index].title =
                                            values.miscLeverName;
                                          setBauProps([...bauProps]);
                                          setIsEditing(false);
                                        }
                                      }}
                                    >
                                      <span className="material-icons-outlined md-18">
                                        check_circle
                                      </span>
                                    </Button>
                                    <Button
                                      size="sm"
                                      variant="light"
                                      onClick={() => setIsEditing(false)}
                                    >
                                      <span className="material-icons-outlined md-18">
                                        cancel
                                      </span>
                                    </Button>
                                  </div>
                                )}
                              </td>
                              <td className="text-center">
                                {scopeOne ? (
                                  <span className="material-icons-outlined md-18">
                                    check
                                  </span>
                                ) : link.includes(
                                    "miscellaneous-decarbonization"
                                  ) ? (
                                  <span className="material-icons-outlined md-18">
                                    close
                                  </span>
                                ) : (
                                  <span className="text-muted">n/a</span>
                                )}
                              </td>
                              <td className="text-center">
                                {scopeTwo ? (
                                  <span className="material-icons-outlined md-18">
                                    check
                                  </span>
                                ) : link.includes(
                                    "miscellaneous-decarbonization"
                                  ) ? (
                                  <span className="material-icons-outlined md-18">
                                    close
                                  </span>
                                ) : (
                                  <span className="text-muted">n/a</span>
                                )}
                              </td>
                              <td className="text-center">
                                {link.includes(
                                  "miscellaneous-decarbonization"
                                ) ? (
                                  scopeThree ? (
                                    <span className="material-icons-outlined md-18">
                                      check
                                    </span>
                                  ) : (
                                    <span className="material-icons-outlined md-18">
                                      close
                                    </span>
                                  )
                                ) : (
                                  <span className="material-icons-outlined md-18">
                                    check
                                  </span>
                                )}
                              </td>
                              <td className="text-center">
                                {isInBau === "n/a" ? (
                                  <span className="text-muted">n/a</span>
                                ) : (
                                  <div key={"default-checkbox"}>
                                    <Form.Check
                                      type={"checkbox"}
                                      id={"default-checkbox"}
                                      disabled={
                                        title ===
                                        "Growth in Production/Turnover"
                                      }
                                      checked={isInBau}
                                      name={title}
                                      onChange={(e) => {
                                        bauProps[index].isInBau =
                                          e.target.checked;
                                        setBauProps([...bauProps]);
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className="text-end">
                                <ButtonGroup aria-label="First group" size="sm">
                                  {link.includes(
                                    "miscellaneous-decarbonization"
                                  ) && (
                                    <Button
                                      size="sm"
                                      variant="outline-primary"
                                      className="py-0"
                                      onClick={() => setIsEditing(index)}
                                    >
                                      <span className="material-icons-outlined md-18">
                                        edit
                                      </span>
                                    </Button>
                                  )}
                                  {title !==
                                    "Growth in Production/Turnover" && (
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      className="py-0"
                                      onClick={() => deleteModule(index)}
                                    >
                                      <span className="material-icons-outlined md-18">
                                        delete
                                      </span>
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose} variant="link">
                  Close
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  className="float-end ms-2"
                  disabled={isLoading || !isValid}
                >
                  {isLoading && (
                    <Spinner animation="border" size="sm" className="me-2" />
                  )}
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ManageEmissions.propTypes = {
  onManageEmissionsUpdated: PropTypes.func.isRequired,
};

export default ManageEmissions;
