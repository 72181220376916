import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import PortfolioDelete from "../PortfolioDelete/PortfolioDelete";
import PortfolioUpdate from "../PortfolioUpdate/PortfolioUpdate";

import { getFormattedEmission } from "utils/StringUtils";

import "./PortfolioList.scss";

const PortfolioList = ({ portfolios, fetchPortfolios }) => {
  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "totalFinancedEmissions",
    totalFinancedEmissions: true,
    sortAsc: false,
  });

  useEffect(() => {
    const data = portfolios?.map((portfolio) => {
      return {
        ...portfolio,
        ...{
          numberOfOrganizations: portfolio?.organizationsCount,
          percentFinancedEmissions: portfolio?.financedEmissionsPercentage,
        },
      };
    });
    data?.sort((a, b) => b.financedEmissions - a.financedEmissions);
    setTableData(data);
  }, [portfolios]);

  const sortTable = (column) => {
    tableData?.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  return (
    <Table hover size="sm" className="my-3 first-column-fixed" responsive>
      <thead>
        <tr>
          <th className="table-sorter" onClick={() => sortTable("name")}>
            Name
            {tableSortState.sortColumn === "name" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("numberOfOrganizations")}
          >
            Number of <br />
            Organizations
            {tableSortState.sortColumn === "numberOfOrganizations" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("topDownEmissions")}
          >
            Top-Down <br /> Estimation <br />
            <small>
              (tCO<sub>2</sub>e)
            </small>
            {tableSortState.sortColumn === "topDownEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("bottomUpEmissions")}
          >
            Bottom-Up GHG <br /> Inventory{" "}
            <small>
              (tCO<sub>2</sub>e)
            </small>
            {tableSortState.sortColumn === "bottomUpEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("financedEmissions")}
          >
            Financed
            <br /> Emissions
            <small>
              (tCO<sub>2</sub>e)
            </small>
            {tableSortState.sortColumn === "financedEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("totalFinancedEmissions")}
          >
            % of Total <br />
            Financed
            <br />
            Emissions
            {tableSortState.sortColumn === "totalFinancedEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th className="table-sorter" onClick={() => sortTable("updatedAt")}>
            Updated At
            {tableSortState.sortColumn === "updatedAt" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((portfolio) => {
          return (
            <tr key={portfolio.id}>
              <td
                className="first-column text-truncate"
                title={portfolio?.name}
              >
                <Link
                  className="text-decoration-none"
                  to={`/portfolios/${portfolio.id}`}
                >
                  {portfolio.name}
                </Link>
              </td>
              <td className="text-end">{portfolio.numberOfOrganizations}</td>
              <td className="text-end">
                {getFormattedEmission(portfolio.topDownEmissions || 0)}
              </td>
              <td className="text-end">
                {getFormattedEmission(portfolio.bottomUpEmissions)}
              </td>

              <td className="text-end">
                {getFormattedEmission(portfolio.financedEmissions)}
              </td>
              <td className="text-end">
                {portfolio.percentFinancedEmissions
                  ? Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(portfolio.percentFinancedEmissions)
                  : 0}
                %
              </td>
              <td className="ps-3">
                <small>
                  {new Date(portfolio.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </td>

              <td className="text-end">
                <PortfolioUpdate
                  portfolio={portfolio}
                  onPortfolioUpdated={() => fetchPortfolios()}
                />{" "}
                <PortfolioDelete
                  portfolio={portfolio}
                  onPortfolioDeleted={() => fetchPortfolios()}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

PortfolioList.propTypes = {
  portfolios: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchPortfolios: PropTypes.func.isRequired,
};

export default PortfolioList;
