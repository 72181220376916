import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";

import { get, put } from "utils/BeeApi";

import { UserContext } from "contexts/UserProvider";

const ContentContext = createContext();

export const useContentContext = () => {
  return useContext(ContentContext);
};

const ContentProvider = ({ children }) => {
  const subscribedPromises = useRef([]);

  const user = useContext(UserContext);

  const [bookmarks, setBookmarks] = useState();

  const [contents, setContents] = useState([]);

  //First Id is for sandbox second is for production
  const contentGroupId = ["64cc0923eabf3", "62a0c2bbe0861"];

  const filteredContent = contents
    ?.filter((filterContent) =>
      contentGroupId.includes(filterContent?.categories[0]?.groups[0]?.groupId)
    )
    .slice(0, 3);

  const allFilteredContent = contents?.filter((filterContent) =>
    contentGroupId.includes(filterContent?.categories[0]?.groups[0]?.groupId)
  );

  const bookmarkableIds = bookmarks?.map(
    ({ bookmarkableId }) => bookmarkableId
  );

  const notification = filteredContent?.filter(
    (item) => !bookmarkableIds?.includes(item.contentId)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetchContents = useCallback(() => {
    setError(null);
    setIsLoading(true);
    let getContents = get(`contents`);

    getContents.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setContents(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(getContents);
  }, []);

  const fetchBookmarks = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const bookmarks = get(`user/${user?.userId}/bookmarks`);

    bookmarks.promise
      .then((response) => {
        setBookmarks(response.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(bookmarks);
  }, [user?.userId]);

  const markBookmark = useCallback(
    (contentId) => {
      setError(null);

      const bookmarks = put(`user/${user?.userId}/bookmarks`, {
        bookmarkableId: contentId,
      });

      bookmarks.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          fetchBookmarks();
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(bookmarks);
    },
    [user?.userId, fetchBookmarks]
  );

  useEffect(() => {
    if (user) {
      fetchContents();
      fetchBookmarks();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchContents, fetchBookmarks]);

  const contextValue = {
    contents,
    filteredContent,
    allFilteredContent,
    bookmarkableIds,
    bookmarks,
    notification,
    isLoading,
    error,
    fetchBookmarks,
    markBookmark,
    fetchContents,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;
