import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageS2Reductions from "./ManageS2Reductions/ManageS2Reductions";
import GridDecarbonizationChart from "./GridDecarbonizationChart/GridDecarbonizationChart";
import "./GridDecarbonization.scss";
import { formatNumber, formatPercentage } from "../helper";
import {
  getEconomicGrowth,
  getGridDecarbonization,
} from "../forecastingModels";

import UserCategories from "utils/userCategories";

const GridDecarbonization = ({
  organization,
  breadcrumbs,
  baselineYear,
  scopeOne,
  scopeTwo,
  scopeThree,
}) => {
  const [emission, setEmission] = useState([]);
  const [growthEmission, setGrowthEmission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [scopeTwoReduction, setScopeTwoReduction] = useState(100);

  const [scopeThreeReduction, setScopeThreeReduction] = useState(100);
  const [scopeThreeReductionGrowth, setScopeThreeReductionGrowth] =
    useState(100);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const userCategory = UserCategories();

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );
      const organizationPromise = get(
        `organizations/${organizationId}/grid-decarbonization`
      );
      Promise.all([economicGrowthPromise.promise, organizationPromise.promise])
        .then((responses) => {
          setIsLoading(false);

          const [{ data: economicGrowth = [] }, { data: gridDecarb = [] }] =
            responses || [];

          setScopeTwoReduction(gridDecarb[0]?.scopeTwoEmissionPercentage || 0);

          setScopeThreeReduction(
            gridDecarb[0]?.scopeThreeEmissionPercentage || 0
          );

          setEmission({
            ...gridDecarb[0],
          });

          setScopeThreeReductionGrowth(
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );

          calculateDecarb(
            { ...gridDecarb[0] },
            economicGrowth[0] ? economicGrowth[0]?.data : [],
            gridDecarb[0] ? gridDecarb[0]?.data : [],
            gridDecarb[0]?.scopeTwoEmissionPercentage || 0,
            gridDecarb[0]?.scopeThreeEmissionPercentage || 0,
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(
        organizationPromise,
        economicGrowthPromise
      );
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    scopeTwo,
    emission?.scopeTwoEmissionPercentage,
    emission?.scopeThreeEmissionPercentage,
  ]);

  const calculateDecarb = (
    emissions = {},
    economicGrowthData = [],
    gridDecarbData = [],
    scopeTwoPercentage = 100,
    scopeThreePercentage = 100,
    scopeThreePercentageGrowth = 100
  ) => {
    const economicGrowthChunk = getEconomicGrowth(
      economicGrowthData,
      scopeOne,
      scopeTwo,
      scopeThree,
      scopeThreePercentageGrowth
    );

    const gridDecarbChunk = getGridDecarbonization(
      gridDecarbData,
      economicGrowthChunk,
      scopeTwoPercentage,
      scopeThreePercentage
    );

    setGrowthEmission(economicGrowthChunk);

    setEmission({
      ...emissions,
      data: gridDecarbChunk,
    });
  };

  return (
    <div id={userCategory}>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: "Grid Decarbonization",
              link: `/organizations/${organization.id}/forecasting/3/grid-decarbonization`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">Decarb of Grid Electricity</h2>
      <div className="my-3 p-3 border">
        <h3>
          Emissions Forecast
          <ManageS2Reductions
            onManageS2ReductionsUpdated={(data) =>
              setEmission({
                ...data,
                data: getGridDecarbonization(
                  data.data,
                  growthEmission,
                  scopeTwoReduction,
                  scopeThreeReduction
                ),
              })
            }
            emissions={emission}
            baselineYear={baselineYear}
          />
        </h3>
        {!isLoading && !error && (
          <GridDecarbonizationChart data={emission?.data} scopeTwo={scopeTwo} />
        )}
        {error && !isLoading && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
      </div>
      <Table
        size="sm"
        striped
        responsive
        className="my-3 mt-5 first-column-fixed"
      >
        <thead>
          <tr>
            <th className="text-end text-nowrap px-3 year-column">Year</th>
            <th className="text-end text-nowrap px-3">
              % Reduction in Grid Electricity Factor
            </th>
            <th className="text-end text-nowrap px-3">
              Cumulative % Reduction
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 2 Reduction{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 2 Emissions
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 3 Reduction{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 3 Emissions
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
          </tr>
        </thead>
        <tbody>
          {emission?.data?.map(
            (
              {
                year,
                cumulativeReduction,
                s2_reduction_percent,
                s2_reduction,
                s2_emission,
                s3_reduction,
                s3_emission,
                emission,
              },
              index
            ) => (
              <tr key={index}>
                <td className="text-end text-nowrap px-3 year-column">
                  {year}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatPercentage(s2_reduction_percent)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatPercentage(cumulativeReduction)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s2_reduction, 2)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s2_emission, 2)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s3_reduction, 2)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s3_emission, 2)}
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
      {!error && !isLoading && !emission?.id && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"62e185b35099e"} view={"full"} />
      </div>
    </div>
  );
};

GridDecarbonization.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  baselineYear: PropTypes.number,
  scopeTwo: PropTypes.number,
};

export default GridDecarbonization;
