import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./AbatementActions.scss";
// import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get, put, post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import CreatableSelect from "react-select/creatable";

const AbatementActions = ({ organization }) => {
  const [actions, setActions] = useState([]);
  const [orgActions, setOrgActions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orgBucketsList, setOrgBucketsList] = useState([]);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  useEffect(() => {
    const fetchAbatementActions = () => {
      setError(null);
      setIsLoading(true);
      const decarbActionsPromise = get(`decarb-action-categories`);
      const organizationPromise = get(
        `organizations/${organizationId}/decarb-action-categories`
      );
      const bucketsPromise = get(
        `organizations/${organizationId}/opportunity-groups`
      );

      Promise.all([
        decarbActionsPromise.promise,
        organizationPromise.promise,
        bucketsPromise.promise,
      ])
        .then((responses) => {
          setIsLoading(false);

          const [
            { data: decarbActions },
            { data: organizationdecarbActions },
            { data: buckets },
          ] = responses;
          setError(null);
          setActions(
            prepareTableData(
              decarbActions,
              organizationdecarbActions?.decarbActionCategories
            )
          );
          setOrgActions(organizationdecarbActions?.decarbActionCategories);
          setOrgBucketsList(
            buckets.map((value) => ({ value: value.id, label: value.name }))
          );
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(
        organizationPromise,
        decarbActionsPromise,
        bucketsPromise
      );
    };

    fetchAbatementActions();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId]);

  const prepareTableData = (decarbActions, orgDecarbActions) => {
    return decarbActions.map((row) => {
      return {
        ...row,
        decarbActionSubcategories: row?.decarbActionSubcategories?.map(
          (subAction) => {
            return {
              ...subAction,
              isChecked: !!orgDecarbActions?.find(
                (actions) => actions?.id === subAction?.id
              ),
              group:
                orgDecarbActions?.find((actions) => {
                  return actions?.id === subAction?.id;
                })?.group || subAction?.group,
            };
          }
        ),
      };
    });
  };

  const addAbatementActions = (id, cb) => {
    setError(null);
    cb(true);
    const organizationPromise = put(
      `organizations/${organization?.id}/decarb-action-categories/${id}`
    );
    organizationPromise.promise
      .then((response) => {
        cb(false);
        setError(null);
        setOrgActions(response?.data);
        setActions(prepareTableData(actions, response?.data));
      })
      .catch((error) => {
        if (!error.isCanceled) {
          cb(true);
          setError(error);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  const addAbatementBucket = (bucketName, id) => {
    setError(null);
    const organizationPromise = post(
      `organizations/${organization?.id}/opportunity-groups`,
      {
        name: bucketName,
      }
    );
    organizationPromise.promise
      .then((response) => {
        setOrgBucketsList((prev) => [
          ...prev,
          {
            label: response?.data?.name,
            value: response?.data?.id,
          },
        ]);
        attachBucketToAction(response?.data?.id, id);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  const attachBucketToAction = (bucketId, actionId, e) => {
    let url = "";
    if (bucketId && actionId) {
      url = `organizations/${organizationId}/categories/${actionId}/groups/${bucketId}`;
    } else if (e === null && !bucketId) {
      url = `organizations/${organizationId}/categories/${actionId}/groups`;
    }
    setError(null);
    const organizationPromise = put(url);

    organizationPromise.promise
      .then((response) => {
        setError(null);
        setActions(
          prepareTableData(actions, response?.data?.decarbActionCategories)
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  return (
    <div>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            {
              name: organization.name,
              link: `/organizations/${organization.id}`,
            },
            {
              name: "Abatement Opportunities",
              link: `/organizations/${organization.id}/abatement-actions`,
              active: true,
            },
          ]}
        />
      </div>
      <h2>Abatement Opportunities</h2>
      <hr />
      {isLoading && <Loader />}
      {error && <ErrorHandler error={error} />}
      {!isLoading && !error && (
        <Table size="sm" responsive className="my-3">
          <thead>
            <tr>
              <th className="w-100 px-3 fs-4 ">Abatement Opportunities</th>
              <th className="px-3 fs-4">Applicable?</th>
              <th className="fs-4" style={{ minWidth: "208px" }}>
                Opportunity Grouping
              </th>
            </tr>
          </thead>
          <tbody>
            {actions.map((row, index) => (
              <ExpandableTableRow
                key={`${row?.id + index}`}
                data={row}
                addAbatementActions={(id, cb) => addAbatementActions(id, cb)}
                addAbatementBucket={(name, id) => addAbatementBucket(name, id)}
                attachBucketToAction={(bucketId, actionId, e) =>
                  attachBucketToAction(bucketId, actionId, e)
                }
                orgBucketsList={orgBucketsList}
              />
            ))}
          </tbody>
        </Table>
      )}
      {/* 
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"625daa9813bea"} view={"full"} />
      </div> */}
    </div>
  );
};

const ExpandableTableRow = ({
  data,
  addAbatementActions,
  addAbatementBucket,
  attachBucketToAction,
  orgBucketsList,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <tr
        role="button"
        className={` ${expanded ? "expanded" : ""}`}
        onClick={toggleExpand}
      >
        <td colSpan={3} className="w-100 fs-4 px-3">
          <span className="material-icons-outlined me-2 md-16 text-muted">
            {expanded ? "remove" : "add"}
          </span>
          {data?.name}
        </td>
      </tr>
      {expanded ? (
        data?.decarbActionSubcategories?.length ? (
          data?.decarbActionSubcategories?.map((row) => {
            return (
              <tr className="expanded-row" key={row?.id}>
                <td className="align-middle w-100 px-3 ps-5">{row?.name}</td>
                <td className="px-3">
                  <div>
                    <CustomToggleSwitch
                      onChange={(cb) => addAbatementActions(row?.id, cb)}
                      isChecked={row?.isChecked}
                    />
                  </div>
                </td>
                <td>
                  {row?.isChecked && (
                    <CreatableSelect
                      placeholder="Select or Create"
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          width: "200px",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary75: "#E6F2FE",
                          primary50: "#E6F2FE",
                          primary25: "#E6F2FE",
                          primary: "#007A5F",
                        },
                      })}
                      onChange={(e) =>
                        attachBucketToAction(e?.value, row?.id, e)
                      }
                      onCreateOption={(name) =>
                        addAbatementBucket(name, row?.id)
                      }
                      value={{ value: row?.group?.id, label: row?.group?.name }}
                      isClearable
                      options={orgBucketsList}
                    />
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr className="expanded-row">
            <td className="align-middle w-100 text-center" colSpan={3}>
              No Sub-Actions Available for {data?.name}
            </td>
          </tr>
        )
      ) : null}
    </>
  );
};

const CustomToggleSwitch = ({ onChange, isChecked }) => {
  const [isToggling, setIsToggling] = useState(false);
  const toggleSwitch = () => {
    if (!isToggling) {
      onChange(setIsToggling);
    }
  };

  return (
    <div
      className={`custom-toggle-switch ${isChecked ? "checked" : ""}  ${
        isToggling ? "disabled" : ""
      }`}
      onClick={toggleSwitch}
    >
      <div className="toggle-background">
        <div className="toggle-button" />
      </div>
      <span className="toggle-label">{isChecked ? "Yes" : "No"}</span>
    </div>
  );
};

export default AbatementActions;
