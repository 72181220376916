import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import { useParams } from "react-router-dom";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import { checkIfIsInBau, unionByYear } from "../helper";
import {
  getBusinessAsUsual,
  getBusinessAsUsualHeatingFuels,
  getBusinessAsUsualMisc,
  getBusinessAsUsualTransport,
  getEconomicGrowth,
  getGridDecarbonizationBAU,
  getHeatingFuelsDecarbonizationBAU,
  getMergedSTBi,
  getMiscDecarbonizationBAU,
  getTransportDecarbonizationBAU,
} from "../forecastingModels";
import BusinessAsUsualChart from "./BusinessAsUsualChart/BusinessAsUsualChart";
import UserCategories from "utils/userCategories";
import { Alert, Dropdown } from "react-bootstrap";

const ForecastingOverview = ({
  isShow,
  organization,
  breadcrumbs,
  scopeOne,
  scopeTwo,
  scopeThree,
  baselineYear,
  forecastingConfig,
  onChartRendered,
  targetScopeOne,
  targetScopeTwo,
  targetScopeThree,
  targetBaselineYear,
  sbtiConfig,
}) => {
  const [bau, setBau] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [selectedScope, setSelectedScope] = useState("Scope 1+2+3");
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();
  const [economicGrowth, setEconomicGrowth] = useState([]);
  const [
    economicGrowthEmissionPercentage,
    setEconomicGrowthEmissionPercentage,
  ] = useState(100);
  const [
    gridDecarbonizationEmissionPercentage,
    setGridDecarbonizationEmissionPercentage,
  ] = useState(100);
  const [
    gridDecarbonizationEmissionPercentageThree,
    setGridDecarbonizationEmissionPercentageThree,
  ] = useState(100);
  const [
    heatingFuelDecarbEmissionPercentage,
    setHeatingFuelDecarbEmissionPercentage,
  ] = useState(100);
  const [
    heatingFuelDecarbEmissionPercentageThree,
    setHeatingFuelDecarbEmissionPercentageThree,
  ] = useState(100);
  const [
    transportDecarbEmissionPercentage,
    setTransportDecarbEmissionPercentage,
  ] = useState(100);
  const [
    transportDecarbEmissionPercentageThree,
    setTransportDecarbEmissionPercentageThree,
  ] = useState(100);
  const [gridDecarbonization, setGridDecarbonization] = useState([]);
  const [scienceBasedTarget, setScienceBasedTarget] = useState({});
  const [decarbOfHeatingFuels, setDecarbOfHeatingFuels] = useState([]);
  const [decarbOfTransport, setDecarbOfTransport] = useState([]);
  const [miscDecarb, setMiscDecarb] = useState([]);

  const userCategory = UserCategories();

  const memoizedConfig = useMemo(() => {
    if (forecastingConfig) {
      return JSON.parse(forecastingConfig);
    } else {
      return [];
    }
  }, [forecastingConfig]);

  const memoizedSBTiConfig = useMemo(() => {
    if (sbtiConfig) {
      return JSON.parse(sbtiConfig);
    } else {
      return [];
    }
  }, [sbtiConfig]);

  useEffect(() => {
    function fetchMiscLever(organizationId) {
      let arr = [];
      if (memoizedConfig.length) {
        const config = memoizedConfig.filter((lever) => lever.type);
        config.forEach((lever) => {
          arr = [
            ...arr,
            get(
              `organizations/${organizationId}/miscellaneous-decarbonization`,
              { params: { type: lever.type } }
            ),
          ];
        });

        if (arr.length) {
          Promise.all(arr.map((promise) => promise.promise))
            .then((responses) => {
              const decarb = responses.filter(
                (response) => response.data.length
              );
              setMiscDecarb(decarb);
              setIsLoading(false);
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setIsLoading(false);
        }
      }
      subscribedPromises.current.push(...arr);
    }
    function fetchForecasting(organizationId) {
      setError(null);
      setIsLoading(true);

      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );

      const gridDecarbonizationPromise = get(
        `organizations/${organizationId}/grid-decarbonization`
      );

      const SBTiPromise = get(
        `organizations/${organizationId}/science-based-target`
      );

      const decarbOfHeatingFuelsPromise = get(
        `organizations/${organizationId}/heating-fuels-decarbonization`
      );

      const decarbOfTransportPromise = get(
        `organizations/${organizationId}/transport-decarbonization`
      );

      Promise.all([
        economicGrowthPromise.promise,
        gridDecarbonizationPromise.promise,
        SBTiPromise.promise,
        decarbOfHeatingFuelsPromise.promise,
        decarbOfTransportPromise.promise,
      ])
        .then((responses) => {
          const [
            { data: economicGrowth = [] },
            { data: gridDecarb = [] },
            { data: scienceBasedTarget = [] },
            { data: decarbOfHeatingFuels = [] },
            { data: decarbOfTransport = [] },
          ] = responses || [];

          setEconomicGrowth(economicGrowth[0] ? economicGrowth[0]?.data : []);
          setEconomicGrowthEmissionPercentage(
            economicGrowth[0]
              ? economicGrowth[0]?.scopeThreeEmissionPercentage
              : []
          );
          setGridDecarbonization(gridDecarb[0] ? gridDecarb[0]?.data : []);
          setGridDecarbonizationEmissionPercentage(
            gridDecarb[0] ? gridDecarb[0]?.scopeTwoEmissionPercentage : []
          );
          setGridDecarbonizationEmissionPercentageThree(
            gridDecarb[0] ? gridDecarb[0]?.scopeThreeEmissionPercentage : []
          );
          setDecarbOfHeatingFuels(
            decarbOfHeatingFuels[0] ? decarbOfHeatingFuels[0]?.data : []
          );
          setHeatingFuelDecarbEmissionPercentage(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeOneEmissionPercentage
              : []
          );
          setHeatingFuelDecarbEmissionPercentageThree(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeThreeEmissionPercentage
              : []
          );
          setDecarbOfTransport(
            decarbOfTransport[0] ? decarbOfTransport[0]?.data : []
          );
          setTransportDecarbEmissionPercentage(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeOneEmissionPercentage
              : []
          );
          setTransportDecarbEmissionPercentageThree(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeThreeEmissionPercentage
              : []
          );
          setScienceBasedTarget(
            scienceBasedTarget[0] ? scienceBasedTarget[0] : {}
          );
          fetchMiscLever(organizationId);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(
        economicGrowthPromise,
        gridDecarbonizationPromise,
        SBTiPromise,
        decarbOfHeatingFuelsPromise,
        decarbOfTransportPromise
      );
    }

    if (!!memoizedConfig?.length) {
      fetchForecasting(organizationId);
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId, memoizedConfig]);

  useEffect(() => {
    const _scopeOne =
      selectedScope === "Scope 2" || selectedScope === "Scope 3" ? 0 : scopeOne;
    const _scopeTwo =
      selectedScope === "Scope 1" || selectedScope === "Scope 3" ? 0 : scopeTwo;
    const _scopeThree =
      selectedScope === "Scope 1+2" ||
      selectedScope === "Scope 1" ||
      selectedScope === "Scope 2"
        ? 0
        : scopeThree;
    const _targetScopeOne =
      selectedScope === "Scope 2" || selectedScope === "Scope 3"
        ? 0
        : targetScopeOne;
    const _targetScopeTwo =
      selectedScope === "Scope 1" || selectedScope === "Scope 3"
        ? 0
        : targetScopeTwo;
    const _targetScopeThree =
      selectedScope === "Scope 1+2" ||
      selectedScope === "Scope 1" ||
      selectedScope === "Scope 2"
        ? 0
        : targetScopeThree;

    const economicGrowthChunk = getEconomicGrowth(
      economicGrowth,
      _scopeOne,
      _scopeTwo,
      _scopeThree,
      economicGrowthEmissionPercentage
    );

    const gridDecarbChunk = getGridDecarbonizationBAU(
      gridDecarbonization,
      economicGrowthChunk,
      gridDecarbonizationEmissionPercentage,
      gridDecarbonizationEmissionPercentageThree
    );

    let scienceBasedTargetChunk = [];

    let sbtiEmissions = {};
    let sbtiEmissionsThree = {};

    sbtiEmissions = {
      limitReductionPercentage: scienceBasedTarget?.limitReductionPercentage,
      reductionPercentageOne: scienceBasedTarget?.reductionPercentageOne,
      reductionPercentageTwo: scienceBasedTarget?.reductionPercentageTwo,
      data: scienceBasedTarget?.data,
    };

    sbtiEmissionsThree = {
      limitReductionPercentage:
        scienceBasedTarget?.sopeThreeLimitReductionPercentage,
      reductionPercentageOne:
        scienceBasedTarget?.sopeThreeReductionPercentageOne,
      reductionPercentageTwo:
        scienceBasedTarget?.sopeThreeReductionPercentageTwo,
      data: scienceBasedTarget?.scopeThreeData,
    };

    const scienceBasedTargetChunkNotScopeThree = getMergedSTBi(
      sbtiEmissions,
      _targetScopeOne,
      _targetScopeTwo,
      0
    );

    const scienceBasedTargetChunkScopeThree = getMergedSTBi(
      sbtiEmissionsThree,
      0,
      0,
      _targetScopeThree
    );

    if (selectedScope === "Scope 1+2+3") {
      const targetSettingData = [];

      for (
        let index = 0;
        index < scienceBasedTargetChunkNotScopeThree.length;
        index++
      ) {
        const element = scienceBasedTargetChunkNotScopeThree[index];
        const elementThree = scienceBasedTargetChunkScopeThree[index];
        let obj = {};
        for (const key in element) {
          if (element.hasOwnProperty.call(element, key)) {
            const val = element[key];
            const val2 = elementThree[key];
            if (
              key !== "percentageReduction" &&
              key !== "percentageReduction25" &&
              key !== "year" &&
              key !== "year25"
            ) {
              obj[key] = val + val2;
            } else {
              obj[key] = val;
            }
          }
        }
        targetSettingData.push(obj);
      }
      scienceBasedTargetChunk = targetSettingData;
    } else if (selectedScope === "Scope 3") {
      scienceBasedTargetChunk = scienceBasedTargetChunkScopeThree;
    } else {
      scienceBasedTargetChunk = scienceBasedTargetChunkNotScopeThree;
    }

    const heatFuelChunk = getHeatingFuelsDecarbonizationBAU(
      decarbOfHeatingFuels,
      economicGrowthChunk,
      heatingFuelDecarbEmissionPercentage,
      heatingFuelDecarbEmissionPercentageThree
    );

    const transportChunk = getTransportDecarbonizationBAU(
      decarbOfTransport,
      economicGrowthChunk,
      transportDecarbEmissionPercentage,
      transportDecarbEmissionPercentageThree
    );

    const miscChunk = getMiscDecarbonizationBAU(
      miscDecarb,
      economicGrowthChunk
    );

    const getRecentYearValues = (arr) => {
      const startValue = Math.max(
        Number(targetBaselineYear),
        Number(baselineYear)
      );
      const startIndex = arr?.findIndex((val) => {
        return val.year === startValue;
      });
      if (startIndex !== -1) {
        return arr?.slice(startIndex);
      }
      return arr;
    };

    let reduction = [];

    setBau(
      getRecentYearValues(
        unionByYear(
          economicGrowthChunk,
          scienceBasedTargetChunk,
          getBusinessAsUsual(unionByYear(economicGrowthChunk, gridDecarbChunk)),
          getBusinessAsUsualTransport(
            checkIfIsInBau(memoizedConfig, "grid-decarbonization"),
            checkIfIsInBau(memoizedConfig, "heating-fuels-decarbonization"),
            unionByYear(
              economicGrowthChunk,
              gridDecarbChunk,
              heatFuelChunk.map((val) => ({
                year: val.year,
                scopeOneReductionHeatingFuel: val.scopeOneReduction,
              })),
              transportChunk
            )
          ),
          getBusinessAsUsualHeatingFuels(
            checkIfIsInBau(memoizedConfig, "grid-decarbonization"),
            unionByYear(economicGrowthChunk, gridDecarbChunk, heatFuelChunk)
          ),
          ...miscChunk.map((miscData, index) => {
            return getBusinessAsUsualMisc(
              unionByYear(
                economicGrowthChunk,
                gridDecarbChunk,
                heatFuelChunk.map((val) => ({
                  year: val.year,
                  scopeOneReductionHeatingFuel: val.scopeOneReduction,
                })),
                transportChunk.map((val) => ({
                  year: val.year,
                  scopeOneReductionTransport: val.scopeOneReduction,
                })),
                miscData.map((val, idx) => {
                  if (
                    reduction[idx] !== undefined &&
                    checkIfIsInBau(memoizedConfig, val.type)
                  ) {
                    reduction[idx] += val.reduction;
                  } else {
                    if (checkIfIsInBau(memoizedConfig, val.type)) {
                      reduction.push(val.reduction);
                    }
                  }
                  return {
                    year: val.year,
                    [`${val.type}-reduction`]: val[`${val.type}-reduction`],
                    reduction: reduction[idx],
                    type: val.type,
                  };
                })
              ),
              checkIfIsInBau(memoizedConfig, "grid-decarbonization"),
              checkIfIsInBau(memoizedConfig, "heating-fuels-decarbonization"),
              checkIfIsInBau(memoizedConfig, "transport-decarbonization")
            );
          })
        )
      )
    );
  }, [
    selectedScope,
    economicGrowth,
    scienceBasedTarget,
    gridDecarbonization,
    decarbOfHeatingFuels,
    decarbOfTransport,
    miscDecarb,
    memoizedConfig,
    scopeOne,
    scopeTwo,
    scopeThree,
    baselineYear,
    targetBaselineYear,
    targetScopeOne,
    targetScopeTwo,
    targetScopeThree,
    economicGrowthEmissionPercentage,
    gridDecarbonizationEmissionPercentage,
    gridDecarbonizationEmissionPercentageThree,
    heatingFuelDecarbEmissionPercentage,
    heatingFuelDecarbEmissionPercentageThree,
    transportDecarbEmissionPercentage,
    transportDecarbEmissionPercentageThree,
  ]);

  return (
    <div id={userCategory}>
      {isShow && (
        <div className="my-3">
          <BreadCrumbs
            breadcrumbs={[
              ...breadcrumbs,
              {
                name: "Forecasting",
                link: `/organizations/${organization.id}/forecasting`,
                active: true,
              },
            ]}
          />
        </div>
      )}
      {isShow && <h2 className="mb-4 pt-2">Forecasting Overview</h2>}
      {!!memoizedConfig?.length ? (
        <div className="my-3 border p-3">
          <div className="d-flex flex-row">
            <h3 className="flex-fill pt-2">
              {isShow ? "Overview" : "Forecasting Overview"}
            </h3>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  size="sm"
                  className="bg-primary bg-opacity-25 text-dark border-0"
                >
                  {selectedScope === "All"
                    ? "Filter by All Scopes"
                    : `Filter by ${selectedScope}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[
                    "Scope 1+2+3",
                    "Scope 1+2",
                    "Scope 1",
                    "Scope 2",
                    "Scope 3",
                  ].map((scope, index) => (
                    <Dropdown.Item
                      key={index}
                      className={selectedScope === scope ? "active" : ""}
                      onClick={() =>
                        setSelectedScope((prev) => {
                          if (prev === scope) return scope;

                          return scope;
                        })
                      }
                    >
                      {scope}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <hr />
          {isLoading && <Loader />}
          {!isLoading && !error && (
            <BusinessAsUsualChart
              data={bau}
              emission={scienceBasedTarget}
              selectedScope={selectedScope}
              forecastingConfig={memoizedConfig}
              onChartRendered={onChartRendered}
              sbtiConfig={memoizedSBTiConfig}
            />
          )}
        </div>
      ) : (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
    </div>
  );
};

export default ForecastingOverview;
