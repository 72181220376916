import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Field, FieldArray, Formik } from "formik";
import * as yup from "yup";
import { post, put } from "utils/DeApi";
import { useParams } from "react-router-dom";
import { isNegative } from "utils/numerals";

const MAX_YEAR = 2051;

const ManageReductions = ({
  emissions,
  onManageReductionsUpdated,
  baselineYear,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [year, setYear] = useState(2021);
  const { organizationId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    scopeThreeEmissionPercentage: yup
      .number()
      .label("Impacted Emissions")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`)
      .required(),
    revenue_increase: yup.array().of(
      yup.object().shape({
        year: yup
          .number()
          .label("Year")
          .min(0)
          .max(
            9999999999999,
            `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
          ),
        percentage_increase: yup
          .number()
          .label("Percentage Increase")
          .max(
            9999999999999,
            `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
          )
          .required(),
      })
    ),
  });

  const updateReduction = ({
    revenue_increase,
    scopeThreeEmissionPercentage,
  }) => {
    setError(null);
    setIsLoading(true);
    const data = {
      organization_id: organizationId,
      revenue_increase,
      scopeThreeEmissionPercentage: scopeThreeEmissionPercentage,
    };
    const organizationPromise = emissions?.id
      ? put(`economic-growth/${emissions?.id}`, data)
      : post(`organizations/${organizationId}/economic-growth`, data);
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onManageReductionsUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    setYear((prev) => {
      return !isNaN(Number(baselineYear)) ? baselineYear : prev;
    });

    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [baselineYear]);

  return (
    <>
      <Button size="sm" onClick={handleShow} className="float-end">
        Manage Projections
      </Button>
      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title className="fs-4">Manage Projections</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {error && <ErrorHandler error={error} />}
          <Formik
            validationSchema={schema}
            onSubmit={(values) => updateReduction(values)}
            enableReinitialize={true}
            initialValues={{
              scopeThreeEmissionPercentage: parseFloat(
                emissions?.scopeThreeEmissionPercentage || 0
              ),
              revenue_increase: [...Array(MAX_YEAR - parseInt(year) || 30)]
                .map((item, index) => ({
                  year: (parseInt(year) + index) % MAX_YEAR,
                  percentage_increase: 0,
                }))
                .map(({ year, percentage_increase }) => {
                  const data =
                    Array.isArray(emissions?.data) &&
                    emissions?.data.find(({ year: y }) => year === y);

                  return {
                    year: year,
                    percentage_increase: data
                      ? parseFloat(data.revenue_increase_percent || 0)
                      : 0,
                  };
                })
                .filter((r) => r.year !== year),
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} className="mb-5 pb-2">
                <Row className="mb-1">
                  <Col xs={12}>% Relative Change to Scope 3</Col>
                </Row>
                <Row>
                  <Form.Group
                    controlId={`scopeThreeEmissionPercentage`}
                    className="mb-2"
                    as={Col}
                    xs={12}
                  >
                    <Field
                      name={`scopeThreeEmissionPercentage`}
                      as={Form.Control}
                      type="number"
                      size="sm"
                      onChange={(ev) => {
                        const reduction = ev.target.valueAsNumber;
                        setFieldValue(
                          `scopeThreeEmissionPercentage`,
                          !isNaN(reduction) ? reduction : ""
                        );
                      }}
                      isValid={values.scopeThreeEmissionPercentage}
                      isInvalid={
                        errors.scopeThreeEmissionPercentage &&
                        touched.scopeThreeEmissionPercentage
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.scopeThreeEmissionPercentage}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-1">
                  <Col xs={4}>Years</Col>
                  <Col xs={8}>
                    % Change for Scope 1&2 due to Business Growth
                  </Col>
                </Row>
                <FieldArray
                  name="revenue_increase"
                  render={(arrayHelpers) => (
                    <>
                      {values.revenue_increase.map((r, index) => (
                        <Row key={index}>
                          <Form.Group
                            controlId={`revenue_increase[${index}].year`}
                            className="mb-2"
                            as={Col}
                            xs={4}
                          >
                            <Field
                              name={`revenue_increase[${index}].year`}
                              as={Form.Control}
                              type="number"
                              size="sm"
                              disabled={true}
                            />
                          </Form.Group>
                          <Form.Group
                            controlId={`revenue_increase[${index}].percentage_increase`}
                            className="mb-2"
                            as={Col}
                            xs={8}
                          >
                            <Field
                              type="number"
                              name={`revenue_increase[${index}].percentage_increase`}
                              as={Form.Control}
                              onChange={(ev) => {
                                const revenue = ev.target.valueAsNumber;
                                setFieldValue(
                                  `revenue_increase[${index}].percentage_increase`,
                                  !isNaN(revenue) ? revenue : ""
                                );

                                values.revenue_increase.forEach((item, ii) => {
                                  if (index < ii)
                                    setFieldValue(
                                      `revenue_increase[${ii}].percentage_increase`,
                                      !isNaN(revenue) ? revenue : ""
                                    );
                                });
                              }}
                              size="sm"
                            />
                          </Form.Group>
                        </Row>
                      ))}
                    </>
                  )}
                />
                <Col className="position-absolute fixed-bottom bg-light p-3">
                  <Button
                    size="sm"
                    type="submit"
                    className="float-end ms-2"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner animation="border" size="sm" className="me-2" />
                    )}
                    Save
                  </Button>
                  <Button
                    size="sm"
                    variant="link"
                    className="float-end"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageReductions.propTypes = {
  onManageReductionsUpdated: PropTypes.func.isRequired,
};

export default ManageReductions;
