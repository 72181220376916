import React, { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import {
  Modal,
  Button,
  Form,
  Tabs,
  Tab,
  Table,
  Collapse,
  Alert,
} from "react-bootstrap";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";

import { put } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import {
  locationsMap,
  projectCombinedStatusMap,
  projectCustomTypesMap,
  projectDataConfidenceMap,
  projectEvalutionStatusMap,
  projectImplementationStatusMap,
  projectStatusMap,
  projectTypesMap,
} from "../constants";
import { get } from "utils/DeApi";
import { useParams } from "react-router-dom";
import { keysToCamelCase } from "utils/objectUtils";

const AbatementProjectEdit = ({ sites, project, onAbatementProjectEdited }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const { organizationId } = useParams();

  const [autocomplete, setAutocomplete] = useState(null);
  const [cordinates, setCordinates] = useState({});
  const [locations, setLocations] = useState([]);
  const [libraries] = useState(["places"]);
  const [projectLocation, setProjectLocation] = useState("");
  const [emissions, setEmissions] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [step, setStep] = useState("step-1");
  const [isYearly, setIsYearly] = useState(false);
  const [startYear, setStartYear] = useState("");
  const [projectLifetime, setProjectLifetime] = useState("");
  const [yearlyEmissions, setYearlyEmissions] = useState([]);
  const [oppurtunityGroups, setOppurtunityGroups] = useState([]);
  const [isCustom, setIsCustom] = useState(false);
  const [useCustom, setUseCustom] = useState(false);
  const [annualCarbonEmissionReduction, setAnnualCarbonEmissionReduction] =
    useState();
  const [annualSavings, setAnnualSavings] = useState();
  const [customField, setCustomField] = useState({
    label: "",
    type: "",
    value: "",
  });
  const [customFields, setCustomFields] = useState([]);
  const [existingCustomFields, setExistingCustomFields] = useState([]);
  const [useCustomFields, setUseCustomFields] = useState({
    label: "",
    type: "",
    value: "",
  });

  const handleClose = () => {
    setShow(false);
    setIsCustom(false);
    setUseCustom(false);
    setIsYearly(false);
    setStep("step-1");
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    //General Info
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    location: yup
      .string()
      .min(1, "Location name should be more than 1 characters!")
      .max(255, "Location name is too long!")
      .required(),
    status: yup
      .number()
      .oneOf([1, 2, 3, 4, 5, 6, 7], "Status is a required field")
      .required(),
    // impStatus: yup.number(),
    oppurtunityGroup: yup.string(),
    projectSize: yup.number(),
    unit: yup.string(),
    maxSize: yup.number(),
    type: yup
      .number()
      .oneOf([1, 2, 3, 4, 5, 6, 7, 8], "Type is a required field")
      .required(),

    //NPV Attributes
    initialInvestment: yup
      .number()
      .label("Initial Investment")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    annualSavings: yup
      .number()
      .label("Annual Cashflow")
      .min(-9999999999999)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    discountRate: yup
      .number()
      .label("Discount Rate")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    disposalCost: yup
      .number()
      .label("Discount Cost")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    emissionSavingsStartDate: yup
      .date()
      .label("Emission Savings Start Date")
      .required(),
    projectLifetime: yup
      .number()
      .label("Project Lifetime")
      .min(1)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .integer()
      .required(),
    annualCarbonEmissionReduction: yup
      .number()
      .label("Annual Carbon Emission Reduction")
      .min(0)
      .max(
        ((emissions && emissions.emissionsScopeOne) || 0) +
          ((emissions && emissions.emissionsScopeTwo) || 0) || 9999999999999,
        ((emissions && emissions.emissionsScopeOne) || 0) +
          ((emissions && emissions.emissionsScopeTwo) || 0)
          ? `Your CO2 savings is more than your scope 1 and scope 2 emissions`
          : `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
      )
      .required(),
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
  });

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    const address = autocomplete.getPlace().name;
    setProjectLocation(address);
    setCordinates({ lat, lng });
  };

  const createProject = ({
    name,
    location,
    status,
    oppurtunityGroup,
    // impStatus,
    maxSize,
    type,
    description,
    annualSavings,
    projectLifetime,
    discountRate,
    disposalCost,
    initialInvestment,
    annualCarbonEmissionReduction,
    emissionSavingsStartDate,
    autoRenew,
    site,
    dataConfidence,
    projectSize,
    unit,
  }) => {
    setError(null);
    setIsLoading(true);

    const organizationPromise = put(
      `/abatement-projects/${project.id}
      `,
      {
        name: name,
        location: location,
        // latitude: cordinates.lat ? cordinates.lat : project.latitude,
        // longitude: cordinates.lng ? cordinates.lng : project.longitude,

        data_confidence: dataConfidence,
        project_status: status,
        opportunity_group_id: oppurtunityGroup,
        // implementationStatus: parseInt(impStatus),
        projectMaxSize: maxSize,
        project_type: type,
        description: description,
        annual_savings: annualSavings,
        project_lifetime: projectLifetime,
        discount_rate: discountRate,
        disposal_cost: disposalCost,
        initial_investment: initialInvestment,
        annual_carbon_emission_reduction: annualCarbonEmissionReduction,
        emission_savings_start_date: moment(emissionSavingsStartDate).format(
          "MM/DD/YYYY"
        ),
        auto_renew: autoRenew,
        site_id: site,
        projectSize,
        unit,
        customFields,
        projectYearData: isYearly ? yearlyEmissions : [],
      }
    );

    organizationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onAbatementProjectEdited(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    if (show) {
      const emissionsPromise = get(`organizations/${organizationId}/emissions`);
      const customFieldPromise = get(
        `organizations/${organizationId}/custom-fields`
      );
      const groupPromise = get(`organizations/${organizationId}/groups`);

      Promise.all([
        emissionsPromise.promise,
        groupPromise.promise,
        customFieldPromise.promise,
      ])
        .then((responses) => {
          setError(null);
          setIsLoading(false);
          setEmissions(responses[0].data[0] || null);
          setOppurtunityGroups(responses[1].data);
          setExistingCustomFields(responses[2].data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(emissionsPromise);
    }
  }, [organizationId, show]);

  useEffect(() => {
    const filteredLocations = sites
      ?.map((site) => site?.address)
      ?.filter(
        (value, index, self) => value !== "" && self.indexOf(value) === index
      );
    setLocations(filteredLocations);
  }, [sites]);

  useEffect(() => {
    setStartYear(new Date(project?.emissionSavingsStartDate)?.getFullYear());
    setProjectLifetime(project?.projectLifetime);
    setCustomFields(project?.customFields);
    setIsYearly(!!project?.projectYearData?.length);
    setAnnualSavings(project?.annualSavings);
    setAnnualCarbonEmissionReduction(project?.annualCarbonEmissionReduction);
  }, [project, show]);

  useEffect(() => {
    if (isYearly) {
      let emissionsByYear;
      let totalYears = Array.from(
        { length: Number(projectLifetime) },
        (_, index) => Number(startYear) + index
      );
      if (!project?.projectYearData?.length) {
        emissionsByYear = totalYears?.map((data) => ({
          year: data,
          annualSavings: annualSavings || 0,
          annualCarbonEmissionReduction: annualCarbonEmissionReduction || 0,
        }));
      } else {
        const result = totalYears.map((year) => {
          const existingData = project?.projectYearData?.find(
            (item) => item?.year === year
          );

          if (existingData) {
            return existingData;
          } else {
            return {
              year,
              annualSavings: annualSavings || 0,
              annualCarbonEmissionReduction: annualCarbonEmissionReduction || 0,
            };
          }
        });
        emissionsByYear = result?.map((data) => keysToCamelCase(data));
      }
      setYearlyEmissions(emissionsByYear);
    }
  }, [isYearly, startYear, projectLifetime, project, show]);

  const deleteModule = (index) => {
    const data = [...customFields];
    data.splice(index, 1);
    setCustomFields([...data]);
  };

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        className="py-0"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-18">tune</span>
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Abatement Project</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createProject(values)}
          initialValues={{
            name: project.name,
            description: project.description || "",
            location: isNaN(Number(project.location)) ? project.location : "",
            status: project.projectStatus,
            oppurtunityGroup: project?.group?.id || "",
            // impStatus: project.implementationStatus || "",
            projectSize: project.projectSize || "",
            unit: project.unit || "",
            maxSize: project.projectMaxSize || "",
            type: project.projectType,
            dataConfidence: project.dataConfidence || "",
            annualSavings: project.annualSavings,
            projectLifetime: project.projectLifetime,
            emissionSavingsStartDate: project.emissionSavingsStartDate
              ? moment(project.emissionSavingsStartDate).format("YYYY-MM-DD")
              : "",
            discountRate: project.discountRate,
            disposalCost: project.disposalCost,
            initialInvestment: project.initialInvestment,
            annualCarbonEmissionReduction:
              project.annualCarbonEmissionReduction || 0,
            autoRenew: project.autoRenew || 0,
            site: project.site ? project.site?.id : "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={step}
                  onSelect={(k) => setStep(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="step-1"
                    title={
                      <>
                        General Information{" "}
                        {((errors.name && touched.name) ||
                          (errors.location && touched.location) ||
                          (errors.status && touched.status) ||
                          (errors.type && touched.type)) && (
                          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        )}
                      </>
                    }
                  >
                    <Form.Group controlId="projectName">
                      <Form.Label>
                        Name<sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.name}
                        isInvalid={errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="oppurtunityGroup" className="my-3">
                      <Form.Label>Abatement Opportunity Grouping</Form.Label>
                      <Form.Select
                        name="oppurtunityGroup"
                        value={values.oppurtunityGroup}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.oppurtunityGroup}
                        isInvalid={
                          errors.oppurtunityGroup && touched.oppurtunityGroup
                        }
                      >
                        <option value={""} disabled={values.oppurtunityGroup}>
                          Select the opportunity group
                        </option>
                        {oppurtunityGroups?.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="projectLocation" className="my-3">
                      <Form.Label>
                        Location{" "}
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Form.Select
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.location}
                        isInvalid={errors.location && touched.location}
                      >
                        <option value={""} disabled={values.location}>
                          Select the project location
                        </option>
                        {locations?.map((loc) => (
                          <option key={loc} value={loc}>
                            {loc}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.location}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="site" className="my-3">
                      <Form.Label>
                        Site
                        <span className="text-muted ms-2">(Optional)</span>
                      </Form.Label>
                      <Form.Select
                        name="site"
                        value={values.site}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.site}
                        isInvalid={errors.site && touched.site}
                      >
                        <option value={0}>Select the project site</option>
                        {sites.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.site}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="projectStatus" className="my-3">
                      <Form.Label>
                        Status
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Form.Select
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.status}
                        isInvalid={errors.status && touched.status}
                      >
                        <option value={""} disabled={values.status}>
                          Select the project evalution status
                        </option>
                        {projectCombinedStatusMap
                          .sort((a, b) => a.order - b.order)
                          .map(({ key, value }) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* {isLoaded && (
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                      >
                        <Form.Group
                          controlId="projectLocation"
                          className="my-3"
                        >
                          <Form.Label>
                            Location{" "}
                            <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                            {!isNaN(Number(project.location)) && (
                              <small className="text-muted ms-2">
                                Please update your location{" "}
                                <strong>
                                  {
                                    locationsMap.find(
                                      ({ key }) =>
                                        parseInt(key) ===
                                        parseInt(project?.location)
                                    )?.value
                                  }
                                </strong>
                              </small>
                            )}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus={!isNaN(Number(project.location))}
                            name="location"
                            placeholder="Please enter location name"
                            value={
                              projectLocation === ""
                                ? values.location
                                : projectLocation
                            }
                            onChange={(e) => {
                              handleChange(e);
                              setProjectLocation("");
                            }}
                            onBlur={handleBlur}
                            isValid={values.location}
                            isInvalid={errors.location && touched.location}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.location}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Autocomplete>
                    )} */}
                    <Form.Group controlId="projectType" className="my-3">
                      <Form.Label>
                        Type<sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Form.Select
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.type}
                        isInvalid={errors.type && touched.type}
                      >
                        <option value={0}>Select the project type</option>
                        {projectTypesMap.map(({ key, value }) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.type}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="projectDescription" className="my-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.description}
                        isInvalid={errors.description && touched.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="projectLifetime" className="my-3">
                      <Form.Label>
                        Project Lifetime (Yrs)
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="projectLifetime"
                        value={values.projectLifetime}
                        onChange={(ev) => {
                          setFieldValue("projectLifetime", ev.target.value);
                          setProjectLifetime(ev.target.value);
                        }}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.projectLifetime}
                        isInvalid={
                          errors.projectLifetime && touched.projectLifetime
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.projectLifetime}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="projectDataConfidence"
                      className="my-3"
                    >
                      <Form.Label>Data Confidence</Form.Label>
                      <Form.Select
                        name="dataConfidence"
                        value={values.dataConfidence}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.dataConfidence}
                        isInvalid={
                          errors.dataConfidence && touched.dataConfidence
                        }
                      >
                        <option value={0}>
                          Select the project data confidence
                        </option>
                        {projectDataConfidenceMap.map(({ key, value }) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.dataConfidence}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group controlId="projectImpStatus" className="my-3">
                      <Form.Label>Status (Implementation)</Form.Label>
                      <Form.Select
                        name="impStatus"
                        value={values.impStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.impStatus}
                        isInvalid={errors.impStatus && touched.impStatus}
                      >
                        <option value={""} disabled={values.impStatus}>
                          Select the project implementation status
                        </option>
                        {projectImplementationStatusMap.map(
                          ({ key, value }) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.impStatus}
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group
                      controlId="emissionSavingsStartDate"
                      className="my-3"
                    >
                      <Form.Label>
                        Emission Savings Start Date
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="emissionSavingsStartDate"
                        value={values.emissionSavingsStartDate}
                        // onChange={handleChange}
                        onChange={(ev) => {
                          setFieldValue(
                            "emissionSavingsStartDate",
                            ev.target.value
                          );
                          setStartYear(new Date(ev.target.value).getFullYear());
                        }}
                        onBlur={handleBlur}
                        isValid={values.emissionSavingsStartDate}
                        isInvalid={
                          errors.emissionSavingsStartDate &&
                          touched.emissionSavingsStartDate
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionSavingsStartDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="projectSize" className="my-3">
                      <Form.Label>
                        Size of the Project (Scaling Unit)
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="projectSize"
                        value={values.projectSize}
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.projectSize}
                        isInvalid={errors.projectSize && touched.projectSize}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.projectSize}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="projectLifetime" className="my-3">
                      <Form.Label>Unit of measurement</Form.Label>
                      <Form.Control
                        type="text"
                        name="unit"
                        value={values.unit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.unit}
                        isInvalid={errors.unit && touched.unit}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.unit}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="maxSize" className="my-3">
                      <Form.Label>Max Size</Form.Label>
                      <Form.Control
                        type="number"
                        name="maxSize"
                        value={values.maxSize}
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.maxSize}
                        isInvalid={errors.maxSize && touched.maxSize}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.maxSize}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-3" controlId="autoRenew">
                      <Form.Check
                        type="checkbox"
                        label="Auto renew project"
                        name="autoRenew"
                        value={values.autoRenew}
                        checked={!!values.autoRenew}
                        onChange={() => {
                          if (!!values.autoRenew) setFieldValue("autoRenew", 0);
                          else setFieldValue("autoRenew", 1);
                        }}
                      />
                    </Form.Group>
                  </Tab>
                  <Tab
                    eventKey="step-2"
                    title={
                      <>
                        NPV (Net Present Value)
                        {((errors.projectLifetime && touched.projectLifetime) ||
                          (errors.discountRate && touched.discountRate) ||
                          (errors.disposalCost && touched.disposalCost) ||
                          (errors.annualSavings && touched.annualSavings) ||
                          (errors.initialInvestment &&
                            touched.initialInvestment)) && (
                          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        )}
                      </>
                    }
                  >
                    <Form.Group controlId="initialInvestment" className="my-3">
                      <Form.Label>
                        Initial Investment ($)
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="initialInvestment"
                        value={values.initialInvestment}
                        prefix={"$"}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("initialInvestment", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.initialInvestment}
                        isInvalid={
                          errors.initialInvestment && touched.initialInvestment
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.initialInvestment}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="annualSavings" className="mt-3">
                      <Form.Label>
                        Annual Cashflow ($)
                        <abbr
                          title={
                            "This can be negative or positive. Negative represents net cost and positive net saving."
                          }
                        >
                          <span className="material-icons-outlined md-18">
                            info
                          </span>
                        </abbr>
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="annualSavings"
                        value={values.annualSavings}
                        prefix={"$"}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("annualSavings", numberItem.value);
                          setAnnualSavings(numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.annualSavings}
                        isInvalid={
                          errors.annualSavings && touched.annualSavings
                        }
                        disabled={isYearly}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.annualSavings}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mt-1">
                      <Form.Check
                        type="checkbox"
                        label={
                          <span
                            onClick={() => setStep("step-3")}
                            className="yearLabel"
                          >
                            Click here to enter year by year cashflow
                          </span>
                        }
                        value={isYearly}
                        checked={!!isYearly}
                        onChange={() => {
                          if (!!isYearly) setIsYearly(false);
                          else setIsYearly(true);
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="discountRate" className="my-3">
                      <Form.Label>
                        Discount Rate (%)
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="discountRate"
                        value={values.discountRate}
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.discountRate}
                        isInvalid={errors.discountRate && touched.discountRate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.discountRate}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="disposalCost" className="my-3">
                      <Form.Label>
                        Disposal Cost ($)
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="disposalCost"
                        value={values.disposalCost}
                        prefix={"$"}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("disposalCost", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.disposalCost}
                        isInvalid={errors.disposalCost && touched.disposalCost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.disposalCost}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Tab>
                  <Tab
                    eventKey="step-3"
                    title={
                      <>
                        Lifetime Emissions Reduction
                        {errors.annualCarbonEmissionReduction &&
                          touched.annualCarbonEmissionReduction && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                      </>
                    }
                  >
                    <Form.Group
                      controlId="annualCarbonEmissionReduction"
                      className="my-3"
                    >
                      <Form.Label>
                        Annual Emissions Reduction (tCO<sub>2</sub>e)
                        <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="annualCarbonEmissionReduction"
                        value={values.annualCarbonEmissionReduction}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue(
                            "annualCarbonEmissionReduction",
                            numberItem.value
                          );
                          setAnnualCarbonEmissionReduction(numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.annualCarbonEmissionReduction}
                        isInvalid={
                          errors.annualCarbonEmissionReduction &&
                          touched.annualCarbonEmissionReduction
                        }
                        disabled={isYearly}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.annualCarbonEmissionReduction}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-1" controlId="isYearly">
                      <Form.Check
                        type="checkbox"
                        label="Year by year emissions reduction"
                        name="isYearly"
                        value={isYearly}
                        checked={!!isYearly}
                        onChange={() => {
                          if (!!isYearly) setIsYearly(false);
                          else setIsYearly(true);
                        }}
                      />
                    </Form.Group>
                    {isYearly &&
                      values?.projectLifetime &&
                      values?.emissionSavingsStartDate && (
                        <Table size="sm" responsive>
                          <thead>
                            <tr>
                              <th>Year</th>
                              <th>Net Cashflow ($)</th>
                              <th>
                                Emissions Reduction (tCO<sub>2</sub>e)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {yearlyEmissions?.map((data) => (
                              <tr key={data?.year}>
                                <td>{data?.year}</td>
                                <td>
                                  <NumberFormat
                                    name="annualCashflow"
                                    value={data?.annualSavings}
                                    prefix={"$"}
                                    customInput={Form.Control}
                                    thousandSeparator={true}
                                    onValueChange={(numberItem) => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualSavings: numberItem.value,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                      setFieldValue(
                                        "annualSavings",
                                        yearlyEmissions
                                          .map((item) => {
                                            if (item.year === data?.year) {
                                              return Number(numberItem.value);
                                            } else {
                                              return item?.annualSavings;
                                            }
                                          })
                                          ?.reduce(
                                            (accumulator, currentValue) =>
                                              Number(accumulator) +
                                              Number(currentValue),
                                            0
                                          ) / values?.projectLifetime
                                      );
                                    }}
                                    onBlur={() => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualSavings:
                                                item.annualSavings === ""
                                                  ? 0
                                                  : item.annualSavings,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                    }}
                                    isValid={
                                      data?.annualSavings === 0 ||
                                      data?.annualSavings
                                    }
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    name="annualReductions"
                                    value={data?.annualCarbonEmissionReduction}
                                    customInput={Form.Control}
                                    thousandSeparator={true}
                                    onValueChange={(numberItem) => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualCarbonEmissionReduction:
                                                numberItem.value,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                      setFieldValue(
                                        "annualCarbonEmissionReduction",
                                        yearlyEmissions
                                          .map((item) => {
                                            if (item.year === data?.year) {
                                              return Number(numberItem.value);
                                            } else {
                                              return item?.annualCarbonEmissionReduction;
                                            }
                                          })
                                          ?.reduce(
                                            (accumulator, currentValue) =>
                                              Number(accumulator) +
                                              Number(currentValue),
                                            0
                                          ) / values?.projectLifetime
                                      );
                                    }}
                                    onBlur={() => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualCarbonEmissionReduction:
                                                item.annualCarbonEmissionReduction ===
                                                ""
                                                  ? 0
                                                  : item.annualCarbonEmissionReduction,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                    }}
                                    isValid={
                                      data?.annualCarbonEmissionReduction ===
                                        0 || data?.annualCarbonEmissionReduction
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                  </Tab>
                  <Tab eventKey="step-4" title={<>User Defined Data Fields</>}>
                    <>
                      <Button
                        variant="link"
                        onClick={() => {
                          setIsCustom(!isCustom);
                        }}
                        className="p-0 my-2"
                      >
                        <span className="material-icons-outlined">info</span>{" "}
                        Add New Custom Field
                      </Button>
                      <Collapse in={isCustom}>
                        <div>
                          <Alert
                            variant="dark"
                            className="bg-light"
                            dismissible
                            onClose={() => setIsCustom(!isCustom)}
                          >
                            <Form.Group
                              controlId="customLabel"
                              className="my-2"
                            >
                              <Form.Label>
                                Label
                                <sup className="ps-1 fs-3 top-0 text-muted">
                                  *
                                </sup>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="customLabel"
                                value={customField?.label}
                                onChange={(ev) =>
                                  setCustomField({
                                    ...customField,
                                    label: ev.target.value,
                                  })
                                }
                                onBlur={handleBlur}
                                isValid={customField?.label}
                              />
                            </Form.Group>
                            <Form.Group controlId="customType" className="my-2">
                              <Form.Label>
                                Type
                                <sup className="ps-1 fs-3 top-0 text-muted">
                                  *
                                </sup>
                              </Form.Label>
                              <Form.Select
                                name="customType"
                                value={
                                  customField?.type === "number"
                                    ? "Number"
                                    : customField?.type === "textarea"
                                    ? "TextArea"
                                    : customField?.type === "date"
                                    ? "Date"
                                    : customField?.type
                                }
                                onChange={(ev) =>
                                  setCustomField({
                                    ...customField,
                                    type: ev.target.value.toLowerCase(),
                                    value: "",
                                  })
                                }
                                onBlur={handleBlur}
                                isValid={customField?.type}
                              >
                                <option value={""} disabled={customField?.type}>
                                  Select the type
                                </option>
                                {projectCustomTypesMap.map(({ key, value }) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            {customField?.type && (
                              <Form.Group
                                controlId="customValue"
                                className="my-2"
                              >
                                <Form.Label>
                                  Value
                                  <sup className="ps-1 fs-3 top-0 text-muted">
                                    *
                                  </sup>
                                </Form.Label>
                                {customField?.type?.toLowerCase() !==
                                "textarea" ? (
                                  <Form.Control
                                    type={customField?.type?.toLowerCase()}
                                    name="customValue"
                                    value={customField?.value}
                                    onChange={(ev) =>
                                      setCustomField({
                                        ...customField,
                                        value: ev.target.value,
                                      })
                                    }
                                    onBlur={handleBlur}
                                    isValid={customField?.value}
                                  />
                                ) : (
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="customValue"
                                    value={customField?.value}
                                    onChange={(ev) =>
                                      setCustomField({
                                        ...customField,
                                        value: ev.target.value,
                                      })
                                    }
                                    onBlur={handleBlur}
                                    isValid={customField?.value}
                                  />
                                )}
                              </Form.Group>
                            )}
                            <div className="mt-4 text-end">
                              <>
                                <Button
                                  size="sm"
                                  variant="link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsCustom(!isCustom);
                                    setCustomField({
                                      label: "",
                                      type: "",
                                      value: "",
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsCustom(!isCustom);
                                    setCustomFields((prev) => [
                                      ...prev,
                                      {
                                        ...customField,
                                        fe_key: customFields?.length + 1,
                                      },
                                    ]);
                                    setCustomField({
                                      label: "",
                                      type: "",
                                      value: "",
                                    });
                                  }}
                                  disabled={
                                    !customField?.label ||
                                    !customField?.type ||
                                    !customField?.value
                                  }
                                >
                                  Save
                                </Button>
                              </>
                            </div>
                          </Alert>
                        </div>
                      </Collapse>
                      <Button
                        variant="link"
                        onClick={() => {
                          setUseCustom(!useCustom);
                        }}
                        className="d-block p-0 my-2"
                      >
                        <span className="material-icons-outlined">info</span>{" "}
                        Add an Existing Custom Field
                      </Button>
                      <Collapse in={useCustom}>
                        <div>
                          <Alert
                            variant="dark"
                            className="bg-light"
                            dismissible
                            onClose={() => setUseCustom(!useCustom)}
                          >
                            <Form.Group controlId="customType" className="my-2">
                              <Form.Label>
                                Existing Custom Field
                                <sup className="ps-1 fs-3 top-0 text-muted">
                                  *
                                </sup>
                              </Form.Label>
                              <Form.Select
                                name="customType"
                                value={useCustomFields?.label}
                                onChange={(ev) =>
                                  setUseCustomFields({
                                    ...existingCustomFields?.filter(
                                      (data) => data?.id === ev.target.value
                                    )?.[0],
                                    value: "",
                                  })
                                }
                                onBlur={handleBlur}
                                isValid={useCustomFields?.label}
                              >
                                <option
                                  value={""}
                                  disabled={useCustomFields?.label}
                                >
                                  Select the existing custom field
                                </option>
                                {existingCustomFields?.map((data) => (
                                  <option key={data?.id} value={data?.id}>
                                    {data?.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            <div className="mt-4 text-end">
                              <>
                                <Button
                                  size="sm"
                                  variant="link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setUseCustom(!useCustom);
                                    setUseCustomFields({
                                      label: "",
                                      type: "",
                                      value: "",
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setUseCustom(!useCustom);
                                    setCustomFields((prev) => [
                                      ...prev,
                                      {
                                        ...useCustomFields,
                                        fe_key: customFields?.length + 1,
                                      },
                                    ]);
                                    setUseCustomFields({
                                      label: "",
                                      type: "",
                                      value: "",
                                    });
                                  }}
                                  disabled={!useCustomFields?.label}
                                >
                                  Use
                                </Button>
                              </>
                            </div>
                          </Alert>
                        </div>
                      </Collapse>
                      {!!customFields?.length &&
                        customFields?.map((field, index) => (
                          <>
                            <Form.Group
                              controlId={`customField${
                                field?.fe_key || field?.id
                              }`}
                              className="my-2"
                              key={field?.fe_key || field?.id}
                            >
                              <div>
                                <Form.Label>{field?.label}</Form.Label>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="py-0 float-end"
                                  onClick={() => deleteModule(index)}
                                >
                                  <span className="material-icons-outlined md-18">
                                    delete
                                  </span>
                                </Button>
                              </div>
                              {field?.type !== "textarea" ? (
                                <Form.Control
                                  type={field?.type?.toLowerCase()}
                                  name={`customField${
                                    field?.fe_key || field?.id
                                  }`}
                                  value={field?.value}
                                  onChange={(ev) => {
                                    setCustomFields(
                                      customFields.map((item) => {
                                        if (
                                          item?.fe_key === field?.fe_key ||
                                          item?.id === field?.id
                                        ) {
                                          return {
                                            ...item,
                                            value: ev.target.value,
                                          };
                                        } else {
                                          return item;
                                        }
                                      })
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isValid={field?.value}
                                />
                              ) : (
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  name={`customField${
                                    field?.fe_key || field?.id
                                  }`}
                                  value={field?.value}
                                  onChange={(ev) => {
                                    setCustomFields(
                                      customFields.map((item) => {
                                        if (
                                          item?.fe_key === field?.fe_key ||
                                          item?.id === field?.id
                                        ) {
                                          return {
                                            ...item,
                                            value: ev.target.value,
                                          };
                                        } else {
                                          return item;
                                        }
                                      })
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isValid={field?.value}
                                />
                              )}
                            </Form.Group>
                          </>
                        ))}
                    </>
                    {/* )} */}
                  </Tab>
                </Tabs>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer className="">
                {step === "step-1" && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    className="px-4"
                    onClick={() => setStep("step-2")}
                  >
                    Next
                  </Button>
                )}

                {step === "step-2" && (
                  <>
                    <Button
                      variant="outline-dark"
                      size="sm"
                      onClick={() => setStep("step-1")}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outline-dark"
                      size="sm"
                      className="px-4"
                      onClick={() => setStep("step-3")}
                    >
                      Next
                    </Button>
                  </>
                )}

                {(step === "step-3" || step === "step-4") && (
                  <>
                    <Button
                      variant="outline-dark"
                      className="float-start px-4"
                      size="sm"
                      onClick={() =>
                        setStep(step === "step-3" ? "step-2" : "step-3")
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      disabled={!isValid}
                      // disabled
                    >
                      Update Project
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AbatementProjectEdit.propTypes = {
  onAbatementProjectEdited: PropTypes.func.isRequired,
};

export default AbatementProjectEdit;
