import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import React, { useEffect, useRef, useState } from "react";
import "./ReductionPotential.scss";
import { Form, Table } from "react-bootstrap";
import { get } from "utils/DeApi";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import ManageReductionPotential from "./ManageReductionPotential/ManageReductionPotential";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import ManageReductionTargetEmissions from "./ManageReductionTargetEmissions/ManageReductionTargetEmissions";
import { formatNumber, formatPercentage } from "../../Forecasting/helper";
import { uniqBy } from "lodash";

const ReductionPotential = ({ organization, baselineYear }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorEmissions, setErrorEmissions] = useState();
  const [isLoadingEmissions, setIsLoadingEmissions] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);
  const [orgActions, setOrgActions] = useState([]);
  const [decarbEmissions, setDecarbEmissions] = useState([]);
  const [expandedScopeOne, setExpandedScopeOne] = useState(false);
  const [expandedScopeTwo, setExpandedScopeTwo] = useState(false);
  const [expandedScopeThree, setExpandedScopeThree] = useState(false);
  const [year, setYear] = useState(baselineYear || "2023");
  const [showActivityTypes, setShowActivityTypes] = useState(false);
  const [showCategories, setShowCategories] = useState([]);
  const subscribedPromises = useRef([]);

  const getTotalReductionPotential = (activityTypesPercentages) => {
    const number =
      parseFloat(
        (parseFloat(
          decarbEmissions?.find((item) => item.scope === 1)?.emissions
        ) /
          100) *
          (parseFloat(
            activityTypesPercentages?.find((item) => item?.scope === 1)
              ?.reductionPercentage
          ) || 0) +
          (parseFloat(
            decarbEmissions?.find((item) => item.scope === 2)?.emissions
          ) /
            100) *
            (parseFloat(
              activityTypesPercentages?.find((item) => item?.scope === 2)
                ?.reductionPercentage
            ) || 0) +
          (parseFloat(
            decarbEmissions?.find((item) => item.scope === 3)?.emissions
          ) /
            100) *
            (parseFloat(
              activityTypesPercentages?.find((item) => item?.scope === 3)
                ?.reductionPercentage
            ) || 0)
      ) || undefined;

    return (
      <>
        <div>{formatNumber(number?.toFixed(), 0).replace("N/A", "0")}</div>
        <hr className="my-2" />
      </>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const activityTypesPromise = get("activity-types");
    const organizationPromise = get(
      `organizations/${organization?.id}/categories/reduction`
    );
    Promise.all([activityTypesPromise.promise, organizationPromise.promise])
      .then((responses) => {
        setError(null);
        setActivityTypes(uniqBy(responses[0]?.data || [], "label"));
        setOrgActions(responses[1]?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(activityTypesPromise, organizationPromise);
  }, [organization.id]);

  useEffect(() => {
    setIsLoadingEmissions(true);
    const emissionsPromise = get(
      `organizations/${organization?.id}/decarb-emissions`
    );

    emissionsPromise.promise
      .then((response) => {
        setErrorEmissions(null);
        setDecarbEmissions(response?.data);
        setIsLoadingEmissions(false);

        setShowActivityTypes(
          response?.data?.length ? !!response.data[0].emissionPreference : 0
        );
        setYear(
          response?.data?.length
            ? response.data[0].yearEnded
            : baselineYear || "2023"
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setErrorEmissions(error);
          setIsLoadingEmissions(false);
        }
      });

    subscribedPromises.current.push(emissionsPromise);
  }, [organization.id, year]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const handleShowCategories = (id) => {
    if (showCategories.includes(id)) {
      const ids = [...showCategories];
      const index = ids.find((val) => val === id);
      ids.splice(index, 1);
      setShowCategories(ids);
    } else {
      setShowCategories((prev) => [...prev, id]);
    }
  };

  return (
    <div>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            {
              name: organization.name,
              link: `/organizations/${organization?.id}`,
            },
            {
              name: "Reduction Potential",
              link: `/organizations/${organization?.id}/reduction-potential`,
              active: true,
            },
          ]}
        />
      </div>
      <div className="mb-4 pt-2 d-flex align-items-center justify-content-between">
        <h2>
          <span>Reduction Potential by Opportunity</span>
        </h2>
        {/* <div> */}
        <div className="d-flex justify-content-end align-items-center">
          <div className="me-1">
            <Form.Select
              aria-label="year-filter"
              size="sm"
              value={year}
              disabled
              onChange={(ev) => {
                let selectedYear = ev.target.value;
                setYear(selectedYear);
              }}
            >
              {[
                "2023",
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015",
                "2014",
                "2013",
                "2012",
                "2011",
                "2010",
              ].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
            {/* </div> */}
          </div>
          <ManageReductionTargetEmissions
            year={year}
            showActivityTypes={showActivityTypes}
            activityTypes={activityTypes}
            decarbEmissionSummary={decarbEmissions}
            onManageReductionsEmissionsUpdated={(data, bool, year) => {
              setDecarbEmissions(data);
              setShowActivityTypes(bool);
              setExpandedScopeOne(false);
              setExpandedScopeTwo(false);
              setExpandedScopeThree(false);
              setYear(year);
            }}
          />
        </div>
      </div>
      {(isLoading || isLoadingEmissions) && <Loader />}
      {(error || errorEmissions) && (
        <ErrorHandler error={error || errorEmissions} />
      )}
      {!isLoading && !isLoadingEmissions && !error && !errorEmissions && (
        <div>
          <Table
            responsive
            striped
            size="sm"
            className="first-column-fixed"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th className="px-3 align-top" style={{ width: "200px" }}>
                  Applicable Opportunities
                </th>
                <th className="ps-3 align-top" style={{ width: "150px" }}>
                  Reduction Potential (tCO<sub>2</sub>e)
                </th>
                <th
                  className="px-3 align-top overflow-hidden cursor-pointer"
                  style={{ width: "200px" }}
                  onClick={() =>
                    setExpandedScopeOne((prev) =>
                      showActivityTypes ? !prev : false
                    )
                  }
                >
                  <div className="d-flex justify-content-between align-items-start flex-column">
                    <abbr title="Expand">
                      Scope 1 Total
                      {showActivityTypes ? (
                        expandedScopeOne ? (
                          <span className="ms-2 material-icons-outlined">
                            remove
                          </span>
                        ) : (
                          <span className="ms-2 material-icons-outlined">
                            add
                          </span>
                        )
                      ) : null}
                    </abbr>
                    <small className="fw-normal">
                      (
                      {formatNumber(
                        parseFloat(
                          decarbEmissions?.find((item) => item.scope === 1)
                            ?.emissions
                        ).toFixed(),
                        0
                      ) || 0}{" "}
                      tCO<sub>2</sub>e)
                    </small>
                  </div>
                </th>
                {(showActivityTypes && expandedScopeOne ? activityTypes : [])
                  .filter(({ scope }) => scope === 1)
                  .map(({ title, label, scope, id }) => (
                    <th
                      key={title + scope}
                      className="px-3 align-top"
                      style={{ width: "200px" }}
                    >
                      <div className="d-flex justify-content-between align-items-start flex-column fs-6 fw-normal">
                        <div>S1: {label}</div>
                        <span className="fw-normal">
                          (
                          {formatNumber(
                            parseFloat(
                              decarbEmissions?.find(
                                (item) => item?.activityType?.id === id
                              )?.emissions
                            ).toFixed(),
                            0
                          ) || 0}{" "}
                          tCO<sub>2</sub>e)
                        </span>
                      </div>
                    </th>
                  ))}
                <th
                  className="px-3 align-top  overflow-hidden cursor-pointer"
                  style={{ width: "200px" }}
                  onClick={() =>
                    setExpandedScopeTwo((prev) =>
                      showActivityTypes ? !prev : false
                    )
                  }
                >
                  <div className="d-flex justify-content-between align-items-start flex-column">
                    <abbr title="Expand">
                      Scope 2 Total
                      {showActivityTypes ? (
                        expandedScopeTwo ? (
                          <span className="ms-2 material-icons-outlined">
                            remove
                          </span>
                        ) : (
                          <span className="ms-2 material-icons-outlined">
                            add
                          </span>
                        )
                      ) : null}
                    </abbr>
                    <small className="fw-normal">
                      (
                      {formatNumber(
                        parseFloat(
                          decarbEmissions?.find((item) => item.scope === 2)
                            ?.emissions
                        ).toFixed(),
                        0
                      ) || 0}{" "}
                      tCO<sub>2</sub>e)
                    </small>
                  </div>
                </th>
                {(showActivityTypes && expandedScopeTwo ? activityTypes : [])
                  .filter(({ scope }) => scope === 2)
                  .map(({ title, label, scope, id }) => (
                    <th
                      key={title + scope}
                      className="px-3 align-top"
                      style={{ width: "200px" }}
                    >
                      <div className="d-flex justify-content-between align-items-start flex-column fs-6 fw-normal">
                        <div>S2: {label}</div>
                        <span>
                          (
                          {formatNumber(
                            parseFloat(
                              decarbEmissions?.find(
                                (item) => item?.activityType?.id === id
                              )?.emissions
                            ).toFixed(),
                            0
                          ) || 0}{" "}
                          tCO<sub>2</sub>e)
                        </span>
                      </div>
                    </th>
                  ))}
                <th
                  className="px-3 align-top  overflow-hidden cursor-pointer"
                  style={{ width: "200px" }}
                  onClick={() =>
                    setExpandedScopeThree((prev) =>
                      showActivityTypes ? !prev : false
                    )
                  }
                >
                  <div className="d-flex justify-content-between align-items-start flex-column">
                    <abbr title="Expand">
                      Scope 3 Total
                      {showActivityTypes ? (
                        expandedScopeThree ? (
                          <span className="ms-2 material-icons-outlined">
                            remove
                          </span>
                        ) : (
                          <span className="ms-2 material-icons-outlined">
                            add
                          </span>
                        )
                      ) : null}
                    </abbr>
                    <small className="fw-normal">
                      (
                      {formatNumber(
                        parseFloat(
                          decarbEmissions?.find((item) => item.scope === 3)
                            ?.emissions
                        ).toFixed(),
                        0
                      ) || 0}{" "}
                      tCO<sub>2</sub>e)
                    </small>
                  </div>
                </th>
                {(showActivityTypes && expandedScopeThree ? activityTypes : [])
                  .filter(({ scope }) => scope === 3)
                  .map(({ title, label, categoryNumber, scope, id }) => (
                    <th
                      key={title + scope}
                      className="px-3 align-top"
                      style={{ width: "200px" }}
                    >
                      <div className="d-flex justify-content-between align-items-start flex-column fs-6 fw-normal">
                        <div>
                          S3: {categoryNumber ? `${categoryNumber}. ` : ""}
                          {label}
                        </div>
                        <span>
                          (
                          {formatNumber(
                            parseFloat(
                              decarbEmissions?.find(
                                (item) => item?.activityType?.id === id
                              )?.emissions
                            ).toFixed(),
                            0
                          ) || 0}{" "}
                          tCO<sub>2</sub>e)
                        </span>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {orgActions.map(({ id, name, parentCategory, ...rest }) => {
                const activityTypesPercentages = rest?.reductions?.filter(
                  (item) => item.yearEnded.includes(year)
                );
                return (
                  <React.Fragment key={id}>
                    <tr>
                      <td className="px-3">
                        <div className="d-flex align-items-start justify-content-between flex-column">
                          <div>{name}</div>
                          <div className="text-end w-100">
                            {" "}
                            {showCategories.includes(id) ? (
                              <span
                                onClick={() => handleShowCategories(id)}
                                className="ms-2 material-icons-outlined cursor-pointer"
                              >
                                remove
                              </span>
                            ) : (
                              <span
                                onClick={() => handleShowCategories(id)}
                                className="ms-2 material-icons-outlined cursor-pointer"
                              >
                                add
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="p-0 align-middle text-center py-2">
                        {getTotalReductionPotential(activityTypesPercentages)}
                        <ManageReductionPotential
                          title={name}
                          activityTypes={activityTypes}
                          parentCategory={parentCategory?.name}
                          id={id}
                          year={year}
                          organization={organization}
                          decarbEmissions={decarbEmissions}
                          activityTypesPercentages={rest?.reductions}
                          showActivityTypes={showActivityTypes}
                          onManageReductionsUpdated={(data) =>
                            setOrgActions(data)
                          }
                        />
                      </td>
                      <td className="px-3 align-top text-center">
                        {formatPercentage(
                          parseFloat(
                            activityTypesPercentages?.find(
                              (item) => item?.scope === 1
                            )?.reductionPercentage
                          ) || undefined
                        ).replace("N/A", "")}
                      </td>
                      {(showActivityTypes && expandedScopeOne
                        ? activityTypes
                        : []
                      )
                        .filter(({ scope }) => scope === 1)
                        .map(({ title, scope, id }) => (
                          <td
                            key={title + scope}
                            className="px-3 align-top text-center"
                          >
                            {formatPercentage(
                              parseFloat(
                                activityTypesPercentages?.find(
                                  (item) => item?.activityType?.id === id
                                )?.reductionPercentage
                              ) || undefined
                            ).replace("N/A", "")}
                          </td>
                        ))}
                      <td className="px-3 align-top text-center">
                        {formatPercentage(
                          parseFloat(
                            activityTypesPercentages?.find(
                              (item) => item?.scope === 2
                            )?.reductionPercentage
                          ) || undefined
                        ).replace("N/A", "")}
                      </td>
                      {(showActivityTypes && expandedScopeTwo
                        ? activityTypes
                        : []
                      )
                        .filter(({ scope }) => scope === 2)
                        .map(({ title, scope, id }) => (
                          <td
                            key={title + scope}
                            className="px-3 align-top text-center"
                          >
                            {formatPercentage(
                              parseFloat(
                                activityTypesPercentages?.find(
                                  (item) => item?.activityType?.id === id
                                )?.reductionPercentage
                              ) || undefined
                            ).replace("N/A", "")}
                          </td>
                        ))}
                      <td className="px-3 align-top text-center">
                        {formatPercentage(
                          parseFloat(
                            activityTypesPercentages?.find(
                              (item) => item?.scope === 3
                            )?.reductionPercentage
                          ) || undefined
                        ).replace("N/A", "")}
                      </td>
                      {(showActivityTypes && expandedScopeThree
                        ? activityTypes
                        : []
                      )
                        .filter(({ scope }) => scope === 3)
                        .map(({ title, scope, id }) => (
                          <td
                            key={title + scope}
                            className="px-3 align-top text-center"
                          >
                            {formatPercentage(
                              parseFloat(
                                activityTypesPercentages?.find(
                                  (item) => item?.activityType?.id === id
                                )?.reductionPercentage
                              ) || undefined
                            ).replace("N/A", "")}
                          </td>
                        ))}
                    </tr>
                    {showCategories.includes(id)
                      ? rest?.decarbCategories?.map((value) => (
                          <tr key={value.id}>
                            <td colSpan={5}>
                              {value?.parent?.name} - {value?.name}
                            </td>
                          </tr>
                        ))
                      : null}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td className="px-3 align-top" style={{ width: "200px" }}>
                  Total Reduction Potential (%)
                </td>
                <td
                  className="ps-3 text-center align-top"
                  style={{ width: "100px" }}
                >
                  <big>
                    {orgActions.length
                      ? formatPercentage(
                          [1, 2, 3].reduce(
                            (prev, currScope) => {
                              const totalScopesEmissions =
                                prev.totalScopesEmissions +
                                parseFloat(
                                  decarbEmissions?.find(
                                    (item) => item?.scope === currScope
                                  )?.emissions
                                );
                              const totalEmissions =
                                prev.totalEmissions +
                                orgActions?.reduce(
                                  (prev, curr) => {
                                    let reductionPercent =
                                      curr?.reductions?.reduce(
                                        (acc, item) =>
                                          item.yearEnded.includes(year) &&
                                          item?.scope === currScope
                                            ? acc +
                                              parseFloat(
                                                item.reductionPercentage
                                              )
                                            : acc,
                                        0
                                      );
                                    let totalPercent =
                                      prev.prev + reductionPercent;
                                    let context =
                                      (parseFloat(
                                        decarbEmissions?.find(
                                          (item) => item?.scope === currScope
                                        )?.emissions
                                      ) /
                                        100) *
                                      parseFloat(totalPercent);
                                    return {
                                      prev: totalPercent,
                                      context: context,
                                    };
                                  },
                                  { prev: 0, context: 0 }
                                )?.context;
                              const totalScopesEmissionsPercent = (
                                (totalEmissions / totalScopesEmissions) *
                                100
                              ).toFixed(2);
                              return {
                                totalEmissions: totalEmissions,
                                totalScopesEmissions: totalScopesEmissions,
                                totalScopesEmissionsPercent:
                                  totalScopesEmissionsPercent,
                              };
                            },
                            {
                              totalEmissions: 0,
                              totalScopesEmissions: 0,
                              totalScopesEmissionsPercent: 0,
                            }
                          )?.totalScopesEmissionsPercent,
                          2
                        ) || "0%"
                      : "0%"}
                  </big>
                </td>
                <td
                  className="px-3 align-top text-center"
                  style={{ width: "200px" }}
                >
                  <big>
                    {orgActions.length
                      ? orgActions?.reduce(
                          (prev, curr) => {
                            let reductionPercent = curr?.reductions?.reduce(
                              (acc, item) =>
                                item.yearEnded.includes(year) &&
                                item?.scope === 1
                                  ? acc + parseFloat(item.reductionPercentage)
                                  : acc,
                              0
                            );
                            let totalPercent = prev.prev + reductionPercent;
                            let context = (
                              <span
                                className={
                                  totalPercent > 100
                                    ? "text-danger"
                                    : totalPercent < 100
                                    ? "text-warning"
                                    : "text-success"
                                }
                              >
                                {formatPercentage(
                                  parseFloat(totalPercent)
                                ).replace("N/A", "")}
                              </span>
                            );
                            return { prev: totalPercent, context: context };
                          },
                          { prev: 0, context: <span></span> }
                        )?.context
                      : "0%"}
                  </big>
                </td>
                {(showActivityTypes && expandedScopeOne ? activityTypes : [])
                  .filter(({ scope }) => scope === 1)
                  .map(({ title, label, scope, id }) => (
                    <td
                      key={title + scope}
                      className="px-3 align-top text-center"
                      style={{ width: "200px" }}
                    >
                      <big>
                        {formatPercentage(
                          parseFloat(
                            orgActions.reduce(
                              (prev, curr) =>
                                prev +
                                curr?.reductions?.reduce(
                                  (acc, item) =>
                                    item.yearEnded.includes(year) &&
                                    item?.activityType?.id === id
                                      ? acc +
                                        parseFloat(item.reductionPercentage)
                                      : acc,
                                  0
                                ),
                              0
                            )
                          )
                        ).replace("N/A", "")}
                      </big>
                    </td>
                  ))}
                <td
                  className="px-3 align-top text-center"
                  style={{ width: "200px" }}
                >
                  <big>
                    {orgActions.length
                      ? orgActions?.reduce(
                          (prev, curr, currIndex) => {
                            let reductionPercent = curr?.reductions?.reduce(
                              (acc, item) =>
                                item.yearEnded.includes(year) &&
                                item?.scope === 2
                                  ? acc + parseFloat(item.reductionPercentage)
                                  : acc,
                              0
                            );
                            let totalPercent = prev.prev + reductionPercent;
                            let context = (
                              <span
                                className={
                                  totalPercent > 100
                                    ? "text-danger"
                                    : totalPercent < 100
                                    ? "text-warning"
                                    : "text-success"
                                }
                              >
                                {formatPercentage(
                                  parseFloat(totalPercent)
                                ).replace("N/A", "")}
                              </span>
                            );
                            return { prev: totalPercent, context: context };
                          },
                          { prev: 0, context: <span></span> }
                        )?.context
                      : "0%"}
                  </big>
                </td>
                {(showActivityTypes && expandedScopeTwo ? activityTypes : [])
                  .filter(({ scope }) => scope === 2)
                  .map(({ title, label, scope, id }) => (
                    <td
                      key={title + scope}
                      className="px-3 align-top text-center"
                      style={{ width: "200px" }}
                    >
                      <big>
                        {formatPercentage(
                          parseFloat(
                            orgActions.reduce(
                              (prev, curr) =>
                                prev +
                                curr?.reductions?.reduce(
                                  (acc, item) =>
                                    item.yearEnded.includes(year) &&
                                    item?.activityType?.id === id
                                      ? acc +
                                        parseFloat(item.reductionPercentage)
                                      : acc,
                                  0
                                ),
                              0
                            )
                          )
                        ).replace("N/A", "")}
                      </big>
                    </td>
                  ))}
                <td
                  className="px-3 align-top text-center"
                  style={{ width: "200px" }}
                >
                  <big>
                    {orgActions.length
                      ? orgActions?.reduce(
                          (prev, curr, currIndex) => {
                            let reductionPercent = curr?.reductions?.reduce(
                              (acc, item) =>
                                item.yearEnded.includes(year) &&
                                item?.scope === 3
                                  ? acc + parseFloat(item.reductionPercentage)
                                  : acc,
                              0
                            );
                            let totalPercent = prev.prev + reductionPercent;
                            let context = (
                              <span
                                className={
                                  totalPercent > 100
                                    ? "text-danger"
                                    : totalPercent < 100
                                    ? "text-warning"
                                    : "text-success"
                                }
                              >
                                {formatPercentage(
                                  parseFloat(totalPercent)
                                ).replace("N/A", "")}
                              </span>
                            );
                            return { prev: totalPercent, context: context };
                          },
                          { prev: 0, context: <span></span> }
                        )?.context
                      : "0%"}
                  </big>
                </td>
                {(showActivityTypes && expandedScopeThree ? activityTypes : [])
                  .filter(({ scope }) => scope === 3)
                  .map(({ title, label, categoryNumber, scope, id }) => (
                    <td
                      key={title + scope}
                      className="px-3 align-top text-center"
                      style={{ width: "200px" }}
                    >
                      <big>
                        {formatPercentage(
                          parseFloat(
                            orgActions.reduce(
                              (prev, curr) =>
                                prev +
                                curr?.reductions?.reduce(
                                  (acc, item) =>
                                    item.yearEnded.includes(year) &&
                                    item?.activityType?.id === id
                                      ? acc +
                                        parseFloat(item.reductionPercentage)
                                      : acc,
                                  0
                                ),
                              0
                            )
                          )
                        ).replace("N/A", "")}
                      </big>
                    </td>
                  ))}
              </tr>
              <tr>
                <td className="px-3 align-top" style={{ width: "200px" }}>
                  Total Reduction Potential (tCO<sub>2</sub>e)
                </td>
                <td className="ps-3 text-center" style={{ width: "100px" }}>
                  <big>
                    {orgActions.length
                      ? formatNumber(
                          [1, 2, 3]
                            .reduce((prev, currScope) => {
                              return (
                                prev +
                                orgActions?.reduce(
                                  (prev, curr) => {
                                    let reductionPercent =
                                      curr?.reductions?.reduce(
                                        (acc, item) =>
                                          item.yearEnded.includes(year) &&
                                          item?.scope === currScope
                                            ? acc +
                                              parseFloat(
                                                item.reductionPercentage
                                              )
                                            : acc,
                                        0
                                      );
                                    let totalPercent =
                                      prev.prev + reductionPercent;
                                    let context =
                                      (parseFloat(
                                        decarbEmissions?.find(
                                          (item) => item?.scope === currScope
                                        )?.emissions
                                      ) /
                                        100) *
                                      parseFloat(totalPercent);
                                    return {
                                      prev: totalPercent,
                                      context: context,
                                    };
                                  },
                                  { prev: 0, context: 0 }
                                )?.context
                              );
                            }, 0)
                            .toFixed(),
                          0
                        ) || 0
                      : "0"}
                  </big>
                </td>
                <td className="px-3 text-center" style={{ width: "200px" }}>
                  <big>
                    {orgActions.length
                      ? orgActions?.reduce(
                          (prev, curr) => {
                            let reductionPercent = curr?.reductions?.reduce(
                              (acc, item) =>
                                item.yearEnded.includes(year) &&
                                item?.scope === 1
                                  ? acc + parseFloat(item.reductionPercentage)
                                  : acc,
                              0
                            );
                            let totalPercent = prev.prev + reductionPercent;
                            let context = (
                              <span
                                className={
                                  totalPercent > 100
                                    ? "text-danger"
                                    : totalPercent < 100
                                    ? "text-warning"
                                    : "text-success"
                                }
                              >
                                {formatNumber(
                                  (
                                    (parseFloat(
                                      decarbEmissions?.find(
                                        (item) => item?.scope === 1
                                      )?.emissions
                                    ) /
                                      100) *
                                    parseFloat(totalPercent)
                                  ).toFixed(),
                                  0
                                ) || 0}
                              </span>
                            );
                            return { prev: totalPercent, context: context };
                          },
                          { prev: 0, context: <span></span> }
                        )?.context
                      : "0"}
                  </big>
                </td>
                {(showActivityTypes && expandedScopeOne ? activityTypes : [])
                  .filter(({ scope }) => scope === 1)
                  .map(({ title, scope, id }) => (
                    <td
                      key={title + scope}
                      className="px-3 text-center"
                      style={{ width: "200px" }}
                    >
                      <big>
                        {formatNumber(
                          (
                            (parseFloat(
                              decarbEmissions?.find(
                                (item) => item?.activityType?.id === id
                              )?.emissions
                            ) /
                              100) *
                            parseFloat(
                              orgActions.reduce(
                                (prev, curr) =>
                                  prev +
                                  curr?.reductions?.reduce(
                                    (acc, item) =>
                                      item.yearEnded.includes(year) &&
                                      item?.activityType?.id === id
                                        ? acc +
                                          parseFloat(item.reductionPercentage)
                                        : acc,
                                    0
                                  ),
                                0
                              )
                            )
                          ).toFixed(),
                          0
                        ) || 0}
                      </big>
                    </td>
                  ))}
                <td className="px-3 text-center" style={{ width: "200px" }}>
                  <big>
                    {orgActions.length
                      ? orgActions?.reduce(
                          (prev, curr) => {
                            let reductionPercent = curr?.reductions?.reduce(
                              (acc, item) =>
                                item.yearEnded.includes(year) &&
                                item?.scope === 2
                                  ? acc + parseFloat(item.reductionPercentage)
                                  : acc,
                              0
                            );
                            let totalPercent = prev.prev + reductionPercent;
                            let context = (
                              <span
                                className={
                                  totalPercent > 100
                                    ? "text-danger"
                                    : totalPercent < 100
                                    ? "text-warning"
                                    : "text-success"
                                }
                              >
                                {formatNumber(
                                  (
                                    (parseFloat(
                                      decarbEmissions?.find(
                                        (item) => item?.scope === 2
                                      )?.emissions
                                    ) /
                                      100) *
                                    parseFloat(totalPercent)
                                  ).toFixed(),
                                  0
                                ) || 0}
                              </span>
                            );
                            return { prev: totalPercent, context: context };
                          },
                          { prev: 0, context: <span></span> }
                        )?.context
                      : "0"}
                  </big>
                </td>
                {(showActivityTypes && expandedScopeTwo ? activityTypes : [])
                  .filter(({ scope }) => scope === 2)
                  .map(({ title, scope, id }) => (
                    <td
                      key={title + scope}
                      className="px-3 text-center"
                      style={{ width: "200px" }}
                    >
                      <big>
                        {formatNumber(
                          (
                            (parseFloat(
                              decarbEmissions?.find(
                                (item) => item?.activityType?.id === id
                              )?.emissions
                            )?.toFixed() /
                              100) *
                            parseFloat(
                              orgActions.reduce(
                                (prev, curr) =>
                                  prev +
                                  curr?.reductions?.reduce(
                                    (acc, item) =>
                                      item.yearEnded.includes(year) &&
                                      item?.activityType?.id === id
                                        ? acc +
                                          parseFloat(item.reductionPercentage)
                                        : acc,
                                    0
                                  ),
                                0
                              )
                            )
                          ).toFixed(),
                          0
                        ) || 0}
                      </big>
                    </td>
                  ))}
                <td className="px-3 text-center" style={{ width: "200px" }}>
                  <big>
                    {orgActions.length
                      ? orgActions?.reduce(
                          (prev, curr) => {
                            let reductionPercent = curr?.reductions?.reduce(
                              (acc, item) =>
                                item.yearEnded.includes(year) &&
                                item?.scope === 3
                                  ? acc + parseFloat(item.reductionPercentage)
                                  : acc,
                              0
                            );
                            let totalPercent = prev.prev + reductionPercent;
                            let context = (
                              <span
                                className={
                                  totalPercent > 100
                                    ? "text-danger"
                                    : totalPercent < 100
                                    ? "text-warning"
                                    : "text-success"
                                }
                              >
                                {formatNumber(
                                  (
                                    (parseFloat(
                                      decarbEmissions?.find(
                                        (item) => item?.scope === 3
                                      )?.emissions
                                    ) /
                                      100) *
                                    parseFloat(totalPercent)
                                  ).toFixed(),
                                  0
                                ) || 0}
                              </span>
                            );
                            return { prev: totalPercent, context: context };
                          },
                          { prev: 0, context: <span></span> }
                        )?.context
                      : "0"}
                  </big>
                </td>
                {(showActivityTypes && expandedScopeThree ? activityTypes : [])
                  .filter(({ scope }) => scope === 3)
                  .map(({ title, scope, id }) => (
                    <td
                      key={title + scope}
                      className="px-3  text-center"
                      style={{ width: "200px" }}
                    >
                      <big>
                        {formatNumber(
                          (
                            (parseFloat(
                              decarbEmissions?.find(
                                (item) => item?.activityType?.id === id
                              )?.emissions
                            )?.toFixed() /
                              100) *
                            parseFloat(
                              orgActions.reduce(
                                (prev, curr) =>
                                  prev +
                                  curr?.reductions?.reduce(
                                    (acc, item) =>
                                      item.yearEnded.includes(year) &&
                                      item?.activityType?.id === id
                                        ? acc +
                                          parseFloat(item.reductionPercentage)
                                        : acc,
                                    0
                                  ),
                                0
                              )
                            )
                          ).toFixed(),
                          0
                        ) || 0}
                      </big>
                    </td>
                  ))}
              </tr>
            </tfoot>
          </Table>
        </div>
      )}
      {/* 
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"625daa9813bea"} view={"full"} />
      </div> */}
    </div>
  );
};

export default ReductionPotential;
