import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";

import * as yup from "yup";
import { Formik, Field } from "formik";
import NumberFormat from "react-number-format";

import { put, post, destroy } from "utils/DeApi";
import { addUnitsToDuplicateEmissionFactors } from "utils/emissionFactors";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import SelectInputFormik from "components/SelectInput/SelectInput";

import { getAllYearsBetweenDates } from "utils/dateUtils";
import CurrencyConverter from "components/Converter/CurrencyConverter/CurrencyConverter";

const TopDownUpdate = ({
  organization,
  organizationInfo,
  emissionFactors,
  onTopDownUpdated,
  onTopDownDeleted,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    year: yup.string().required("Year is required"),
    revenue: yup
      .number()
      .min(0)
      .max(
        9999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999)}.`
      )
      .required("Annual Revenue is Required"),
    location: yup
      .string()
      .required("Organization Primary Location is Required"),
    industry: yup.string().required("Organization industry is required"),
    rawUnit: yup.string(),
    rawAmount: yup
      .number()
      .min(0)
      .max(
        9999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999)}.`
      ),
  });

  const updateTopDown = ({
    year,
    revenue,
    location,
    industry,
    rawAmount,
    rawUnit,
  }) => {
    setError(null);
    setIsLoading(true);

    let yearInfo = organizationInfo.find(
      (item) => item.yearEnded.substring(0, 4) === year
    );

    const organizationPromise = yearInfo
      ? put(`organization-details/${yearInfo.id}`, {
          revenue: revenue,
          location: location,
          industry: _getEmissionFactorFromId(industry).emissionResource,
          emissionFactorId: industry,
          rawAmount,
          rawUnit,
        })
      : post(`organizations/${organization.id}/organization-details`, {
          yearEnded: `${year}-12-31`,
          revenue: revenue,
          location: location,
          industry: _getEmissionFactorFromId(industry).emissionResource,
          emissionFactorId: industry,
          rawAmount,
          rawUnit,
        });

    organizationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onTopDownUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const deleteTopDown = (orgInfo) => {
    setError(null);
    setIsLoading(true);
    const organizationPromise = destroy(`organization-details/${orgInfo.id}`);
    organizationPromise.promise
      .then(() => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onTopDownDeleted(orgInfo);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  function _getSectorsFromCountry(country) {
    const emFactors = emissionFactors.filter((factor) => {
      const isMatchingCountry = factor.metadata?.find(
        (ef) => ef.value === country
      );
      return isMatchingCountry;
    });
    return addUnitsToDuplicateEmissionFactors(emFactors);
  }

  const getCountriesFromEmissionFactors = (emissionFactors) => {
    const counter = {};
    // eslint-disable-next-line array-callback-return
    const countries = emissionFactors.map((ef) => {
      const country = ef.metadata?.find(
        (meta) => meta.type.toLowerCase() === "country"
      );
      if (country) {
        country.label = country.value;
        counter[country.value] = counter[country.value]
          ? (counter[country.value] += 1)
          : 1;
        if (counter[country.value] === 1) {
          return country;
        }
      }
    });
    return countries
      .filter((country) => !!country)
      .sort((a, b) => (a.value < b.value ? -1 : 1));
  };

  const _getEmissionFactorFromId = (emissionFactorId) => {
    return (
      emissionFactors.find((factor) => factor.id === emissionFactorId) || {}
    );
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        Manage Top-Down Estimation
      </Button>

      <Modal
        show={show}
        backdrop={"static"}
        onHide={handleClose}
        size="lg"
        className="right"
        fullscreen="sm-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>
              {organization.name}
              <br />
            </small>
            Manage Top-Down Estimation
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateTopDown(values)}
          validateOnMount
          initialValues={{
            year: "",
            revenue: "",
            location: "",
            industry: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Modal.Body>
                  <Form.Group controlId="activity-year" className="my-3">
                    <Form.Label>
                      Select year to estimate or update emissions.
                    </Form.Label>
                    <Field
                      as={Form.Select}
                      name="year"
                      value={values.year}
                      onChange={(ev) => {
                        let option = ev.target.value;
                        let info = organizationInfo.find(
                          (item) => item.yearEnded.substring(0, 4) === option
                        );
                        let industry = info?.emissionFactor?.id || "";
                        let location =
                          info?.emissionFactor?.metadata?.find(
                            (meta) => meta.type === "country"
                          )?.value || "";

                        setFieldValue("revenue", info ? info.revenue : "");
                        setFieldValue("location", location);
                        setFieldValue("industry", industry);
                        setFieldValue(
                          "rawUnit",
                          info?.rawUnit ? info.rawUnit : ""
                        );
                        setFieldValue(
                          "rawAmount",
                          info?.rawAmount ? info.rawAmount : ""
                        );

                        return handleChange(ev);
                      }}
                      onBlur={handleBlur}
                      isValid={values.year}
                    >
                      <option>Select year</option>
                      {getAllYearsBetweenDates().map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Field>
                  </Form.Group>

                  {values.year && (
                    <>
                      <Form.Group controlId="revenue" className="mt-2">
                        <Form.Label>Annual Revenue (k$)</Form.Label>
                        <NumberFormat
                          customInput={Form.Control}
                          value={values.revenue}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            setFieldValue("revenue", value);
                          }}
                          name="revenue"
                          prefix="$"
                          suffix="k"
                          placeholder="Enter annual revenue"
                          onBlur={handleBlur}
                          isValid={values.revenue}
                          isInvalid={errors.revenue && touched.revenue}
                          className="md-6"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.revenue}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <CurrencyConverter
                        setFieldValue={setFieldValue}
                        reportingYear={values?.year?.toString()}
                        values={values}
                        emissionUnit={"USD"}
                        selectedOption={{
                          label: values?.rawUnit,
                          value: values?.rawUnit,
                        }}
                        module="topdown"
                      />

                      <Form.Group controlId="location" className="my-3">
                        <Form.Label>
                          Primary Location (If not available select the closest
                          available location)
                        </Form.Label>
                        <Field
                          name="location"
                          key={values.year}
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.location}
                        >
                          {({ field, form }) => {
                            return (
                              <SelectInputFormik
                                name="location"
                                values={values}
                                options={getCountriesFromEmissionFactors(
                                  emissionFactors
                                )}
                                onChange={(option) => {
                                  setFieldValue("location", option.value);
                                  setFieldValue("industry", null);
                                  return option;
                                }}
                                form={form}
                                field={field}
                              />
                            );
                          }}
                        </Field>
                        <Form.Control.Feedback type="invalid">
                          {errors.location}
                        </Form.Control.Feedback>
                      </Form.Group>

                      {values.location && (
                        <Form.Group controlId="industry" className="my-3">
                          <Form.Label>Industry</Form.Label>
                          <Field
                            name="industry"
                            key={values.location}
                            value={values.industry}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={values.industry}
                          >
                            {({ field, form }) => (
                              <SelectInputFormik
                                name="industry"
                                values={values}
                                options={_getSectorsFromCountry(
                                  values.location
                                )}
                                form={form}
                                field={field}
                              />
                            )}
                          </Field>
                          {values.industry && !!emissionFactors.length && (
                            <Form.Text>
                              EF{" "}
                              {
                                _getEmissionFactorFromId(values.industry)
                                  .emissionFactor
                              }{" "}
                              <small>
                                tCO<sub>2</sub>e
                              </small>
                              {""}/
                              {_getEmissionFactorFromId(values.industry).unit} (
                              {
                                _getEmissionFactorFromId(values.industry)
                                  .reference
                              }
                              ), Inflation Factor{" "}
                              {
                                _getEmissionFactorFromId(
                                  values.industry
                                )?.dataset?.inflationFactors?.find(
                                  (item) =>
                                    Number.parseInt(item.year) ===
                                    Number.parseInt(values.year)
                                )?.value
                              }
                            </Form.Text>
                          )}
                          <Form.Control.Feedback type="invalid">
                            {errors.industry}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    </>
                  )}

                  {error && <ErrorHandler error={error} />}
                  {isLoading && <Loader />}
                </Modal.Body>

                <Modal.Footer>
                  <Button size="sm" variant="link" onClick={handleClose}>
                    Cancel
                  </Button>
                  {organizationInfo.find(
                    (item) => item.yearEnded.substring(0, 4) === values.year
                  ) && (
                    <Button
                      size="sm"
                      className="bg-danger text-danger border-0 bg-opacity-25"
                      onClick={() => {
                        let info = organizationInfo.find(
                          (item) =>
                            item.yearEnded.substring(0, 4) === values.year
                        );
                        deleteTopDown(info);
                        setFieldValue("revenue", null);
                        setFieldValue("location", null);
                        setFieldValue("industry", null);
                      }}
                    >
                      Clear
                    </Button>
                  )}
                  <Button
                    onClick={handleSubmit}
                    size="sm"
                    disabled={!isValid || isLoading}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

TopDownUpdate.propTypes = {
  organization: PropTypes.object.isRequired,
  organizationInfo: PropTypes.array.isRequired,
  emissionFactors: PropTypes.array.isRequired,
  onTopDownUpdated: PropTypes.func.isRequired,
  onTopDownDeleted: PropTypes.func.isRequired,
};

export default TopDownUpdate;
