import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React from "react";
import { Alert, NavDropdown } from "react-bootstrap";

import { Link } from "react-router-dom";

import { useContentContext } from "contexts/ContentContext";

import "./WhatsNew.scss";

const WhatsNew = () => {
  const { filteredContent, bookmarkableIds, notification, isLoading, error } =
    useContentContext() || {};

  return (
    <div className="WhatsNew">
      <NavDropdown
        align="end"
        title={
          <>
            What's New{" "}
            {notification?.length > 0 && (
              <sup>
                <span className="fs-5 text-orange">&#9679;</span>
              </sup>
            )}
          </>
        }
      >
        {error && <ErrorHandler error={error} />}

        <div className="DropDown mb-2">
          <Link to="/all-updates" className="float-end text-decoration-none ">
            <small>View all updates</small>
          </Link>
          <h2>What's New</h2>

          <NavDropdown.Divider />
          {isLoading ? (
            <Loader />
          ) : (
            <WhatsNewBody
              filteredContent={filteredContent}
              bookmarkableIds={bookmarkableIds}
            />
          )}
        </div>
      </NavDropdown>
    </div>
  );
};

const WhatsNewBody = ({ filteredContent, bookmarkableIds, allUpdates }) => {
  return (
    <>
      {filteredContent.length !== 0 ? (
        <div className="mt-3">
          {filteredContent?.map((item, index) => (
            <div key={item.contentId}>
              <NavDropdown.Item as="div" className="p-0 bg-transparent">
                <Link
                  to={`/contents/${item.contentId}`}
                  className="p-0 text-decoration-none"
                >
                  {allUpdates ? (
                    <h3>
                      {item.title}{" "}
                      {!bookmarkableIds?.includes(item.contentId) && (
                        <sup>
                          <span className="fs-5 ms-1 text-orange">&#9679;</span>
                        </sup>
                      )}
                    </h3>
                  ) : (
                    <small>
                      {item.title}{" "}
                      {!bookmarkableIds?.includes(item.contentId) && (
                        <sup>
                          <span className="fs-5 ms-1 text-orange">&#9679;</span>
                        </sup>
                      )}
                    </small>
                  )}
                </Link>
                <span className="float-end gray">
                  <small>
                    {new Date(item.createdAt).toLocaleString("default", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </small>
                </span>
              </NavDropdown.Item>
              {allUpdates ? (
                <p className="gray w-75">{item.description}</p>
              ) : (
                <span className="gray">
                  <small>
                    {item?.description && item?.description?.length > 150
                      ? item?.description?.substring(0, 157) + "..."
                      : item?.description}
                  </small>
                </span>
              )}
              {index < filteredContent.length - 1 && <hr className="divider" />}
            </div>
          ))}
        </div>
      ) : (
        <Alert variant="info" className="my-2">
          There is currently nothing to show here.
        </Alert>
      )}
    </>
  );
};

export { WhatsNew, WhatsNewBody };
