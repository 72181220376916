import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Col, Offcanvas, Spinner, Form } from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import * as yup from "yup";
import { post, put } from "utils/DeApi";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import NumberFormat from "react-number-format";

const ManageReductions = ({
  emissions,
  onManageReductionsUpdated,
  sbtiConfig,
  allEmissions,
  currentType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const { organizationId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    reductionPercentageOne: yup
      .number()
      .label("Reduction percentage")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    reductionPercentageTwo: yup
      .number()
      .label("Reduction percentage")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    limitReductionPercentage: yup
      .number()
      .label("Reduction percentage")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      ),
    nearTermLimitReductionPercentage: yup
      .number()
      .label("% reduction limit")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`)
      .required(),
    sopeThreeReductionPercentageOne: yup
      .number()
      .label("Reduction percentage")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    sopeThreeReductionPercentageTwo: yup
      .number()
      .label("Reduction percentage")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    sopeThreeLimitReductionPercentage: yup
      .number()
      .label("Reduction percentage")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      ),
    netZeroLimitReductionPercentage: yup
      .number()
      .label("% reduction limit")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`)
      .required(),
  });

  const updateReduction = ({
    reductionPercentageOne,
    reductionPercentageTwo,
    limitReductionPercentage,
    nearTermLimitReductionPercentage,
    sopeThreeReductionPercentageOne,
    sopeThreeReductionPercentageTwo,
    sopeThreeLimitReductionPercentage,
    netZeroLimitReductionPercentage,
  }) => {
    setError(null);
    setIsLoading(true);
    const data = {
      nearTermScopeOneAnnualReductionPercentage: reductionPercentageOne,
      nearTermScopeTwoAnnualReductionPercentage: reductionPercentageTwo,
      nearTermScopeThreeAnnualReductionPercentage: !isNaN(
        Number(limitReductionPercentage)
      )
        ? Number(limitReductionPercentage)
        : "",
      nearTermLimitReductionPercentage,
      netZeroScopeOneAnnualReductionPercentage: sopeThreeReductionPercentageOne,
      netZeroScopeTwoAnnualReductionPercentage: sopeThreeReductionPercentageTwo,
      netZeroScopeThreeAnnualReductionPercentage: !isNaN(
        Number(sopeThreeLimitReductionPercentage)
      )
        ? Number(sopeThreeLimitReductionPercentage)
        : "",
      netZeroLimitReductionPercentage,
    };
    const organizationPromise = put(
      `science-based-target-settings/${emissions?.id}`,
      data
    );
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onManageReductionsUpdated(
          response?.data?.[0]?.settings?.find(
            (setting) => setting?.type === currentType
          )
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      <Button size="sm" onClick={handleShow} className="float-end">
        Manage Reductions
      </Button>
      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title className="fs-4">Manage Reductions</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {error && <ErrorHandler error={error} />}
          <Formik
            validationSchema={schema}
            onSubmit={(values) => updateReduction(values)}
            initialValues={{
              reductionPercentageOne:
                emissions?.nearTermScopeOneAnnualReductionPercentage > 0
                  ? emissions?.nearTermScopeOneAnnualReductionPercentage
                  : 2.5,
              reductionPercentageTwo:
                emissions?.nearTermScopeTwoAnnualReductionPercentage > 0
                  ? emissions?.nearTermScopeTwoAnnualReductionPercentage
                  : 4.2,
              limitReductionPercentage:
                emissions?.nearTermScopeThreeAnnualReductionPercentage || 50,
              nearTermLimitReductionPercentage:
                emissions?.nearTermLimitReductionPercentage || 10,
              sopeThreeReductionPercentageOne:
                emissions?.netZeroScopeOneAnnualReductionPercentage > 0
                  ? emissions?.netZeroScopeOneAnnualReductionPercentage
                  : 2.5,
              sopeThreeReductionPercentageTwo:
                emissions?.netZeroScopeTwoAnnualReductionPercentage > 0
                  ? emissions?.netZeroScopeTwoAnnualReductionPercentage
                  : 4.2,
              sopeThreeLimitReductionPercentage:
                emissions?.netZeroScopeThreeAnnualReductionPercentage || 50,
              netZeroLimitReductionPercentage:
                emissions?.netZeroLimitReductionPercentage || 10,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <h3 className="mb-3">
                  Near-Term Targets ({allEmissions?.baselineYear || 2022}
                  {" - "}
                  {allEmissions?.nearTermTargetYear || 2030})
                </h3>
                {sbtiConfig?.length ? (
                  <Form.Group
                    controlId="reductionPercentageOne"
                    className="mb-2"
                  >
                    <Form.Label>
                      Scope 1: % reduction per annum from base year
                      <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                    </Form.Label>
                    <NumberFormat
                      name="reductionPercentageOne"
                      size="sm"
                      value={values.reductionPercentageOne}
                      customInput={Form.Control}
                      thousandSeparator={true}
                      onValueChange={(numberItem) => {
                        setFieldValue(
                          "reductionPercentageOne",
                          numberItem.value
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.reductionPercentageOne}
                      isInvalid={
                        errors.reductionPercentageOne &&
                        touched.reductionPercentageOne
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reductionPercentageOne}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : null}
                {sbtiConfig?.length ? (
                  <Form.Group
                    controlId="reductionPercentageTwo"
                    className="my-2"
                  >
                    <Form.Label>
                      Scope 2: % reduction per annum from base year
                      <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                    </Form.Label>
                    <NumberFormat
                      name="reductionPercentageTwo"
                      size="sm"
                      value={values.reductionPercentageTwo}
                      customInput={Form.Control}
                      thousandSeparator={true}
                      onValueChange={(numberItem) => {
                        setFieldValue(
                          "reductionPercentageTwo",
                          numberItem.value
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.reductionPercentageTwo}
                      isInvalid={
                        errors.reductionPercentageTwo &&
                        touched.reductionPercentageTwo
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reductionPercentageTwo}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : null}

                <Form.Group
                  controlId="limitReductionPercentage"
                  className="my-2"
                >
                  <Form.Label>
                    Scope 3: % reduction per annum from base year
                  </Form.Label>
                  <NumberFormat
                    name="limitReductionPercentage"
                    size="sm"
                    value={values.limitReductionPercentage}
                    customInput={Form.Control}
                    thousandSeparator={true}
                    onValueChange={(numberItem) => {
                      setFieldValue(
                        "limitReductionPercentage",
                        numberItem.value
                      );
                    }}
                    onBlur={handleBlur}
                    isValid={values.limitReductionPercentage}
                    isInvalid={
                      errors.limitReductionPercentage &&
                      touched.limitReductionPercentage
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.limitReductionPercentage}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="nearTermLimitReductionPercentage"
                  className="my-2"
                >
                  <Form.Label>% reduction limit</Form.Label>
                  <NumberFormat
                    name="nearTermLimitReductionPercentage"
                    size="sm"
                    value={values.nearTermLimitReductionPercentage}
                    customInput={Form.Control}
                    thousandSeparator={true}
                    onValueChange={(numberItem) => {
                      setFieldValue(
                        "nearTermLimitReductionPercentage",
                        numberItem.value
                      );
                    }}
                    onBlur={handleBlur}
                    isValid={values.nearTermLimitReductionPercentage}
                    isInvalid={
                      errors.nearTermLimitReductionPercentage &&
                      touched.nearTermLimitReductionPercentage
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nearTermLimitReductionPercentage}
                  </Form.Control.Feedback>
                </Form.Group>

                <h3 className="mt-4 mb-3">
                  Net-Zero Targets ({allEmissions?.baselineYear || 2022}
                  {" - "}
                  {allEmissions?.netzeroTargetYear || 2050})
                </h3>
                {sbtiConfig?.length ? (
                  <Form.Group
                    controlId="sopeThreeReductionPercentageOne"
                    className="mb-2"
                  >
                    <Form.Label>
                      Scope 1: % absolute reduction from base year
                      <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                    </Form.Label>
                    <NumberFormat
                      name="sopeThreeReductionPercentageOne"
                      size="sm"
                      value={values.sopeThreeReductionPercentageOne}
                      customInput={Form.Control}
                      thousandSeparator={true}
                      onValueChange={(numberItem) => {
                        setFieldValue(
                          "sopeThreeReductionPercentageOne",
                          numberItem.value
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.sopeThreeReductionPercentageOne}
                      isInvalid={
                        errors.sopeThreeReductionPercentageOne &&
                        touched.sopeThreeReductionPercentageOne
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sopeThreeReductionPercentageOne}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : null}
                {sbtiConfig?.length ? (
                  <Form.Group
                    controlId="sopeThreeReductionPercentageTwo"
                    className="my-2"
                  >
                    <Form.Label>
                      Scope 2: % absolute reduction from base year
                      <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                    </Form.Label>
                    <NumberFormat
                      name="sopeThreeReductionPercentageTwo"
                      size="sm"
                      value={values.sopeThreeReductionPercentageTwo}
                      customInput={Form.Control}
                      thousandSeparator={true}
                      onValueChange={(numberItem) => {
                        setFieldValue(
                          "sopeThreeReductionPercentageTwo",
                          numberItem.value
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.sopeThreeReductionPercentageTwo}
                      isInvalid={
                        errors.sopeThreeReductionPercentageTwo &&
                        touched.sopeThreeReductionPercentageTwo
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sopeThreeReductionPercentageTwo}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : null}
                <Form.Group
                  controlId="sopeThreeLimitReductionPercentage"
                  className="my-2"
                >
                  <Form.Label>
                    Scope 3: % absolute reduction from base year
                  </Form.Label>
                  <NumberFormat
                    name="sopeThreeLimitReductionPercentage"
                    size="sm"
                    value={values.sopeThreeLimitReductionPercentage}
                    customInput={Form.Control}
                    thousandSeparator={true}
                    onValueChange={(numberItem) => {
                      setFieldValue(
                        "sopeThreeLimitReductionPercentage",
                        numberItem.value
                      );
                    }}
                    onBlur={handleBlur}
                    isValid={values.sopeThreeLimitReductionPercentage}
                    isInvalid={
                      errors.sopeThreeLimitReductionPercentage &&
                      touched.sopeThreeLimitReductionPercentage
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.sopeThreeLimitReductionPercentage}
                  </Form.Control.Feedback>
                </Form.Group>
                <Col className="pt-4">
                  <Button size="sm" variant="link" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    className="float-end ms-2"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner animation="border" size="sm" className="me-2" />
                    )}
                    Save
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageReductions.propTypes = {
  onManageReductionsUpdated: PropTypes.func.isRequired,
};

export default ManageReductions;
