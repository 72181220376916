import React, { useContext } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";

import Feedback from "../Feedback/Feedback";
import { UserContext } from "contexts/UserProvider";

import Help from "../Help/Help";
import DataLink from "../DataLink/DataLink";
import AccountToggler from "components/Account/AccountToggler/AccountToggler";
import { WhatsNew } from "../WhatsNew/WhatsNew";
import { AccountContext } from "contexts/AccountProvider";
import UserCategories from "utils/userCategories";

const Header = ({ title }) => {
  const user = useContext(UserContext);
  const account = useContext(AccountContext);

  const userCategory = UserCategories();

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || ""}</title>
      </Helmet>
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        fixed="top"
        className="border-bottom MainHeader"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <span className="d-flex  align-items-center justify-content-center">
              <span className="border-end border-2 pe-2">
                <span className="text-center">
                  Net Zero Compass{" "}
                  <sub>
                    <sub>by</sub>
                  </sub>
                </span>{" "}
              </span>
              <span>
                <img
                  className="img-responsive"
                  src="/logo.svg"
                  width="144px"
                  alt="Logo"
                />{" "}
              </span>
            </span>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <Feedback />
              <Help />
              <WhatsNew />
              <AccountToggler />
              <NavDropdown align="end" title={`Hi, ${user?.firstName}`}>
                <NavDropdown.Item id={userCategory} disabled>
                  {user?.email}
                </NavDropdown.Item>
                {(account.member.role === "owner" ||
                  account.member.role === "admin") && (
                  <>
                    <NavDropdown.Item as={Link} to="/account-management">
                      Manage <i>{account.name}</i>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/audit-trails">
                      Audit Trails
                    </NavDropdown.Item>

                    <DataLink />
                  </>
                )}
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
