import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  ButtonGroup,
  Alert,
  Form,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import AbatementChart from "./AbatementChart/AbatementChart";
import AbatementProjectCreate from "./AbatementProjectCreate/AbatementProjectCreate";
import AbatementProjectDelete from "./AbatementProjectDelete/AbatementProjectDelete";
import AbatementProjectEdit from "./AbatementProjectEdit/AbatementProjectEdit";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import {
  locationsMap,
  projectCombinedStatusMap,
  projectEvalutionStatusMap,
  projectStatusMap,
  projectTypesMap,
} from "./constants";
import AbatementLibrary from "./AbatementLibrary/AbatementLibrary";
import UserCategories from "utils/userCategories";

const Abatement = ({
  organization,
  sites,
  onAbatementUpdated,
  status = [],
  setStatus,
  type = [],
  setType,
  site = [],
  setSite,
  onChartRendered,
  isShow,
}) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const userCategory = UserCategories();

  const handleSelect = (eventKey, cb, valArr, prevVal) => {
    if (eventKey === "clear") {
      cb([]);
    } else {
      const option = valArr.find(
        (o) => o?.key == eventKey || o?.id == eventKey
      );
      const newSelectedOptions = [...prevVal];
      const index = newSelectedOptions.findIndex(
        (o) =>
          (o?.key && o?.key === option?.key) || (o?.id && o?.id === option?.id)
      );
      if (index !== -1) {
        newSelectedOptions.splice(index, 1);
      } else {
        newSelectedOptions.push(option);
      }
      cb(newSelectedOptions);
    }
  };

  const gettitle = (arr, filter) => {
    return arr.length === 0
      ? `Filter by ${filter}`
      : arr.map((option) => option?.value || option?.name).join(", ");
  };

  function handleOnAbatementProjectCreated(project) {
    setProjects((prevProjects) => [project, ...prevProjects]);
    onAbatementUpdated();
  }

  function handleOnAbatementProjectDeleted(project) {
    setProjects((prevProjects) =>
      prevProjects.filter(({ id }) => id !== project.id)
    );
    onAbatementUpdated();
  }

  function handleOnAbatementProjectEdited(project) {
    setProjects((prevProjects) =>
      prevProjects.map((p) => (p.id === project.id ? project : p))
    );
    onAbatementUpdated();
  }

  function getAbatementPotential() {
    if (projects.length === 0) return 0;
    return projects
      .filter((project) => {
        if (!type.length) return 1;
        return type.filter(
          (val) => parseInt(project?.projectType) === parseInt(val.key)
        ).length;
      })
      .filter((project) => {
        if (!status.length) return 1;
        return status.filter(
          (val) => parseInt(project?.projectStatus) === parseInt(val.key)
        ).length;
      })
      .filter((project) => {
        if (!site.length) return 1;
        return site.filter((val) => project?.site?.id === val.id).length;
      })
      .filter(
        ({ annualCarbonEmissionReduction }) =>
          !isNaN(annualCarbonEmissionReduction)
      )
      .reduce(
        (previous, { annualCarbonEmissionReduction: current }) =>
          previous + current,
        0
      );
  }

  useEffect(() => {
    const fetchAbatementProjects = () => {
      setError(null);
      setIsLoading(true);
      const organizationPromise = get(
        `organizations/${organizationId}/abatement-projects`
      );
      organizationPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setProjects(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    fetchAbatementProjects();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId]);

  return (
    <div id={userCategory}>
      {isShow && (
        <div className="my-3">
          <BreadCrumbs
            breadcrumbs={[
              { name: "Dashboard", link: "/" },
              {
                name: organization.name,
                link: `/organizations/${organization.id}`,
              },
              {
                name: "Abatement Planning",
                link: `/organizations/${organization.id}/abatement`,
                active: true,
              },
            ]}
          />
        </div>
      )}
      <div className="d-inline-flex float-end">
        <div className="mx-1 px-3 bg-success text-success bg-opacity-10">
          <span className="fs-1">
            {new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 3,
            }).format(getAbatementPotential())}{" "}
          </span>
          <small>
            tCO<sub>2</sub>e
          </small>{" "}
          <small>/year Abatement Potential</small>
        </div>
      </div>
      {isShow ? (
        <h2 className="mb-4 pt-2">Abatement Planning</h2>
      ) : (
        <h3 className="mb-4">Abatement Planning</h3>
      )}
      <div className={`${isShow ? "my-3 p-3 border" : ""}`}>
        {isShow && (
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="m-0">
              <span>
                Marginal Abatement Cost Curve
                <br />
                <small>In {new Date().getFullYear()} Value</small>
              </span>
            </h3>
            <div>
              <AbatementLibrary
                sites={sites}
                onAbatementProjectCreated={handleOnAbatementProjectCreated}
              />
              <AbatementProjectCreate
                sites={sites}
                onAbatementProjectCreated={handleOnAbatementProjectCreated}
              />
            </div>
          </div>
          // </h3>
        )}
        <hr />
        <Row>
          <Form.Group
            as={Col}
            xs={12}
            md={4}
            controlId="Sectors"
            className="mb-3"
          >
            <DropdownButton
              title={gettitle(status, "status")}
              id="multi-select-dropdown"
              onSelect={(e) =>
                handleSelect(
                  e,
                  setStatus,
                  projectCombinedStatusMap.sort((a, b) => a.order - b.order),
                  status
                )
              }
              variant="outline-dark"
              className="no-hover"
            >
              {projectCombinedStatusMap
                .sort((a, b) => a.order - b.order)
                .map(({ key, value }) => (
                  <Dropdown.Item
                    key={key}
                    eventKey={key}
                    active={status.some((o) => o.value === value)}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
              <Dropdown.Divider />
              <Dropdown.Item eventKey="clear" disabled={status.length === 0}>
                Clear selections
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            md={4}
            controlId="Sectors"
            className="mb-3"
          >
            <DropdownButton
              title={gettitle(type, "type")}
              id="multi-select-dropdown"
              onSelect={(e) => handleSelect(e, setType, projectTypesMap, type)}
              variant="outline-dark"
              className="no-hover"
            >
              {projectTypesMap.map(({ key, value }) => (
                <Dropdown.Item
                  key={key}
                  eventKey={key}
                  active={type.some((o) => o.value === value)}
                >
                  {value}
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
              <Dropdown.Item eventKey="clear" disabled={type.length === 0}>
                Clear selections
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>
          <Form.Group as={Col} xs={12} md={4} controlId="site" className="mb-3">
            <DropdownButton
              title={gettitle(site, "site")}
              id="multi-select-dropdown"
              onSelect={(e) => handleSelect(e, setSite, sites, site)}
              variant="outline-dark"
              className="no-hover"
            >
              {sites.map(({ id, name }) => (
                <Dropdown.Item
                  key={id}
                  eventKey={id}
                  active={site.some((o) => o.name === name)}
                >
                  {name}
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
              <Dropdown.Item eventKey="clear" disabled={site.length === 0}>
                Clear selections
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>
        </Row>
        <div className="my-3">
          {!isLoading && !error && (
            <AbatementChart
              data={
                !min ||
                !Number.isFinite(min) ||
                Number.isNaN(min) ||
                !max ||
                !Number.isFinite(max) ||
                Number.isNaN(max)
                  ? projects
                  : projects
                      .filter((project) => {
                        if (!type.length) return true;
                        return type.filter(
                          (val) =>
                            parseInt(project?.projectType) === parseInt(val.key)
                        ).length;
                      })
                      .filter((project) => {
                        if (!status.length) return true;
                        return status.filter(
                          (val) =>
                            parseInt(project?.projectStatus) ===
                            parseInt(val.key)
                        ).length;
                      })
                      .filter((project) => {
                        if (!site.length) return true;
                        return site.filter(
                          (val) => project?.site?.id === val.id
                        ).length;
                      })
                      .map((project) => project)
              }
              max={max}
              min={min}
              setMin={setMin}
              setMax={setMax}
              onChartRendered={onChartRendered}
            />
          )}
          {error && !isLoading && <ErrorHandler error={error} />}
          {isLoading && <Loader />}
        </div>
      </div>
      {isShow && <h4>Abatement Opportunities</h4>}
      {isShow && (
        <Table
          size="sm"
          responsive
          striped
          className="my-3 first-column-fixed last-column-fixed"
        >
          <thead>
            <tr>
              <th className="text-nowrap px-3 mw-50 projectCol">Project</th>
              <th className="text-nowrap px-3">Status</th>
              <th className="text-nowrap px-3">Type</th>
              <th className="text-nowrap px-3">Location</th>
              <th className="text-end text-nowrap px-3">
                Project Lifetime <small>(Yrs)</small>
              </th>
              <th className="text-end text-nowrap px-3">
                Payback Period <small>(Yrs)</small>
              </th>
              <th className="text-end text-nowrap px-3">
                Internal Rate of Return
              </th>
              <th className="text-end text-nowrap px-3">Net Present Value</th>
              <th className="text-end text-nowrap px-3">
                Lifetime Emissions Reduction
              </th>
              <th className="text-end text-nowrap px-3">
                MAC{" "}
                <small>
                  ($ per tCO<sub>2</sub>e)
                </small>
              </th>
              <th className="text-end text-nowrap px-3">Initial Investment</th>
              <th className="text-end text-nowrap px-3">
                Annual Emissions Reduction
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects
              .filter((project) => {
                if (!type.length) return true;
                return type.filter(
                  (val) => parseInt(project?.projectType) === parseInt(val.key)
                ).length;
              })
              .filter((project) => {
                if (!status.length) return true;
                return status.filter(
                  (val) =>
                    parseInt(project?.projectStatus) === parseInt(val.key)
                ).length;
              })
              .filter((project) => {
                if (!site.length) return true;
                return site.filter((val) => project?.site?.id === val.id)
                  .length;
              })
              .map((project) => (
                <tr key={project?.id}>
                  <td
                    className={`${
                      project?.name?.length > 30 ? "text-wrap" : "text-nowrap"
                    } px-3 projectCol mw-50`}
                  >
                    {project?.name}{" "}
                    <abbr
                      title={project?.description || "No description added."}
                    >
                      <span className="material-icons-outlined md-18">
                        info
                      </span>
                    </abbr>
                  </td>
                  <td className="text-nowrap px-3 align-middle">
                    {
                      projectCombinedStatusMap.find(
                        ({ key }) =>
                          parseInt(key) === parseInt(project?.projectStatus)
                      )?.value
                    }
                  </td>
                  <td className="text-nowrap px-3 align-middle">
                    {
                      projectTypesMap.find(
                        ({ key }) =>
                          parseInt(key) === parseInt(project?.projectType)
                      )?.value
                    }
                  </td>
                  <td className="text-nowrap px-3 align-middle">
                    {isNaN(Number(project?.location))
                      ? project?.location
                      : locationsMap.find(
                          ({ key }) =>
                            parseInt(key) === parseInt(project?.location)
                        )?.value}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {project?.projectLifetime}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {project?.paybackPeriod}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {!isNaN(Number(project?.internalRateOfReturn))
                      ? new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 3,
                          style: "percent",
                        }).format(project?.internalRateOfReturn / 100)
                      : "N/A"}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(project?.netPresentValue)}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 3,
                    }).format(project?.lifetimeCarbonEmissionReduction)}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 3,
                    }).format(project?.marginalAbatementCost)}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(project?.initialInvestment)}
                  </td>
                  <td className="text-end text-nowrap px-3 align-middle">
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 3,
                    }).format(project?.annualCarbonEmissionReduction)}
                  </td>
                  <td className="text-end align-middle">
                    <ButtonGroup aria-label="First group" size="sm">
                      <AbatementProjectEdit
                        sites={sites}
                        project={project}
                        onAbatementProjectEdited={
                          handleOnAbatementProjectEdited
                        }
                      />
                      <AbatementProjectDelete
                        project={project}
                        onAbatementProjectDeleted={
                          handleOnAbatementProjectDeleted
                        }
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-nowrap px-3">Total</td>
              <td colSpan={10} className="text-end text-nowrap px-3">
                <big>
                  {Intl.NumberFormat("en-us", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    projects
                      .filter((project) => {
                        if (!type.length) return true;
                        return type.filter(
                          (val) =>
                            parseInt(project?.projectType) === parseInt(val.key)
                        ).length;
                      })
                      .filter((project) => {
                        if (!status.length) return true;
                        return status.filter(
                          (val) =>
                            parseInt(project?.projectStatus) ===
                            parseInt(val.key)
                        ).length;
                      })
                      .filter((project) => {
                        if (!site.length) return true;
                        return site.filter(
                          (val) => project?.site?.id === val.id
                        ).length;
                      })
                      .reduce((acc, curr) => {
                        return acc + curr?.initialInvestment;
                      }, 0)
                  )}
                </big>{" "}
              </td>
              <td className="text-end text-nowrap px-3">
                <big>
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 3,
                  }).format(
                    projects
                      .filter((project) => {
                        if (!type.length) return true;
                        return type.filter(
                          (val) =>
                            parseInt(project?.projectType) === parseInt(val.key)
                        ).length;
                      })
                      .filter((project) => {
                        if (!status.length) return true;
                        return status.filter(
                          (val) =>
                            parseInt(project?.projectStatus) ===
                            parseInt(val.key)
                        ).length;
                      })
                      .filter((project) => {
                        if (!site.length) return true;
                        return site.filter(
                          (val) => project?.site?.id === val.id
                        ).length;
                      })
                      .reduce((acc, curr) => {
                        return acc + curr?.annualCarbonEmissionReduction;
                      }, 0)
                  )}
                </big>{" "}
              </td>
              <td></td>
            </tr>
          </tfoot>
        </Table>
      )}
      {isShow &&
        !error &&
        !isLoading &&
        projects
          .filter((project) => {
            if (!type.length) return true;
            return type.filter(
              (val) => parseInt(project?.projectType) === parseInt(val.key)
            ).length;
          })
          .filter((project) => {
            if (!status.length) return true;
            return status.filter(
              (val) => parseInt(project?.projectStatus) === parseInt(val.key)
            ).length;
          })
          .map((project) => project).length === 0 && (
          <Alert variant="info">There is currently nothing to show here.</Alert>
        )}
      {isShow && (
        <div className="my-4 p-3 border">
          <ContentDetails contentId={"625daa9813bea"} view={"full"} />
        </div>
      )}
    </div>
  );
};

export default Abatement;
