import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Badge, Table } from "react-bootstrap";
import InvitationUpdate from "../Invitation/InvitationUpdate/InvitationUpdate";
import MemberDelete from "../Member/MemberDelete/MemberDelete";
import MemberUpdate from "../Member/MemberUpdate/MemberUpdate";

const MemberList = ({
  members,
  account,
  organizations,
  portfolios,
  onMemberUpdated,
  onInvitationUpdated,
  onMemberDeleted,
}) => {
  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });

  useEffect(() => {
    const data = [...members];
    data.sort((a, b) => (a["updatedAt"] <= b["updatedAt"] ? 1 : -1));
    setTableData(data);
  }, [members]);

  const sortTable = (column) => {
    tableData.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  return (
    <>
      <Table hover responsive size="lg">
        <thead>
          <tr>
            <th className="table-sorter" onClick={() => sortTable("email")}>
              Email
              <ColumnSorter name={"email"} sortState={tableSortState} />
            </th>
            <th className="table-sorter" onClick={() => sortTable("role")}>
              Role
              <ColumnSorter name={"role"} sortState={tableSortState} />
            </th>
            <th className="table-sorter" onClick={() => sortTable("joinedAt")}>
              Joined At
              <ColumnSorter name={"joinedAt"} sortState={tableSortState} />
            </th>
            <th className="table-sorter" onClick={() => sortTable("updatedAt")}>
              Updated At
              <ColumnSorter name={"updatedAt"} sortState={tableSortState} />
            </th>
            <th />
          </tr>
        </thead>

        <tbody>
          {tableData.map((member) => {
            return (
              <tr key={member.id}>
                <td>{member.email}</td>
                <td>
                  <Badge className="bg-secondary text-white bg-rounded px-1 me-1">
                    {member.role}
                  </Badge>
                </td>
                <td>
                  <small>
                    {member.joinedAt ? (
                      new Date(member.joinedAt).toLocaleString([], {
                        dateStyle: "short",
                        timeStyle: "short",
                      })
                    ) : (
                      <small className="bg-warning p-1 ">
                        Pending Invitation
                      </small>
                    )}
                  </small>
                </td>
                <td>
                  <small>
                    {new Date(member.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </td>
                <td className="text-end">
                  {member.id !== account.member.id &&
                    member.id !== account.primaryOwner?.id && (
                      <>
                        {!member.joinedAt && member.invitation.token && (
                          <InvitationUpdate
                            account={account}
                            member={member}
                            onInvitationUpdated={(invite) =>
                              onInvitationUpdated(member, invite)
                            }
                          />
                        )}{" "}
                        <MemberUpdate
                          account={account}
                          member={member}
                          organizations={organizations}
                          portfolios={portfolios}
                          onMemberUpdated={onMemberUpdated}
                        />{" "}
                        <MemberDelete
                          account={account}
                          member={member}
                          onMemberDeleted={onMemberDeleted}
                        />
                      </>
                    )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

let ColumnSorter = ({ name, sortState }) => {
  return (
    <>
      {sortState.sortColumn === name && (
        <span className="material-icons-outlined mx-1 md-20 text-muted">
          {sortState.sortAsc && "arrow_downward"}
          {!sortState.sortAsc && "arrow_upward"}
        </span>
      )}
    </>
  );
};

MemberList.propTypes = {
  members: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  portfolios: PropTypes.array.isRequired,

  onMemberUpdated: PropTypes.func.isRequired,
  onInvitationUpdated: PropTypes.func.isRequired,
  onMemberDeleted: PropTypes.func.isRequired,
};

export default MemberList;
