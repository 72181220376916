import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageReductions from "./ManageReductions/ManageReductions";
import EconomicGrowthChart from "./EconomicGrowthChart/EconomicGrowthChart";
import "./EconomicGrowth.scss";
import { formatNumber, formatPercentage, invertSymbol } from "../helper";
import { getEconomicGrowth } from "../forecastingModels";

import UserCategories from "utils/userCategories";

const EconomicGrowth = ({
  organization,
  breadcrumbs,
  baselineYear,
  scopeOne,
  scopeTwo,
  scopeThree,
}) => {
  const [emission, setEmission] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [scopeThreeReduction, setScopeThreeReduction] = useState(100);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const userCategory = UserCategories();

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const organizationPromise = get(
        `organizations/${organizationId}/economic-growth`
      );
      organizationPromise.promise
        .then((response) => {
          setIsLoading(false);
          setScopeThreeReduction(
            response?.data[0]?.scopeThreeEmissionPercentage || 0
          );
          setEmission({
            ...response.data[0],
            data: getEconomicGrowth(
              response.data[0]?.data,
              scopeOne,
              scopeTwo,
              scopeThree,
              response?.data[0]?.scopeThreeEmissionPercentage || 0
            ),
          });
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId, scopeOne, scopeTwo, scopeThree]);

  return (
    <div id={userCategory}>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: "Growth in Production/Turnover",
              link: `/organizations/${organization.id}/economic-growth`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">Growth in Production/Turnover</h2>
      <div className="my-3 p-3 border">
        <h3>
          Emissions Forecast
          <ManageReductions
            onManageReductionsUpdated={(data) =>
              setEmission({
                ...data,
                data: getEconomicGrowth(
                  data.data,
                  scopeOne,
                  scopeTwo,
                  scopeThree,
                  data?.scopeThreeEmissionPercentage || 0
                ),
              })
            }
            emissions={emission}
            baselineYear={baselineYear}
          />
        </h3>
        {!isLoading && !error && <EconomicGrowthChart data={emission?.data} />}
        {error && !isLoading && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
      </div>
      <Table
        size="sm"
        responsive
        striped
        className="first-column-fixed my-3 mt-5"
      >
        <thead>
          <tr>
            <th className="px-3 text-end text-nowrap year-column">Year</th>
            <th className="px-3 text-end text-nowrap">
              % Change for Scope 1&2 due to Business Growth
            </th>
            <th className="px-3 text-end text-nowrap">
              Cumulative Change to Scope 1
            </th>
            <th className="px-3 text-end text-nowrap">
              Cumulative Change to Scope 2
            </th>
            <th className="px-3 text-end text-nowrap">
              Cumulative Change to Scope 3
            </th>
          </tr>
        </thead>
        <tbody>
          {emission?.data.map(
            (
              {
                year,
                revenue_increase_percent,
                s1_reduction_percent,
                s2_reduction_percent,
                s1_reduction,
                s2_reduction,
                s3_reduction,
              },
              index
            ) => (
              <tr key={index}>
                <td className="px-3 text-end text-nowrap year-column">
                  {year}
                </td>
                <td className="px-3 text-end text-nowrap">
                  {formatPercentage(revenue_increase_percent)}
                </td>
                <td className="px-3 text-end text-nowrap">
                  {formatNumber(invertSymbol(s1_reduction), 2)}
                </td>
                <td className="px-3 text-end text-nowrap">
                  {formatNumber(invertSymbol(s2_reduction), 2)}
                </td>
                <td className="px-3 text-end text-nowrap">
                  {formatNumber(invertSymbol(s3_reduction), 2)}
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
      {!error && !isLoading && !emission?.id && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"62eaca7272397"} view={"full"} />
      </div>
    </div>
  );
};

EconomicGrowth.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  baselineYear: PropTypes.number,
  scopeOne: PropTypes.number,
  scopeTwo: PropTypes.number,
};

export default EconomicGrowth;
