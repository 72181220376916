import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const MemberCreate = ({ account, invitations, onMemberCreated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setError(null);
    setIsLoading(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .notOneOf(
        invitations,
        "The member with this email address already exists in the account."
      )
      .required(),
    role: yup.string().oneOf(["member", "admin", "owner"]),
  });

  const createMember = ({ email, role }) => {
    setError(null);
    setIsLoading(true);

    const createMember = post(`/accounts/${account.id}/members`, {
      email: email,
      role: role.toLowerCase(),
    });

    createMember.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onMemberCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        Add Member
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Add New Member to <i>{account.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createMember(values)}
          initialValues={{ email: "", role: "member" }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="email">
                  <Form.Label>Member Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email}
                    placeholder="Enter members email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.email}
                    isInvalid={errors.email && touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="role" className="my-3">
                  <Form.Label>Members Role.</Form.Label>
                  <br />
                  <Form.Check
                    name="role"
                    type="radio"
                    label="Member"
                    inline
                    className="pe-5"
                    checked={values.role === "member"}
                    onChange={() => setFieldValue("role", "member")}
                  />

                  <Form.Check
                    name="role"
                    type="radio"
                    label="Admin"
                    className="pe-5"
                    inline
                    checked={values.role === "admin"}
                    onChange={() => setFieldValue("role", "admin")}
                  />

                  {account.member.role === "owner" && (
                    <Form.Check
                      name="role"
                      type="radio"
                      label="Owner"
                      className="pe-5"
                      inline
                      checked={values.role === "owner"}
                      onChange={() => setFieldValue("role", "owner")}
                    />
                  )}

                  <Form.Control.Feedback type="invalid">
                    {errors.admin}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                  Add Member{" "}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

MemberCreate.propTypes = {
  account: PropTypes.object.isRequired,
  onMemberCreated: PropTypes.func.isRequired,
};

export default MemberCreate;
