import Header from "components/App/Header/Header";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { get } from "utils/DeApi";
import { useSearchParams } from "react-router-dom";
import UserCategories from "utils/userCategories";

const EmissionFactors = () => {
  const [emissionFactors, setEmissionFactors] = useState([]);
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [searchParams] = useSearchParams();

  const activityTypeId = searchParams.get("activityCategoryId");

  useEffect(() => {
    const fetchEmissionFactors = () => {
      setError(null);
      setIsLoading(true);

      const emissionFactorsPromise = get(`emission-factors`, {
        params: {
          activityTypeId: searchParams.get("activityCategoryId"),
          filter: "id",
        },
      });

      emissionFactorsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);

          setEmissionFactors(response.data);
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(emissionFactorsPromise);
    };
    fetchEmissionFactors();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [searchParams]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <Header />
      <Container id={userCategory} className="MainContent py-3">
        <Row className="my-3">
          <Col xs={12} md={12}>
            <h2>
              Scope {searchParams.get("scope")} -{" "}
              {emissionFactors && emissionFactors[0]?.activityTypes[0]?.title}
            </h2>
            {/* TODO: need to revise this logic, the activityTypeId is uuid and won't match any of these */}
            {activityTypeId === "13" ? (
              <p>
                Emissions = (Cost of Capital Good)*(Emission Factor
                Corresponding To Country & Industry)*1000/(inflation factor)
              </p>
            ) : activityTypeId === "25" ? (
              <p>
                Emissions = (Investee Revenue)*(Emission Factor Corresponding To
                Country & Industry)*(Percent Equity Ownership)*1000/(inflation
                factor)
              </p>
            ) : activityTypeId === "21" || activityTypeId === "15" ? (
              <p>
                Emissions = (Quantity of resource in k€)*(Emission
                Factor)*1000/(inflation factor)
              </p>
            ) : (
              <p>
                Emissions = Quantity of Resource Used * Corresponding Emission
                Factor {["2", "5"].includes(activityTypeId) && "/ 1000"}
              </p>
            )}

            <Table hover striped className="my-4" size="sm">
              <thead>
                <tr>
                  <th>Activity Type</th>
                  <th>Source of Emission</th>

                  <th className="text-center pe-3" colSpan={2}>
                    Emissions Factor{" "}
                    <small className="text-nowrap">
                      (tCO<sub>2</sub>e)/unit
                    </small>
                  </th>
                  <th className="text-nowrap">Dataset</th>
                  <th>Other Metadata</th>
                </tr>
              </thead>
              <tbody>
                {emissionFactors &&
                  emissionFactors
                    ?.filter((ef) => !ef.reference.includes("IEA"))
                    .map((ef) => {
                      return (
                        <tr key={ef.id}>
                          <td>{ef.activityTypes[0]?.title}</td>
                          <td>{ef.emissionResource}</td>
                          <td className="text-end">
                            {Intl.NumberFormat("en-us", {
                              maximumFractionDigits: 10,
                              minimumFractionDigits: 2,
                            }).format(ef.emissionFactor)}
                          </td>
                          <td>
                            <small className="text-nowrap">
                              tCO<sub>2</sub>e/{ef.unit ? ef.unit : "unit"}
                            </small>
                          </td>
                          <td>{ef.reference}</td>
                          <td>
                            {ef.metadata.length >= 1
                              ? ef.metadata.map((data) => (
                                  <>
                                    <small className="bg-light text-nowrap border px-1 me-2">
                                      {data.label}: {data.value}
                                    </small>
                                    <br />
                                  </>
                                ))
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmissionFactors;
