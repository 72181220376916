import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Table } from "react-bootstrap";
import { uniqBy } from "lodash";

import { getFormattedEmission } from "utils/StringUtils";
import { getMonthLabelFromValue } from "utils/dateUtils";

const ReportedActivityList = ({
  activities,
  sortSiteActivities,
  tableSortState,
  isLoading,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const data = activities.map((activity) => {
      const type = activity.activityType;

      return {
        ...activity,
        ...{
          title: type.title,
          categoryNumber: type.categoryNumber,
          type: type,
        },
      };
    });

    setTableData(data);
  }, [activities]);

  return (
    <Table hover className="my-4" size="sm" responsive>
      <thead>
        <tr>
          <th
            className="table-sorter align-middle"
            onClick={() => !isLoading && sortSiteActivities("yearEnded")}
            rowSpan={2}
          >
            Date
            {tableSortState.sortColumn === "yearEnded" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-nowrap table-sorter align-middle"
            onClick={() => !isLoading && sortSiteActivities("activityType")}
            rowSpan={2}
          >
            Source
            {tableSortState.sortColumn === "activityType" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-nowrap table-sorter align-middle"
            onClick={() => !isLoading && sortSiteActivities("emissionResource")}
            rowSpan={2}
          >
            Resource
            {tableSortState.sortColumn === "emissionResource" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter align-middle"
            onClick={() => !isLoading && sortSiteActivities("scope")}
            rowSpan={2}
          >
            Scope
            {tableSortState.sortColumn === "scope" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            rowSpan={2}
            className="text-end align-middle"
            onClick={() => !isLoading && sortSiteActivities("co2Emissions")}
          >
            CO<sub>2</sub>
            <small className="text-muted">
              {" "}
              in tC0<sub>2</sub>e
            </small>
            {tableSortState.sortColumn === "co2Emissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            rowSpan={2}
            className="text-end align-middle"
            onClick={() => !isLoading && sortSiteActivities("n2oEmissions")}
          >
            N<sub>2</sub>O
            <small className="text-muted">
              {" "}
              in tC0<sub>2</sub>e
            </small>
            {tableSortState.sortColumn === "n2oEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            rowSpan={2}
            className="text-end align-middle"
            onClick={() => !isLoading && sortSiteActivities("ch4Emissions")}
          >
            CH<sub>4</sub>
            <small className="text-muted">
              {" "}
              in tC0<sub>2</sub>e
            </small>
            {tableSortState.sortColumn === "ch4Emissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-end align-middle table-sorter"
            rowSpan={2}
            onClick={() => !isLoading && sortSiteActivities("emissions")}
          >
            tCO<sub>2</sub>e
            {tableSortState.sortColumn === "emissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-nowrap text-end table-sorter align-middle"
            onClick={() => !isLoading && sortSiteActivities("updatedAt")}
            rowSpan={2}
          >
            Updated At
            {tableSortState.sortColumn === "updatedAt" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {uniqBy(tableData, "id").map((activity) => {
          return (
            <tr key={activity?.id}>
              <td>
                {activity.month && (
                  <span>
                    {getMonthLabelFromValue(activity.month)} <br />
                  </span>
                )}
                {activity.calendarYear?.substring(0, 4) ||
                  activity.yearEnded?.substring(0, 4) ||
                  "2021"}
              </td>
              <td>
                {activity?.categoryNumber
                  ? `${activity?.categoryNumber}. `
                  : ""}
                {activity?.title || ""}
              </td>
              <td>
                {activity?.emissionFactor
                  ? activity?.emissionFactor?.emissionResource +
                    "  " +
                    "-" +
                    "  " +
                    activity?.emissionFactor?.unit
                  : "n/a"}
              </td>
              <td>Scope {activity?.scope || ""}</td>

              <td className="text-end text-nowrap pe-2">
                {activity.co2Emissions ? (
                  getFormattedEmission(activity.co2Emissions, 1)
                ) : (
                  <small className="text-muted">n/a</small>
                )}{" "}
              </td>
              <td className="text-end text-nowrap pe-2">
                {activity.n2oEmissions ? (
                  getFormattedEmission(activity.n2oEmissions, 1)
                ) : (
                  <small className="text-muted">n/a</small>
                )}{" "}
              </td>
              <td className="text-end text-nowrap pe-2">
                {activity.ch4Emissions ? (
                  getFormattedEmission(activity.ch4Emissions, 1)
                ) : (
                  <small className="text-muted">n/a</small>
                )}{" "}
              </td>
              <td className="text-end text-nowrap pe-2">
                {getFormattedEmission(activity.emissions, 1)}{" "}
              </td>
              <td className="text-end text-nowrap">
                <small>
                  {new Date(activity?.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

ReportedActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default ReportedActivityList;
