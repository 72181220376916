import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Table,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

import Loader from "components/Loader/Loader";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import UserCategories from "utils/userCategories";

import "./Projections.scss";
import { get } from "utils/DeApi";
import {
  checkIfIsInBau,
  convertNegativeToZero,
  sum,
  unionByYear,
} from "../helper";
import EmissionProjectionsChart from "./EmissionProjectionsChart/EmissionProjectionsChart";
import InteractiveProject from "./InteractiveProject/InteractiveProject";
import {
  getMergedSTBi,
  getEconomicGrowth,
  getBusinessAsUsualHeatingFuels,
  getGridDecarbonizationBAU,
  getHeatingFuelsDecarbonizationBAU,
  getBusinessAsUsual,
  getTransportDecarbonizationBAU,
  getBusinessAsUsualTransport,
  getBusinessAsUsualMisc,
  getMiscDecarbonizationBAU,
} from "../forecastingModels";
import {
  projectStatusMap,
  projectTypesMap,
} from "../../Abatement/AbatementProjects/constants";

const colorsPallete = [
  "#AFEEEE",
  "#D1E8E2",
  "#9DC4D6",
  "#D1E6FF",
  "#D4E4F7",
  "#AEC6CF",
  "#C5D9E9",
  "#E6E6FA",
  "#CBFDCB",
  "#E0FFFF",
  "#B0E0E6",
  "#D4DDE9",
  "#E7EBF1",
  "#CCE7EF",
  "#E7F5F8",
  "#CDEBED",
  "#E7F5F6",
  "#D9EDE4",
  "#EAF6F0",
  "#E5F1D9",
  "#F0F7EA",
];

const selectedProjections = {
  "BAU trend": true,
  "1.5C SBTi Target  Scope 1 + 2 (w/ constrain)": true,
  "WB2C SBTi Target  Scope 1 + 2 (w/ constrain)": true,
};

const Projections = ({
  isShow,
  organization,
  breadcrumbs,
  scopeOne,
  scopeTwo,
  scopeThree,
  targetScopeOne,
  targetScopeTwo,
  targetScopeThree,
  targetBaselineYear,
  baselineYear,
  sites = [],
  forecastingConfig,
  sbtiConfig,
  onChartRendered,
  setTotalCarbonSaving,
  setTotalRoadmapEmissions,
}) => {
  const [emission, setEmission] = useState();
  const [projections, setProjections] = useState();
  const [decarbLever, setDecarbLever] = useState("");

  const [status, setStatus] = useState([
    {
      value: "In Flight",
      key: 1,
    },
    {
      value: "Committed",
      key: 3,
    },
    {
      value: "Completed",
      key: 4,
    },
  ]);
  const [type, setType] = useState([]);
  const [site, setSite] = useState([]);

  const [projects, setProjects] = useState([]);
  const [economicGrowth, setEconomicGrowth] = useState([]);
  const [scienceBasedTarget, setScienceBasedTarget] = useState({});
  const [gridDecarbonization, setGridDecarbonization] = useState([]);
  const [decarbOfHeatingFuels, setDecarbOfHeatingFuels] = useState([]);
  const [decarbOfTransport, setDecarbOfTransport] = useState([]);
  const [miscDecarb, setMiscDecarb] = useState([]);
  const [showAllProjects, setShowAllProjects] = useState(true);
  const [
    economicGrowthEmissionPercentage,
    setEconomicGrowthEmissionPercentage,
  ] = useState(100);
  const [
    gridDecarbonizationEmissionPercentage,
    setGridDecarbonizationEmissionPercentage,
  ] = useState([]);
  const [
    gridDecarbonizationEmissionPercentageThree,
    setGridDecarbonizationEmissionPercentageThree,
  ] = useState([]);
  const [
    heatingFuelDecarbEmissionPercentage,
    setHeatingFuelDecarbEmissionPercentage,
  ] = useState([]);
  const [
    heatingFuelDecarbEmissionPercentageThree,
    setHeatingFuelDecarbEmissionPercentageThree,
  ] = useState([]);
  const [
    transportDecarbEmissionPercentage,
    setTransportDecarbEmissionPercentage,
  ] = useState([]);
  const [
    transportDecarbEmissionPercentageThree,
    setTransportDecarbEmissionPercentageThree,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState();
  const [error, setError] = useState(null);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const userCategory = UserCategories();

  const memoizedConfig = useMemo(() => {
    if (sbtiConfig) {
      return JSON.parse(sbtiConfig);
    } else {
      return [];
    }
  }, [sbtiConfig]);

  const memoizedForecastingConfig = useMemo(() => {
    if (forecastingConfig) {
      return JSON.parse(forecastingConfig);
    } else {
      return [];
    }
  }, [forecastingConfig]);

  const handleSelect = (eventKey, cb, valArr, prevVal) => {
    if (eventKey === "clear") {
      cb([]);
    } else {
      const option = valArr.find(
        (o) => o?.key == eventKey || o?.id == eventKey
      );
      const newSelectedOptions = [...prevVal];
      const index = newSelectedOptions.findIndex((o) => {
        return (
          (o?.key && o?.key === option?.key) || (o?.id && o?.id === option?.id)
        );
      });
      if (index !== -1) {
        newSelectedOptions.splice(index, 1);
      } else {
        newSelectedOptions.push(option);
      }
      cb(newSelectedOptions);
    }
  };

  const [selectedLegends, setSelectedLegends] = useState({
    ...selectedProjections,
  });

  const handleProjectUpdate = (data) => {
    setProjects(
      projects.map((p) => {
        if (p.id === data.id) return data;
        return p;
      })
    );
  };

  const toggleOffcanvas = () => {
    setShow(!show);
  };

  const projectFilter = (projects, type, status, site) => {
    if (!Array.isArray(projects)) return [];

    return projects
      .filter((project) => {
        if (!type.length) return true;
        if (type === "reset") return true;
        return type.filter(
          (val) => parseInt(project?.projectType) === parseInt(val.key)
        ).length;
      })
      .filter((project) => {
        if (!status.length) return true;
        if (status === "reset") return true;
        return status.filter(
          (val) => parseInt(project?.projectStatus) === parseInt(val.key)
        ).length;
      })
      .filter((project) => {
        if (!site.length) return true;
        if (site === "reset") return true;
        return site.filter((val) => project?.site?.id === val.id).length;
      });
  };

  const gettitle = (arr, filter) => {
    return arr.length === 0
      ? `Filter by ${filter}`
      : arr.map((option) => option?.value || option?.name).join(", ");
  };

  useEffect(() => {
    function fetchMiscLever(organizationId) {
      let arr = [];
      if (memoizedForecastingConfig.length) {
        const config = memoizedForecastingConfig.filter((lever) => lever.type);
        config.forEach((lever) => {
          arr = [
            ...arr,
            get(
              `organizations/${organizationId}/miscellaneous-decarbonization`,
              { params: { type: lever.type } }
            ),
          ];
        });

        if (arr.length) {
          Promise.all(arr.map((promise) => promise.promise))
            .then((responses) => {
              const decarb = responses.filter(
                (response) => response.data.length
              );
              setMiscDecarb(decarb);
              setIsLoading(false);
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setIsLoading(false);
        }
      }

      subscribedPromises.current.push(...arr);
    }

    function fetchForecasting(organizationId) {
      setError(null);
      setIsLoading(true);

      const abatementProjects = get(
        `organizations/${organizationId}/abatement-projects`
      );

      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );

      const SBTiPromise = get(
        `organizations/${organizationId}/science-based-target`
      );

      const gridDecarbonizationPromise = get(
        `organizations/${organizationId}/grid-decarbonization`
      );

      const decarbOfHeatingFuelsPromise = get(
        `organizations/${organizationId}/heating-fuels-decarbonization`
      );

      const decarbOfTransportPromise = get(
        `organizations/${organizationId}/transport-decarbonization`
      );

      Promise.all([
        abatementProjects.promise,
        economicGrowthPromise.promise,
        SBTiPromise.promise,
        gridDecarbonizationPromise.promise,
        decarbOfHeatingFuelsPromise.promise,
        decarbOfTransportPromise.promise,
      ])
        .then((responses) => {
          const [
            { data: projects = [] },
            { data: economicGrowth = [] },
            { data: scienceBasedTarget = [] },
            { data: gridDecarbonization = [] },
            { data: decarbOfHeatingFuels = [] },
            { data: decarbOfTransport = [] },
          ] = responses || [];

          setProjects(
            projects.map((p) => ({
              show: true,
              ...p,
            }))
          );

          setEconomicGrowth(economicGrowth[0] ? economicGrowth[0]?.data : []);
          setEconomicGrowthEmissionPercentage(
            economicGrowth[0]
              ? economicGrowth[0]?.scopeThreeEmissionPercentage
              : []
          );
          setGridDecarbonization(
            gridDecarbonization[0] ? gridDecarbonization[0]?.data : []
          );
          setGridDecarbonizationEmissionPercentage(
            gridDecarbonization[0]
              ? gridDecarbonization[0]?.scopeTwoEmissionPercentage
              : []
          );
          setGridDecarbonizationEmissionPercentageThree(
            gridDecarbonization[0]
              ? gridDecarbonization[0]?.scopeThreeEmissionPercentage
              : []
          );
          setScienceBasedTarget(
            scienceBasedTarget[0] ? scienceBasedTarget[0] : {}
          );
          setDecarbOfHeatingFuels(
            decarbOfHeatingFuels[0] ? decarbOfHeatingFuels[0]?.data : []
          );
          setHeatingFuelDecarbEmissionPercentage(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeOneEmissionPercentage
              : []
          );
          setHeatingFuelDecarbEmissionPercentageThree(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeThreeEmissionPercentage
              : []
          );
          setDecarbOfTransport(
            decarbOfTransport[0] ? decarbOfTransport[0]?.data : []
          );
          setTransportDecarbEmissionPercentage(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeOneEmissionPercentage
              : []
          );
          setTransportDecarbEmissionPercentageThree(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeThreeEmissionPercentage
              : []
          );

          fetchMiscLever(organizationId);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(
        abatementProjects,
        economicGrowthPromise,
        SBTiPromise,
        gridDecarbonizationPromise,
        decarbOfHeatingFuelsPromise,
        decarbOfTransportPromise
      );
    }

    if (!!memoizedConfig?.length) {
      fetchForecasting(organizationId);
    }

    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    scopeOne,
    scopeTwo,
    scopeThree,
    baselineYear,
    targetScopeOne,
    targetScopeTwo,
    targetScopeThree,
    targetBaselineYear,
    memoizedConfig,
  ]);

  useEffect(() => {
    const _projects = projectFilter(projects, type, status, site)
      .map((project, index) => {
        let obj = {
          ...project,
          lineStyle: {
            color: colorsPallete[index],
          },
          itemStyle: {
            color: colorsPallete[index],
          },
          areaStyle: {
            type: "default",
            color: colorsPallete[index],
            opacity: 1,
          },
        };

        return { ...obj };
      })
      .filter((p) => p?.show);

    const economicGrowthChunk = getEconomicGrowth(
      economicGrowth,
      scopeOne,
      scopeTwo,
      scopeThree,
      economicGrowthEmissionPercentage
    );
    const gridDecarbChunk = getGridDecarbonizationBAU(
      gridDecarbonization,
      economicGrowthChunk,
      gridDecarbonizationEmissionPercentage,
      gridDecarbonizationEmissionPercentageThree
    );
    const heatingFuelsDecard = getHeatingFuelsDecarbonizationBAU(
      decarbOfHeatingFuels,
      economicGrowthChunk,
      heatingFuelDecarbEmissionPercentage,
      heatingFuelDecarbEmissionPercentageThree
    );
    const transportDecarb = getTransportDecarbonizationBAU(
      decarbOfTransport,
      economicGrowthChunk,
      transportDecarbEmissionPercentage,
      transportDecarbEmissionPercentageThree
    );

    let scienceBasedTargetChunk = [];

    let sbtiEmissions = {};
    let sbtiEmissionsThree = {};

    sbtiEmissions = {
      limitReductionPercentage: scienceBasedTarget?.limitReductionPercentage,
      reductionPercentageOne: scienceBasedTarget?.reductionPercentageOne,
      reductionPercentageTwo: scienceBasedTarget?.reductionPercentageTwo,
      data: scienceBasedTarget?.data,
    };

    sbtiEmissionsThree = {
      limitReductionPercentage:
        scienceBasedTarget?.sopeThreeLimitReductionPercentage,
      reductionPercentageOne:
        scienceBasedTarget?.sopeThreeReductionPercentageOne,
      reductionPercentageTwo:
        scienceBasedTarget?.sopeThreeReductionPercentageTwo,
      data: scienceBasedTarget?.scopeThreeData,
    };

    const scienceBasedTargetChunkNotScopeThree = getMergedSTBi(
      sbtiEmissions,
      targetScopeOne,
      targetScopeTwo,
      0
    );

    const scienceBasedTargetChunkScopeThree = getMergedSTBi(
      sbtiEmissionsThree,
      0,
      0,
      targetScopeThree
    );
    const targetSettingData = [];
    for (
      let index = 0;
      index < scienceBasedTargetChunkNotScopeThree.length;
      index++
    ) {
      const element = scienceBasedTargetChunkNotScopeThree[index];
      const elementThree = scienceBasedTargetChunkScopeThree[index];
      let obj = {};
      for (const key in element) {
        if (element.hasOwnProperty.call(element, key)) {
          const val = element[key];
          const val2 = elementThree[key];
          if (
            key !== "percentageReduction" &&
            key !== "percentageReduction25" &&
            key !== "year" &&
            key !== "year25"
          ) {
            obj[key] = val + val2;
          } else {
            obj[key] = val;
          }
        }
      }
      targetSettingData.push(obj);
    }
    scienceBasedTargetChunk = targetSettingData;

    const miscChunk = getMiscDecarbonizationBAU(
      miscDecarb,
      economicGrowthChunk
    );

    let bau = [];
    let bauLever = "";

    const availableMiscLevers = memoizedForecastingConfig.length
      ? memoizedForecastingConfig.filter(
          (val) =>
            val.type &&
            checkIfIsInBau(memoizedForecastingConfig, val.type) &&
            miscChunk.filter((value) => value[0]?.[val.type]).length
        )
      : [];

    if (availableMiscLevers.length) {
      const miscLever = availableMiscLevers.at(-1);
      let reduction = [];
      bauLever = miscLever?.type;
      setDecarbLever(miscLever?.type);
      bau = unionByYear(
        ...miscChunk.map((miscData, index) => {
          return getBusinessAsUsualMisc(
            unionByYear(
              economicGrowthChunk,
              gridDecarbChunk,
              heatingFuelsDecard.map((val) => ({
                year: val.year,
                scopeOneReductionHeatingFuel: val.scopeOneReduction,
              })),
              transportDecarb.map((val) => ({
                year: val.year,
                scopeOneReductionTransport: val.scopeOneReduction,
              })),
              miscData.map((val, idx) => {
                if (
                  reduction[idx] !== undefined &&
                  checkIfIsInBau(memoizedForecastingConfig, val.type)
                ) {
                  reduction[idx] += val[`${val.type}-reduction`];
                } else {
                  if (checkIfIsInBau(memoizedForecastingConfig, val.type)) {
                    reduction.push(val[`${val.type}-reduction`]);
                  }
                }
                return {
                  year: val.year,
                  [`${val.type}-reduction`]: val[`${val.type}-reduction`],
                  reduction: reduction[idx],
                  type: val.type,
                };
              })
            ),
            checkIfIsInBau(memoizedForecastingConfig, "grid-decarbonization"),
            checkIfIsInBau(
              memoizedForecastingConfig,
              "heating-fuels-decarbonization"
            ),
            checkIfIsInBau(
              memoizedForecastingConfig,
              "transport-decarbonization"
            )
          );
        })
      );
    } else if (
      memoizedForecastingConfig?.length &&
      memoizedForecastingConfig?.find(
        (value) => value.link === "transport-decarbonization" && value.isInBau
      )
    ) {
      bauLever = "bauTransport";
      setDecarbLever("bauTransport");
      bau = getBusinessAsUsualTransport(
        checkIfIsInBau(memoizedForecastingConfig, "grid-decarbonization"),
        checkIfIsInBau(
          memoizedForecastingConfig,
          "heating-fuels-decarbonization"
        ),
        unionByYear(
          economicGrowthChunk,
          gridDecarbChunk,
          heatingFuelsDecard.map((val) => ({
            year: val.year,
            scopeOneReductionHeatingFuel: val.scopeOneReduction,
          })),
          transportDecarb
        )
      );
    } else if (
      memoizedForecastingConfig?.length &&
      memoizedForecastingConfig?.find(
        (value) =>
          value.link === "heating-fuels-decarbonization" && value.isInBau
      )
    ) {
      bauLever = "bauHeatingFuels";
      setDecarbLever("bauHeatingFuels");
      bau = getBusinessAsUsualHeatingFuels(
        checkIfIsInBau(memoizedForecastingConfig, "grid-decarbonization"),
        unionByYear(economicGrowthChunk, gridDecarbChunk, heatingFuelsDecard)
      );
    } else if (
      memoizedForecastingConfig?.length &&
      memoizedForecastingConfig?.find(
        (value) => value.link === "grid-decarbonization" && value.isInBau
      )
    ) {
      bauLever = "bau";
      setDecarbLever("bau");
      bau = getBusinessAsUsual(
        unionByYear(economicGrowthChunk, gridDecarbChunk)
      );
    } else {
      bauLever = "bau";
      setDecarbLever("bau");
      bau = getBusinessAsUsual(unionByYear(economicGrowthChunk));
    }

    function getAnnualSavings(data = [], bau) {
      const timeLine = createTimeline(data);
      const finalYear = getFinalYear(timeLine, bau);
      const projectsWithTimePeriod = updateTimeline(timeLine, finalYear);
      const startYear = parseInt(baselineYear);
      const numberOfYears =
        finalYear - startYear + 1 > 0 ? finalYear - startYear + 1 : 0;

      let prevAnnualSaving = undefined;

      if (data.length <= 0) return [];

      return Array(numberOfYears)
        .fill("")
        .map((item, index) => {
          const year = (startYear + index) % (startYear + numberOfYears);
          const projects = projectsWithTimePeriod.filter(
            ({ startYear, finalYear }) => {
              return year >= startYear && year <= finalYear;
            }
          );

          const bauDecarb = bau.find(({ year: y }) => y === year) || {};
          const sumOfAnnualSaving = projects.reduce(
            (aggregate, { annualCarbonEmissionReduction }) =>
              sum(aggregate, annualCarbonEmissionReduction),
            0
          );

          prevAnnualSaving =
            prevAnnualSaving === undefined
              ? sumOfAnnualSaving
              : sum(prevAnnualSaving, sumOfAnnualSaving);

          prevAnnualSaving = sumOfAnnualSaving;
          return {
            year: year,
            projects: projects,
            sumOfAnnualSaving: !isNaN(bauDecarb?.[`${bauLever}`])
              ? bauDecarb?.[`${bauLever}`] - prevAnnualSaving
              : "N/A",
          };
        });
    }

    function getEmissionProjections(data = [], bau) {
      const timeLine = createTimeline(data);
      const finalYear = getFinalYear(timeLine, bau);
      const projectsWithTimePeriod = updateTimeline(timeLine, finalYear).sort(
        (a, b) => {
          if (a.startYear > b.startYear) return 1;
          if (a.startYear < b.startYear) return -1;
          return 0;
        }
      );

      const startYear = parseInt(baselineYear);
      const targetStartYear = parseInt(targetBaselineYear);
      const baseYear =
        startYear && targetStartYear && targetStartYear <= startYear
          ? targetStartYear
          : startYear;
      const numberOfYears = finalYear - baseYear + 1;
      const projects = [];
      const prev = {};
      const _projectsWithTimePeriod = projectsWithTimePeriod.filter(
        (project) => project.annualCarbonEmissionReduction
      );

      for (let index = 0; index < numberOfYears; index++) {
        const year = (baseYear + index) % (baseYear + numberOfYears);
        let startOffset = undefined;
        if (startOffset === undefined)
          startOffset = bau.find(({ year: y }) => y === year)?.[`${bauLever}`];
        let prevReduction = startOffset;
        let totalAnnualReduction = 0;
        _projectsWithTimePeriod.forEach((p, index) => {
          const found = projects.find(({ id }) => id === p.id);

          const isWithinRange = year >= p.startYear && year <= p.finalYear;
          const annualCarbonEmissionReduction = isWithinRange
            ? p.annualCarbonEmissionReduction
            : 0;
          prev[p.id] =
            prev[p.id] === undefined
              ? annualCarbonEmissionReduction
              : sum(prev[p.id], annualCarbonEmissionReduction);

          totalAnnualReduction += annualCarbonEmissionReduction;
          let remainingReduction =
            prevReduction - annualCarbonEmissionReduction;
          let carbonReduction = 0;
          if (prevReduction >= annualCarbonEmissionReduction) {
            carbonReduction = annualCarbonEmissionReduction;
          } else {
            carbonReduction = prevReduction;
          }
          prevReduction = remainingReduction;
          if (!found) {
            projects.push({
              ...p,
              type: "line",
              stack: "x",
              data: [carbonReduction],
            });
          } else {
            const index = projects.findIndex(({ id }) => id === found.id);
            projects[index].data = [...found.data, carbonReduction];
          }
        });
        const found = projects.find(
          ({ name }) => name === "Unabated Emissions"
        );
        if (!found) {
          projects.push({
            name: "Unabated Emissions",
            type: "line",
            symbolSize: 0,
            color: "#A6A6A6",
            show: true,
            stack: "x",
            data: [startOffset - totalAnnualReduction],
          });
        } else {
          const index = projects.findIndex(
            ({ name }) => name === "Unabated Emissions"
          );
          projects[index].data = [
            ...found.data,
            startOffset - totalAnnualReduction,
          ];
        }
      }

      return projects.reverse();
    }

    const getRecentYearValues = (arr, hasYear = true) => {
      // const startValue = Math.max(
      //   Number(targetBaselineYear),
      //   Number(baselineYear)
      // );
      const startValue = Number(targetBaselineYear);
      const difference = Number(targetBaselineYear) - Number(baselineYear);
      if (hasYear) {
        const startIndex = arr?.findIndex((val) => {
          return val.year === startValue;
        });
        if (startIndex !== -1) {
          return arr?.slice(startIndex);
        }
      } else {
        if (difference > 0) {
          return arr?.map((val) => ({
            ...val,
            data: val.data.slice(difference),
          }));
        }
      }
      return arr;
    };

    setProjections(
      getRecentYearValues(getEmissionProjections(_projects, bau), false)
    );

    setEmission(
      getRecentYearValues(
        unionByYear(
          economicGrowthChunk,
          scienceBasedTargetChunk,
          bau,
          getAnnualSavings(_projects, bau)
        )
      )
    );
  }, [
    economicGrowth,
    scienceBasedTarget,
    gridDecarbonization,
    decarbOfHeatingFuels,
    decarbOfTransport,
    miscDecarb,
    scopeOne,
    scopeTwo,
    scopeThree,
    baselineYear,
    projects,
    type,
    status,
    site,
    show,
    memoizedForecastingConfig,
    targetScopeOne,
    targetScopeTwo,
    targetScopeThree,
    targetBaselineYear,
    memoizedConfig,
    economicGrowthEmissionPercentage,
    gridDecarbonizationEmissionPercentage,
    gridDecarbonizationEmissionPercentageThree,
    heatingFuelDecarbEmissionPercentage,
    heatingFuelDecarbEmissionPercentageThree,
    transportDecarbEmissionPercentage,
    transportDecarbEmissionPercentageThree,
  ]);

  useEffect(() => {
    const totalEmissions = emission?.length;
    const total = projections?.map((project, index) => {
      const { annualCarbonEmissionReduction } = project;
      return annualCarbonEmissionReduction;
    });
    setTotalCarbonSaving &&
      setTotalCarbonSaving(
        total
          ?.filter((data) => !!data)
          ?.reduce((a, b) => Number(a) + Number(b), 0)
      );
    setTotalRoadmapEmissions && setTotalRoadmapEmissions(totalEmissions);
  }, [projections, emission]);

  return (
    <>
      {isShow && (
        <div className="my-3">
          <BreadCrumbs
            breadcrumbs={[
              ...breadcrumbs,
              {
                name: "Projections",
                link: `/organizations/${organization.id}/forecasting/1/projections`,
                active: true,
              },
            ]}
          />
        </div>
      )}
      {isShow && <h2 className="mb-4 pt-2">Projections</h2>}
      {!!memoizedConfig?.length ? (
        <div id={userCategory} className="my-3 p-3 border">
          <div className="d-flex flex-row mb-3">
            <h3 className="flex-fill">Interactive Projections</h3>{" "}
            {isShow && (
              <Button
                onClick={toggleOffcanvas}
                size="sm"
                className="bg-primary bg-opacity-25 text-dark border-0"
              >
                Interactive projections
              </Button>
            )}
          </div>
          <hr />
          <Row>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="Sectors"
              className="mb-3"
            >
              <DropdownButton
                title={gettitle(status, "status")}
                id="multi-select-dropdown"
                onSelect={(e) =>
                  handleSelect(e, setStatus, projectStatusMap, status)
                }
                variant="outline-dark"
                className="no-hover"
              >
                {projectStatusMap.map(({ key, value }) => (
                  <Dropdown.Item
                    key={key}
                    eventKey={key}
                    active={status.some((o) => o.value === value)}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item eventKey="clear" disabled={status.length === 0}>
                  Clear selections
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="Sectors"
              className="mb-3"
            >
              <DropdownButton
                title={gettitle(type, "type")}
                id="multi-select-dropdown"
                onSelect={(e) =>
                  handleSelect(e, setType, projectTypesMap, type)
                }
                variant="outline-dark"
                className="no-hover"
              >
                {projectTypesMap.map(({ key, value }) => (
                  <Dropdown.Item
                    key={key}
                    eventKey={key}
                    active={type.some((o) => o.value === value)}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item eventKey="clear" disabled={type.length === 0}>
                  Clear selections
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="site"
              className="mb-3"
            >
              <DropdownButton
                title={gettitle(site, "site")}
                id="multi-select-dropdown"
                onSelect={(e) => handleSelect(e, setSite, sites, site)}
                variant="outline-dark"
                className="no-hover"
              >
                {sites.map(({ id, name }) => (
                  <Dropdown.Item
                    key={id}
                    eventKey={id}
                    active={site.some((o) => o.name === name)}
                  >
                    {name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item eventKey="clear" disabled={site.length === 0}>
                  Clear selections
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          </Row>
          <Row className="justify-content-between">
            <Form.Group
              as={Col}
              controlId="chartSwitch"
              className="mb-3 d-flex justify-content-start"
            >
              <Form.Check
                type="switch"
                id="custom-switch-line"
                label={
                  <small className="text-uppercase text-muted fw-semibold">
                    Show individual projects
                  </small>
                }
                checked={showAllProjects}
                onChange={(e) => {
                  setShowAllProjects(e.target.checked);
                }}
              />
            </Form.Group>
          </Row>
          <div className="my-3">
            {!isLoading && !error && (
              <EmissionProjectionsChart
                selectedLegends={selectedLegends}
                setSelectedLegends={setSelectedLegends}
                data={emission || []}
                projections={projections}
                scopeTwo={scopeTwo}
                showAllProjects={showAllProjects}
                sbtiConfig={memoizedConfig}
                decarbLever={decarbLever}
                onChartRendered={onChartRendered}
                emission={scienceBasedTarget}
              />
            )}
          </div>
          {error && !isLoading && <ErrorHandler error={error} />}
          {isLoading && <Loader />}
          {isShow && (
            <>
              <h4>Emissions Reduction by Project</h4>
              <Table size="sm" responsive striped className="my-3">
                <thead>
                  <tr>
                    <th className="text-nowrap px-3">Project</th>
                    <th className="text-nowrap px-3">Annual Reduction</th>
                  </tr>
                </thead>
                <tbody>
                  {projections?.map((project, index) => {
                    const { id, name, annualCarbonEmissionReduction } = project;
                    if (name !== "Unabated Emissions") {
                      return (
                        <tr key={id}>
                          <td className="text-nowrap px-3">{name}</td>
                          <td className="text-nowrap px-3">
                            {annualCarbonEmissionReduction}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </>
          )}
          {isShow && !isLoading && !projections?.length && (
            <Alert variant="info">
              There is currently nothing to show here.
            </Alert>
          )}
          <Modal show={show} fullscreen={true} onHide={toggleOffcanvas}>
            <Modal.Header closeButton>
              <Modal.Title>Interactive projections</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={7} md={12} xs={12}>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      controlId="Sectors"
                      className="mb-3"
                    >
                      <DropdownButton
                        title={gettitle(status, "status")}
                        id="multi-select-dropdown"
                        onSelect={(e) =>
                          handleSelect(e, setStatus, projectStatusMap, status)
                        }
                        variant="outline-dark"
                        className="no-hover"
                      >
                        {projectStatusMap.map(({ key, value }) => (
                          <Dropdown.Item
                            key={key}
                            eventKey={key}
                            active={status.some((o) => o.value === value)}
                          >
                            {value}
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={status.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      controlId="Sectors"
                      className="mb-3"
                    >
                      <DropdownButton
                        title={gettitle(type, "type")}
                        id="multi-select-dropdown"
                        onSelect={(e) =>
                          handleSelect(e, setType, projectTypesMap, type)
                        }
                        variant="outline-dark"
                        className="no-hover"
                      >
                        {projectTypesMap.map(({ key, value }) => (
                          <Dropdown.Item
                            key={key}
                            eventKey={key}
                            active={type.some((o) => o.value === value)}
                          >
                            {value}
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={type.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      controlId="site"
                      className="mb-3"
                    >
                      <DropdownButton
                        title={gettitle(site, "site")}
                        id="multi-select-dropdown"
                        onSelect={(e) => handleSelect(e, setSite, sites, site)}
                        variant="outline-dark"
                        className="no-hover"
                      >
                        {sites.map(({ id, name }) => (
                          <Dropdown.Item
                            key={id}
                            eventKey={id}
                            active={site.some((o) => o.name === name)}
                          >
                            {name}
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={site.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                  </Row>
                  <Row className="justify-content-between">
                    <Form.Group
                      as={Col}
                      controlId="chartSwitch"
                      className="mb-3 d-flex justify-content-start"
                    >
                      <Form.Check
                        type="switch"
                        id="custom-switch-line"
                        label={
                          <small className="text-uppercase text-muted fw-semibold">
                            Show individual projects
                          </small>
                        }
                        checked={showAllProjects}
                        onChange={(e) => {
                          setShowAllProjects(e.target.checked);
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <div className="border rounded-3 py-3 mb-3">
                    {!isLoading && !error && (
                      <EmissionProjectionsChart
                        selectedLegends={selectedLegends}
                        setSelectedLegends={setSelectedLegends}
                        data={emission || []}
                        projections={projections}
                        scopeTwo={scopeTwo}
                        showAllProjects={showAllProjects}
                        sbtiConfig={memoizedConfig}
                        decarbLever={decarbLever}
                        onChartRendered={onChartRendered}
                      />
                    )}
                  </div>
                </Col>
                <Col lg={5} md={12} xs={12}>
                  <h2>Abatement projects</h2>
                  <hr />
                  {projectFilter(projects, type, status, site)
                    .map((project, index) => {
                      let obj = {
                        ...project,
                        lineStyle: {
                          color: colorsPallete[index],
                        },
                        itemStyle: {
                          color: colorsPallete[index],
                        },
                        areaStyle: {
                          type: "default",
                          color: colorsPallete[index],
                          opacity: 1,
                        },
                      };
                      return { ...obj };
                    })
                    .map((p) => {
                      return (
                        <InteractiveProject
                          project={p}
                          onProjectUpdate={handleProjectUpdate}
                          key={p.id}
                        />
                      );
                    })}
                  <>
                    {!error &&
                      !isLoading &&
                      projectFilter(projects, type, status, site).length ===
                        0 && (
                        <Alert variant="info">
                          There is currently nothing to show here.
                        </Alert>
                      )}
                  </>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
    </>
  );
};

function createTimeline(data = []) {
  return data
    .filter(
      ({ projectLifetime, emissionSavingsStartDate }) =>
        !isNaN(Number(projectLifetime)) && !!emissionSavingsStartDate
    )
    .map(({ projectLifetime, emissionSavingsStartDate, ...rest }) => {
      const startYear = parseInt(
        new Date(emissionSavingsStartDate).getFullYear()
      );
      return {
        ...rest,
        projectLifetime,
        startYear: startYear,
        finalYear: sum(projectLifetime, startYear),
      };
    });
}

function updateTimeline(data = [], finalYear) {
  return data.map(({ finalYear: year, autoRenew, ...rest }) => ({
    ...rest,
    finalYear: !!autoRenew ? finalYear : year,
  }));
}

function getFinalYear(timeLine = [], emission = []) {
  return 2050;
}

export default Projections;
