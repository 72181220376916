import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Table } from "react-bootstrap";

import { getFormattedEmission } from "utils/StringUtils";
import { getMonthLabelFromValue } from "utils/dateUtils";

const OrganizationActivityList = ({
  activities,
  sortOrgActivities,
  tableSortState,
  isLoading,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const data = activities.map((activity) => {
      const type = activity.activityType || {};

      return {
        ...activity,
        ...{
          title: type.title,
          categoryNumber: type.categoryNumber,
          type: type,
        },
      };
    });

    setTableData(data);
  }, [activities]);

  return (
    <Table hover className="my-4" size="sm" responsive>
      <thead>
        <tr>
          <th
            className="table-sorter align-middle"
            onClick={() => !isLoading && sortOrgActivities("siteName")}
          >
            Site
            {tableSortState.sortColumn === "siteName" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-start table-sorter align-middle"
            onClick={() => !isLoading && sortOrgActivities("yearEnded")}
          >
            Date
            {tableSortState.sortColumn === "yearEnded" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-nowrap table-sorter align-middle"
            onClick={() => !isLoading && sortOrgActivities("activityType")}
          >
            Source
            {tableSortState.sortColumn === "activityType" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-nowrap table-sorter align-middle"
            onClick={() => !isLoading && sortOrgActivities("emissionResource")}
          >
            Resource
            {tableSortState.sortColumn === "emissionResource" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter align-middle"
            onClick={() => !isLoading && sortOrgActivities("scope")}
          >
            Scope
            {tableSortState.sortColumn === "scope" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-end text-nowrap table-sorter align-middle"
            onClick={() => !isLoading && sortOrgActivities("emissions")}
          >
            Emissions{" "}
            <small>
              (tCO<sub>2</sub>e)
            </small>
            {tableSortState.sortColumn === "emissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
          <th
            className="text-nowrap text-end table-sorter align-middle"
            onClick={() => !isLoading && sortOrgActivities("updatedAt")}
          >
            Updated At
            {tableSortState.sortColumn === "updatedAt" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {tableSortState.sortAsc && "arrow_upward"}
                {!tableSortState.sortAsc && "arrow_downward"}
              </span>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((activity) => {
          return (
            <tr key={activity.id}>
              <td>{activity?.site?.name || ""}</td>
              <td className="text-start">
                {activity.month && (
                  <span>
                    {getMonthLabelFromValue(activity.month)} <br />
                  </span>
                )}
                {activity.calendarYear || activity.yearEnded || "2021"}
              </td>
              <td>
                {activity?.categoryNumber
                  ? `${activity?.categoryNumber}. `
                  : ""}
                {activity?.title || ""}
              </td>
              <td>
                {activity?.emissionFactor
                  ? activity?.emissionFactor?.emissionResource +
                    "  " +
                    "-" +
                    "  " +
                    activity?.emissionFactor?.unit
                  : "n/a"}
              </td>
              <td>Scope {activity?.scope || ""}</td>

              <td className="text-end text-nowrap pe-2">
                {getFormattedEmission(activity?.emissions, 1)}
              </td>
              <td className="text-end text-nowrap">
                <small>
                  {new Date(activity?.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

OrganizationActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default OrganizationActivityList;
