import React, { useEffect, useRef, useState } from "react";
import { Navbar, Modal } from "react-bootstrap";
import { get } from "utils/BeeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { Link } from "react-router-dom";
import { getContentId } from "utils/contentUtil";

const Help = () => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const getContent = () => {
    setError(null);
    setIsLoading(true);
    const categoryId = getContentId("help");
    const contentIdsPromise = get(
      `search/contents?q=categories.category_id:${categoryId}`
    );

    contentIdsPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setContent(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });

    subscribedPromises.current.push(contentIdsPromise);
  };

  const handleShowModal = () => {
    getContent();
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
    setIsLoading(true);
    setContent([]);
  };

  return (
    <>
      <Navbar.Text
        className="me-3"
        onClick={handleShowModal}
        style={{ cursor: "pointer" }}
      >
        Help <span className="material-icons-outlined">help_outline</span>
      </Navbar.Text>
      <Modal
        show={show}
        backdrop={"static"}
        onHide={handleCloseModal}
        className="right"
        fullscreen="sm-down"
      >
        <Modal.Header closeButton>
          <Modal.Title as={"div"}>
            <h2>Help Center</h2>
            <p className="mb-0">
              Articles for getting the most out of Net Zero Compass.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader error={isLoading} />}
          {error && <ErrorHandler error={error?.message} />}
          {content && (
            <ul>
              {content?.map((item) => (
                <li key={item?.contentId}>
                  <Link
                    to={`/contents/${item?.contentId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Help;
