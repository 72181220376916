import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import ReportScopeThreeActivity from "./ScopeThree/ReportScopeThreeActivity";
import ReportScopeTwoActivity from "./ScopeTwo/ReportScopeTwoActivity";
import ReportScopeOneActivity from "./ScopeOne/ReportScopeOneActivity";

import { REPORTING_METHODS } from "./constants";

function ReportActivity(props) {
  const { scope, activity, activityTypes, isUpdate } = props;

  const [searchParams] = useSearchParams();

  const [baseInitialValues] = useState({
    year: activity.yearEnded
      ? activity.yearEnded.substring(0, 4)
      : (new Date().getFullYear() - 1).toString(),
    month: activity.month || "",
    quarter: activity.quarter || "",
    activityType:
      activity.activityTypeId ||
      (activityTypes.length === 1 ? activityTypes[0]?.id : ""),
    resource: activity.emissionFactorId || "",
    reference: activity.emissionFactor?.dataset?.id || "",
    amount: activity.amount,
    rawUnit: activity.rawUnit || "",
    rawAmount: activity.rawAmount || "",
    comment: activity.comment,
    country: activity.emissionFactorCountry?.id,
    customEmissionFactor: activity.customEmissionFactor,
    customEmissionFactorUnit: activity.customEmissionFactorUnit,
    reportingMethod: isUpdate
      ? activity.reportingMethod || ""
      : REPORTING_METHODS.standardEF,
    emissions: activity.actualEmissions,
    activityCategory: searchParams.get("activityCategoryId"),
    tags:
      activity?.primaryTag && activity?.secondaryTags
        ? [activity?.primaryTag, ...(activity?.secondaryTags || [])]?.filter(
            (data) => data
          )
        : [],
    isFlagged: activity.isFlagged,
    gwpModel: activity.gwpModel,
  });

  // In the case of scope specific initialValues, extend baseInitialValues and attach the extra values
  const [scopeThreeInitialValues] = useState({
    ...baseInitialValues,
    equityOwnership: activity.equityOwnership,
    country: activity.emissionFactorCountry?.id,
    pcafQodScore: isNaN(activity.pcafQodScore) ? "" : activity.pcafQodScore,
    subActivityTypeId: activity.subActivityType?.id || "",
    projectType: activity.projectType || "",
    vehicleType: activity.vehicleType || "",
  });

  if (scope === 1) {
    return (
      <ReportScopeOneActivity {...props} initialValues={baseInitialValues} />
    );
  } else if (scope === 2) {
    return (
      <ReportScopeTwoActivity {...props} initialValues={baseInitialValues} />
    );
  } else if (scope === 3) {
    return (
      <ReportScopeThreeActivity
        {...props}
        initialValues={scopeThreeInitialValues}
      />
    );
  }
}

ReportActivity.defaultProps = {
  activity: {
    activityTypeId: "",
    emissionFactorId: "",
    amount: "",
    rawUnit: "",
    rawAmount: "",
    comment: "",
    equityOwnership: "",
    pcafQodScore: "",
    yearEnded: "",
    month: "",
    quarter: "",
    attachments: [],
    reportingMethod: "",
    emissionFactorCountry: {
      id: "",
      name: "",
    },
    subActivityType: {
      id: "",
      title: "",
    },
    emissionFactor: {
      id: "",
      emissionResource: "",
      dataset: {
        id: "",
        name: "",
        inflationFactors: [],
      },
      metadata: [],
      unit: "",
    },
    activityType: {
      id: "",
      title: "",
    },
    customEmissionFactor: "",
    customEmissionFactorUnit: "",
    projectType: "",
    vehicleType: "",
    gwpModel: {
      name: "",
    },
  },
  site: {
    id: "",
  },
  isUpdate: false,
  organization: {
    gwpModels: [],
  },
};

ReportActivity.propTypes = {
  scope: PropTypes.number.isRequired,
  activityTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onActivityReported: PropTypes.func.isRequired,
  organization: PropTypes.object,
  activity: PropTypes.object,
  isUpdate: PropTypes.bool,
};

export default ReportActivity;
