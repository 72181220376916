import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Col, Container, Row, Table, Tabs, Tab } from "react-bootstrap";

import { get } from "utils/DeApi";

import Header from "components/App/Header/Header";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import { AccountContext } from "contexts/AccountProvider";
import MemberCreate from "./Member/MemberCreate/MemberCreate";
import UpdateGWPModel from "./GWPModel/UpdateGWPModel";
import { useNavigate, useSearchParams } from "react-router-dom";
import MemberList from "./MemberList/MemberList";
import UserCategories from "utils/userCategories";
import UpdateAccountFiscalYear from "./UpdateAccountFiscalYear";

import { getAllYearsBetweenDates } from "utils/dateUtils";

const Account = () => {
  const subscribedPromises = useRef([]);

  const accountContext = useContext(AccountContext);

  const [account, setAccount] = useState();
  const [members, setMembers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [gwpModels, setGwpModels] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tab, setTab] = useState(
    searchParams.get("tab") || "member-management"
  );

  useEffect(() => {
    setTab(searchParams.get("tab") || "member-management");
  }, [searchParams]);

  const userCategory = UserCategories();

  const onMemberUpdated = (member) => {
    setMembers([
      ...members.map((item) => (item.id === member.id ? member : item)),
    ]);
  };

  const onInvitationUpdated = (member, invite) => {
    setMembers([
      ...members.map((item) =>
        item.id === member.id ? { ...member, invitation: invite } : item
      ),
    ]);
  };

  const onMemberDeleted = (member) => {
    setMembers([...members.filter((item) => item.id !== member.id)]);
  };

  const fetchAccountInfo = useCallback(() => {
    if (accountContext.member.role === "member") {
      navigate("/");
    } else {
      setError(null);
      setIsLoading(true);
      let fetchAccount = get(`accounts/${accountContext.id}`);
      let fetchMembers = get(`accounts/${accountContext.id}/members`);
      let fetchOrganizations = get(
        `accounts/${accountContext.id}/organizations`
      );
      let fetchPortfolios = get(`/portfolios`, {
        params: { accountId: accountContext.id },
      });

      Promise.all([
        fetchAccount.promise,
        fetchMembers.promise,
        fetchOrganizations.promise,
        fetchPortfolios.promise,
      ])
        .then((responses) => {
          setAccount(responses[0].data);
          setMembers(responses[1].data);
          setOrganizations(responses[2].data);
          setPortfolios(responses[3].data);
          setIsLoading(false);
          setError(null);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(
        fetchAccount,
        fetchMembers,
        fetchOrganizations,
        fetchPortfolios
      );
    }
  }, [accountContext, navigate]);

  useEffect(() => {
    if (tab === "member-management") fetchAccountInfo();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAccountInfo, tab]);

  const fetchAccountGwpModels = useCallback(() => {
    if (accountContext.member.role === "member") {
      navigate("/");
    } else {
      setError(null);
      setIsLoading(true);
      const fetchGwpModels = get(`/gwp-models`);
      const fetchAccount = get(`accounts/${accountContext.id}`);

      Promise.all([fetchGwpModels.promise, fetchAccount.promise])
        .then((responses) => {
          setGwpModels(responses[0].data);
          setAccount(responses[1].data);
          setIsLoading(false);
          setError(null);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(fetchGwpModels, fetchAccount);
    }
  }, [accountContext, navigate]);

  useEffect(() => {
    if (tab === "gwp-preference") fetchAccountGwpModels();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAccountGwpModels, tab]);

  const setTabKey = (value) => {
    setTab(value);
    setSearchParams({ ...searchParams, tab: value });
  };

  return (
    <>
      <Header />
      <Container id={userCategory} className="MainContent py-3">
        <Row className="my-3">
          <Col xs={12} md={6}>
            <h1>{accountContext.name}</h1>
            <h5>Account management and member permissioning dashboard.</h5>
          </Col>
          <Col xs={12} md={6} className="text-end">
            <br />
            <UpdateAccountFiscalYear />
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Tabs
            defaultActiveKey={tab}
            activeKey={tab}
            onSelect={(k) => setTabKey(k)}
            className="mb-3"
          >
            <Tab eventKey="member-management" title="Members">
              <Col xs={12} className="my-1">
                <div className="border p-3">
                  <span className="float-end">
                    <div className="d-inline-flex">
                      <MemberCreate
                        account={accountContext}
                        invitations={[...members.map((item) => item.email)]}
                        onMemberCreated={(item) =>
                          setMembers([...members, item])
                        }
                      />
                    </div>
                  </span>
                  <h4>Manage members and permissioning.</h4>

                  <div className="my-3">
                    {isLoading && <Loader />}
                    {error && <ErrorHandler error={error} />}

                    {!!members.length && !isLoading && (
                      <MemberList
                        members={members}
                        account={account}
                        organizations={organizations}
                        portfolios={portfolios}
                        onInvitationUpdated={onInvitationUpdated}
                        onMemberDeleted={onMemberDeleted}
                        onMemberUpdated={onMemberUpdated}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Tab>
            <Tab eventKey="gwp-preference" title="GWP Models">
              <div className="border p-3">
                <span className="float-end">
                  <div className="d-inline-flex">
                    {!isLoading && account && (
                      <UpdateGWPModel
                        account={account}
                        gwpModels={gwpModels}
                        onGWPModelUpdated={(_) => fetchAccountGwpModels()}
                      />
                    )}
                  </div>
                </span>
                <h4>GWP Basis by Year</h4>

                <div className="my-3">
                  {isLoading && <Loader />}
                  {error && <ErrorHandler error={error} />}

                  {!isLoading && account && (
                    <Table hover className="my-4" size="sm" responsive>
                      <thead>
                        <tr>
                          <th className="text-end">Year</th>
                          <th className="text-end">GWP Basis</th>
                          <th className="text-end">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getAllYearsBetweenDates().map((year) => (
                          <tr key={year}>
                            <td className="text-end text-nowrap">{year}</td>
                            <td className="text-end text-nowrap">
                              {account?.gwpModels.find(
                                (model) =>
                                  Number.parseInt(model.year) ===
                                  Number.parseInt(year)
                              )?.name || (
                                <small className="text-muted">n/a</small>
                              )}
                            </td>
                            <td className="text-end text-nowrap">
                              {account?.gwpModels.find(
                                (model) =>
                                  Number.parseInt(model.year) ===
                                  Number.parseInt(year)
                              )?.description || (
                                <small className="text-muted">n/a</small>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </>
  );
};

export default Account;
