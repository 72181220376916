import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import "./SiteDetail.scss";
import PropTypes from "prop-types";

import { Col, Row, Nav, Accordion, Button } from "react-bootstrap";
import {
  Link,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import { uniqBy } from "lodash";

import { get } from "utils/DeApi";

import ScopeOne from "./ScopeOne/ScopeOne";
import ScopeThree from "./ScopeThree/ScopeThree";
import ScopeTwo from "./ScopeTwo/ScopeTwo";
import SiteOverview from "./SiteOverview/SiteOverview";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import SiteUpdate from "../SiteUpdate/SiteUpdate";
import SiteDelete from "../SiteDelete/SiteDelete";
import SiteReview from "../SiteReview/SiteReview";
import AllReportedActivity from "./SiteOverview/AllReportedActivity/AllReportedActivity";

import { AccountContext } from "contexts/AccountProvider";
import SiteLockUnlock from "../SiteLockUnlock/SiteLockUnlock";

import UserCategories from "utils/userCategories";

const SiteDetail = ({ organization }) => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [site, setSite] = useState();
  const [activityTypesScopeOne, setActivityTypesScopeOne] = useState([]);
  const [activityTypesScopeTwo, setActivityTypesScopeTwo] = useState([]);
  const [activityTypesScopeThree, setActivityTypesScopeThree] = useState([]);
  const [siteYearsUnderReview, setSiteYearsUnderReview] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activityTypes = uniqBy(
    [...activityTypesScopeOne, ...activityTypesScopeTwo],
    "label"
  );

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const scopeValue = location.pathname.slice(-1);

  const siteId = params.siteId;
  const [searchParams] = useSearchParams();

  const account = useContext(AccountContext);
  const [siteAccess, setSiteAccess] = useState(
    account.member.role !== "member"
  );

  const getSiteYearsUnderReview = (siteReviews) => {
    const siteYearsUnderReview = [];

    siteReviews.forEach((review) => {
      const latestStageOne = review.auditActivityHistory?.find(
        (hist) => hist.stage === 1
      );
      const latestStageTwo = review.auditActivityHistory?.find(
        (hist) => hist.stage === 2
      );
      const latestStageThree = review.auditActivityHistory?.find(
        (hist) => hist.stage === 3
      );

      if (
        latestStageOne?.status === 2 ||
        latestStageTwo?.status === 2 ||
        latestStageThree?.status === 2
      ) {
        siteYearsUnderReview.push(new Date(review.year).getFullYear());
      }
    });

    return siteYearsUnderReview;
  };

  const fetchSiteAndEmissionFactors = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const sitePromise = get(`/sites/${siteId}`);
    const activityTypesPromise = get("activity-types", {
      params: { filter: { scope: [1, 2, 3] } },
    });

    const memberSitesPromise = get(`members/${account.member.id}/sites`, {
      params: { organizationId: organization.id },
    });
    const siteAuditPromise = get("/audit-activities", {
      params: {
        "filter[siteId]": siteId,
      },
    });

    Promise.all([
      sitePromise.promise,
      activityTypesPromise.promise,
      memberSitesPromise.promise,
      siteAuditPromise.promise,
    ])
      .then((responses) => {
        setSite(responses[0].data);
        setActivityTypesScopeOne(
          responses[1].data.filter((type) => type.scope === 1)
        );
        setActivityTypesScopeTwo(
          responses[1].data.filter((type) => type.scope === 2)
        );
        setActivityTypesScopeThree(
          responses[1].data.filter((type) => type.scope === 3)
        );

        setSiteAccess(
          siteAccess || !!responses[2].data.find((item) => item.id === siteId)
        );

        setSiteYearsUnderReview(getSiteYearsUnderReview(responses[3].data));

        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.log(error);
      });
    subscribedPromises.current.push(
      sitePromise,
      activityTypesPromise,
      memberSitesPromise,
      siteAuditPromise
    );
  }, [account.member.id, organization.id, siteAccess, siteId]);

  useEffect(() => {
    fetchSiteAndEmissionFactors();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchSiteAndEmissionFactors]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!site) return <span />;
  const breadcrumbs = [
    { name: "Dashboard", link: "/" },
    {
      name: organization.name,
      link: `/organizations/${organization.id}`,
    },
    {
      name: "Bottom-Up GHG Inventory",
      link: `/organizations/${organization.id}/bottom-up`,
    },
    {
      name: site.name,
      link: `/organizations/${organization.id}/sites/${site.id}`,
    },
  ];

  return (
    <Row id={userCategory} className="SiteDetail">
      <Col xs={12} md={3} className="py-3 scroller border-end">
        <div className="my-3">
          <h5>
            {organization.name} - <i>Bottom-Up GHG Inventory</i>
          </h5>
          <h1>{site.name}</h1>
          <p>{site.description}</p>
          <p className="mb-0">
            <small>
              Last updated at{" "}
              {new Date(site.updatedAt).toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </small>
          </p>
          {account.member.role !== "member" && (
            <>
              <SiteUpdate
                site={site}
                onSiteUpdated={() => fetchSiteAndEmissionFactors()}
              />{" "}
              <SiteDelete
                site={site}
                onSiteDeleted={() =>
                  navigate(`/organizations/${organization.id}/bottom-up`)
                }
              />{" "}
              <Button
                variant="outline-info"
                onClick={() =>
                  navigate(
                    `/organizations/${organization.id}/sites/${site.id}/lock-unlock`
                  )
                }
                size="sm"
                className="py-0"
              >
                Lock / Unlock
              </Button>{" "}
              <SiteReview
                site={site}
                onSiteUpdated={() => fetchSiteAndEmissionFactors()}
              />
            </>
          )}
        </div>
        <hr />
        <Nav className="flex-column" variant="pills">
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              end={true}
              to={`/organizations/${organization.id}/sites/${site.id}`}
            >
              Overview
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Accordion flush defaultActiveKey={scopeValue}>
          <Accordion.Item eventKey="1" className="border-0">
            <Accordion.Header as={"span"}>Scope 1</Accordion.Header>
            <Accordion.Body className="p-2">
              <Nav className="flex-column" variant="pills">
                {uniqBy(activityTypesScopeOne, "label").map(
                  (activityType, index) => (
                    <Nav.Item key={activityType.id}>
                      <Nav.Link
                        as={Link}
                        to={`/organizations/${organization.id}/sites/${
                          site.id
                        }/scopes/1?activityCategoryId=${
                          activityType.id
                        }&activityCategory=${
                          activityType.label
                        }&categoryNumber=${index + 1}`}
                        active={
                          activityType.id ===
                          searchParams.get("activityCategoryId")
                        }
                      >
                        {activityType.label}
                      </Nav.Link>
                    </Nav.Item>
                  )
                )}
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="border-0">
            <Accordion.Header as={"span"}>Scope 2</Accordion.Header>
            <Accordion.Body className="p-2">
              <Nav className="flex-column" variant="pills">
                {uniqBy(activityTypesScopeTwo, "label").map(
                  (activityType, index) => (
                    <Nav.Item key={activityType.id}>
                      <Nav.Link
                        as={Link}
                        to={`/organizations/${organization.id}/sites/${
                          site.id
                        }/scopes/2?activityCategoryId=${
                          activityType.id
                        }&activityCategory=${
                          activityType.label
                        }&categoryNumber=${index + 1}`}
                        active={
                          activityType.id ===
                          searchParams.get("activityCategoryId")
                        }
                      >
                        {activityType.label}
                      </Nav.Link>
                    </Nav.Item>
                  )
                )}
              </Nav>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="border-0">
            <Accordion.Header as={"span"}>Scope 3</Accordion.Header>
            <Accordion.Body className="p-2">
              <Nav className="flex-column" variant="pills">
                {uniqBy(activityTypesScopeThree, "label").map(
                  (activityType, index) => (
                    <Nav.Item key={activityType.id}>
                      <Nav.Link
                        as={Link}
                        to={`/organizations/${organization.id}/sites/${
                          site.id
                        }/scopes/3?activityCategoryId=${
                          activityType.id
                        }&activityCategory=${
                          activityType.label
                        }&categoryNumber=${index + 1}`}
                        active={
                          activityType.id ===
                          searchParams.get("activityCategoryId")
                        }
                      >
                        {index + 1}. {activityType.label}
                      </Nav.Link>
                    </Nav.Item>
                  )
                )}
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      <Col xs={12} md={9} className="py-3">
        <Routes>
          <Route
            path="/"
            element={
              <SiteOverview
                site={site}
                breadcrumbs={breadcrumbs}
                siteAccess={siteAccess}
                activityTypes={activityTypes}
              />
            }
          />
          <Route
            path="/scopes/1"
            element={
              <ScopeOne
                site={site}
                breadcrumbs={breadcrumbs}
                siteAccess={siteAccess}
                siteYearsUnderReview={siteYearsUnderReview}
                organization={organization}
              />
            }
          />
          <Route
            path="/scopes/2"
            element={
              <ScopeTwo
                site={site}
                breadcrumbs={breadcrumbs}
                siteAccess={siteAccess}
                siteYearsUnderReview={siteYearsUnderReview}
                organization={organization}
              />
            }
          />
          <Route
            path="/scopes/3"
            element={
              <ScopeThree
                site={site}
                breadcrumbs={breadcrumbs}
                siteAccess={siteAccess}
                siteYearsUnderReview={siteYearsUnderReview}
                organization={organization}
              />
            }
          />
          <Route
            path="/reported-activities"
            element={
              <AllReportedActivity site={site} breadcrumbs={breadcrumbs} />
            }
          />
          <Route
            path="/lock-unlock"
            element={
              <SiteLockUnlock
                site={site}
                organization={organization}
                breadcrumbs={breadcrumbs}
                siteAccess={siteAccess}
                siteYearsUnderReview={siteYearsUnderReview}
                setSiteActYearsUnderReview={setSiteYearsUnderReview}
              />
            }
          />
        </Routes>
      </Col>
    </Row>
  );
};

SiteDetail.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default SiteDetail;
