import React, { useEffect, useRef, useState, useCallback } from "react";
import { Alert, Button, Col, Row, Spinner, Form } from "react-bootstrap";
import PropTypes from "prop-types";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import OrganizationActivityList from "./OrganizationActivityList";
import UserCategories from "utils/userCategories";

import { getAllYearsBetweenDates } from "utils/dateUtils";
import { get, download } from "utils/DeApi";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";

const OrganizationActivities = ({
  organization,
  breadcrumbs,
  sites,
  selectedScopeTwoPreference,
}) => {
  const subscribedPromises = useRef([]);
  const userCategory = UserCategories();

  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [isExpanding, setIsExpanding] = useState(false);
  const [expandingError, setExpandingError] = useState();

  const [isExporting, setIsExporting] = useState(false);
  const [exportingError, setExportingError] = useState();
  const [scopeTwoPreference, setScopeTwoPreference] = useState(
    selectedScopeTwoPreference
  );

  const [showingAllActivities, setShowingAllActivities] = useState(false);

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });
  const recentYear = new Date().getFullYear() - 1;
  const [year, setYear] = useState(recentYear.toString());

  const [selectedSite, setSelectedSite] = useState();
  const [scope, setScope] = useState();

  const [offset, setOffset] = useState(0);

  const showMoreActivities = useCallback(
    (offset) => {
      setExpandingError(null);
      setIsExpanding(true);

      const params = {
        params: {
          offset: offset,
          limit: 10,
          sort: "-updatedAt",
          "filter[scope]": scope,
          "filter[sites]": selectedSite,
          "filter[yearEnded]": year,
          "filter[preference]": scopeTwoPreference,
        },
      };

      const activitiesPromise = get(
        `organizations/${organization.id}/activities`,
        params
      );

      activitiesPromise.promise
        .then((responses) => {
          setActivities((prevState) => [...prevState, ...responses.data]);
          setOffset((prevState) => prevState + responses.data.length);
          setExpandingError(null);
          setIsExpanding(false);
          if (responses.data.length < 10) {
            setShowingAllActivities(true);
          } else {
            setShowingAllActivities(false);
          }
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setExpandingError(error);
            setIsExpanding(false);
          }
        });
      subscribedPromises.current.push(activitiesPromise);
    },
    [scope, selectedSite, year, scopeTwoPreference, organization.id]
  );

  const exportActivities = () => {
    setIsExporting(true);

    const exportPromise = download(
      `/organizations/${organization.id}/activities/export`,
      {
        params: {
          "filter[scope]": scope,
          "filter[sites]": selectedSite,
          "filter[yearEnded]": year,
          "filter[preference]": scopeTwoPreference,
        },
      }
    );

    exportPromise.promise
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `${organization.name} - Activities.xlsx`);
        document.body.appendChild(link);

        link.click();
        setIsExporting(false);
      })
      .catch((error) => {
        setIsExporting(false);
        setExportingError(error);
        console.warn(error);
      });

    subscribedPromises.current.push(exportPromise);
  };

  const sortOrgActivities = (sortingvalue) => {
    setError(null);
    setIsLoading(true);
    setTableSortState({
      sortAsc: !tableSortState.sortAsc,
      sortColumn: sortingvalue,
    });

    const allScopesParams = {
      params: {
        offset: 0,
        limit: activities.length,
        sort: !tableSortState.sortAsc ? sortingvalue : `-${sortingvalue}`,
        "filter[scope]": scope,
        "filter[sites]": selectedSite,
        "filter[yearEnded]": year,
        "filter[preference]": scopeTwoPreference,
      },
    };

    const activitiesPromise = get(
      `organizations/${organization.id}/activities`,
      allScopesParams
    );

    activitiesPromise.promise
      .then((response) => {
        setActivities(response.data);
        setError(null);
        setIsLoading(false);
        if (response.data.length < 10) {
          setShowingAllActivities(true);
        }
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(activitiesPromise);
  };

  useEffect(() => {
    setActivities([]);

    setError(null);
    setIsLoading(true);

    const params = {
      params: {
        offset: 0,
        limit: 10,
        sort: "-updatedAt",
        "filter[scope]": scope,
        "filter[sites]": selectedSite,
        "filter[yearEnded]": year,
        "filter[preference]": scopeTwoPreference,
      },
    };

    const activitiesPromise = get(
      `organizations/${organization.id}/activities`,
      params
    );

    activitiesPromise.promise
      .then((responses) => {
        setActivities(responses.data);
        setOffset((prevState) => prevState + responses.data.length);
        setError(null);
        setIsLoading(false);
        if (responses.data.length < 10) {
          setShowingAllActivities(true);
        } else {
          setShowingAllActivities(false);
        }
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activitiesPromise);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [scope, organization.id, selectedSite, year, scopeTwoPreference]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!activities) return <span />;

  return (
    <div id={userCategory}>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: `Bottom-Up GHG Inventory`,
              link: `/organizations/${organization.id}/bottom-up`,
            },
            {
              name: `Details`,
              link: `/organizations/${organization.id}/org-reported-activities`,
              active: true,
            },
          ]}
        />
      </div>
      <Row>
        <Col xs={12} md={2}>
          <h3 className="my-2">Details</h3>
        </Col>
        <Col md={10}>
          <div className="text-end">
            <ScopeTwoPreferenceToggler
              className="d-inline-flex mx-1 py-1"
              scopeTwoPreference={scopeTwoPreference}
              setScopeTwoPreference={setScopeTwoPreference}
            />
            <div className="my-3 ms-2 d-inline-flex">
              <Form.Select
                aria-label="sites-filter"
                size="sm"
                value={selectedSite || "All Sites"}
                onChange={(ev) => {
                  let selectedSite = ev.target.value;
                  setSelectedSite(selectedSite);
                }}
              >
                <option value="">All Sites</option>
                {sites?.map((site) => (
                  <option
                    key={site.id}
                    data-bs-toggle="tooltip"
                    title={site?.name}
                    value={site.id}
                  >
                    {site?.name.length > 20
                      ? site.name.substring(0, 18) + "..."
                      : site.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="my-3 ms-2 d-inline-flex">
              <Form.Select
                aria-label="scope-filter"
                size="sm"
                value={scope || "Scope"}
                onChange={(ev) => {
                  let selectedScope = ev.target.value;
                  setScope(selectedScope);
                }}
              >
                <option value="">All Scopes</option>
                {[1, 2, 3].map((scope) => (
                  <option key={scope} value={scope}>
                    {scope}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="my-3 ms-2 d-inline-flex">
              <Form.Select
                aria-label="year-filter"
                size="sm"
                value={year || "All Years"}
                onChange={(ev) => {
                  let selectedYear = ev.target.value;
                  setYear(selectedYear);
                }}
              >
                <option value="">All Years</option>
                {getAllYearsBetweenDates().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="my-3 ms-2 d-inline-flex">
              <Button
                className="px-3"
                size="sm"
                variant="primary"
                onClick={exportActivities}
                disabled={isExporting}
              >
                Export Filtered Activities{"  "}
                {isExporting && (
                  <Spinner
                    animation="border"
                    variant="white"
                    size="sm"
                    className="ms-3"
                  />
                )}
                {exportingError && (
                  <span className="material-icons-outlined md-18 text-danger">
                    error
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {!!activities.length && (
            <>
              <OrganizationActivityList
                activities={activities}
                sortOrgActivities={sortOrgActivities}
                tableSortState={tableSortState}
                isLoading={isLoading}
              />
              <div className="my-3">
                {isExpanding && <Loader />}
                {expandingError && <ErrorHandler error={expandingError} />}
                {!showingAllActivities && (
                  <p className="text-center">
                    <Button
                      size="sm"
                      variant="primary"
                      className="px-3 my-2 bg-opacity-25"
                      onClick={() => showMoreActivities(offset)}
                      disabled={isExpanding}
                    >
                      Show More
                    </Button>
                  </p>
                )}
              </div>
            </>
          )}
          {!activities.length && (
            <Alert variant="info">
              There is currently nothing to show here.
            </Alert>
          )}
        </Col>
      </Row>
    </div>
  );
};

OrganizationActivities.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  selectedScopeTwoPreference: PropTypes.number.isRequired,
};

export default OrganizationActivities;
