import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { getFormattedEmission } from "utils/StringUtils";

import PortfilioOrganizationEdit from "../PortfolioOrganizationEdit/PortfilioOrganizationEdit";
import PortfilioNzifEdit from "../PortfilioNzifEdit/PortfolioNzifEdit";

import "./PortfolioOrganizationList.scss";
import PortfolioOrganizationDelete from "../PortfolioOrganizationDelete/PortfolioOrganizationDelete";
import PortfolioOrganizationHistory from "../PortfolioOrganizationHistory/PortfolioOrganizationHistory";

function PortfolioOrganizationList({
  portfolio,
  fetchPortfolioDetails,
  emissionYear,
}) {
  const { organizations = [] } = portfolio;

  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "bottomUpEmissions",
    sortAsc: false,
  });

  useEffect(() => {
    const portfolioEmissions = organizations.reduce((val, org) => {
      let summary =
        org.summary.find(
          (item) =>
            item.yearEnded.toString().substring(0, 4) ===
            emissionYear.toString()
        ) || {};

      let attributableEmissions = Number(org.equityOwnership)
        ? (Number(org.equityOwnership) * summary.bottomUpEmissions) / 100
        : 0;
      return val + attributableEmissions;
    }, 0);

    const data = organizations.map((org) => {
      let summary =
        org.summary?.find(
          (item) =>
            item.yearEnded.toString().substring(0, 4) ===
            emissionYear.toString()
        ) || {};

      return {
        ...org,
        ...{
          bottomUpEmissions: summary.bottomUpEmissions,
          topDownEmissions: summary.topDownEmissions,
          attributionFactor: org?.equityOwnership,
          attributableEmissions: org?.attributableEmissions,
          percentPortfolioEmissions: org?.attributableEmissionsPercent,
          assetType: !!org?.nzifDetails?.length
            ? org?.nzifDetails?.[0]?.assetType
            : "",
        },
      };
    });
    data.sort((a, b) =>
      a["bottomUpEmissions"] <= b["bottomUpEmissions"] ? 1 : -1
    );

    setTableData(data);
  }, [organizations]);

  const sortTable = (column) => {
    tableData.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  return (
    <Table hover size="sm" responsive className="my-3 first-column-fixed">
      <thead>
        <tr>
          <th
            className="table-sorter organizationColumn"
            onClick={() => sortTable("name")}
          >
            Organization
            {tableSortState.sortColumn === "name" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("topDownEmissions")}
          >
            {emissionYear}
            <br />
            Top-Down <br />
            Estimation
            <br />
            <small>
              (tCO<sub>2</sub>e)
            </small>
            {tableSortState.sortColumn === "topDownEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("bottomUpEmissions")}
          >
            {emissionYear} Bottom-Up <br /> GHG Inventory
            <br />
            <small>
              (tCO<sub>2</sub>e)
            </small>
            {tableSortState.sortColumn === "bottomUpEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("attributionFactor")}
          >
            {emissionYear}
            <br />
            Attribution <br />
            Factor
            {tableSortState.sortColumn === "attributionFactor" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>

          <th
            className="table-sorter text-end"
            onClick={() => sortTable("effectiveFrom")}
          >
            Effective <br />
            From
            {tableSortState.sortColumn === "effectiveFrom" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("attributableEmissions")}
          >
            {emissionYear} Attributable <br />
            Emissions
            <br />
            <small>
              (tCO<sub>2</sub>e)
            </small>
            {tableSortState.sortColumn === "attributableEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("percentPortfolioEmissions")}
          >
            % of Total <br />
            Attributable <br />
            Emissions
            {tableSortState.sortColumn === "percentPortfolioEmissions" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th
            className="table-sorter text-end"
            onClick={() => sortTable("aum")}
          >
            AUM (USD)
            {tableSortState.sortColumn === "aum" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th className="table-sorter" onClick={() => sortTable("assetType")}>
            Asset Type
            {tableSortState.sortColumn === "assetType" && (
              <span className="material-icons-outlined mx-1 md-20 text-muted">
                {!tableSortState.sortAsc && "arrow_downward"}
                {tableSortState.sortAsc && "arrow_upward"}
              </span>
            )}
          </th>
          <th>Asset Breakdown</th>
          <th className="text-end">% of AUM</th>
          <th>NZIF Category</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((organization) => {
          return (
            <tr key={organization.id}>
              <td
                className="text-nowrap text-truncate align-middle"
                title={organization?.name}
              >
                <Link
                  className="text-decoration-none"
                  to={`/organizations/${organization.id}`}
                >
                  {organization.name}
                </Link>
              </td>
              <td className="text-end align-middle">
                {getFormattedEmission(organization.topDownEmissions || 0)}
              </td>
              <td className="text-end align-middle">
                {getFormattedEmission(organization.bottomUpEmissions)}
              </td>
              <td className="text-end align-middle">
                {organization.attributionFactor}
                <small>%</small>
              </td>
              <td className="text-end align-middle">
                <small>
                  {organization.effectiveFrom
                    ? organization.effectiveFrom
                    : 2010}
                </small>
              </td>
              <td className="text-end align-middle">
                {getFormattedEmission(organization.attributableEmissions || 0)}
              </td>

              <td className="text-end align-middle">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(organization.percentPortfolioEmissions || 0)}
                <small>%</small>
              </td>
              <td className="text-end align-middle">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(organization.aum) || 0}
              </td>
              <td className="text-nowrap align-middle">
                {!!organization?.nzifDetails?.length ? (
                  organization?.nzifDetails?.[0]?.assetType === 1 ? (
                    "Infrastructure"
                  ) : (
                    "Non-Infrastructure"
                  )
                ) : (
                  <span className="text-muted">N/A</span>
                )}
              </td>
              <td className="text-nowrap align-middle">
                {!!organization?.nzifDetails?.length ? (
                  organization?.nzifDetails?.length > 1 ? (
                    <>
                      <span>Asset in Operation</span>
                      <hr className="m-0" />
                      <span>Asset Under Construction</span>
                    </>
                  ) : (
                    "N/A"
                  )
                ) : (
                  <span className="text-muted">N/A</span>
                )}
              </td>
              <td className="text-end align-middle">
                {!!organization?.nzifDetails?.length ? (
                  organization?.nzifDetails?.length > 1 ? (
                    <>
                      {organization?.nzifDetails?.sort(
                        (a, b) => a?.assetStatus - b?.assetStatus
                      ) && (
                        <>
                          <span>
                            {organization?.nzifDetails?.[0]?.assetPercentage}%
                          </span>
                          <hr className="m-0" />
                          <span>
                            {organization?.nzifDetails?.[1]?.assetPercentage}%
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span>
                      {organization?.nzifDetails?.[0]?.assetPercentage}%
                    </span>
                  )
                ) : (
                  <span className="text-muted">0.00%</span>
                )}
              </td>
              <td className="text-nowrap align-middle">
                {!!organization?.nzifDetails?.length ? (
                  organization?.nzifDetails?.length > 1 ? (
                    <>
                      {organization?.nzifDetails?.sort(
                        (a, b) => a?.assetStatus - b?.assetStatus
                      ) && (
                        <>
                          <span>
                            {organization?.nzifDetails?.[0]?.nzifCategory?.name}
                          </span>
                          <hr className="m-0" />
                          <span>
                            {organization?.nzifDetails?.[1]?.nzifCategory?.name}
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span>
                      {organization?.nzifDetails?.[0]?.nzifCategory?.name}
                    </span>
                  )
                ) : (
                  <span className="text-muted">N/A</span>
                )}
              </td>
              <td className="text-nowrap text-end align-middle">
                <PortfilioNzifEdit
                  organization={organization}
                  emissionYear={emissionYear}
                  portfolio={portfolio}
                  onOrganizationDeleted={() => fetchPortfolioDetails()}
                  portfolioId={portfolio.id}
                  onOrganizationUpdated={() => fetchPortfolioDetails()}
                />
                <PortfolioOrganizationHistory
                  organization={organization}
                  portfolio={portfolio}
                  portfolioId={portfolio.id}
                />
                <PortfilioOrganizationEdit
                  organization={organization}
                  emissionYear={emissionYear}
                  portfolio={portfolio}
                  onOrganizationDeleted={() => fetchPortfolioDetails()}
                  portfolioId={portfolio.id}
                  onOrganizationUpdated={() => fetchPortfolioDetails()}
                />
                <PortfolioOrganizationDelete
                  organization={organization}
                  emissionYear={emissionYear}
                  portfolio={portfolio}
                  onOrganizationDeleted={() => fetchPortfolioDetails()}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

PortfolioOrganizationList.propTypes = {
  portfolio: PropTypes.object.isRequired,
};

export default PortfolioOrganizationList;
