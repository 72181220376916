import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./ErrorHandler.scss";

const ErrorHandler = ({ error, message, className = "" }) => {
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("Oh snap! You got an error!");
  const [errorMsg, setErrorMsg] = useState(
    "Something unexpected happened. Please try again."
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      if (error.status) {
        setStatus(error.status);
      }
      if (error.statusText) {
        setStatusText(error.statusText);
      }

      let allowedPaths = ["/login", "/two-factor-code"];

      if (
        error.status === 401 &&
        allowedPaths.indexOf(location.pathname) === -1
      ) {
        navigate("/logout");
      }
      if (error.data && error.data.error) {
        if ("message" in error.data.error) {
          setErrorMsg(error.data.error.message);
        } else {
          setErrorMsg(error.data.error[Object.keys(error.data.error)[0]]);
        }
      }
    }
  }, [error]);
  if (status === 403)
    return (
      <div className="text-center pt-5 pb-5">
        <h4>Access Denied!</h4>
        <p>Sorry, you do not have permission to access this page.</p>
        <code className="text-danger">
          403
          <br />
          Unauthorized
        </code>
        <p className="mt-3 mb-3">
          <Link to={"/"}>
            Go back to Dashboard
            <span className="material-icons">arrow_forward</span>
          </Link>
        </p>
      </div>
    );
  else
    return (
      <Alert variant="warning" className={`mt-3 mb-3 ${className}`}>
        <h4>{statusText}</h4>
        <p>{message || errorMsg}</p>
        <small>{status}</small>
      </Alert>
    );
};

export default ErrorHandler;
