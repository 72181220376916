import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageS1Reductions from "../ManageS1Reductions/ManageS1Reductions";
import TransportDecarbonizationChart from "../DecarbonizationChart/DecarbonizationChart";
import "./TransportDecarbonization.scss";
import { formatNumber, formatPercentage } from "../helper";
import {
  getEconomicGrowth,
  getTransportDecarbonization,
} from "../forecastingModels";

import UserCategories from "utils/userCategories";

const TransportDecarbonization = ({
  organization,
  breadcrumbs,
  baselineYear,
  scopeOne,
  scopeTwo,
  scopeThree,
}) => {
  const userCategory = UserCategories();

  const [emission, setEmission] = useState();
  const [growthEmission, setGrowthEmission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [scopeOneReduction, setScopeOneReduction] = useState(100);
  const [scopeThreeReduction, setScopeThreeReduction] = useState(100);
  const [scopeThreeReductionGrowth, setScopeThreeReductionGrowth] =
    useState(100);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );
      const organizationPromise = get(
        `organizations/${organizationId}/transport-decarbonization`
      );
      Promise.all([economicGrowthPromise.promise, organizationPromise.promise])
        .then((responses) => {
          setIsLoading(false);

          const [{ data: economicGrowth = [] }, { data: transport = [] }] =
            responses || [];

          setScopeOneReduction(transport[0]?.scopeOneEmissionPercentage || 0);
          setScopeThreeReduction(
            transport[0]?.scopeThreeEmissionPercentage || 0
          );
          setEmission({
            ...transport[0],
          });

          setScopeThreeReductionGrowth(
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );

          calculateDecarb(
            { ...transport[0] },
            economicGrowth[0] ? economicGrowth[0]?.data : [],
            transport[0] ? transport[0]?.data : [],
            transport[0]?.scopeOneEmissionPercentage || 0,
            transport[0]?.scopeThreeEmissionPercentage || 0,
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    scopeOne,
    emission?.scopeOneEmissionPercentage,
    emission?.scopeThreeEmissionPercentage,
  ]);

  const calculateDecarb = (
    emissions = {},
    economicGrowthData = [],
    transportData = [],
    scopeOnePercentage = 100,
    scopeThreePercentage = 100,
    scopeThreePercentageGrowth = 100
  ) => {
    const economicGrowthChunk = getEconomicGrowth(
      economicGrowthData,
      scopeOne,
      scopeTwo,
      scopeThree,
      scopeThreePercentageGrowth
    );

    const transportChunk = getTransportDecarbonization(
      transportData,
      economicGrowthChunk,
      scopeOnePercentage,
      scopeThreePercentage
    );

    setGrowthEmission(economicGrowthChunk);
    setEmission({
      ...emissions,
      data: transportChunk,
    });
  };

  return (
    <div id={userCategory}>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: "Decarbonization of Transport",
              link: `/organizations/${organization.id}/forecasting/3/transport-decarbonization`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">Decarbonization of Transport</h2>
      <div className="my-3 p-3 border">
        <h3>
          Emissions Forecast
          <ManageS1Reductions
            onManageS1ReductionsUpdated={(data) =>
              setEmission({
                ...data,
                data: getTransportDecarbonization(
                  data?.data,
                  growthEmission,
                  scopeOneReduction,
                  scopeThreeReduction
                ),
              })
            }
            emissions={emission}
            baselineYear={baselineYear}
            projectType="transport"
          />
        </h3>
        {!isLoading && !error && (
          <TransportDecarbonizationChart
            data={emission?.data}
            scopeOne={scopeOne}
          />
        )}
        {error && !isLoading && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
      </div>
      <Table
        size="sm"
        striped
        responsive
        className="my-3 mt-5 first-column-fixed"
      >
        <thead>
          <tr>
            <th className="text-end text-nowrap px-3 year-column">Year</th>
            <th className="text-end text-nowrap px-3">
              % Reduction in Transport Emission Factor
            </th>
            <th className="text-end text-nowrap px-3">
              Cumulative % Reduction
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 1 Reduction{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 1 Emissions
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 3 Reduction{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
            <th className="text-end text-nowrap px-3">
              Scope 3 Emissions
              <small>
                (tCO<sub>2</sub>e)
              </small>
            </th>
          </tr>
        </thead>
        <tbody>
          {emission?.data?.map(
            (
              {
                year,
                cumulativeReduction,
                s1_reduction_percent,
                s1_reduction,
                s1_emission,
                s3_reduction,
                s3_emission,
                emission,
              },
              index
            ) => (
              <tr key={index}>
                <td className="text-end text-nowrap px-3 year-column">
                  {year}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatPercentage(s1_reduction_percent)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatPercentage(cumulativeReduction)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s1_reduction, 2)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s1_emission, 2)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s3_reduction, 2)}
                </td>
                <td className="text-end text-nowrap px-3">
                  {formatNumber(s3_emission, 2)}
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
      {!error && !isLoading && !emission?.id && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"63176351a824f"} view={"full"} />
      </div>
    </div>
  );
};

TransportDecarbonization.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  baselineYear: PropTypes.number,
  scopeOne: PropTypes.number,
};

export default TransportDecarbonization;
