import React, { useState, useContext } from "react";

import { Link } from "react-router-dom";

import {
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import "./Login.scss";

import { login } from "utils/BeeApi";
import { login as loginDE } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";
import CopyRight from "../Copyright/Copyright";

import TextLogo from "../TextLogo/TextLogo";

import { isValidEmail } from "utils/StringUtils";
import { UserDispatchContext } from "contexts/UserProvider";
import { twoFactorLogin } from "utils/BeeApi";

/**
 * Handles logging in to the application.
 * Handles single sign on.
 */
const Login = () => {
  const [requireTwoFactor, setRequireTwoFactor] = useState();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);

  const [code, setCode] = useState("");
  const [codeValid, setCodeValid] = useState(false);

  const [error, setError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const setUser = useContext(UserDispatchContext);

  function handleEmailChange(event) {
    let _email = event.target.value;
    setEmail(_email);
    setEmailValid(isValidEmail(_email));
    setFormSubmitted(false);
  }

  function handlePasswordChange(event) {
    let password = event.target.value;
    setPassword(password);
    setPasswordValid(!!password.length);
    setFormSubmitted(false);
  }

  function handleCodeChange(event) {
    let code = event.target.value;
    setCode(code);
    setCodeValid(!!code.length);
    setFormSubmitted(false);
  }

  function toggleVisiblePassword() {
    setShowPassword(!showPassword);
  }

  function handleLogin(event) {
    event.preventDefault();

    if (emailValid && passwordValid) {
      setError("");
      setIsLoading(true);
      login({ email: email, password: password })
        .then((response) => {
          if (response.data.requireTwoFactorCode) {
            setRequireTwoFactor(true);
            setError(null);
            setIsLoading(false);
            return Promise.reject("two-step");
          } else {
            return loginDE(response.data);
          }
        })
        .then(
          (response) =>
            new Promise((resolve) => setTimeout(() => resolve(response), 1000))
        )
        .then((response) => {
          let token = response.data.token;
          let payload = JSON.parse(window.atob(token.split(".")[1]));
          setUser({
            userId: payload.sub,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
          });
          setError(null);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error !== "two-step") {
            setError(error);
            setIsLoading(false);
          }
        });
    }
  }

  function submitTwoFactorCode(event) {
    event.preventDefault();

    if (codeValid) {
      setError("");
      setIsLoading(true);
      twoFactorLogin({ code: code, email: email })
        .then((response) => {
          return loginDE(response.data);
        })
        .then(
          (response) =>
            new Promise((resolve) => setTimeout(() => resolve(response), 1000))
        )
        .then((response) => {
          let token = response.data.token;
          let payload = JSON.parse(window.atob(token.split(".")[1]));
          setUser({
            userId: payload.sub,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
          });
          setError(null);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setIsLoading(false);
        });
    }
  }

  function renderLoginForm() {
    return (
      <Form onSubmit={handleLogin}>
        <div className="text-center">
          <h2 className="pre-title">Login to continue</h2>
        </div>
        <hr />
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            autoComplete="username"
            onChange={handleEmailChange}
            isInvalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
            data-testid="email-input"
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              autoComplete="current-password"
              onChange={handlePasswordChange}
              isInvalid={!passwordValid && formSubmitted}
              placeholder="Password"
              data-testid="password-input"
            />
            <Button
              variant="light"
              className="border-top border-end border-start  border-bottom"
              onClick={toggleVisiblePassword}
            >
              {showPassword ? (
                <span className="material-icons-outlined">visibility_off</span>
              ) : (
                <span className="material-icons-outlined">visibility</span>
              )}
            </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            This is required
          </Form.Control.Feedback>
        </Form.Group>

        {error && <ErrorHandler error={error} />}
        <div className="d-grid gap-2 mb-3">
          <Button
            type="submit"
            color="primary"
            disabled={isLoading}
            data-testid="submit-button"
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Sign in
          </Button>
        </div>

        <p className="text-center">
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
        <p className="text-center">
          New to Net Zero Compass?{" "}
          <Link to="/forgot-password">Get Started.</Link>
        </p>
      </Form>
    );
  }

  function renderTwoFactorForm() {
    return (
      <Form onSubmit={submitTwoFactorCode}>
        <div className="text-center">
          <h2 className="pre-title">Two-step authentication.</h2>
        </div>
        <hr />
        <p>
          To continue, please enter the 6-digit code sent to your email. The
          code is valid for 10 minutes only.
        </p>
        <Form.Group className="mb-3">
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              value={code}
              onChange={handleCodeChange}
              isInvalid={!codeValid && formSubmitted}
              placeholder="Enter code"
              autoFocus
            />
            <Button
              type="submit"
              color="primary"
              disabled={isLoading || !codeValid}
            >
              {isLoading && (
                <Spinner
                  className="me-2"
                  animation="border"
                  size="sm"
                  variant="light"
                />
              )}
              Continue
            </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            This is required
          </Form.Control.Feedback>
        </Form.Group>

        {error && <ErrorHandler error={error} />}

        <p className="text-center">
          <Button
            variant="link"
            onClick={() => {
              setRequireTwoFactor(false);
              setError(null);
            }}
          >
            Login.
          </Button>
        </p>
      </Form>
    );
  }

  return (
    <>
      <Container id="login-page" className="Login mt-2 mb-2">
        <RedirectIfAuthenticated />
        <Row>
          <Col
            xl={{ span: 4, offset: 4 }}
            lg={{ span: 4, offset: 4 }}
            md={{ span: 6, offset: 3 }}
            sm={{ span: 8, offset: 2 }}
          >
            <TextLogo />
            <Card className="border shadow-sm">
              <Card.Body>
                {requireTwoFactor ? renderTwoFactorForm() : renderLoginForm()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <CopyRight />
      </Container>
    </>
  );
};

export default Login;
