import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";

import { Container, Row, Col, Alert, Nav, Form } from "react-bootstrap";
import { Link, NavLink, Route, Routes } from "react-router-dom";

import { AccountContext } from "contexts/AccountProvider";

import { getFormattedEmission } from "utils/StringUtils";
import { getEmissionFromPercentageEquity } from "utils/numerals";
import { getAllYearsBetweenDates } from "utils/dateUtils";

import { get } from "utils/DeApi";

import Header from "components/App/Header/Header";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";

import PortfolioDetail from "./PortfolioDetail/PortfolioDetail";
import PortfolioCreate from "./PortfolioCreate/PortfolioCreate";
import PortfolioList from "./PortfolioList/PortfolioList";
import PortfolioEmissions from "./PortfolioChart/PortfolioEmissionsChart";
import PortfolioNzifChart from "./PortfolioNzifChart/PortfolioNzifChart";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import UserCategories from "utils/userCategories";

export default function Portfolio() {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const account = useContext(AccountContext);

  const [portfolios, setPortfolios] = useState([]);
  const [nzifStatistics, setNzifStatistics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [scopeTwoPreference, setScopeTwoPreference] = useState(0);

  const [emissionYear, setEmissionYear] = useState(
    new Date().getFullYear() - 1
  );

  const fetchPortfolios = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const portfoliosPromise = get("portfolios", {
      params: {
        accountId: account.id,
        yearEnded: `${emissionYear}-12-31`,
        "filter[preference]": scopeTwoPreference,
      },
    });
    const statisticPromise = get("/nzif-statistics", {
      params: {
        yearEnded: emissionYear + "-12-31",
        accountId: account.id,
      },
    });

    Promise.all([portfoliosPromise.promise, statisticPromise.promise])
      .then((responses) => {
        setPortfolios(responses[0].data);
        setNzifStatistics(responses[1].data);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(portfoliosPromise);
  }, [account.id, emissionYear, scopeTwoPreference]);

  useEffect(() => {
    fetchPortfolios();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchPortfolios]);

  return (
    <>
      <Header />
      <Container id={userCategory} className="MainContent">
        <Row>
          <Col xs={12} md={3} className="border-end py-3 scroller">
            <div className="my-3">
              <h1>Portfolios</h1>
              <h4 className="my-3">
                <Link className="text-decoration-none" to={`/portfolios`}>
                  Overview
                </Link>
              </h4>
              <hr />
            </div>

            <Nav className="flex-column" variant="pills">
              {portfolios?.portfolios?.map((portfolio) => (
                <Nav.Item key={portfolio.id}>
                  <Nav.Link as={NavLink} to={`/portfolios/${portfolio.id}`}>
                    {portfolio.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col xs={12} md={9} className="py-3">
            <Routes>
              <Route
                path="/"
                element={
                  <div>
                    {isLoading && <Loader />}
                    {error && <ErrorHandler error={error} />}
                    {portfolios && !error && !isLoading && (
                      <>
                        <div className="my-3">
                          <BreadCrumbs
                            breadcrumbs={[
                              { name: "Dashboard", link: "/" },
                              {
                                name: "Portfolios",
                                link: `/portfolios`,
                                active: true,
                              },
                            ]}
                          />
                        </div>
                        <span className="float-end">
                          <PortfolioCreate
                            onPortfolioCreated={fetchPortfolios}
                            variant="primary"
                          />
                        </span>
                        <h2>Overview</h2>
                        <p className="pt-1">
                          A portfolio is a group of portfolio companies in which
                          an investment firm owns equity.
                        </p>
                        <hr />
                        <>
                          {(() => {
                            return (
                              <>
                                <div className="text-end">
                                  <div className="text-end">
                                    <div className="d-inline-flex me-2">
                                      <ScopeTwoPreferenceToggler
                                        scopeTwoPreference={scopeTwoPreference}
                                        setScopeTwoPreference={
                                          setScopeTwoPreference
                                        }
                                      />
                                    </div>
                                    <div className="d-inline-flex">
                                      <Form.Label className="text-nowrap pt-2 me-2">
                                        <small>Reporting Year</small>
                                      </Form.Label>{" "}
                                      <Form.Select
                                        aria-label="year-filter"
                                        size="sm"
                                        className="ps-3"
                                        value={emissionYear}
                                        onChange={(ev) => {
                                          let selectedYear = ev.target.value;
                                          setEmissionYear(selectedYear);
                                        }}
                                      >
                                        {getAllYearsBetweenDates().map(
                                          (year) => (
                                            <option key={year} value={year}>
                                              {year}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>{" "}
                                    </div>
                                    <div className="d-inline-flex align-items-center mx-1 my-3 px-3 pb-1 bg-warning text-nowrap text-primary bg-opacity-10 text-nowrap">
                                      <span className="fs-1 me-1">
                                        {portfolios &&
                                          portfolios?.portfolios?.length}
                                      </span>{" "}
                                      Portfolios
                                    </div>
                                    <div className="d-inline-flex align-items-center mx-1 my-3 px-3 pb-1 bg-danger text-danger bg-opacity-10 text-nowrap">
                                      <span className="fs-1 me-1">
                                        {getFormattedEmission(
                                          portfolios?.totalFinancedEmissions ||
                                            0
                                        )}
                                      </span>
                                      <small className="me-1">
                                        tCO<sub>2</sub>e
                                      </small>{" "}
                                      {emissionYear} Emissions
                                    </div>
                                  </div>
                                </div>
                                {!portfolios?.portfolios?.length &&
                                !isLoading ? (
                                  <Alert variant="info">
                                    There are currently no portfolios to show.
                                    Add portfolios to start grouping
                                    organization.
                                  </Alert>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      <Col
                                        md={6}
                                        className="p-3 me-2 my-3 border text-center"
                                      >
                                        <h4 className="my-3">
                                          {emissionYear} Financed Emissions by
                                          Portfolio
                                        </h4>
                                        <h5>&nbsp;</h5>
                                        <PortfolioEmissions
                                          portfolios={portfolios?.portfolios}
                                        />
                                      </Col>
                                      <Col
                                        md={6}
                                        className="p-3 my-3 border text-center"
                                      >
                                        <h4 className="my-3">
                                          {emissionYear} NZIF Alignment for All
                                          Portfolios
                                        </h4>
                                        <h5>
                                          (Total AUM: USD{" "}
                                          {getFormattedEmission(
                                            nzifStatistics?.totalAum || 0
                                          )}
                                          )
                                        </h5>
                                        <PortfolioNzifChart
                                          nzifStatistics={
                                            nzifStatistics?.nzifCategories
                                          }
                                        />
                                      </Col>
                                    </div>
                                    <PortfolioList
                                      portfolios={portfolios?.portfolios}
                                      fetchPortfolios={fetchPortfolios}
                                    />
                                  </>
                                )}
                              </>
                            );
                          })()}
                        </>
                      </>
                    )}
                  </div>
                }
              />
              <Route path="/:portfolioId" element={<PortfolioDetail />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
}
