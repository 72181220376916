import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import { uniqBy } from "lodash";

import { get, put } from "utils/DeApi";
import { getMonthLabelFromValue } from "utils/dateUtils";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { getFormattedEmission } from "utils/StringUtils";
import SiteEmissionsByYear from "./SiteEmissionsByYear/SiteEmissionsByYear";
import SiteEmissionsByActivity from "./SiteEmissionsByActivity/SiteEmissionsByActivity";
import { Link } from "react-router-dom";
import ActivitiesUpload from "./ActivitiesUpload/ActivitiesUpload";
import SiteEmissionsByCategory from "./SiteEmissionsByCategory/SiteEmissionsByCategory";
import ScopeTwoPreferenceToggler from "../../ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import SiteQodScoreSummary from "components/Organization/Site/SiteDetail/SiteOverview/SiteQodScoreSummary";

import UserCategories from "utils/userCategories";

const SiteOverview = ({ site, breadcrumbs }) => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [activities, setActivities] = useState();

  const [emissionsSummary, setEmissionsSummary] = useState([]);
  const [activitiesEmissions, setActivitiesEmissions] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [scopeFilter, setScopeFilter] = useState(1);
  const [yearFilter, setYearFilter] = useState("2022");
  const [categoryFilter, setCategoryFilter] = useState(0);
  const [scopeTwoPreference, setScopeTwoPreference] = useState(
    site.scopeTwoPreference
  ); // market based is preference 1 or true and location based is preference 0 or false

  const handleSetScopeTwoPreference = (choice) => {
    setError(null);
    setIsLoading(true);
    const scopeTwoPreferencePromise = put(
      `/sites/${site.id}/scope-two-preference`,
      {
        scopeTwoPreference: choice,
      }
    );
    scopeTwoPreferencePromise.promise
      .then((response) => {
        setScopeTwoPreference(response.data.scopeTwoPreference);
        fetchActivities();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const fetchActivities = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const allScopesParams = {
      params: {
        // sites: [site.id],
        offset: 0,
        limit: 10,
      },
    };

    const activitiesPromise = get(
      `/sites/${site.id}/activities`,
      allScopesParams
    );

    const emissionsSummaryPromise = get(`sites/${site.id}/emissions-summary`);
    const activitiesSummaryPromise = get(`sites/${site.id}/activities-summary`);
    const activityTypesPromise = get("activity-types");

    Promise.all([
      activitiesPromise.promise,
      emissionsSummaryPromise.promise,
      activitiesSummaryPromise.promise,
      activityTypesPromise.promise,
    ])
      .then((responses) => {
        setActivities([...responses[0].data]);
        setEmissionsSummary(
          responses[1].data.filter((item) => !!item.emissions)
        );
        setActivitiesEmissions(responses[2].data);
        setActivityTypes(responses[3].data);

        setError(null);
        setIsLoading(false);
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activitiesPromise, emissionsSummaryPromise);
  }, [site.id, site.organization.id]);

  useEffect(() => {
    fetchActivities();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActivities]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!activities) return <span />;

  return (
    <div id={userCategory}>
      <div className="my-3">
        <BreadCrumbs breadcrumbs={breadcrumbs} />
      </div>
      <Row>
        <Col xs={12} md={3}>
          <h2>Overview</h2>
        </Col>
        <Col xs={12} md={9} className="text-end">
          {(() => {
            let latestYear =
              !!emissionsSummary.length &&
              Math.max(
                ...emissionsSummary.map((item) =>
                  item.yearEnded.substring(0, 4)
                )
              ) + "";

            let totalActivities = emissionsSummary.find(
              (item) => item.yearEnded.substring(0, 4) === latestYear
            );

            let latestEmisssions = emissionsSummary.filter(
              (item) => item.yearEnded.substring(0, 4) === latestYear
            );

            return (
              <>
                <div className="d-inline-flex align-items-start ms-1 align-top">
                  <ScopeTwoPreferenceToggler
                    scopeTwoPreference={scopeTwoPreference}
                    setScopeTwoPreference={handleSetScopeTwoPreference}
                  />
                </div>
                <div className="d-inline-flex align-items-center px-3 mx-2 bg-warning text-primary bg-opacity-10">
                  <span className="fs-1 mx-2">
                    {totalActivities?.activitiesCount || 0}{" "}
                  </span>
                  {latestYear || ""} Activities
                </div>
                <div className="d-inline-flex align-items-center px-3 ml-2 bg-danger text-danger bg-opacity-10">
                  <span className="fs-1 mx-1">
                    {getFormattedEmission(
                      latestEmisssions.reduce(
                        (sum, item) => item.emissions + sum,
                        0
                      )
                    )}
                  </span>{" "}
                  <small className="mx-2">
                    tCO<sub>2</sub>e
                  </small>{" "}
                  {latestYear || ""} Emissions
                </div>
              </>
            );
          })()}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className="p-3 my-3 border">
            <SiteEmissionsByYear
              emissionsSummary={emissionsSummary}
              activitiesEmissions={activitiesEmissions}
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="p-3 my-3 border">
            <span className="float-end">
              <div className="d-inline-flex">
                <Form.Label className="text-nowrap pt-1 me-1">
                  <small>Select Scope</small>
                </Form.Label>
                <Form.Select
                  aria-label="scope-filter"
                  size="sm"
                  className="ps-3"
                  value={scopeFilter || ""}
                  onChange={(ev) => setScopeFilter(Number(ev.target.value))}
                >
                  {[1, 2, 3].map((filter) => (
                    <option key={filter} value={filter}>
                      {filter}
                    </option>
                  ))}
                </Form.Select>
              </div>{" "}
            </span>
            <h4 className="mt-1 mb-3">Emissions By Source</h4>
            <SiteEmissionsByActivity
              scope={scopeFilter}
              activitiesEmissions={activitiesEmissions}
            />
          </div>
        </Col>

        <Col xs={12}>
          <div className="border p-3 mb-3">
            <SiteQodScoreSummary
              emissionsSummary={emissionsSummary}
              site={site}
            />
          </div>
        </Col>

        <Col xs={12}>
          <div className="p-3 my-3 border">
            <span className="float-end">
              <div className="d-inline-flex">
                <Form.Label className="text-nowrap pt-1 me-1">
                  <small>Select Year</small>
                </Form.Label>
                <Form.Select
                  aria-label="year-filter"
                  size="sm"
                  className="ps-3"
                  value={yearFilter || ""}
                  onChange={(ev) => setYearFilter(ev.target.value)}
                >
                  {(() => {
                    let startDate = new Date("2010-01-01");
                    let endDate = new Date();
                    let initialYear = startDate.getFullYear();
                    let currentYear = endDate.getFullYear();
                    const years = [];
                    while (currentYear >= initialYear) {
                      const year = currentYear--;
                      years.push(year.toString());
                    }

                    return years;
                  })().map((filter) => (
                    <option key={filter} value={filter}>
                      {filter}
                    </option>
                  ))}
                </Form.Select>
              </div>{" "}
            </span>
            <h4 className="mt-1 mb-3">Scope 3 Emissions by Category</h4>
            <SiteEmissionsByCategory
              activityTypes={uniqBy(
                activityTypes.filter((type) => type.scope === 3),
                "label"
              )}
              activitiesEmissions={activitiesEmissions}
              scope={3}
              year={yearFilter}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="p-3 border my-3">
            <span className="float-end">
              <ActivitiesUpload
                onActivitiesUploaded={fetchActivities}
                site={site}
                activityTypes={activityTypes}
              />
            </span>
            <h4 className="my-2">Recently Reported Activities</h4>
            {activities.length > 0 && (
              <>
                <Table size="sm" hover responsive>
                  <thead>
                    <tr>
                      <th rowSpan={2} className="align-middle">
                        Date
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Source
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Scope
                      </th>
                      <th rowSpan={2} className="text-end align-middle">
                        CO<sub>2</sub>
                        <small className="text-muted">
                          {" "}
                          in tC0<sub>2</sub>e
                        </small>
                      </th>
                      <th rowSpan={2} className="text-end align-middle">
                        N<sub>2</sub>O
                        <small className="text-muted">
                          {" "}
                          in tC0<sub>2</sub>e
                        </small>
                      </th>
                      <th rowSpan={2} className="text-end align-middle">
                        CH<sub>4</sub>
                        <small className="text-muted">
                          {" "}
                          in tC0<sub>2</sub>e
                        </small>
                      </th>
                      <th rowSpan={2} className="text-end align-middle">
                        tCO<sub>2</sub>e
                      </th>
                      <th rowSpan={2} className="text-end align-middle">
                        Updated At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities
                      .sort(
                        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                      )
                      .slice(0, 10)
                      .map((activity, index) => {
                        let type = activity.activityType;
                        return (
                          <tr key={index}>
                            <td>
                              {activity.month && (
                                <span>
                                  {getMonthLabelFromValue(activity.month)}{" "}
                                  <br />
                                </span>
                              )}
                              {activity.calendarYear?.substring(0, 4) ||
                                activity.yearEnded?.substring(0, 4) ||
                                "2021"}
                            </td>

                            <td>
                              <small>
                                {type?.categoryNumber
                                  ? `${type?.categoryNumber}. `
                                  : ""}
                                {type.title}
                              </small>{" "}
                            </td>
                            <td>
                              <small>Scope {activity.scope}</small>
                            </td>
                            <td className="text-end text-nowrap pe-2">
                              {activity.co2Emissions ? (
                                getFormattedEmission(activity.co2Emissions, 1)
                              ) : (
                                <small className="text-muted">n/a</small>
                              )}{" "}
                            </td>
                            <td className="text-end text-nowrap pe-2">
                              {activity.n2oEmissions ? (
                                getFormattedEmission(activity.n2oEmissions, 1)
                              ) : (
                                <small className="text-muted">n/a</small>
                              )}{" "}
                            </td>
                            <td className="text-end text-nowrap pe-2">
                              {activity.ch4Emissions ? (
                                getFormattedEmission(activity.ch4Emissions, 1)
                              ) : (
                                <small className="text-muted">n/a</small>
                              )}{" "}
                            </td>
                            <td className="text-end text-nowrap pe-2">
                              {getFormattedEmission(activity.emissions, 1)}{" "}
                            </td>
                            <td className="text-end text-nowrap">
                              <small>
                                {new Date(activity.updatedAt).toLocaleString(
                                  [],
                                  {
                                    dateStyle: "short",
                                    timeStyle: "short",
                                  }
                                )}
                              </small>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <Button
                  as={Link}
                  size="sm"
                  variant="outline-primary"
                  className="px-3"
                  to={`/organizations/${site.organization.id}/sites/${site.id}/reported-activities`}
                >
                  See all reported activities{" "}
                </Button>
              </>
            )}
            {activities.length === 0 && (
              <Alert variant="info">
                There is currently nothing to show here.
              </Alert>
            )}
          </div>
        </Col>
        <Col xs={12}>
          <div className="p-3 border my-3">
            <ContentDetails contentId={"624c84d2a22dd"} view={"full"} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

SiteOverview.propTypes = {
  site: PropTypes.object.isRequired,
};
export default SiteOverview;
