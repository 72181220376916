import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./BusinessAsUsualChart.scss";
import * as echarts from "echarts";
import {
  checkIfIsInBau,
  convertNegativeToZero,
  formatNumber,
} from "../../helper";

const colorsPallete = [
  "#AFEEEE",
  "#D1E8E2",
  "#D1E6FF",
  "#9DC4D6",
  "#D4E4F7",
  "#C5D9E9",
  "#AEC6CF",
  "#E6E6FA",
  "#CBFDCB",
  "#E0FFFF",
  "#B0E0E6",
  "#D4DDE9",
  "#E7EBF1",
  "#CCE7EF",
  "#E7F5F8",
  "#CDEBED",
  "#E7F5F6",
  "#D9EDE4",
  "#EAF6F0",
  "#E5F1D9",
  "#F0F7EA",
];

const BusinessAsUsualChart = ({
  data = [],
  selectedScope,
  forecastingConfig,
  onChartRendered,
  sbtiConfig,
  emission,
}) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);

  const userDefinedColors = [
    "#436D5D",
    "#82A78D",
    "#B7B2AA",
    "#82887E",
    "#19FFBC",
    "#4682B4",
    "#87CEEB",
    "#132B15",
  ];

  const currentPreferences =
    emission?.sbtiConfig &&
    JSON.parse(emission?.sbtiConfig)?.filter((data) => data?.show);
  const targets = currentPreferences?.map((data) =>
    data?.type === "sbti" ? data?.title : data?.type
  );

  const filteredScope =
    selectedScope === "Scope 1+2+3"
      ? "allScopes"
      : selectedScope === "Scope 1+2"
      ? "scopeOneAndTwo"
      : selectedScope === "Scope 1"
      ? "scopeOne"
      : selectedScope === "Scope 2"
      ? "scopeTwo"
      : "scopeThree";

  let firstLineData;
  let secondLineData;

  if (selectedScope === "Scope 3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_three_no_fla";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "scope_three_no_fla_wbc";
    } else secondLineData = "";
  } else if (selectedScope === "Scope 1+2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_and_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1+2+3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "all_scope_fla";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "all_scope_no_fla_wbc";
    } else secondLineData = "";
  } else {
    firstLineData = "";
    secondLineData = "";
  }

  const filteredTarget = "netZero";

  const filteredSbtiData = emission?.sbtiData?.[filteredTarget]?.calculations;

  const filterNewDataScopeOne = filteredSbtiData?.filter(
    (item) => item?.[firstLineData]
  );

  const filterNewDataScopeTwo = filteredSbtiData?.filter(
    (item) => item?.[secondLineData]
  );

  const scopeOneData = filterNewDataScopeOne?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[firstLineData]),
  ]);
  const scopeTwoData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[secondLineData]),
  ]);

  const filteredUserDefinedData = emission?.settings?.filter((data) =>
    targets?.includes(data?.type)
  );

  const filteredUserDefinedDataMap = filteredUserDefinedData?.map(
    (data) => data?.calculations?.[filteredScope]?.["netZero"]
  );

  const userDefinedNames =
    filteredUserDefinedData?.map((data) => data?.type) || [];

  const userDefinedValues =
    filteredUserDefinedDataMap?.map(
      (data) => data?.map((inData) => inData?.emission_reduction) || []
    ) || [];

  useEffect(() => {
    const availableMiscLevers = (
      forecastingConfig.length
        ? forecastingConfig.filter(
            (val) =>
              val.type &&
              checkIfIsInBau(forecastingConfig, val.type) &&
              data[0]?.[val.type]
          )
        : []
    ).reverse();

    const _data = data.reduce(
      (
        prev,
        {
          year,
          economicGrowth,
          gridReduction,
          heatingFuelsReduction,
          transportReduction,
          limitConstrainedReductionEmission,
          limitConstrainedReductionEmission25,
          ...rest
        }
      ) => {
        return {
          year: [...prev.year, `${year}`],
          economicGrowth: [...prev.economicGrowth, economicGrowth],
          bau: [...prev.bau, gridReduction],
          bauHeatingFuels: [...prev.bauHeatingFuels, heatingFuelsReduction],
          bauTransport: [...prev.bauTransport, transportReduction],
          residualEmissions: [
            ...prev.residualEmissions,
            convertNegativeToZero(
              economicGrowth -
                (checkIfIsInBau(forecastingConfig, "grid-decarbonization")
                  ? gridReduction
                  : 0) -
                (checkIfIsInBau(
                  forecastingConfig,
                  "heating-fuels-decarbonization"
                )
                  ? heatingFuelsReduction
                  : 0) -
                (checkIfIsInBau(forecastingConfig, "transport-decarbonization")
                  ? transportReduction
                  : 0) -
                availableMiscLevers.reduce(
                  (prev, curr) => prev + rest[`${curr.type}-reduction`],
                  0
                )
            ),
          ],
          limitConstrainedReductionEmission: [
            ...prev.limitConstrainedReductionEmission,
            limitConstrainedReductionEmission,
          ],
          limitConstrainedReductionEmission25: [
            ...prev.limitConstrainedReductionEmission25,
            limitConstrainedReductionEmission25,
          ],
        };
      },
      {
        year: [],
        economicGrowth: [],
        bau: [],
        bauHeatingFuels: [],
        bauTransport: [],
        residualEmissions: [],
        limitConstrainedReductionEmission: [],
        limitConstrainedReductionEmission25: [],
      }
    );

    let option = {
      title: {
        show:
          data.length === 0 &&
          scopeOneData?.length === 0 &&
          scopeTwoData?.length === 0 &&
          userDefinedValues?.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: "15%",
        right: 220,
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          let tooltipContent = `<div class="mb-2">${params[0].axisValueLabel}</div>`; // Initialize the tooltip content
          let prevValue = 0;
          params?.reverse().forEach((legend) => {
            tooltipContent += `<div class="d-flex justify-content-between align-items-center mb-1"><div><span class="d-inline-block me-2" style="width: 10px; height: 10px; border-radius: 50%; background-color: ${
              legend.color
            }"></span>
            ${
              legend.seriesName === "Growth in Production/Turnover" ||
              legend.seriesName === "1.5C SBTi (w/ constrain)" ||
              legend.seriesName === "WB2C SBTi (w/ constrain)" ||
              legend.seriesName === "BAU" ||
              legend.seriesName === "Residual Emissions"
                ? legend.seriesName
                : `<b>${legend.seriesName}</b>`
            }
            </div><div class="ps-4">${
              legend.seriesName === "Growth in Production/Turnover" ||
              legend.seriesName === "1.5C SBTi (w/ constrain)" ||
              legend.seriesName === "WB2C SBTi (w/ constrain)" ||
              legend.seriesName === "BAU" ||
              legend.seriesName === "Residual Emissions"
                ? formatNumber(
                    typeof legend?.value === "number" ||
                      typeof legend?.value === "string"
                      ? Number(legend?.value)?.toFixed()
                      : Number(legend?.value?.[1])?.toFixed(),
                    0
                  )
                : `<b>${formatNumber(
                    typeof legend?.value === "number" ||
                      typeof legend?.value === "string"
                      ? Number(legend?.value)?.toFixed()
                      : Number(legend?.value?.[1])?.toFixed(),
                    0
                  )}</b>`
            }</div></div>`;
            prevValue = legend.value;
          });

          return tooltipContent;
        },
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {},

      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: _data.year,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        min: 0,
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        ...(scopeOneData && scopeOneData?.length !== 0
          ? [
              {
                name: "SBTi 1.5C Aligned",
                data: scopeOneData,
                type: "line",
                color: "#32CD32",

                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                z: 105,
              },
            ]
          : []),

        ...(scopeTwoData && scopeTwoData?.length !== 0
          ? [
              {
                name: "SBTi 1.5C S1+2 & WB2C S3",
                data: scopeTwoData,
                type: "line",
                color: "#90EE90",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                z: 106,
              },
            ]
          : []),

        ...(userDefinedValues.length !== 0
          ? userDefinedValues
              .filter((filteredData) => filteredData)
              .map((data, i) => ({
                name: userDefinedNames[i],
                data: data,
                type: "line",
                color: userDefinedColors[i],
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                targetType: "userDefined",
                symbolSize: 0,
                z: 105,
              }))
          : []),

        {
          name: "Residual Emissions",
          data: _data.residualEmissions,
          symbolSize: 0,
          color: "#A6A6A6",
          type: "line",
          stack: "x",
          areaStyle: {
            type: "default",
            opacity: 1,
          },
          lineStyle: {
            normal: {
              opacity: 0,
            },
          },
          z: 100,
        },
        ...[
          ...(availableMiscLevers.length
            ? availableMiscLevers.map((val, index) => {
                const chartData = data.map(
                  (value) => value[`${val.type}-reduction`]
                );
                return {
                  name: val.title,
                  data: chartData,
                  type: "line",
                  symbolSize: 0,
                  scope: val.scopeOne
                    ? 1
                    : val.scopeThree
                    ? 3
                    : val.scopeTwo
                    ? 2
                    : 0,
                  stack: "x",
                  areaStyle: {
                    type: "default",
                    opacity: 1,
                  },
                  lineStyle: {
                    normal: {
                      opacity: 0,
                    },
                  },
                };
              })
            : []),
          {
            name: "Decarb of Transport",
            data: _data.bauTransport,
            type: "line",
            symbolSize: 0,
            scope: 1,
            stack: "x",
            areaStyle: {
              type: "default",
              opacity: 1,
            },
            lineStyle: {
              normal: {
                opacity: 0,
              },
            },
          },
          {
            name: "Decarb of Heating Fuels",
            data: _data.bauHeatingFuels,
            type: "line",
            symbolSize: 0,
            scope: 1,
            stack: "x",
            areaStyle: {
              type: "default",
              opacity: 1,
            },
            lineStyle: {
              normal: {
                opacity: 0,
              },
            },
          },
          {
            name: "Decarb of Grid Electricity",
            data: _data.bau,
            type: "line",
            symbolSize: 0,
            scope: 2,
            stack: "x",
            areaStyle: {
              type: "default",
              opacity: 1,
            },
            lineStyle: {
              normal: {
                opacity: 0,
              },
            },
          },
        ].sort((a, b) => b.scope - a.scope),
        ...(sbtiConfig.length &&
        sbtiConfig.filter(
          (val) =>
            (val.title === "2° C SBTi (w/ constrain)" ||
              val.title === "WB2C SBTi (w/ constrain)") &&
            val.show
        ).length
          ? [
              {
                name: "WB2C SBTi (w/ constrain)",
                data: _data.limitConstrainedReductionEmission25,
                type: "line",
                color: "#32CD32",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                z: 104,
              },
            ]
          : []),
        ...(!sbtiConfig.length ||
        (sbtiConfig.length &&
          sbtiConfig.filter(
            (val) =>
              (val.title === "1.5° C SBTi (w/ constrain)" ||
                val.title === "1.5C SBTi (w/ constrain)") &&
              val.show
          ).length)
          ? [
              {
                name: "1.5C SBTi (w/ constrain)",
                data: _data.limitConstrainedReductionEmission,
                type: "line",
                lineStyle: {
                  width: 5,
                },
                symbolSize: 0,
                color: "#90EE90",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                z: 103,
              },
            ]
          : []),

        {
          name: "BAU",
          data: _data.residualEmissions,
          type: "line",
          symbolSize: 0,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
          color: "#FFDC60",
          z: 101,
        },
        {
          name: "Growth in Production/Turnover",
          data: _data.economicGrowth,
          type: "line",
          symbolSize: 0,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
          color: "#5470c6",
          z: 102,
        },
      ],
    };

    option.legend = {
      orient: "vertical",
      top: "center",
      right: 0,
      formatter: (value) =>
        value.length > 25 ? value.substring(0, 25) + "..." : value,
      tooltip: { show: true },
      data: option.series
        .map((item, index) => ({
          name: item.name,
          icon:
            item.name === "Growth in Production/Turnover" ||
            item.name === "BAU" ||
            item.name === "WB2C SBTi (w/ constrain)" ||
            item.name === "1.5C SBTi (w/ constrain)"
              ? ""
              : "rect",

          textStyle: {
            fontWeight:
              item.name === "Growth in Production/Turnover" ||
              item.name === "BAU" ||
              item.name === "WB2C SBTi (w/ constrain)" ||
              item.name === "1.5C SBTi (w/ constrain)" ||
              item.name === "Residual Emissions"
                ? 400
                : 600,
          },
        }))
        .reverse(),
    };

    option.series = option.series.map((item, index) => {
      return {
        ...item,
        ...(item.name !== "Growth in Production/Turnover" &&
        item.name !== "BAU" &&
        item.name !== "Residual Emissions" &&
        item.name !== "WB2C SBTi (w/ constrain)" &&
        item.name !== "1.5C SBTi (w/ constrain)" &&
        item.name !== "SBTi 1.5C Aligned" &&
        item.name !== "SBTi 1.5C S1+2 & WB2C S3" &&
        item?.targetType !== "userDefined"
          ? {
              color: colorsPallete[index],
            }
          : {}),
      };
    });

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });

    if (selectedScope !== "Scope 1+2+3") {
      option = {
        ...option,
        series: option.series.filter((val) => {
          const bool = !!forecastingConfig?.find((lever) => {
            if (
              val?.name === "Growth in Production/Turnover" ||
              val?.name === "1.5C SBTi (w/ constrain)" ||
              val?.name === "BAU" ||
              val?.name === "Residual Emissions" ||
              val?.name === "WB2C SBTi (w/ constrain)" ||
              val?.name === "SBTi 1.5C Aligned" ||
              val?.name === "SBTi 1.5C S1+2 & WB2C S3" ||
              val?.targetType === "userDefined"
            ) {
              return true;
            }
            if (
              selectedScope === "Scope 3" &&
              (val?.name === "Decarb of Grid Electricity" ||
                val?.name === "Decarb of Heating Fuels" ||
                val?.name === "Decarb of Transport" ||
                val?.name === "SBTi 1.5C Aligned" ||
                val?.name === "SBTi 1.5C S1+2 & WB2C S3" ||
                val?.targetType === "userDefined")
            ) {
              return true;
            }

            return (
              lever?.title === val?.name &&
              ((selectedScope === "Scope 1+2" &&
                (lever.scopeOne || lever.scopeTwo)) ||
                (selectedScope === "Scope 1" && lever.scopeOne) ||
                (selectedScope === "Scope 2" && lever.scopeTwo) ||
                (selectedScope === "Scope 3" && lever.scopeThree))
            );
          });

          return !!bool;
        }),
      };

      const idx = option.series.findIndex(
        (val) => val.name === "Residual Emissions"
      );
      const bauIdx = option.series.findIndex((val) => val.name === "BAU");

      option.series[idx] = {
        ...option.series[idx],
        data: data.reduce(
          (
            prev,
            {
              economicGrowth,
              gridReduction,
              heatingFuelsReduction,
              transportReduction,
              ...rest
            }
          ) => {
            return [
              ...prev,
              convertNegativeToZero(
                economicGrowth -
                  (checkIfIsInBau(forecastingConfig, "grid-decarbonization")
                    ? gridReduction
                    : 0) -
                  (checkIfIsInBau(
                    forecastingConfig,
                    "heating-fuels-decarbonization"
                  )
                    ? heatingFuelsReduction
                    : 0) -
                  (checkIfIsInBau(
                    forecastingConfig,
                    "transport-decarbonization"
                  )
                    ? transportReduction
                    : 0) -
                  availableMiscLevers.reduce(
                    (prev, curr) =>
                      prev +
                      (!!option.series.find((item) => item.name === curr.title)
                        ? rest[`${curr.type}-reduction`]
                        : 0),
                    0
                  )
              ),
            ];
          },
          []
        ),
      };
      option.series[bauIdx] = {
        ...option.series[bauIdx],
        data: [...option.series[idx].data],
      };
    }

    if (forecastingConfig) {
      option = {
        ...option,
        series: option.series.filter((val) => {
          let isInBau = true;

          if (val.name === "Decarb of Heating Fuels") {
            isInBau = forecastingConfig.find((bauModule) => {
              return bauModule.link === "heating-fuels-decarbonization";
            })?.isInBau;
          } else if (val.name === "Decarb of Transport") {
            isInBau = forecastingConfig.find((bauModule) => {
              return bauModule.link === "transport-decarbonization";
            })?.isInBau;
          } else if (val.name === "Decarb of Grid Electricity") {
            isInBau = forecastingConfig.find((bauModule) => {
              return bauModule.link === "grid-decarbonization";
            })?.isInBau;
          }

          return isInBau;
        }),
      };
    } else {
      option = {
        ...option,
        series: option.series.filter((val) => {
          return !(
            val.name === "Decarb of Grid Electricity" ||
            val.name === "Decarb of Heating Fuels" ||
            val.name === "Decarb of Transport"
          );
        }),
      };
    }

    const maxValue = Math.max(
      ...[]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => !isNaN(item))
    );
    if (
      !max ||
      max < 1 ||
      max < maxValue ||
      ![]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => !isNaN(item))
        .includes(max)
    ) {
      setMax(maxValue);
    }
    option.yAxis.max = max;

    chart.setOption(option);

    // add an event listener for legendselectchanged
    // chart.on("legendselectchanged", function (params) {
    //   var option = chart.getOption(); // get the current option

    //   // if the markline has been removed before, add it back
    //   if (params && params.name === "Decarb of Heating Fuels") {
    //     // find the series by seriesName
    //     let series = option.series.find(function (series) {
    //       return series.name === "Decarb of Grid Electricity";
    //     });

    //     if (!series) {
    //       series = option.series.find(function (series) {
    //         return series.name === "Growth in Production/Turnover";
    //       });
    //     }

    //     if (!params.selected[params.name]) {
    //       if (series) {
    //         series.originalMarkLine = series.markLine;
    //         series.markLine = null;
    //       }
    //     } else {
    //       if (series && series.originalMarkLine) {
    //         series.markLine = series.originalMarkLine;
    //         series.originalMarkLine = null;
    //       }
    //     }
    //   } else if (params && params.name === "Decarb of Transport") {
    //     // find the series by seriesName
    //     let series = option.series.find(function (series) {
    //       return series.name === "Decarb of Heating Fuels";
    //     });

    //     if (!series) {
    //       series = option.series.find(function (series) {
    //         return series.name === "Decarb of Grid Electricity";
    //       });
    //     }

    //     if (!params.selected[params.name]) {
    //       if (series) {
    //         series.originalMarkLine = series.markLine;
    //         series.markLine = null;
    //       }
    //     } else {
    //       if (series && series.originalMarkLine) {
    //         series.markLine = series.originalMarkLine;
    //         series.originalMarkLine = null;
    //       }
    //     }
    //   } else if (params && params.name === "Decarb of Grid Electricity") {
    //     // find the series by seriesName
    //     let series = option.series.find(function (series) {
    //       return series.name === "Growth in Production/Turnover";
    //     });

    //     if (!params.selected[params.name]) {
    //       if (series) {
    //         series.originalMarkLine = series.markLine;
    //         series.markLine = null;
    //       }
    //     } else {
    //       if (series && series.originalMarkLine) {
    //         series.markLine = series.originalMarkLine;
    //         series.originalMarkLine = null;
    //       }
    //     }
    //   }

    //   // update the chart with the modified option
    //   chart.setOption(option);
    // });

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    onChartRendered &&
      chart.on("finished", () => {
        let src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });

        onChartRendered(src);
      });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    data,
    forecastingConfig,
    sbtiConfig,
    max,
    emission,
    onChartRendered,
    scopeOneData,
    scopeTwoData,
    selectedScope,
    filteredScope,
    userDefinedNames,
    userDefinedValues,
  ]);

  return (
    <div className="BusinessAsUsualChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

BusinessAsUsualChart.propTypes = {
  // data: PropTypes.array.isRequired,
};

export default BusinessAsUsualChart;
