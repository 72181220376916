import React, { Fragment, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import {
  Table,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import { getFormattedEmission } from "utils/StringUtils";
import { getMonthLabelFromValue } from "utils/dateUtils";
import ReportActivity from "components/Organization/Site/SiteDetail/ReportActivity/ReportActivity";
import DeleteActivity from "../SiteDetail/DeleteActivity/DeleteActivity";

import { AccountContext } from "contexts/AccountProvider";
import { isInvestments } from "utils/emissionFactors";
import ActivityHistory from "../SiteDetail/ActivityHistory/ActivityHistory";

import "./ActivityList.scss";

const ActivityList = ({
  activities,
  activityTypes,
  onActivityUpdated,
  siteAccess,
  siteYearsUnderReview,
  isException,
  organization,
}) => {
  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });
  const [showComment, setShowComment] = useState();
  const [showAttachments, setShowAttachments] = useState();
  const [showDatasetWarning, setShowDatasetWarning] = useState();

  const accountContext = useContext(AccountContext);
  const [searchParams] = useSearchParams();

  const activityCategory = searchParams.get("activityCategory");

  const hasSubCategories = (activityCategory) =>
    activityTypes.filter((type) => type.label === activityCategory)?.length > 1;

  useEffect(() => {
    const data = activities.map((activity) => {
      const { activityType = {} } = activity;
      const { emissionFactor } = activity;
      const activityUpdatedAt = new Date(activity.updatedAt);
      const activityEmissionFactorUpdatedAt = new Date(
        emissionFactor?.updatedAt
      );
      return {
        ...activity,
        ...{
          title: activityType.title,
          label: activityType.label,
          resource: emissionFactor?.emissionResource || "",
          unit: emissionFactor?.unit || "",
          factor: emissionFactor,
          type: activityType,
          gwp: activity?.gwpModel?.name,
          isOk: activity?.isFlagged,
          anomalyMessage: activity?.isFlaggedMessage
            ? activity?.isFlaggedMessage?.body
            : activity?.reportingMethod === "Standard EF" &&
              activityEmissionFactorUpdatedAt > activityUpdatedAt
            ? "Emission factor for this activity has been updated, please recalculate the emissions as needed."
            : !activity.isActivity && !activity.attachments?.length
            ? "This is a user-calculated emission but has no supporting documents, please update as needed"
            : "Emission factor for this activity has been updated, please recalculate the emissions as needed.",
        },
      };
    });

    setTableData(
      isException ? data?.filter((activity) => activity?.isOk) : data
    );
  }, [activities, activityTypes, isException]);

  const sortActivities = (column) => {
    tableData.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  const isExiobaseDataset = (datasetName) =>
    datasetName?.toLowerCase().includes("exiobase") &&
    datasetName?.toLowerCase().includes("2019");

  return (
    <div className="table-scroll-end">
      <Table
        hover
        className="my-4"
        size="sm"
        responsive
        id="site-activity-listing"
      >
        <thead>
          <tr>
            <th
              className="table-sorter align-middle"
              onClick={() => sortActivities("yearEnded")}
              rowSpan={2}
            >
              Date
              {tableSortState.sortColumn === "yearEnded" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              className="text-nowrap table-sorter"
              onClick={() => sortActivities("gwp")}
            >
              GWP
              <br />
              Basis
              {tableSortState.sortColumn === "gwp" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            {isInvestments(activityCategory) && (
              <th
                rowSpan={2}
                className="table-sorter text-center align-middle"
                onClick={() => sortActivities("pcafQodScore")}
                title="Data Quality Score"
              >
                Data Quality Score
                {tableSortState.sortColumn === "pcafQodScore" && (
                  <span
                    className="material-icons-outlined mx-1 md-20 text-muted"
                    title={
                      tableSortState.sortAsc
                        ? "Ascending order"
                        : "Descending order"
                    }
                  >
                    {tableSortState.sortAsc && "arrow_upward"}
                    {!tableSortState.sortAsc && "arrow_downward"}
                  </span>
                )}
              </th>
            )}
            <th
              rowSpan={2}
              className="text-nowrap table-sorter align-middle"
              onClick={() => sortActivities("label")}
            >
              Activity Type
              {tableSortState.sortColumn === "label" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            {hasSubCategories(activityCategory) && (
              <th
                rowSpan={2}
                className="text-nowrap table-sorter align-middle"
                onClick={() => sortActivities("title")}
              >
                Subcategory
                {tableSortState.sortColumn === "title" && (
                  <span
                    className="material-icons-outlined mx-1 md-20 text-muted"
                    title={
                      tableSortState.sortAsc
                        ? "Ascending order"
                        : "Descending order"
                    }
                  >
                    {tableSortState.sortAsc && "arrow_upward"}
                    {!tableSortState.sortAsc && "arrow_downward"}
                  </span>
                )}
              </th>
            )}
            <th
              rowSpan={2}
              className="table-sorter align-middle"
              onClick={() => sortActivities("resource")}
            >
              Resource
              {tableSortState.sortColumn === "resource" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              rowSpan={2}
              className="text-end table-sorter align-middle"
              onClick={() => sortActivities("amount")}
            >
              Quantity
              {tableSortState.sortColumn === "amount" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              rowSpan={2}
              className="text-end table-sorter align-middle"
              onClick={() => sortActivities("co2Emissions")}
            >
              CO<sub>2</sub>
              <small className="text-muted">
                {" "}
                in tC0<sub>2</sub>e
              </small>
              {tableSortState.sortColumn === "co2Emissions" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              rowSpan={2}
              className="text-end table-sorter align-middle"
              onClick={() => sortActivities("n2oEmissions")}
            >
              N<sub>2</sub>O
              <small className="text-muted">
                {" "}
                in tC0<sub>2</sub>e
              </small>
              {tableSortState.sortColumn === "n2oEmissions" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              rowSpan={2}
              className="text-end table-sorter align-middle"
              onClick={() => sortActivities("ch4Emissions")}
            >
              CH<sub>4</sub>
              <small className="text-muted">
                {" "}
                in tC0<sub>2</sub>e
              </small>
              {tableSortState.sortColumn === "ch4Emissions" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              rowSpan={2}
              className="text-end table-sorter align-middle"
              onClick={() => sortActivities("emissions")}
            >
              tCO<sub>2</sub>e
              {tableSortState.sortColumn === "emissions" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>

            <th
              rowSpan={2}
              className="text-nowrap text-end table-sorter align-middle"
              onClick={() => sortActivities("updatedAt")}
            >
              Updated At
              {tableSortState.sortColumn === "updatedAt" && (
                <span
                  className="material-icons-outlined mx-1 md-20 text-muted"
                  title={
                    tableSortState.sortAsc
                      ? "Ascending order"
                      : "Descending order"
                  }
                >
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((activity) => {
            return (
              <Fragment key={activity.id}>
                <tr>
                  <td>
                    {activity.month && (
                      <span>
                        {getMonthLabelFromValue(activity.month)} <br />
                      </span>
                    )}
                    {activity.calendarYear?.substring(0, 4) ||
                      activity.yearEnded?.substring(0, 4) ||
                      "2021"}
                  </td>
                  <td className="text-nowrap">
                    {activity.isActivity ? (
                      activity?.gwp
                    ) : (
                      <small className="text-muted">n/a</small>
                    )}
                  </td>
                  {isInvestments(activityCategory) && (
                    <td className="text-center">{activity.pcafQodScore}</td>
                  )}
                  <td>
                    {activity.type.categoryNumber}
                    {activity.type.categoryNumber && ". "}
                    {activity.type.label}
                    {activity.comment && (
                      <Button
                        variant="link"
                        size="sm"
                        className="py-0"
                        onClick={() => {
                          showComment === activity.id
                            ? setShowComment(null)
                            : setShowComment(activity.id);
                        }}
                      >
                        <span className="material-icons-outlined mt-n2 md-20">
                          info
                        </span>
                      </Button>
                    )}
                    {activity.emissionFactor?.dataset?.isDeleted === true && (
                      <Button
                        variant="link"
                        size="sm"
                        className="py-0"
                        onClick={() => {
                          showDatasetWarning === activity.id
                            ? setShowDatasetWarning(null)
                            : setShowDatasetWarning(activity.id);
                        }}
                      >
                        <span className="material-icons-outlined mt-n2 md-20 text-danger">
                          warning_amber
                        </span>
                      </Button>
                    )}
                    {activity.attachments && activity.attachments.length > 0 && (
                      <Button
                        variant="link"
                        size="sm"
                        className="py-0"
                        onClick={() =>
                          showAttachments === activity.id
                            ? setShowAttachments(null)
                            : setShowAttachments(activity.id)
                        }
                      >
                        <span className="material-icons-outlined mt-n2 md-20">
                          attachment
                        </span>
                      </Button>
                    )}
                  </td>
                  {hasSubCategories(activityCategory) && (
                    <td>{activity.title || ""}</td>
                  )}
                  <td>
                    <span>
                      {activity.resource || "n/a"}{" "}
                      {activity.isOk === 1 && (
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>{activity.anomalyMessage}</Tooltip>}
                        >
                          <span className="material-icons-outlined md-20 text-danger">
                            report_problem
                          </span>
                        </OverlayTrigger>
                      )}
                    </span>
                  </td>
                  <td className="text-end text-nowrap">
                    {activity.amount !== null ? (
                      <>
                        {getFormattedEmission(activity.amount)}{" "}
                        <small>
                          {activity.isActivity
                            ? isExiobaseDataset(
                                activity.emissionFactor?.dataset?.name
                              )
                              ? "USD"
                              : activity.unit
                            : activity.customEmissionFactorUnit ||
                              activity.unit}{" "}
                        </small>
                      </>
                    ) : (
                      "n/a"
                    )}{" "}
                  </td>
                  <td className="text-end text-nowrap pe-2">
                    {activity.co2Emissions ? (
                      getFormattedEmission(activity.co2Emissions, 1)
                    ) : (
                      <small className="text-muted">n/a</small>
                    )}{" "}
                  </td>
                  <td className="text-end text-nowrap pe-2">
                    {activity.n2oEmissions ? (
                      getFormattedEmission(activity.n2oEmissions, 1)
                    ) : (
                      <small className="text-muted">n/a</small>
                    )}{" "}
                  </td>
                  <td className="text-end text-nowrap pe-2">
                    {activity.ch4Emissions ? (
                      getFormattedEmission(activity.ch4Emissions, 1)
                    ) : (
                      <small className="text-muted">n/a</small>
                    )}{" "}
                  </td>
                  <td className="text-end text-nowrap pe-2">
                    {getFormattedEmission(activity.emissions, 1)}{" "}
                  </td>
                  <td className="text-end text-nowrap">
                    <small>
                      {new Date(activity.updatedAt).toLocaleString([], {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                    </small>
                    <br />
                    {siteAccess &&
                      !siteYearsUnderReview.includes(
                        new Date(activity.yearEnded).getFullYear()
                      ) && (
                        <ButtonGroup aria-label="First group" size="sm">
                          {activity.isActivity ? (
                            <ReportActivity
                              activityTypes={activityTypes.filter((type) =>
                                type.label.includes(activityCategory)
                              )}
                              activity={activity}
                              onActivityReported={onActivityUpdated}
                              scope={activity.scope}
                              isInvestments={isInvestments(activityCategory)}
                              isUpdate
                              siteYearsUnderReview={siteYearsUnderReview}
                              organization={organization}
                            />
                          ) : (
                            <ReportActivity
                              activityTypes={activityTypes.filter((type) =>
                                type.label.includes(activityCategory)
                              )}
                              activity={activity}
                              onActivityReported={onActivityUpdated}
                              scope={activity.scope}
                              isInvestments={isInvestments(activityCategory)}
                              isUpdate
                              siteYearsUnderReview={siteYearsUnderReview}
                              organization={organization}
                            />
                          )}
                          <ActivityHistory
                            activityId={activity.id}
                            accountId={accountContext.id}
                          />
                          <DeleteActivity
                            activity={activity}
                            type={activity.type}
                            factor={activity.factor}
                            onActivityDeleted={onActivityUpdated}
                            scope={activity.scope}
                          />
                        </ButtonGroup>
                      )}
                    {siteYearsUnderReview.includes(
                      new Date(activity.yearEnded).getFullYear()
                    ) && (
                      <ButtonGroup aria-label="First group" size="sm">
                        <ReportActivity
                          activityTypes={activityTypes.filter((type) =>
                            type.label.includes(activityCategory)
                          )}
                          activity={activity}
                          onActivityReported={onActivityUpdated}
                          scope={activity.scope}
                          isInvestments={isInvestments(activityCategory)}
                          isUpdate
                          siteYearsUnderReview={siteYearsUnderReview}
                          organization={organization}
                        />
                        <ActivityHistory
                          activityId={activity.id}
                          accountId={accountContext.id}
                        />
                        <Button
                          size="sm"
                          variant="outline-primary"
                          className="py-0"
                        >
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip>
                                Inventory for this year is locked
                              </Tooltip>
                            }
                          >
                            <span className="material-icons-outlined md-18">
                              lock
                            </span>
                          </OverlayTrigger>
                        </Button>
                      </ButtonGroup>
                    )}
                  </td>
                </tr>
                {showComment === activity.id && (
                  <tr>
                    <td className="text-center pt-1">
                      <button
                        className="btn-close btn-sm"
                        aria-label="Close"
                        onClick={() => setShowComment(null)}
                      ></button>
                    </td>
                    <td colSpan={6} className="pb-3">
                      {activity.comment}
                    </td>
                  </tr>
                )}
                {showDatasetWarning === activity.id && (
                  <tr>
                    <td className="text-center pt-1">
                      <button
                        className="btn-close btn-sm"
                        aria-label="Close"
                        onClick={() => setShowDatasetWarning(null)}
                      ></button>
                    </td>
                    <td colSpan={6} className="pb-3">
                      This dataset has been deleted. Please select from
                      available dataset in the list.
                    </td>
                  </tr>
                )}
                {showAttachments === activity.id && (
                  <tr>
                    <td>
                      <button onClick={() => setShowAttachments(null)}></button>
                    </td>
                    <td colSpan={6} className="pb-3">
                      {activity.attachments.map((attachment, index) => {
                        return (
                          <React.Fragment key={attachment.id}>
                            <a
                              href={attachment.url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              Attachment{index + 1}
                              <span className="material-icons md-18 material-icons-outlined">
                                open_in_new
                              </span>
                            </a>{" "}
                          </React.Fragment>
                        );
                      })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ActivityList.defaultProps = {
  siteYearsUnderReview: [],
};

ActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
  activityTypes: PropTypes.array.isRequired,
  onActivityUpdated: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  siteYearsUnderReview: PropTypes.array,
};

export default ActivityList;
