import React, { useEffect, useRef, useState } from "react";
import "./SBTinitiativeChart.scss";
import * as echarts from "echarts";
import { convertNegativeToZero, formatNumber } from "../../helper";
import PropTypes from "prop-types";

const SBTinitiativeChart = ({
  data = [],
  sbtiConfig,
  newData = {},
  selectedScope,
  selectedTarget,
  emission,
  step,
}) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);

  let firstLineData;
  let secondLineData;

  if (selectedScope === "Scope 3") {
    if (step) {
      firstLineData = "scope_three_no_fla_uncovered";
      secondLineData = "scope_three_no_fla_wbc_uncovered";
    } else {
      firstLineData = "scope_three_no_fla";
      secondLineData = "scope_three_no_fla_wbc";
    }
  } else if (selectedScope === "Scope 1+2") {
    firstLineData = "scope_one_and_two_fla";
    secondLineData = "";
  } else if (selectedScope === "Scope 1") {
    firstLineData = "scope_one_fla";
    secondLineData = "";
  } else if (selectedScope === "Scope 2") {
    firstLineData = "scope_two_fla";
    secondLineData = "";
  } else if (selectedScope === "All Scopes") {
    if (step) {
      firstLineData = "all_scope_fla_uncovered";
      secondLineData = "all_scope_no_fla_wbc_uncovered";
    } else {
      firstLineData = "all_scope_fla";
      secondLineData = "all_scope_no_fla_wbc";
    }
  } else {
    firstLineData = "";
    secondLineData = "";
  }

  const filteredTarget =
    selectedTarget === "Near-Term Targets" ? "nearTerm" : "netZero";
  const filteredData = newData?.[filteredTarget]?.calculations;

  const filterNewDataScopeOne = filteredData?.filter(
    (item) => item?.[firstLineData]
  );

  const filterNewDataScopeTwo = filteredData?.filter(
    (item) => item?.[secondLineData]
  );

  const scopeOneData = filterNewDataScopeOne?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[firstLineData]),
  ]);
  const scopeTwoData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[secondLineData]),
  ]);

  const uncoveredEmissionsData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.uncovered_emissions),
  ]);

  useEffect(() => {
    const _data = filteredData?.reduce(
      (
        prev,
        {
          year,
          limitConstrainedReductionEmission25,
          limitConstrainedReductionEmission,
          scope_one_fla,
          scope_two_no_fla,
        }
      ) => ({
        year: [...prev.year, year],
        limitConstrainedReductionEmission: [
          ...prev.limitConstrainedReductionEmission,
          convertNegativeToZero(scope_one_fla || 0),
        ],
        limitConstrainedReductionEmission25: [
          ...prev.limitConstrainedReductionEmission25,
          convertNegativeToZero(scope_two_no_fla),
        ],
      }),
      {
        year: [],
        limitConstrainedReductionEmission: [],
        limitConstrainedReductionEmission25: [],
      }
    );

    const option = {
      title: {
        show: filteredData?.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: "15%",
        right: "10%",
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => formatNumber(value, 1),
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {},

      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: _data?.year,
      },

      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        min: 0,
        ...((selectedScope === "Scope 3" && {
          max: Number(emission?.emissionsScopeThree || 0) + 500,
        }) ||
          (selectedScope === "All Scopes" && {
            max:
              Number(emission?.emissionsScopeOne || 0) +
              Number(emission?.emissionsScopeTwo || 0) +
              Number(emission?.emissionsScopeThree || 0) +
              500,
          })),
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",

        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        {
          name: selectedScope === "Scope 3" ? "1.5C" : "SBTi 1.5C Aligned",
          data: scopeOneData,
          type: "line",
          color: "#32CD32",
        },

        ...(scopeTwoData && scopeTwoData?.length !== 0
          ? [
              {
                name:
                  selectedScope === "All Scopes"
                    ? "SBTi 1.5C S1+2 & WB2C S3"
                    : selectedScope === "Scope 3"
                    ? "WB2C"
                    : "WB2C S3",
                data: scopeTwoData,
                type: "line",

                color: "#6AADE2",
              },
            ]
          : []),
        ...((selectedScope === "Scope 3" || selectedScope === "All Scopes") &&
        step &&
        uncoveredEmissionsData?.length !== 0
          ? [
              {
                name: "Not Covered by Target (S3)",
                data: uncoveredEmissionsData,
                type: "line",

                color: "#F6F33D",
              },
            ]
          : []),
      ],
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });

    if (!max || max < 1) {
      setMax(Math.max(...[].concat(...option.series.map((val) => val.data))));
    }
    // option.yAxis.max = max;
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    data,
    sbtiConfig,
    max,
    newData,
    scopeOneData,
    scopeTwoData,
    selectedScope,
    selectedTarget,
    filteredData,
    uncoveredEmissionsData,
    step,
    emission,
  ]);

  return (
    <div className="SBTinitiativeChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

SBTinitiativeChart.defaultProps = {
  step: true,
};

SBTinitiativeChart.propTypes = {
  step: PropTypes.bool,
};

export default SBTinitiativeChart;
