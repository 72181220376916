import React from "react";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="Logo">
    <div className="d-flex align-items-center justify-content-center">
      <div className="p-3 border-end border-5 ">
        <br />
        <h1 className="text-center">
          <big>
            <a href="/">
              Net Zero <br />
              Compass
            </a>
          </big>
        </h1>
        <h4 className="float-end">by</h4>
      </div>
      <div>
        <a href="/">
          <img
            className="img-responsive"
            src="/logo.svg"
            width="144px"
            alt="Logo"
          />{" "}
        </a>
      </div>
    </div>
  </div>
);

export default TextLogo;
