import React, { useEffect, useState, useRef, useCallback } from "react";
import { Alert, Table } from "react-bootstrap";

import { get } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import DocumentUpdate from "./Documents/DocumentUpdate";
import DeleteDocument from "./Documents/DeleteDocument";
import UpdateComments from "./Documents/DocumentComments";

export default function OrganizationAttachments({ organization }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [attachments, setAttachments] = useState([]);
  const subscribedPromises = useRef([]);

  const fetchOrgAttachments = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const fetchAttachments = get(
      `organizations/${organization.id}/attachments`
    );
    fetchAttachments.promise
      .then((response) => {
        setAttachments([...response.data]);
        setError();
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(fetchAttachments);
  }, [organization]);

  useEffect(() => {
    fetchOrgAttachments();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrgAttachments]);

  return (
    <div>
      <span className="float-end">
        <div className="d-inline-flex">
          <DocumentUpdate
            organization={organization}
            gwpModels={[]}
            onDocumentUploaded={(_) => fetchOrgAttachments()}
          />
        </div>
      </span>
      <h4>Manage organization document repository</h4>

      <div className="my-4">
        {isLoading && <Loader />}
        {!isLoading && !!error && <ErrorHandler error={error} />}
        {attachments.length === 0 && !isLoading && (
          <Alert variant="info">No documents present</Alert>
        )}

        {!isLoading && attachments.length > 0 && (
          <Table hover className="my-2" size="sm" responsive>
            <thead>
              <tr>
                <th className="text-end">File</th>
                <th className="text-end">Uploaded By</th>
                <th className="text-end">Date Uploaded</th>
                <th className="text-end">Comments</th>
                <th className="text-end"></th>
              </tr>
            </thead>
            <tbody>
              {attachments.map((attachment, index) => (
                <tr key={attachment.id}>
                  <td className="text-end">
                    <a
                      href={attachment.url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <span className="material-icons md-20 material-icons-outlined">
                        article
                      </span>
                    </a>{" "}
                  </td>
                  <td className="text-end">
                    {attachment.subscriber?.name || "Unkown"}
                  </td>
                  <td className="text-end">
                    {new Date(attachment.createdAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </td>
                  <td className="text-end">
                    <UpdateComments
                      attachment={attachment}
                      onCommentsUpdated={fetchOrgAttachments}
                    />
                  </td>
                  <td className="text-end">
                    <DeleteDocument
                      attachment={attachment}
                      organization={organization}
                      onDocumentDeleted={fetchOrgAttachments}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
}
