import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Modal, Tab, Table, Tabs } from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { post } from "utils/DeApi";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { get } from "utils/DeApi";
import "./ManageReductionTargetEmissions.scss";
import Loader from "components/Loader/Loader";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";

const ManageReductionTargetEmissions = ({
  year,
  activityTypes,
  decarbEmissionSummary,
  onManageReductionsEmissionsUpdated,
  showActivityTypes,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [emissionSummary, setEmissionSummary] = useState([]);
  // const [decarbEmissionSummary, setDecarbEmissionSummary] = useState([]);
  const [filteredScopes, setFilteredScopes] = useState([]);
  const [filteredActivityTypes, setFilteredActivityTypes] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [step, setStep] = useState(false);
  const [marketPreference, setMarketPreference] = useState(1); // market based is preference 1 or true and location based is preference 0 or false
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const { organizationId } = useParams();

  const handleClose = () => {
    setShow(false);
    setStep(showActivityTypes);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    emissionsScopeOne: yup
      .number()
      .label("Emissions Scope 1")
      .min(0)
      .when("step", {
        is: () => !step,
        then: yup.number().required(),
      }),
    emissionsScopeTwo: yup
      .number()
      .label("Emissions Scope 2")
      .min(0)
      .when("step", {
        is: () => !step,
        then: yup.number().required(),
      }),
    emissionsScopeThree: yup
      .number()
      .label("Emissions Scope 3")
      .min(0)
      .when("step", {
        is: () => !step,
        then: yup.number().required(),
      }),
    emissionsStationaryCombustionSourcesElectricityGeneration: yup
      .number()
      .label("Emissions Stationary Combustion Sources - Electricity Generation")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsStationaryCombustionSourcesHeatGeneration: yup
      .number()
      .label("Emissions Stationary Combustion Sources - Heat Generation")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsMobileCombustionSourcesLeasedOwned: yup
      .number()
      .label("Emissions Mobile Combustion Sources - Leased / Owned")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsDirectEmissionsRefrigerants: yup
      .number()
      .label("Emissions Direct Emissions - Refrigerants")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsDirectEmissionsOtherGases: yup
      .number()
      .label("Emissions Direct Emissions - Other Gases")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsEnergyConsumptionElectricityImport: yup
      .number()
      .label("Emissions Energy Consumption - Electricity Import")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsEnergyConsumptionHeatColdImport: yup
      .number()
      .label("Emissions Energy Consumption - Heat / Cold Import")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsPurchasedGoodsandServices: yup
      .number()
      .label("Emissions Purchased Goods and Services")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsCapitalGoodsCAPEX: yup
      .number()
      .label("Emissions Capital Goods / CAPEX")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsFuelandEnergyRelatedActivities: yup
      .number()
      .label("Emissions Fuel and Energy Related Activities")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsUpstreamTransportationandDistribution: yup
      .number()
      .label("Emissions Upstream Transportation and Distribution")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsWasteGeneratedinOperations: yup
      .number()
      .label("Emissions Waste Generated in Operations")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsBusinessTravel: yup
      .number()
      .label("Emissions Business Travel")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsEmployeeCommuting: yup
      .number()
      .label("Emissions Employee Commuting")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsUpstreamLeasedAssets: yup
      .number()
      .label("Emissions Upstream Leased Assets")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsDownstreamTransportationandDistribution: yup
      .number()
      .label("Emissions Downstream Transportation and Distribution")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsProcessingofSoldProducts: yup
      .number()
      .label("Emissions Processing of Sold Products")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsUseofSoldProducts: yup
      .number()
      .label("Emissions Use of Sold Products")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsEndoflifeTreatmentofSoldProducts: yup
      .number()
      .label("Emissions End-of-life Treatment of Sold Products")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsDownstreamLeasedAssets: yup
      .number()
      .label("Emissions Downstream Leased Assets")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsFranchises: yup
      .number()
      .label("Emissions Franchises")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    emissionsInvestments: yup
      .number()
      .label("Emissions Investments")
      .min(0)
      .when("step", {
        is: () => step,
        then: yup.number().required(),
      }),
    year: yup
      .number("Baseline Year is a required field")
      .label("Baseline Year")
      .required(),
  });

  const updateEmissions = (values) => {
    setError(null);
    setIsLoading(true);
    const data = {
      yearEnded: selectedYear + "-12-31",
      emissions: emissionSummary?.map((data) => ({
        [Object.keys(data)?.includes("activityTypeId")
          ? "activityTypeId"
          : "scope"]: Object.keys(data)?.includes("activityTypeId")
          ? data?.activityTypeId
          : data?.scope,
        emissionPreference: step,
        emissions:
          Object.entries(values).find(([key, value]) =>
            data?.label
              ? key ===
                  "emissions" + data?.label?.replace(/[^a-zA-Z0-9]/g, "") &&
                step
              : key ===
                (data?.scope === 1
                  ? "emissionsScopeOne"
                  : data?.scope === 2
                  ? "emissionsScopeTwo"
                  : "emissionsScopeThree")
          )?.[1] || 0,
      })),
    };
    const organizationPromise = post(
      `organizations/${organizationId}/decarb-emissions`,
      data
    );
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();

        onManageReductionsEmissionsUpdated(
          response?.data,
          response?.data?.length ? !!response.data[0].emissionPreference : 0,
          response?.data?.length ? response.data[0].yearEnded : 0
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    setSelectedYear(year);
  }, [year]);

  useEffect(() => {
    setStep(showActivityTypes);
  }, [showActivityTypes]);

  useEffect(() => {
    setError(null);
    setIsLoading(true);
    const emissionSummaryPromise = get(
      `organizations/${organizationId}/emissions-summary`,
      {
        params: {
          year: year,
          "filter[preference]": marketPreference,
        },
      }
    );
    Promise.all([emissionSummaryPromise.promise])
      .then((responses) => {
        setError(null);
        setIsLoading(false);
        setEmissionSummary(responses?.[0]?.data);
        // setDecarbEmissionSummary(responses?.[1]?.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(emissionSummaryPromise);
  }, [marketPreference, show, organizationId, year]);

  const handleYearChange = (currentYear, setFieldValue) => {
    setError(null);
    setIsLoading(true);
    const emissionSummaryPromise = get(
      `organizations/${organizationId}/emissions-summary`,
      {
        params: {
          year: currentYear,
        },
      }
    );
    Promise.all([emissionSummaryPromise.promise])
      .then((responses) => {
        setError(null);
        setIsLoading(false);
        setEmissionSummary(responses?.[0]?.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    const filteredSummaryScopes = emissionSummary?.filter((data) =>
      Object.keys(data).includes("scope")
    );

    const filteredSummaryActivityTypes = activityTypes
      ?.map(({ id }) =>
        emissionSummary?.find((item) => item?.activityTypeId === id)
      )
      ?.map((data) => ({
        type: "emissions" + data?.label?.replace(/[^a-zA-Z0-9]/g, ""),
        activityType: data?.label,
        emissions: data?.emissions,
        activityTypeId: data?.activityTypeId,
        categoryNumber: data?.categoryNumber,
        scope:
          !data?.categoryNumber && !data?.label?.includes("Energy")
            ? "S1"
            : data?.categoryNumber === null && data?.label?.includes("Energy")
            ? "S2"
            : "S3",
      }));
    setFilteredScopes(filteredSummaryScopes);
    setFilteredActivityTypes(filteredSummaryActivityTypes);
  }, [emissionSummary, activityTypes, decarbEmissionSummary]);

  return (
    <>
      <Button
        size="sm"
        variant="outline-primary"
        onClick={handleShow}
        className="mx-1"
      >
        Set Inventory Baseline
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom" closeButton>
          <Modal.Title className="fs-4">Set Inventory Baseline</Modal.Title>
        </Modal.Header>

        {error && <ErrorHandler error={error} />}
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateEmissions(values)}
          initialValues={{
            ...(decarbEmissionSummary.length
              ? decarbEmissionSummary
                  ?.map((data) => ({
                    [data?.activityType === null
                      ? data?.scope === 1
                        ? "emissionsScopeOne"
                        : data?.scope === 2
                        ? "emissionsScopeTwo"
                        : "emissionsScopeThree"
                      : `emissions${data?.activityType?.label.replace(
                          /[^a-zA-Z0-9]/g,
                          ""
                        )}`]: data?.emissions,
                  }))
                  .reduce((prev, curr) => ({ ...prev, ...curr }), {})
              : {
                  emissionsScopeOne: "0.00",
                  emissionsScopeTwo: "0.00",
                  emissionsScopeThree: "0.00",
                  emissionsStationaryCombustionSourcesElectricityGeneration:
                    "0.00",
                  emissionsStationaryCombustionSourcesHeatGeneration: "0.00",
                  emissionsMobileCombustionSourcesLeasedOwned: "0.00",
                  emissionsDirectEmissionsRefrigerants: "0.00",
                  emissionsDirectEmissionsOtherGases: "0.00",
                  emissionsEnergyConsumptionElectricityImport: "0.00",
                  emissionsEnergyConsumptionHeatColdImport: "0.00",
                  emissionsPurchasedGoodsandServices: "0.00",
                  emissionsCapitalGoodsCAPEX: "0.00",
                  emissionsFuelandEnergyRelatedActivities: "0.00",
                  emissionsUpstreamTransportationandDistribution: "0.00",
                  emissionsWasteGeneratedinOperations: "0.00",
                  emissionsBusinessTravel: "0.00",
                  emissionsEmployeeCommuting: "0.00",
                  emissionsUpstreamLeasedAssets: "0.00",
                  emissionsDownstreamTransportationandDistribution: "0.00",
                  emissionsProcessingofSoldProducts: "0.00",
                  emissionsUseofSoldProducts: "0.00",
                  emissionsEndoflifeTreatmentofSoldProducts: "0.00",
                  emissionsDownstreamLeasedAssets: "0.00",
                  emissionsFranchises: "0.00",
                  emissionsInvestments: "0.00",
                }),
            year: year || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="activity-year" className="mb-3">
                  <Form.Label>
                    Inventory Year{" "}
                    <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                  </Form.Label>
                  <Field
                    as={Form.Select}
                    name="year"
                    value={values.year}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setFieldValue("year", value);
                      setSelectedYear(value);
                      setEmissionSummary([]);
                      // setDecarbEmissionSummary([]);
                      handleYearChange(value, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    isValid={values.year}
                    isInvalid={errors.year && touched.year}
                  >
                    {[
                      "2023",
                      "2022",
                      "2021",
                      "2020",
                      "2019",
                      "2018",
                      "2017",
                      "2016",
                      "2015",
                      "2014",
                      "2013",
                      "2012",
                      "2011",
                      "2010",
                    ].map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Field>
                  <Form.Control.Feedback type="invalid">
                    {errors.year}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  // as={Col}
                  controlId="chartSwitch"
                  className="mb-3 d-flex justify-content-start"
                >
                  <label
                    title=""
                    htmlFor=""
                    // className="form-check-label "
                    className="text-uppercase fw-semibold me-4"
                  >
                    Set Inventory Baseline:
                  </label>
                  <label
                    title=""
                    htmlFor="custom-switch-line"
                    // className="form-check-label "
                    className="text-uppercase text-muted fw-semibold me-2"
                  >
                    <small>By Scope</small>
                  </label>
                  <Form.Check
                    type="switch"
                    id="custom-switch-line"
                    label={
                      <small className="text-uppercase text-muted fw-semibold">
                        By Activity Type
                      </small>
                    }
                    checked={step}
                    onChange={(e) => {
                      setStep(e.target.checked ? 1 : 0);
                      if (e.target.checked) {
                        setFieldValue("emissionsScopeOne", "0.00");
                        setFieldValue("emissionsScopeTwo", "0.00");
                        setFieldValue("emissionsScopeThree", "0.00");
                      } else {
                        setFieldValue(
                          "emissionsStationaryCombustionSourcesElectricityGeneration",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsStationaryCombustionSourcesHeatGeneration",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsMobileCombustionSourcesLeasedOwned",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsDirectEmissionsRefrigerants",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsDirectEmissionsOtherGases",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsEnergyConsumptionElectricityImport",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsEnergyConsumptionHeatColdImport",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsPurchasedGoodsandServices",
                          "0.00"
                        );
                        setFieldValue("emissionsCapitalGoodsCAPEX", "0.00");
                        setFieldValue(
                          "emissionsFuelandEnergyRelatedActivities",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsUpstreamTransportationandDistribution",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsWasteGeneratedinOperations",
                          "0.00"
                        );
                        setFieldValue("emissionsBusinessTravel", "0.00");
                        setFieldValue("emissionsEmployeeCommuting", "0.00");
                        setFieldValue("emissionsUpstreamLeasedAssets", "0.00");
                        setFieldValue(
                          "emissionsDownstreamTransportationandDistribution",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsProcessingofSoldProducts",
                          "0.00"
                        );
                        setFieldValue("emissionsUseofSoldProducts", "0.00");
                        setFieldValue(
                          "emissionsEndoflifeTreatmentofSoldProducts",
                          "0.00"
                        );
                        setFieldValue(
                          "emissionsDownstreamLeasedAssets",
                          "0.00"
                        );
                        setFieldValue("emissionsFranchises", "0.00");
                        setFieldValue("emissionsInvestments", "0.00");
                      }
                    }}
                  />
                </Form.Group>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {!step ? (
                      <>
                        <div className="bg-light px-2 py-2 border rounded-1 mb-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0">
                              Estimated emissions{" "}
                              <small>
                                (tCO<sub>2</sub>e)
                              </small>{" "}
                              from Bottom-Up Analysis{" "}
                              <span className="text-muted">optional</span>
                            </p>
                            <div className="d-inline-flex align-items-center mx-1 align-center">
                              <ScopeTwoPreferenceToggler
                                scopeTwoPreference={marketPreference}
                                setScopeTwoPreference={setMarketPreference}
                              />
                            </div>
                          </div>
                          <hr />
                          {!!filteredScopes?.length ? (
                            <Table size="sm" responsive>
                              <thead>
                                <tr>
                                  <th scope="col">Year</th>
                                  <th scope="col">Scope 1</th>
                                  <th scope="col">Scope 2</th>
                                  <th scope="col">Scope 3</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  <tr>
                                    <th scope="row">{values.year}</th>
                                    {filteredScopes?.map((data, i) => (
                                      <td key={i}>
                                        {isNaN(Number(data?.emissions))
                                          ? "n/a"
                                          : new Intl.NumberFormat("en-US", {
                                              maximumFractionDigits: 3,
                                            }).format(data?.emissions)}
                                      </td>
                                    ))}
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="outline-dark"
                                        className="py-0 float-end"
                                        onClick={() => {
                                          setFieldValue(
                                            "emissionsScopeOne",
                                            filteredScopes?.[0]?.emissions || 0,
                                            true
                                          );

                                          setFieldValue(
                                            "emissionsScopeTwo",
                                            filteredScopes?.[1]?.emissions || 0,
                                            true
                                          );

                                          setFieldValue(
                                            "emissionsScopeThree",
                                            filteredScopes?.[2]?.emissions || 0,
                                            true
                                          );

                                          setTimeout(() => {
                                            setFieldTouched(
                                              "emissionsScopeOne",
                                              true
                                            );
                                            setFieldTouched(
                                              "emissionsScopeTwo",
                                              true
                                            );
                                            setFieldTouched(
                                              "emissionsScopeThree",
                                              true
                                            );
                                          }, 100);
                                        }}
                                      >
                                        use values
                                      </Button>
                                    </td>
                                  </tr>
                                }
                              </tbody>
                            </Table>
                          ) : (
                            <p>No scope data available</p>
                          )}
                        </div>
                        <Form.Group
                          controlId="emissionsScopeOne"
                          className="mb-2"
                        >
                          <Form.Label>
                            Emissions scope 1{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>
                            <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                          </Form.Label>
                          <NumberFormat
                            name="emissionsScopeOne"
                            size="sm"
                            defaultValue={0}
                            value={values.emissionsScopeOne}
                            customInput={Form.Control}
                            thousandSeparator={true}
                            onValueChange={(numberItem) => {
                              setFieldValue(
                                "emissionsScopeOne",
                                numberItem.value
                              );
                            }}
                            onBlur={handleBlur}
                            isValid={values.emissionsScopeOne}
                            isInvalid={
                              errors.emissionsScopeOne &&
                              touched.emissionsScopeOne
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.emissionsScopeOne}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          controlId="emissionsScopeTwo"
                          className="my-2"
                        >
                          <Form.Label>
                            Emissions scope 2{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>
                            <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                          </Form.Label>
                          <NumberFormat
                            name="emissionsScopeTwo"
                            size="sm"
                            defaultValue={0}
                            value={values.emissionsScopeTwo}
                            customInput={Form.Control}
                            thousandSeparator={true}
                            onValueChange={(numberItem) => {
                              setFieldValue(
                                "emissionsScopeTwo",
                                numberItem.value
                              );
                            }}
                            onBlur={handleBlur}
                            isValid={values.emissionsScopeTwo}
                            isInvalid={
                              errors.emissionsScopeTwo &&
                              touched.emissionsScopeTwo
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.emissionsScopeTwo}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          controlId="emissionsScopeThree"
                          className="my-2"
                        >
                          <Form.Label>
                            Emissions scope 3{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>
                            <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                          </Form.Label>
                          <NumberFormat
                            name="emissionsScopeThree"
                            size="sm"
                            defaultValue={0}
                            value={values.emissionsScopeThree}
                            customInput={Form.Control}
                            thousandSeparator={true}
                            onValueChange={(numberItem) => {
                              setFieldValue(
                                "emissionsScopeThree",
                                numberItem.value
                              );
                            }}
                            onBlur={handleBlur}
                            isValid={values.emissionsScopeThree}
                            isInvalid={
                              errors.emissionsScopeThree &&
                              touched.emissionsScopeThree
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.emissionsScopeThree}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    ) : (
                      <>
                        <div className="bg-light px-2 py-2 border rounded-1 mb-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0">
                              Estimated emissions{" "}
                              <small>
                                (tCO<sub>2</sub>e)
                              </small>{" "}
                              from Bottom-Up Analysis{" "}
                              <span className="text-muted">optional</span>
                            </p>
                            <div className="d-inline-flex align-items-center mx-1 align-center">
                              <ScopeTwoPreferenceToggler
                                scopeTwoPreference={marketPreference}
                                setScopeTwoPreference={setMarketPreference}
                              />
                            </div>
                          </div>
                          <hr />
                          {!!filteredActivityTypes?.length && (
                            <Table
                              size="sm"
                              responsive
                              className="table-head-fixed"
                            >
                              <thead>
                                <tr>
                                  <th scope="col">Activity Types</th>
                                  <th scope="col">Emissions</th>
                                  <th scope="col">
                                    <Button
                                      size="sm"
                                      variant="outline-dark"
                                      className="py-0 px-3 float-end"
                                      onClick={() => {
                                        filteredActivityTypes?.forEach(
                                          (data) => {
                                            setFieldValue(
                                              data?.type,
                                              data?.emissions || 0
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      use all
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredActivityTypes?.map((data, i) => (
                                  <tr key={i}>
                                    <td>
                                      {data?.scope}:{" "}
                                      {data?.categoryNumber
                                        ? `${data?.categoryNumber}. `
                                        : ""}
                                      {data?.activityType}
                                    </td>
                                    <td>
                                      {isNaN(Number(data?.emissions))
                                        ? "n/a"
                                        : new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 3,
                                          }).format(data?.emissions)}
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="outline-dark"
                                        className="py-0 float-end"
                                        onClick={() => {
                                          setFieldValue(
                                            data?.type,
                                            data?.emissions
                                          );
                                        }}
                                      >
                                        use value
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </div>
                        <div>
                          {/* <hr /> */}
                          {filteredActivityTypes?.map((data, i) => {
                            const type = data.type;
                            return (
                              <Form.Group
                                key={i}
                                controlId={data.type}
                                className="my-2"
                              >
                                <Form.Label>
                                  Emissions {data?.scope}:{" "}
                                  {data?.categoryNumber
                                    ? `${data?.categoryNumber}. `
                                    : ""}
                                  {data.activityType}{" "}
                                  <small>
                                    (tCO<sub>2</sub>e)
                                  </small>
                                  <sup className="ps-1 fs-3 top-0 text-muted">
                                    *
                                  </sup>
                                </Form.Label>
                                <NumberFormat
                                  name={data.type}
                                  size="sm"
                                  defaultValue={0}
                                  value={values[type]}
                                  customInput={Form.Control}
                                  thousandSeparator={true}
                                  onValueChange={(numberItem) => {
                                    setFieldValue(data.type, numberItem.value);

                                    const totalEmissionsforScope =
                                      filteredActivityTypes
                                        .filter(
                                          (value) => value.scope === data?.scope
                                        )
                                        .reduce(
                                          (prev, curr) =>
                                            prev +
                                            (curr.type === data?.type
                                              ? Number(numberItem.value)
                                              : Number(values[curr.type])),
                                          0
                                        );
                                    const scopeName =
                                      data?.scope === "S1"
                                        ? "emissionsScopeOne"
                                        : data?.scope === "S2"
                                        ? "emissionsScopeTwo"
                                        : "emissionsScopeThree";

                                    setFieldValue(
                                      scopeName,
                                      Number(totalEmissionsforScope)
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isValid={values?.[data.type]}
                                  isInvalid={
                                    errors?.[data.type] && touched?.[data.type]
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors[type]}
                                </Form.Control.Feedback>
                              </Form.Group>
                            );
                          })}
                        </div>
                        <div className="bg-light px-2 py-2 border rounded-1 my-2">
                          {!!filteredScopes?.length ? (
                            <Table size="sm" responsive>
                              <thead>
                                <tr>
                                  <th scope="col">Year</th>
                                  <th scope="col">Scope 1</th>
                                  <th scope="col">Scope 2</th>
                                  <th scope="col">Scope 3</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  <tr>
                                    <th scope="row">{values.year}</th>
                                    <td>
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 3,
                                      }).format(values?.emissionsScopeOne)}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 3,
                                      }).format(values?.emissionsScopeTwo)}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 3,
                                      }).format(values?.emissionsScopeThree)}
                                    </td>
                                  </tr>
                                }
                              </tbody>
                            </Table>
                          ) : (
                            <p>No scope data available</p>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose} variant="link">
                  Close
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  className="float-end ms-2"
                  disabled={isLoading || !isValid}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ManageReductionTargetEmissions;
