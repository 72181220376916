import React, { useEffect, useRef, useState } from "react";
import "./EmissionProjectionsChart.scss";
import * as echarts from "echarts";
import { convertNegativeToZero, formatNumber } from "../../helper";

const EmissionProjectionsChart = ({
  data = [],
  projections = {},
  scopeTwo = 0,
  collapsed,
  selectedLegends,
  setSelectedLegends,
  showAllProjects,
  sbtiConfig,
  decarbLever,
  onChartRendered,
  emission,
}) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);

  const userDefinedColors = [
    "#436D5D",
    "#82A78D",
    "#B7B2AA",
    "#82887E",
    "#19FFBC",
    "#4682B4",
    "#87CEEB",
    "#132B15",
  ];

  const currentPreferences =
    emission?.sbtiConfig &&
    JSON.parse(emission?.sbtiConfig)?.filter((data) => data?.show);

  const targets = currentPreferences?.map((data) =>
    data?.type === "sbti" ? data?.title : data?.type
  );

  let firstLineData = "all_scope_fla";

  let secondLineData = "all_scope_no_fla_wbc";

  const filteredTarget = "netZero";

  const filteredSbtiData = emission?.sbtiData?.[filteredTarget]?.calculations;

  const filterNewDataScopeOne = filteredSbtiData?.filter(
    (item) => item?.[firstLineData]
  );

  const filterNewDataScopeTwo = filteredSbtiData?.filter(
    (item) => item?.[secondLineData]
  );

  const scopeOneData = filterNewDataScopeOne?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[firstLineData]),
  ]);
  const scopeTwoData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[secondLineData]),
  ]);

  const filteredUserDefinedData = emission?.settings?.filter((data) =>
    targets?.includes(data?.type)
  );

  const filteredUserDefinedDataMap = filteredUserDefinedData?.map(
    (data) => data?.calculations.allScopes.netZero
  );

  const userDefinedNames =
    filteredUserDefinedData?.map((data) => data?.type) || [];

  const userDefinedValues =
    filteredUserDefinedDataMap?.map(
      (data) => data?.map((inData) => inData?.emission_reduction) || []
    ) || [];

  useEffect(() => {
    const _data = data.reduce(
      (prev, values) => ({
        year: [...prev.year, values.year],
        bauHeatingFuels: [
          ...prev.bauHeatingFuels,
          convertNegativeToZero(values?.[`${decarbLever}`]),
        ],
        sumOfAnnualSaving: [
          ...prev.sumOfAnnualSaving,
          convertNegativeToZero(values.sumOfAnnualSaving),
        ],
        limitConstrainedReductionEmission: [
          ...prev.limitConstrainedReductionEmission,
          convertNegativeToZero(values.limitConstrainedReductionEmission),
        ],
        limitConstrainedReductionEmission25: [
          ...prev.limitConstrainedReductionEmission25,
          convertNegativeToZero(values.limitConstrainedReductionEmission25),
        ],
      }),
      {
        year: [],
        bauHeatingFuels: [],
        sumOfAnnualSaving: [],
        limitConstrainedReductionEmission: [],
        limitConstrainedReductionEmission25: [],
      }
    );

    const projects = Array.isArray(projections)
      ? projections.map((project, index) => ({
          ...project,
          data: project.data.map((value) => convertNegativeToZero(value)),
        }))
      : [];

    let option = {
      title: {
        show:
          data.length === 0 &&
          scopeOneData?.length === 0 &&
          scopeTwoData?.length === 0 &&
          userDefinedValues?.length === 0,

        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: "15%",
        right: 220,
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => formatNumber(value, 0),
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        orient: "vertical",
        top: "center",
        right: 0,
        formatter: (value) =>
          value.length > 25 ? value.substring(0, 25) + "..." : value,
        tooltip: { show: true },
        data: [
          {
            name: "SBTi 1.5C S1+2 & WB2C S3",
          },
          {
            name: "SBTi 1.5C Aligned",
          },
          {
            name: "BAU trend",
          },
          {
            name: "Emissions Pathway",
          },

          ...(userDefinedValues.length !== 0
            ? userDefinedValues
                .filter((filteredData) => filteredData)
                .map((data, i) => ({
                  name: userDefinedNames[i],
                }))
            : []),

          ...(!sbtiConfig.length ||
          (sbtiConfig.length &&
            sbtiConfig.filter(
              (val) =>
                (val.title === "1.5° C SBTi (w/ constrain)" ||
                  val.title === "1.5C SBTi (w/ constrain)") &&
                val.show
            ).length)
            ? [
                {
                  name: "1.5C SBTi (w/ constrain)",
                },
              ]
            : []),
          ...(sbtiConfig.length &&
          sbtiConfig.filter(
            (val) =>
              (val.title === "2° C SBTi (w/ constrain)" ||
                val.title === "WB2C SBTi (w/ constrain)") &&
              val.show
          ).length
            ? [
                {
                  name: "WB2C SBTi (w/ constrain)",
                },
              ]
            : []),
        ],
        selected: { ...selectedLegends },
      },

      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: _data.year,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        ...(scopeOneData && scopeOneData?.length !== 0
          ? [
              {
                name: "SBTi 1.5C Aligned",
                data: scopeOneData,
                type: "line",
                color: "#32CD32",

                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),

        {
          name: "BAU trend",
          data: _data.bauHeatingFuels,
          type: "line",
          symbolSize: 0,
          color: "#FFDC60",
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
        },

        ...(scopeTwoData && scopeTwoData?.length !== 0
          ? [
              {
                name: "SBTi 1.5C S1+2 & WB2C S3",
                data: scopeTwoData,
                type: "line",
                color: "#90EE90",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),

        ...(userDefinedValues.length !== 0
          ? userDefinedValues
              .filter((filteredData) => filteredData)
              .map((data, i) => ({
                name: userDefinedNames[i],
                data: data,
                type: "line",
                color: userDefinedColors[i],
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              }))
          : []),

        {
          name: "Emissions Pathway",
          data: _data.sumOfAnnualSaving,
          type: "line",
          symbolSize: 0,
          color: "#3353FF",
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
        },
        ...(!sbtiConfig.length ||
        (sbtiConfig.length &&
          sbtiConfig.filter(
            (val) =>
              (val.title === "1.5° C SBTi (w/ constrain)" ||
                val.title === "1.5C SBTi (w/ constrain)") &&
              val.show
          ).length)
          ? [
              {
                name: "1.5C SBTi (w/ constrain)",
                data: _data.limitConstrainedReductionEmission,
                type: "line",
                symbolSize: 0,
                color: "#90EE90",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),
        ...(sbtiConfig.length &&
        sbtiConfig.filter(
          (val) =>
            (val.title === "2° C SBTi (w/ constrain)" ||
              val.title === "WB2C SBTi (w/ constrain)") &&
            val.show
        ).length
          ? [
              {
                name: "WB2C SBTi (w/ constrain)",
                data: _data.limitConstrainedReductionEmission25,
                type: "line",
                symbolSize: 0,
                color: "#32CD32",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),
      ],
    };
    if (showAllProjects) {
      const sort = (a, b) => {
        for (let index = 0; index < a.data.length; index++) {
          const element = a.data[index];
          const element2 = b.data[index];
          if (element) {
            return element < (element2 || 0);
          }
        }
      };

      option.series = [
        ...option.series,
        ...projects.toSorted(sort).map((project) => ({
          ...project,
          symbolSize: 0,
          lineStyle: {
            ...project?.lineStyle,
            width: 5,
          },
        })),
      ];

      option.legend.data = [
        ...option.legend.data,
        ...projects
          .toSorted(sort)
          .reverse()
          .map((project) => ({
            name: project.name,
            icon: "rect",
            textStyle: {
              fontWeight: 600,
            },
          })),
      ];
      function customTooltipFormatter(params) {
        var tooltipContent = `<div class="mb-2">${params[0].axisValueLabel}</div>`; // Initialize the tooltip content
        option.legend.data.forEach((legend) => {
          var dataValue = params.find((param) => {
            return param.seriesName === legend.name;
          });
          if (dataValue) {
            tooltipContent += `<div class="d-flex justify-content-between align-items-center mb-1"><div><span class="d-inline-block me-2" style="width: 10px; height: 10px; border-radius: 50%; background-color: ${
              dataValue?.color
            }"></span>
            ${
              legend.name === "1.5C SBTi (w/ constrain)" ||
              legend.name === "WB2C SBTi (w/ constrain)" ||
              userDefinedNames?.includes(legend.name) ||
              legend.name === "SBTi 1.5C Aligned" ||
              legend.name === "SBTi 1.5C S1+2 & WB2C S3" ||
              legend.name === "BAU trend" ||
              legend.name === "Emissions Pathway"
                ? legend.name
                : `<b>${legend.name}</b>`
            }
          </div><div class="ps-4"><b>${formatNumber(
            dataValue?.value?.length
              ? Number(dataValue?.value[1]).toFixed()
              : Number(dataValue?.value)?.toFixed(),
            0
          )}</b></div></div>`;
          }
        });

        return tooltipContent;
      }
      option.tooltip.formatter = (params) => customTooltipFormatter(params);
    }
    option.series = option.series.map((val, index) => {
      if (
        val.name === "1.5C SBTi (w/ constrain)" ||
        val.name === "WB2C SBTi (w/ constrain)" ||
        userDefinedNames?.includes(val.name) ||
        val.name === "SBTi 1.5C Aligned" ||
        val.name === "SBTi 1.5C S1+2 & WB2C S3" ||
        val.name === "BAU trend" ||
        val.name === "Emissions Pathway"
      ) {
        return {
          ...val,
          symbolSize: 0,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
        };
      } else {
        return {
          ...val,
          symbolSize: 0,
          lineStyle: {
            ...val?.lineStyle,
            width: 0,
          },
          itemStyle: {
            ...val?.itemStyle,
          },
          areaStyle: {
            ...val?.areaStyle,
            type: "default",
          },
        };
      }
    });

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });

    const maxNumData = []
      .concat(...option.series.map((val) => val.data))
      .filter((val) => !isNaN(val));
    if (
      !max ||
      max < 1 ||
      !maxNumData.includes(max) ||
      isNaN(max) ||
      !isFinite(max)
    ) {
      setMax(
        Math.max(
          ...[]
            .concat(...option.series.map((val) => val.data))
            .filter((val) => !isNaN(val))
        )
      );
    }

    option.yAxis.max = max;
    chart.setOption(option);

    chart.on("legendselectchanged", function (params) {
      setSelectedLegends({ ...params.selected });
    });

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    onChartRendered &&
      chart.on("finished", () => {
        let src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });

        onChartRendered(src);
      });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    data,
    projections,
    scopeTwo,
    collapsed,
    showAllProjects,
    sbtiConfig,
    max,
    scopeOneData,
    emission,
    scopeTwoData,
    setSelectedLegends,
    selectedLegends,
    userDefinedValues,
    onChartRendered,
    userDefinedNames,
    decarbLever,
  ]);

  return (
    <div className="EmissionProjectionsChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default EmissionProjectionsChart;
