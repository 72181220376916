import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Table, Dropdown, Form, Button, Spinner } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get, put, post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageReductions from "./ManageReductions/ManageReductions";
import SBTinitiativeChart from "./SBTinitiativeChart/SBTinitiativeChart";
import "./SBTinitiative.scss";
import { formatNumber, formatPercentage } from "../helper";
import { getMergedSTBi } from "../forecastingModels";
import NumberFormat from "react-number-format";
import { Formik } from "formik";
import * as yup from "yup";

import UserCategories from "utils/userCategories";

const SBTinitiative = ({
  organization,
  breadcrumbs,
  scopeOne = 0,
  scopeTwo = 0,
  scopeThree = 0,
  sbtiConfig,
  isTargetUpdate,
}) => {
  const [emission, setEmission] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedScope, setSelectedScope] = useState("All Scopes");
  const [selectedTarget, setSelectedTarget] = useState("Near-Term Targets");
  const [sbtiData, setSbtiData] = useState([]);

  const [sbtiChartData, setSbtiChartData] = useState();
  const [error, setError] = useState();
  const [isManaging, setIsManaging] = useState(false);
  const [managingError, setManagingError] = useState();
  const [step, setStep] = useState(false);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const schema = yup.object().shape({
    scopeThreeEmissionsCoveredNearTerm: yup
      .number()
      .label("Scope 3 Emissions covered by near-term targets")
      .min(
        ((emission?.emissionsScopeThree || 0) / 100) * 66.33,
        "Scope 3 Emissions covered by near-term targets need to be at least 2/3 of the total scope 3 emissions."
      )
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    scopeThreeEmissionsCoveredNetZero: yup
      .number()
      .label("Scope 3 Emissions covered by net-zero targets")
      .min(
        ((emission?.emissionsScopeThree || 0) / 100) * 90,
        "Scope 3 Emissions covered by netzero targets need to be at least 90% of the total scope 3 emissions."
      )
      .max(
        emission?.emissionsScopeThree || 0,
        "Scope 3 Emissions covered by netzero targets should not exceed from the total scope 3 emissions."
      )
      .required(),
  });
  const userCategory = UserCategories();

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const organizationPromise = get(
        `organizations/${organizationId}/science-based-target`
      );
      organizationPromise.promise
        .then((response) => {
          setIsLoading(false);
          const data = response.data || [];
          const sbtiChart = data.at(0) || {};

          const calculations = sbtiChart?.sbtiData;
          setSbtiChartData(calculations);
          setEmission(data.at(0) || {});
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId, scopeOne, scopeTwo, scopeThree, isTargetUpdate]);

  useEffect(() => {
    const _scopeOne = selectedScope === "Scope 3" ? 0 : scopeOne;
    const _scopeTwo = selectedScope === "Scope 3" ? 0 : scopeTwo;
    const _scopeThree = selectedScope === "Scope 1+2" ? 0 : scopeThree;

    let sbtiEmissions = {};
    let sbtiEmissionsThree = {};

    sbtiEmissions = {
      limitReductionPercentage: emission?.limitReductionPercentage,
      reductionPercentageOne: emission?.reductionPercentageOne,
      reductionPercentageTwo: emission?.reductionPercentageTwo,
      data: emission?.data,
    };

    sbtiEmissionsThree = {
      limitReductionPercentage: emission?.sopeThreeLimitReductionPercentage,
      reductionPercentageOne: emission?.sopeThreeReductionPercentageOne,
      reductionPercentageTwo: emission?.sopeThreeReductionPercentageTwo,
      data: emission?.scopeThreeData,
    };

    const scienceBasedTargetChunk = getMergedSTBi(
      sbtiEmissions,
      _scopeOne,
      _scopeTwo,
      0
    );

    const scienceBasedTargetChunkScopeThree = getMergedSTBi(
      sbtiEmissionsThree,
      0,
      0,
      _scopeThree
    );

    if (selectedScope === "All Scopes") {
      const targetSettingData = [];

      for (let index = 0; index < scienceBasedTargetChunk.length; index++) {
        const element = scienceBasedTargetChunk[index];
        const elementThree = scienceBasedTargetChunkScopeThree[index];
        let obj = {};
        for (const key in element) {
          if (element.hasOwnProperty.call(element, key)) {
            const val = element[key];
            const val2 = elementThree[key];
            if (
              key !== "percentageReduction" &&
              key !== "percentageReduction25" &&
              key !== "year" &&
              key !== "year25"
            ) {
              obj[key] = val + val2;
            } else {
              obj[key] = val;
            }
          }
        }
        targetSettingData.push(obj);
      }
      setSbtiData(targetSettingData);
    } else if (selectedScope === "Scope 3") {
      setSbtiData(scienceBasedTargetChunkScopeThree);
    } else {
      setSbtiData(scienceBasedTargetChunk);
    }
  }, [organizationId, emission, scopeOne, scopeTwo, scopeThree, selectedScope]);

  const memoizedConfig = useMemo(() => {
    if (sbtiConfig) {
      return JSON.parse(sbtiConfig);
    } else {
      return [];
    }
  }, [sbtiConfig]);

  const updateReduction = ({
    scopeThreeEmissionsCoveredNearTerm,
    scopeThreeEmissionsCoveredNetZero,
  }) => {
    setError(null);
    setIsLoading(true);
    setIsManaging(true);
    const data = {
      organization_id: organizationId,
      scope_three_emissions_covered_near_term: !isNaN(
        Number(scopeThreeEmissionsCoveredNearTerm)
      )
        ? Number(scopeThreeEmissionsCoveredNearTerm)
        : "",
      scope_three_emissions_covered_net_zero: !isNaN(
        Number(scopeThreeEmissionsCoveredNetZero)
      )
        ? Number(scopeThreeEmissionsCoveredNetZero)
        : "",
      ...(!emission.sbtiConfig && {
        sbtiConfig: JSON.stringify([
          {
            title: "1.5C SBTi (w/ constrain)",
            show: false,
          },
          {
            title: "WB2C SBTi (w/ constrain)",
            show: true,
          },
        ]),
      }),
    };
    const organizationPromise = emission?.id
      ? put(`science-based-target/${emission?.id}`, data)
      : post(`organizations/${organizationId}/science-based-target`, data);
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        setIsManaging(false);
        setEmission(response.data);
        setSbtiChartData(response?.data?.sbtiData);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setManagingError(error);
          setIsManaging(false);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  return (
    <div id={userCategory}>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: "Science Based Target initiative (SBTi)",
              link: `/organizations/${organization.id}/forecating/4/science-based-target-initiative`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">Science Based Target initiative (SBTi)</h2>
      {!isLoading && !error && (
        <>
          <div className="my-3 p-3 border">
            <h3
              className={`${
                ["All Scopes", "Scope 3"]?.includes(selectedScope)
                  ? "mb-2"
                  : "mb-4"
              }`}
            >
              SBTi Targets
              <Dropdown className="float-end me-3">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  size="sm"
                  className="bg-primary bg-opacity-25 text-dark border-0"
                >
                  {selectedScope === "All"
                    ? "Filter by All Scopes"
                    : `Filter by ${selectedScope}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[
                    "All Scopes",
                    "Scope 1+2",
                    "Scope 1",
                    "Scope 2",
                    "Scope 3",
                  ].map((scope, index) => (
                    <Dropdown.Item
                      key={index}
                      className={selectedScope === scope ? "active" : ""}
                      onClick={() =>
                        setSelectedScope((prev) => {
                          if (prev === scope) return scope;

                          return scope;
                        })
                      }
                    >
                      {scope}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="float-end me-3">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  size="sm"
                  className="bg-primary bg-opacity-25 text-dark border-0"
                >
                  Filter by {selectedTarget}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {["Near-Term Targets", "Net-Zero Targets"].map(
                    (target, index) => (
                      <Dropdown.Item
                        key={index}
                        className={selectedTarget === target ? "active" : ""}
                        onClick={() =>
                          setSelectedTarget((prev) => {
                            if (prev === target) return target;

                            return target;
                          })
                        }
                      >
                        {target}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </h3>
            {["All Scopes", "Scope 3"]?.includes(selectedScope) && (
              <Form.Group
                controlId="chartSwitch"
                className="d-flex align-items-center mb-3"
              >
                <label
                  title=""
                  htmlFor="custom-switch-line"
                  className="text-muted me-1 fs-5"
                >
                  <small>Add S3 emissions not covered by SBTi targets</small>
                </label>
                <Form.Check
                  type="switch"
                  id="custom-switch-line"
                  checked={step}
                  onChange={(e) => {
                    setStep(e.target.checked);
                  }}
                />
              </Form.Group>
            )}
            <SBTinitiativeChart
              newData={sbtiChartData}
              data={sbtiData}
              sbtiConfig={memoizedConfig}
              selectedScope={selectedScope}
              selectedTarget={selectedTarget}
              emission={emission}
              step={step}
            />
          </div>
          <h3 className="my-2">Manage Reductions</h3>
          <div>
            <b>Target Setting Method:</b> Absolute contraction approach
            <br />
            <b>Net-Zero Sector Pathway:</b> Cross-sector pathway
            <h3 className="mt-4 mb-3">Scope 1+2</h3>
            <h4>1.5 Degree Scenario (1.5C)</h4>
            <Table size="sm" responsive striped className="first-column-fixed">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-end text-nowrap px-3">
                    Base Year ({emission?.baselineYear})
                  </th>
                  <th className="text-end text-nowrap px-3">
                    {Math.abs(
                      Number(emission?.mostRecentYear) -
                        Number(emission?.baselineYear)
                    ) >= 2
                      ? `Most Recent Year (${emission?.mostRecentYear})`
                      : "Same as Base Year"}
                  </th>
                  <th className="text-end text-nowrap px-3">
                    Target Year ({emission?.nearTermTargetYear})
                  </th>
                  <th className="text-end text-nowrap px-3">
                    % Reduction to date
                  </th>
                  <th className="text-end text-nowrap px-3">
                    % FLA Adjustment
                  </th>
                  <th className="text-end text-nowrap px-3">% SBT Reduction</th>
                </tr>
              </thead>
              <tbody>
                <>
                  <tr>
                    <td className="text-nowrap px-3">
                      <b>
                        Scope 1 emissions (tCO<sub>2</sub>e)
                      </b>
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.baseYearEmissions
                          ?.scopeOne
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {Math.abs(
                        Number(emission?.mostRecentYear) -
                          Number(emission?.baselineYear)
                      ) >= 2
                        ? formatNumber(
                            emission?.sbtiData?.nearTerm
                              ?.mostRecentYearEmissions?.scopeOne
                          )
                        : "----------"}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.targetYearEmissions
                          ?.scopeOne
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.reductionToDate?.scopeOne
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.flaAdjustments?.scopeOne
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.targetPercentageWithFla
                          ?.scopeOne
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap px-3">
                      <b>
                        Scope 2 emissions (tCO<sub>2</sub>e)
                      </b>
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.baseYearEmissions
                          ?.scopeTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {Math.abs(
                        Number(emission?.mostRecentYear) -
                          Number(emission?.baselineYear)
                      ) >= 2
                        ? formatNumber(
                            emission?.sbtiData?.nearTerm
                              ?.mostRecentYearEmissions?.scopeTwo
                          )
                        : "----------"}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.targetYearEmissions
                          ?.scopeTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.reductionToDate?.scopeTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.flaAdjustments?.scopeTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.targetPercentageWithFla
                          ?.scopeTwo
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap px-3">
                      <b>
                        Scope 1+2 emissions (tCO<sub>2</sub>e)
                      </b>
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.baseYearEmissions
                          ?.scopeOneAndTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {Math.abs(
                        Number(emission?.mostRecentYear) -
                          Number(emission?.baselineYear)
                      ) >= 2
                        ? formatNumber(
                            emission?.sbtiData?.nearTerm
                              ?.mostRecentYearEmissions?.scopeOneAndTwo
                          )
                        : "----------"}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.targetYearEmissions
                          ?.scopeOneAndTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.reductionToDate
                          ?.scopeOneAndTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.flaAdjustments
                          ?.scopeOneAndTwo
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        emission?.sbtiData?.nearTerm?.targetPercentageWithFla
                          ?.scopeOneAndTwo
                      )}
                    </td>
                  </tr>
                </>
              </tbody>
            </Table>
          </div>
          <div>
            <h3 className="mt-4 mb-3">Scope 3</h3>
            <p>
              Scope 3 Emissions Total (tCO<sub>2</sub>e):{" "}
              {formatNumber(emission?.emissionsScopeThree)}
            </p>
            <Formik
              validationSchema={schema}
              onSubmit={(values) => updateReduction(values)}
              initialValues={{
                scopeThreeEmissionsCoveredNearTerm:
                  emission?.scopeThreeEmissionsCoveredNearTerm || 0,
                scopeThreeEmissionsCoveredNetZero:
                  emission?.scopeThreeEmissionsCoveredNetZero || 0,
              }}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                isValid,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="scopeThreeEmissionsCoveredNearTerm"
                    className="my-2"
                  >
                    <Form.Label className="fs-6">
                      Scope 3 Emissions covered by near-term targets (tCO
                      <sub>2</sub>e)
                    </Form.Label>
                    <NumberFormat
                      name="scopeThreeEmissionsCoveredNearTerm"
                      size="sm"
                      value={values.scopeThreeEmissionsCoveredNearTerm}
                      customInput={Form.Control}
                      thousandSeparator={true}
                      onValueChange={(numberItem) => {
                        setFieldValue(
                          "scopeThreeEmissionsCoveredNearTerm",
                          numberItem.value
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.scopeThreeEmissionsCoveredNearTerm}
                      isInvalid={
                        errors.scopeThreeEmissionsCoveredNearTerm &&
                        touched.scopeThreeEmissionsCoveredNearTerm
                      }
                    />
                    {values.scopeThreeEmissionsCoveredNearTerm <
                      ((emission?.emissionsScopeThree || 0) / 100) * 66.33 && (
                      <div className="invalid-feedback d-block">
                        {errors.scopeThreeEmissionsCoveredNearTerm}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    controlId="scopeThreeEmissionsCoveredNetZero"
                    className="my-2"
                  >
                    <Form.Label className="fs-6">
                      Scope 3 Emissions covered by net-zero targets (tCO
                      <sub>2</sub>e)
                    </Form.Label>
                    <NumberFormat
                      name="scopeThreeEmissionsCoveredNetZero"
                      size="sm"
                      value={values.scopeThreeEmissionsCoveredNetZero}
                      customInput={Form.Control}
                      thousandSeparator={true}
                      onValueChange={(numberItem) => {
                        setFieldValue(
                          "scopeThreeEmissionsCoveredNetZero",
                          numberItem.value
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.scopeThreeEmissionsCoveredNetZero}
                      isInvalid={
                        errors.scopeThreeEmissionsCoveredNetZero &&
                        touched.scopeThreeEmissionsCoveredNetZero
                      }
                    />
                    <div className="invalid-feedback d-block">
                      {errors.scopeThreeEmissionsCoveredNetZero}
                    </div>
                  </Form.Group>
                  <div className="d-flex justify-content-end my-3">
                    <Button
                      size="sm"
                      type="submit"
                      disabled={isManaging || !isValid}
                    >
                      Save
                      {isManaging && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                      {managingError && (
                        <span className="material-icons-outlined md-18 text-danger">
                          error
                        </span>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <Table size="sm" responsive striped>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-end text-nowrap px-3">
                    Base Year ({emission?.baselineYear})
                  </th>
                  <th className="text-end text-nowrap px-3">
                    Target Year ({emission?.nearTermTargetYear})
                  </th>
                  <th className="text-end text-nowrap px-3">% SBT Reduction</th>
                  <th className="text-end text-nowrap px-3">
                    Scope 3 emissions
                    <br />
                    not covered by
                    <br />
                    SBTi targets
                  </th>
                </tr>
              </thead>
              <tbody>
                <>
                  <tr>
                    <td className="text-nowrap px-3">
                      <b>
                        {organization?.name} | Scope 3 emissions - WB2C (tCO
                        <sub>2</sub>e)
                      </b>
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.baseYearEmissions
                          ?.scopeThree
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.targetYearEmissions
                          ?.scopeThreeWb2C
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        ((emission?.sbtiData?.nearTerm?.baseYearEmissions
                          ?.scopeThree -
                          emission?.sbtiData?.nearTerm?.targetYearEmissions
                            ?.scopeThreeWb2C) /
                          emission?.sbtiData?.nearTerm?.baseYearEmissions
                            ?.scopeThree) *
                          100,
                        1
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {Number(emission?.emissionsScopeThree) -
                        Number(emission?.scopeThreeEmissionsCoveredNearTerm)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap px-3">
                      <b>
                        {organization?.name} | Scope 3 emissions - 1.5C (tCO
                        <sub>2</sub>e)
                      </b>
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.netZero?.baseYearEmissions
                          ?.scopeThree
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.sbtiData?.nearTerm?.targetYearEmissions
                          ?.scopeThree
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(
                        ((emission?.sbtiData?.nearTerm?.baseYearEmissions
                          ?.scopeThree -
                          emission?.sbtiData?.nearTerm?.targetYearEmissions
                            ?.scopeThree) /
                          emission?.sbtiData?.nearTerm?.baseYearEmissions
                            ?.scopeThree) *
                          100,
                        1
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {Number(emission?.emissionsScopeThree) -
                        Number(emission?.scopeThreeEmissionsCoveredNearTerm)}
                    </td>
                  </tr>
                </>
              </tbody>
            </Table>
            <Table size="sm" responsive striped className="my-2">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-end text-nowrap px-3">
                    Base Year ({emission?.baselineYear})
                  </th>
                  <th className="text-end text-nowrap px-3">
                    Net-Zero Target Year ({emission?.netzeroTargetYear})
                  </th>
                  <th className="text-end text-nowrap px-3">
                    % Absolute Reduction
                  </th>
                  <th className="text-end text-nowrap px-3">
                    Scope 3 emissions
                    <br />
                    not covered by
                    <br />
                    SBTi targets
                  </th>
                </tr>
              </thead>
              <tbody>
                <>
                  <tr>
                    <td className="text-nowrap px-3">
                      <b>
                        {organization?.name} | Scope 1,2 & 3 (tCO
                        <sub>2</sub>e)
                      </b>
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        Number(emission?.emissionsScopeOne || 0) +
                          Number(emission?.emissionsScopeTwo || 0) +
                          Number(
                            emission?.scopeThreeEmissionsCoveredNetZero || 0
                          ) || 0
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(
                        emission?.scopeThreeEmissionsCoveredNetZero ||
                          emission?.emissionsScopeOne ||
                          emission?.emissionsScopeTwo
                          ? (Number(emission?.emissionsScopeOne || 0) +
                              Number(emission?.emissionsScopeTwo || 0) +
                              Number(
                                emission?.scopeThreeEmissionsCoveredNetZero || 0
                              )) /
                              10
                          : 0
                      )}
                    </td>
                    <td className="text-end text-nowrap px-3">90.0%</td>
                    <td className="text-end text-nowrap px-3">
                      {emission?.emissionsScopeThree -
                        emission?.scopeThreeEmissionsCoveredNetZero}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap px-3">
                      <b>Long Term SBT formulation</b>
                    </td>
                    <td colSpan={4} className="text-center text-nowrap ps-5">
                      {organization?.name} commits to reduce Scopes 1,2 & 3
                      emissions 90% by {emission?.netzeroTargetYear} from a{" "}
                      {emission?.baselineYear} base year
                    </td>
                  </tr>
                </>
              </tbody>
            </Table>
          </div>
        </>
      )}
      {error && !isLoading && <ErrorHandler error={error} />}
      {isLoading && <Loader />}
    </div>
  );
};

SBTinitiative.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  scopeOne: PropTypes.number,
  scopeTwo: PropTypes.number,
  scopeThree: PropTypes.number,
};

export default SBTinitiative;
