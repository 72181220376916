const projectStatusMap = [
  {
    value: "In Flight",
    key: 1,
  },
  {
    value: "Not Committed",
    key: 2,
  },
  {
    value: "Committed",
    key: 3,
  },
  {
    value: "Completed",
    key: 4,
  },
  {
    value: "Deferred",
    key: 5,
  },
];

const projectEvalutionStatusMap = [
  {
    value: "Under Consideration",
    key: 1,
  },
  {
    value: "Committed",
    key: 2,
  },
  {
    value: "Not Committed",
    key: 3,
  },
  {
    value: "Deferred",
    key: 4,
  },
];

const projectImplementationStatusMap = [
  {
    value: "Not Started",
    key: 1,
  },
  {
    value: "In-Flight",
    key: 2,
  },
  {
    value: "Completed",
    key: 3,
  },
  {
    value: "Cancelled",
    key: 4,
  },
];

const projectCombinedStatusMap = [
  {
    value: "To be Evaluated",
    key: 6,
    order: 1,
  },
  {
    value: "Not Committed",
    key: 2,
    order: 2,
  },
  {
    value: "Deferred",
    key: 5,
    order: 3,
  },
  {
    value: "Committed - Not Started",
    key: 3,
    order: 4,
  },
  {
    value: "Implemented - In Flight",
    key: 1,
    order: 5,
  },
  {
    value: "Implemented - Completed",
    key: 4,
    order: 6,
  },
  {
    value: "Implemented - Cancelled",
    key: 7,
    order: 7,
  },
];

const projectCustomTypesMap = [
  {
    value: "Number",
    key: 1,
  },
  {
    value: "TextArea",
    key: 2,
  },
  {
    value: "Date",
    key: 3,
  },
];

const projectTypesMap = [
  {
    value: "CCUS",
    key: 1,
  },
  {
    value: "Renewables & Commercial",
    key: 2,
  },
  {
    value: "Flare Reduction",
    key: 3,
  },
  {
    value: "Methane Emission Reduction",
    key: 4,
  },
  {
    value: "Reservoir/Subsurface Mgmt",
    key: 5,
  },
  {
    value: "TBD",
    key: 6,
  },
  {
    value: "Energy Efficiency",
    key: 7,
  },
  {
    value: "LPOs",
    key: 8,
  },
];

const locationsMap = [
  {
    value: "US",
    key: 1,
  },
  {
    value: "SASBU",
    key: 2,
  },
  {
    value: "ABU",
    key: 3,
  },
  {
    value: "TCO",
    key: 4,
  },
  {
    value: "Europe",
    key: 5,
  },
  {
    value: "GB",
    key: 6,
  },
];

const projectDataConfidenceMap = [
  {
    value: "High (measured or from vendor quotes)",
    key: 1,
  },
  {
    value: "Medium (public scientific and technical literature)",
    key: 2,
  },
  {
    value: "Low (SME or client estimate)",
    key: 3,
  },
];

export {
  projectStatusMap,
  projectEvalutionStatusMap,
  projectImplementationStatusMap,
  projectCombinedStatusMap,
  projectCustomTypesMap,
  projectTypesMap,
  locationsMap,
  projectDataConfidenceMap,
};
