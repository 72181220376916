import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Modal, Button, Form, Alert } from "react-bootstrap";

import { post } from "utils/DeApi";
import { getFormattedEmission } from "utils/StringUtils";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { put, destroy } from "utils/DeApi";
import NumberFormat from "react-number-format";
import CurrencyConverter from "components/Converter/CurrencyConverter/CurrencyConverter";

const CarbonIntensityUpdate = ({
  organization,
  organizationInfo,
  bottomUpSummary,
  carbonIntensity,
  onCarbonIntensityUpdated,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    revenue: yup
      .number()
      .required("Annual Revenue is Required")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      ),
    emissions: yup
      .number()
      .required("Annual Total Carbon Emissions is Required")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      ),
    year: yup.string(),
    rawUnit: yup.string(),
    rawAmount: yup
      .number()
      .min(0)
      .max(
        9999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999)}.`
      ),
  });

  const updateCarbonIntensity = ({
    year,
    revenue,
    emissions,
    rawAmount,
    rawUnit,
  }) => {
    setError(null);
    setIsLoading(true);

    let yearCarbonIntensity = carbonIntensity.find(
      (item) => item.yearEnded.substring(0, 4) === year
    );

    const carbonIntensityPromise = yearCarbonIntensity
      ? put(`carbon-intensity/${yearCarbonIntensity.id}`, {
          revenue: revenue * 1000000,
          emissions: emissions,
          rawAmount,
          rawUnit,
        })
      : post(`organizations/${organization.id}/carbon-intensity`, {
          yearEnded: `${year}-12-31`,
          revenue: revenue * 1000000,
          emissions: emissions,
          rawAmount,
          rawUnit,
        });

    carbonIntensityPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onCarbonIntensityUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const deleteCarbonIntensity = (year) => {
    let yearCarbonIntensity = carbonIntensity.find(
      (item) => item.yearEnded.substring(0, 4) === year
    );

    if (!year || !yearCarbonIntensity) return;

    setError(null);
    setIsLoading(true);

    const deleteCarbonIntensityPromise = destroy(
      `carbon-intensity/${yearCarbonIntensity.id}`
    );

    deleteCarbonIntensityPromise.promise
      .then(() => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onCarbonIntensityUpdated(yearCarbonIntensity, true);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        Manage Revenue Based Carbon Intensity
      </Button>

      <Modal
        show={show}
        backdrop={"static"}
        onHide={handleClose}
        size="lg"
        className="right"
        fullscreen="sm-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>
              {organization.name}
              <br />
            </small>
            Manage Carbon Emissions to Revenue Intensity
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateCarbonIntensity(values)}
          initialValues={{
            year: "",
            revenue: "",
            emissions: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="activity-year" className="my-3">
                  <Form.Label>
                    Select year to add or update emissions to revenue intensity.
                  </Form.Label>
                  <Field
                    as={Form.Select}
                    name="year"
                    value={values.year}
                    onChange={(ev) => {
                      let option = ev.target.value;
                      let yearCI = carbonIntensity.find(
                        (item) => item.yearEnded.substring(0, 4) === option
                      );
                      setFieldValue(
                        "revenue",
                        yearCI ? yearCI.revenue / 1000000 : ""
                      );
                      setFieldValue(
                        "emissions",
                        yearCI ? yearCI.emissions : ""
                      );
                      setFieldValue(
                        "rawUnit",
                        yearCI?.rawUnit ? yearCI.rawUnit : ""
                      );
                      setFieldValue(
                        "rawAmount",
                        yearCI?.rawAmount ? yearCI?.rawAmount : ""
                      );

                      return handleChange(ev);
                    }}
                    onBlur={handleBlur}
                    isValid={values.year}
                  >
                    <option>Select year</option>
                    {[
                      "2023",
                      "2022",
                      "2021",
                      "2020",
                      "2019",
                      "2018",
                      "2017",
                      "2016",
                      "2015",
                      "2014",
                      "2013",
                      "2012",
                      "2011",
                      "2010",
                    ].map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Field>
                </Form.Group>

                {values.year && (
                  <>
                    <Form.Group controlId="revenue" className="mt-3">
                      <Form.Label>Revenue (MM USD)</Form.Label>
                      <NumberFormat
                        customInput={Form.Control}
                        value={values.revenue}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("revenue", numberItem.value);
                        }}
                        name="revenue"
                        prefix="$"
                        suffix="MM"
                        placeholder="Enter annual revenue"
                        onBlur={handleBlur}
                        isValid={values.revenue}
                        isInvalid={errors.revenue && touched.revenue}
                        className="md-6"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.revenue}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <CurrencyConverter
                      setFieldValue={setFieldValue}
                      reportingYear={values?.year?.toString()}
                      values={values}
                      emissionUnit={"USD"}
                      selectedOption={{
                        label: values?.rawUnit,
                        value: values?.rawUnit,
                      }}
                      module="carbonintensity"
                    />

                    <Form.Group controlId="emissions" className="my-3">
                      <Form.Label>
                        Emissions (tCO<sub>2</sub>e)
                      </Form.Label>

                      {(() => {
                        let yearTopDown = organizationInfo.find(
                          (orgInfo) =>
                            orgInfo.yearEnded.substring(0, 4) === values.year
                        );
                        let topDownEmissions = yearTopDown
                          ? yearTopDown.organizationEmissions
                          : null;
                        let bottomUpEmissions = bottomUpSummary
                          .filter(
                            (item) =>
                              item.yearEnded.toString().substring(0, 4) ===
                              values.year
                          )
                          .reduce((sum, item) => sum + item.emissions, 0);

                        return bottomUpEmissions ? (
                          <Alert variant="info">
                            Estimated emissions from bottom-up analysis <br />
                            <big>
                              {getFormattedEmission(bottomUpEmissions)}
                            </big>{" "}
                            tCO
                            <sub>2</sub>e{" "}
                            <Button
                              size="sm"
                              variant="outline-dark"
                              className="py-0 px-3 ms-2"
                              onClick={() =>
                                setFieldValue("emissions", bottomUpEmissions)
                              }
                            >
                              Use value
                            </Button>
                          </Alert>
                        ) : topDownEmissions ? (
                          <Alert variant="info">
                            Estimated emissions from top-down analysis <br />
                            <big>
                              {getFormattedEmission(topDownEmissions)}
                            </big>{" "}
                            tCO
                            <sub>2</sub>e{" "}
                            <Button
                              size="sm"
                              variant="outline-dark"
                              className="py-0 px-3 ms-2"
                              onClick={() =>
                                setFieldValue("emissions", topDownEmissions)
                              }
                            >
                              Use value
                            </Button>
                          </Alert>
                        ) : (
                          <></>
                        );
                      })()}
                      <NumberFormat
                        customInput={Form.Control}
                        value={values.emissions}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("emissions", numberItem.value);
                        }}
                        name="emissions"
                        placeholder="Enter Annual Total Carbon Emissions"
                        onBlur={handleBlur}
                        isValid={values.emissions}
                        isInvalid={errors.emissions && touched.emissions}
                        className="md-6"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.emissions}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                {carbonIntensity.find(
                  (item) => item.yearEnded.substring(0, 4) === values.year
                ) ? (
                  <>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => deleteCarbonIntensity(values.year)}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      size="sm"
                      disabled={!isValid}
                    >
                      Update
                    </Button>
                  </>
                ) : (
                  <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                    Add
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

CarbonIntensityUpdate.propTypes = {
  organization: PropTypes.object.isRequired,
  carbonIntensity: PropTypes.array.isRequired,
  onCarbonIntensityUpdated: PropTypes.func.isRequired,
};

export default CarbonIntensityUpdate;
