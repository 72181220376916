import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import {
  Page,
  Document,
  View,
  Text,
  Link,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { get } from "utils/BeeApi";

import { getFormattedEmission } from "utils/StringUtils";
import { getEmissionFromPercentageEquity } from "utils/numerals";

import ExportContent from "components/ExportContent/ExportContent";
import ExportStyles from "./ExportStyles";

const styles = ExportStyles;

const PortfolioExport = ({
  portfolio,
  portfolioOrganizationChart,
  emissionYear,
  scopeTwoPreference,
  contentId,
}) => {
  const subscribedPromises = useRef([]);
  const { organizations = [] } = portfolio;
  const [organizationData, setOrganizationData] = useState([]);
  const [portfolioContent, setPortfolioContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const promises = subscribedPromises.current;
    setIsLoading(true);
    setError("");

    const contentPromise = get(`contents/${contentId}`);
    contentPromise.promise
      .then((response) => {
        setPortfolioContent(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(contentPromise);

    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  useEffect(() => {
    const data = organizations.map((org) => {
      let summary = org.summary[0];
      return {
        ...org,
        ...{
          topDownEmissions: summary?.topDownEmissions,
          bottomUpEmissions: summary?.bottomUpEmissions,
          abatementPotential: summary?.abatementPotential,
          carbonIntensity: summary?.carbonIntensity,
          equityOwnership: Number(org?.equityOwnership),
        },
      };
    });
    data.sort((a, b) =>
      a["bottomUpEmissions"] <= b["bottomUpEmissions"] ? 1 : -1
    );
    setOrganizationData(data);
  }, [organizations]);

  if (error) {
    console.warn(error);
    return <span />;
  }
  if (!portfolioContent || !portfolioOrganizationChart) return <span />;

  return (
    <>
      <PDFDownloadLink
        document={
          <PortfolioDocument
            portfolio={portfolio}
            portfolioOrganizationChart={portfolioOrganizationChart}
            organization={organizationData}
            portfolioContent={portfolioContent}
            emissionYear={emissionYear}
            scopeTwoPreference={scopeTwoPreference}
          />
        }
        fileName={`${portfolio.name}-${emissionYear}.pdf`}
        className="btn btn-secondary text-white btn-sm px-1 pt-1"
      >
        {({ blob, url, loading, error }) =>
          !portfolioOrganizationChart ? (
            "Loading..."
          ) : (
            <span>
              Download {emissionYear} Summary Report
              <span className="material-icons">file_download</span>
            </span>
          )
        }
      </PDFDownloadLink>
    </>
  );
};

const PortfolioDocument = ({
  portfolio,
  portfolioOrganizationChart,
  organization,
  portfolioContent,
  emissionYear,
  scopeTwoPreference,
}) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.body}>
        <View style={styles.heading}>
          <View style={styles.container}>
            <View style={styles.column}>
              <Text style={styles.logoText}>Net Zero Compass by</Text>
            </View>
            <View>
              <View style={styles.column}>
                <Image style={styles.logoImage} src="/logo.png" />
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.title}>{portfolio.name}</Text>

            <Text style={styles.subtitle}>
              Summary Report of Attributable Emissions for the Year{" "}
              {emissionYear}{" "}
              {scopeTwoPreference ? "(Market-Based)" : "(Location-Based)"}.
            </Text>
            <Text style={styles.paragraph}>
              {new Date().toLocaleDateString(undefined, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Text>
          </View>
        </View>
        {organization?.length && (
          <View style={styles.section}>
            <View>
              <ExportContent content={portfolioContent} styles={styles} />
            </View>
            <View style={styles.tableSection}>
              <OrganizationSummary organizationSummary={organization} />
            </View>
            <View style={styles.chartSection} wrap={false}>
              <Text style={styles.chartTitle}>
                {emissionYear} Financed Emissions by Portfolio Organization
              </Text>
              <Image
                src={portfolioOrganizationChart}
                style={styles.chart}
              ></Image>
            </View>
          </View>
        )}
        <Text
          style={styles.timeStamp}
          render={() => {
            var timeStamp = new Date();
            return `Prepared on: ${timeStamp.toDateString()}`;
          }}
          fixed
        />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <View>
          <Text style={styles.disclaimerHeading}>
            ERM Helpdesk:{" "}
            <Text>
              <Link
                src={"mailto:support.nzc@erm.com"}
                style={styles.disclaimerLink}
              >
                <Text>support.nzc@erm.com</Text>
              </Link>
            </Text>
          </Text>
          <Text style={styles.disclaimerHeading}>Disclaimer and IP</Text>
          <Text style={styles.disclaimerText}>
            The methods contained within the Net Zero Compass software product
            are protected and owned by ERM Information Solutions, Inc. No
            parties may copy, reproduce or otherwise transit the data or methods
            contained in this platform without prior written consent of ERM. ERM
            is not responsible for how this platform is used by clients or any
            third parties and we disclaim any responsibility for reliance on the
            results which depend on user inputs. We recommend that users perform
            quality assurance of the results before use in any reporting or to
            support any Net Zero investment decisions.
          </Text>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>
            Copyright © {new Date().getFullYear()} ERM
          </Text>
          <Text style={styles.footerText}>
            <Link src={"https://www.netzerocompass.com"} style={styles.link}>
              <Text>https://www.netzerocompass.com</Text>
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const OrganizationSummary = ({ organizationSummary }) => {
  const emissionSum = organizationSummary?.reduce((prevValue, org) => {
    let summary = org.summary[0];

    return (
      prevValue +
      getEmissionFromPercentageEquity(
        summary.bottomUpEmissions || 0,
        org.equityOwnership
      )
    );
  }, 0);
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>
            Organization Name &nbsp;&nbsp;&nbsp;
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>
            Bottom-Up GHG Inventory (tCO<Text style={styles.subscript}>2</Text>
            e)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>
            Attribution Factor (%)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>
            Attributable Emissions (tCO<Text style={styles.subscript}>2</Text>
            e)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>
            % of Total Attributable Emissions
          </Text>
        </View>
      </View>
      {organizationSummary?.map((organization, index) => {
        const summary = organization.summary[0];

        return (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text
                style={
                  /\p{Script=Han}/u.test(organization?.name)
                    ? styles.tableCellC
                    : styles.tableCell
                }
              >
                {organization.name?.length > 12 ? (
                  <Text>
                    {organization?.name?.slice(0, 12)}
                    <View></View>
                    {organization?.name?.slice(12)}
                  </Text>
                ) : (
                  organization?.name
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {getFormattedEmission(summary.bottomUpEmissions)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {organization.equityOwnership}%
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {getFormattedEmission(
                  getEmissionFromPercentageEquity(
                    summary.bottomUpEmissions,
                    organization.equityOwnership
                  )
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {emissionSum
                  ? Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 2,
                    }).format(
                      (getEmissionFromPercentageEquity(
                        summary.bottomUpEmissions,
                        organization.equityOwnership
                      ) /
                        emissionSum) *
                        100
                    )
                  : 0}
                %
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

PortfolioExport.propTypes = {
  portfolio: PropTypes.object.isRequired,
};

export default PortfolioExport;
