import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { AccountContext } from "contexts/AccountProvider";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import CreateUpdateGWPModel from "./UpdateGwpModels/UpdateGwpModels";
import { get } from "utils/DeApi";
import { getAllYearsBetweenDates } from "utils/dateUtils";

const GwpModels = ({ organization }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);

  const [gwpModels, setGwpModels] = useState([]);
  const [updatedOrg, setUpdatedOrg] = useState(organization);

  const accountContext = useContext(AccountContext);
  const navigate = useNavigate();

  const orgCreatedDate = new Date(updatedOrg?.createdAt).getFullYear();

  const fetchOrganizationGwpModels = useCallback(() => {
    if (accountContext.member.role === "member") {
      navigate(`/organizations/${organization.id}`);
    } else {
      setError(null);
      setIsLoading(true);
      const fetchGwpModels = get(`/gwp-models`);
      const fetchOrg = get(`organizations/${organization.id}`);

      Promise.all([fetchGwpModels.promise, fetchOrg.promise])
        .then((responses) => {
          setGwpModels(responses[0].data);
          setUpdatedOrg(responses[1].data);
          setIsLoading(false);
          setError(null);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(fetchGwpModels, fetchOrg);
    }
  }, [accountContext, organization.id, navigate]);

  useEffect(() => {
    fetchOrganizationGwpModels();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrganizationGwpModels]);

  return (
    <div>
      <span className="float-end">
        <div className="d-inline-flex">
          <CreateUpdateGWPModel
            organization={updatedOrg}
            gwpModels={gwpModels}
            onGWPModelUpdated={(_) => fetchOrganizationGwpModels()}
          />
        </div>
      </span>
      <h4>GWP Basis by Year</h4>

      <div className="my-3">
        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}

        {!isLoading && (
          <Table hover className="my-4" size="sm" responsive>
            <thead>
              <tr>
                <th className="text-end">Year</th>
                <th className="text-end">GWP Basis</th>
                <th className="text-end">Description</th>
              </tr>
            </thead>
            <tbody>
              {getAllYearsBetweenDates().map((year) => (
                <tr key={year}>
                  <td className="text-end text-nowrap">{year}</td>
                  <td className="text-end text-nowrap">
                    {updatedOrg?.gwpModels?.find(
                      (model) =>
                        Number.parseInt(model.year) === Number.parseInt(year)
                    )?.name || <small className="text-muted">n/a</small>}
                  </td>
                  <td className="text-end text-nowrap">
                    {updatedOrg?.gwpModels?.find(
                      (model) =>
                        Number.parseInt(model.year) === Number.parseInt(year)
                    )?.description || <small className="text-muted">n/a</small>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default GwpModels;
