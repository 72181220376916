import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { getFormattedEmission } from "utils/StringUtils";
import { Table } from "react-bootstrap";

import { uniqBy } from "lodash";

const OrganizationList = ({ organizations, sortOrg, isLoading }) => {
  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });

  useEffect(() => {
    const data = organizations.map((org) => {
      let summary = org.summary[0];
      return {
        ...org,
        ...{
          topDownEmissions: summary?.topDownEmissions,
          bottomUpEmissions: summary?.bottomUpEmissions,
          abatementPotential: summary?.abatementPotential,
          carbonIntensity: summary?.carbonIntensity,
          qodScore: summary?.qodScore,
        },
      };
    });
    setTableData(data);
  }, [organizations]);

  const sortOrganizations = (column) => {
    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });

    let columnValue = tableSortState.sortAsc
      ? tableSortState.sortColumn
      : `-${tableSortState.sortColumn}`;
    sortOrg(columnValue);
  };

  return (
    <>
      <Table hover responsive size="lg">
        <thead>
          <tr>
            <th
              className="table-sorter"
              onClick={() => !isLoading && sortOrganizations("name")}
            >
              Name
              {tableSortState.sortColumn === "name" && (
                <span className="material-icons-outlined mx-1 md-20 text-muted">
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              className="text-end table-sorter"
              onClick={() =>
                !isLoading && sortOrganizations("topDownEmissions")
              }
            >
              Top-Down <br /> Estimation{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
              {tableSortState.sortColumn === "topDownEmissions" && (
                <span className="material-icons-outlined mx-1 md-20 text-muted">
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              className="text-end table-sorter"
              onClick={() =>
                !isLoading && sortOrganizations("bottomUpEmissions")
              }
            >
              Bottom-Up GHG
              <br /> Inventory{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
              {tableSortState.sortColumn === "bottomUpEmissions" && (
                <span className="material-icons-outlined mx-1 md-20 text-muted">
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              className="text-end table-sorter"
              onClick={() =>
                !isLoading && sortOrganizations("abatementPotential")
              }
            >
              Abatement <br />
              Potential{" "}
              <small>
                (tCO<sub>2</sub>e/year)
              </small>{" "}
              <abbr title={"Excluding Non-Committed and Deferred projects."}>
                <span className="material-icons-outlined md-18">info</span>
              </abbr>
              {tableSortState.sortColumn === "abatementPotential" && (
                <span className="material-icons-outlined mx-1 md-20 text-muted">
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              className="text-end table-sorter"
              onClick={() => !isLoading && sortOrganizations("carbonIntensity")}
            >
              Emissions to Revenue <br />
              Intensity{" "}
              <small>
                (tCO<sub>2</sub>e/$M)
              </small>{" "}
              {tableSortState.sortColumn === "carbonIntensity" && (
                <span className="material-icons-outlined mx-1 md-20 text-muted">
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
            <th
              className="table-sorter ps-3"
              onClick={() => !isLoading && sortOrganizations("updatedAt")}
            >
              Updated At
              {tableSortState.sortColumn === "updatedAt" && (
                <span className="material-icons-outlined mx-1 md-20 text-muted">
                  {tableSortState.sortAsc && "arrow_upward"}
                  {!tableSortState.sortAsc && "arrow_downward"}
                </span>
              )}
            </th>
          </tr>
        </thead>

        <tbody>
          {uniqBy(tableData, "id").map((org) => {
            return (
              <tr key={org.id}>
                <td className="w-25">
                  <Link
                    to={`/organizations/${org.id}`}
                    className="text-decoration-none"
                  >
                    {org.name}
                  </Link>
                </td>
                <td className="text-end">
                  {getFormattedEmission(org.topDownEmissions || 0)}
                </td>
                <td className="text-end">
                  {getFormattedEmission(org.bottomUpEmissions || 0)}
                </td>
                <td className="text-end">
                  {getFormattedEmission(org.abatementPotential || 0)}
                </td>
                <td className="text-end">
                  {org.carbonIntensity ? (
                    getFormattedEmission(org.carbonIntensity || 0)
                  ) : (
                    <small>n/a</small>
                  )}{" "}
                </td>
                <td className="ps-3 text-nowrap">
                  <small>
                    {new Date(org.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default OrganizationList;
