import React, { useState, useRef, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form, Alert, Table, Col } from "react-bootstrap";

import * as yup from "yup";
import { Formik } from "formik";

import { upload } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import { AccountContext } from "contexts/AccountProvider";

import "./OrganizationsUpload.scss";

const OrganizationsUpload = ({ onOrganizationsUploaded }) => {
  const subscribedPromises = useRef([]);
  const attachmentRef = useRef();

  const [show, setShow] = useState(false);

  const account = useContext(AccountContext);

  const [fileData, setFileData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setIsLoading();
    setError();
    setSuccess();
    setFileData();
    attachmentRef.current.reset();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const uploadOrganizations = (file) => {
    setError();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("accountId", account.id);

    const filePromise = upload(`organizations/import`, formData);

    filePromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        setSuccess(true);
        attachmentRef.current.reset();
        setFileData();
        onOrganizationsUploaded();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setSuccess();
        }
      });
    subscribedPromises.current.push(filePromise);
  };

  const previewOrganizations = (file) => {
    setError();
    setIsLoading(true);
    setSuccess(false);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("accountId", account.id);
    formData.append("preview", 1);

    const previewPromise = upload(`organizations/import`, formData);

    previewPromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        const blankRow = response?.data.map((item) => item.name).includes("");
        const txtType = "text/plain".includes(file?.type);

        if (txtType || file?.type !== "text/csv") {
          setError({
            data: {
              message: "The file should be in CSV format (.csv extension only)",
            },
          });
          attachmentRef.current.reset();
        } else if (blankRow) {
          setError({
            data: {
              message:
                "The file has organizations without names. Please provide the missing organization names and reupload.",
            },
          });
          attachmentRef.current.reset();
        } else {
          setFileData(response.data);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setFileData();
        }
      });
    subscribedPromises.current.push(previewPromise);
  };

  const schema = yup.object().shape({
    file: yup.mixed().required("A file is required"),
  });

  return (
    <>
      <Button
        variant="secondary"
        onClick={handleShow}
        size="sm"
        className="text-white"
      >
        Import Organizations
      </Button>

      <Modal
        className="OrganizationsUpload"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Organizations</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            uploadOrganizations(values.file);
          }}
          initialValues={{ file: "" }}
        >
          {({
            handleSubmit,
            handleBlur,
            setFieldValue,
            touched,
            errors,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} ref={attachmentRef}>
              <Modal.Body>
                <h4>Requirements.</h4>
                <ul>
                  <li>
                    The file should be in CSV format (.csv extension only)
                  </li>
                  <li>
                    The file should have two columns only. The first -{" "}
                    <i>Organization Name</i> and the second -{" "}
                    <i>Organization Description</i>.
                  </li>
                  <li>The first row will be ignored.</li>
                </ul>

                <p>
                  <small>
                    <i>
                      Can't seem to find what you're looking for? Please feel
                      free to reach out to us at any time by selecting the
                      Feedback button at the top of your screen. We would be
                      happy to answer any questions you may have.
                    </i>
                  </small>
                </p>

                <Form.Group controlId="files" className="mb-3">
                  <Form.Label>Please attach csv file.</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    disabled={fileData}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("file", file);
                      previewOrganizations(file);
                    }}
                    onBlur={handleBlur}
                    isValid={touched.file && !errors.file}
                    accept=".csv"
                    multiple
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.files}
                  </Form.Control.Feedback>
                </Form.Group>

                {isLoading && <Loader />}
                {error && (
                  <ErrorHandler message={error.data.message} error={error} />
                )}
                {success && (
                  <Alert variant="info">
                    Organizations uploaded successfully!
                  </Alert>
                )}
                {fileData && (
                  <div className="table-scroll mt-4">
                    <Table responsive striped size="sm">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fileData?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.name}</td>
                            <td>{data.description || "N/A"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                {fileData && (
                  <Button
                    size="sm"
                    variant="outline-primary"
                    disabled={error}
                    onClick={() => {
                      setFileData();
                      attachmentRef.current.reset();
                    }}
                  >
                    Discard
                  </Button>
                )}
                <Button
                  type="submit"
                  size="sm"
                  disabled={!isValid || isLoading || !fileData || error}
                >
                  Import
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

OrganizationsUpload.propTypes = {
  onOrganizationsUploaded: PropTypes.func.isRequired,
};

export default OrganizationsUpload;
