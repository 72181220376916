import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import {
  Modal,
  Button,
  Form,
  Tabs,
  Tab,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import NumberFormat from "react-number-format";
import moment from "moment";

import { post, get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import {
  projectStatusMap,
  projectTypesMap,
  projectDataConfidenceMap,
} from "../../constants";

const AbatementProjectCreate = ({
  sites = [],
  onAbatementProjectCreated,
  project,
}) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const { organizationId } = useParams();

  const [autocomplete, setAutocomplete] = useState(null);
  const [cordinates, setCordinates] = useState({});
  const [libraries] = useState(["places"]);
  const [projectLocation, setProjectLocation] = useState("");
  const [emissions, setEmissions] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("step-1");
  const [error, setError] = useState();
  const [quantity, setQuantity] = useState(1);
  const target = useRef(null);
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setShow(false);
    setQuantity(1);
    setStep("step-1");
    setTimeout(() => setVisible(false), 0);
  };
  const handleShow = () => {
    setVisible(false);
  };

  const toggleOverlay = () => {
    setShow(true);
    setVisible(true);
  };

  const schema = yup.object().shape({
    //General Info
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    location: yup
      .string()
      .min(1, "Location name should be more than 1 characters!")
      .max(255, "Location name is too long!")
      .required(),
    status: yup
      .number()
      .oneOf([1, 2, 3, 4, 5], "Status is a required field")
      .required(),
    type: yup
      .number()
      .oneOf([1, 2, 3, 4, 5, 6, 7, 8], "Type is a required field")
      .required(),

    //NPV Attributes
    initialInvestment: yup
      .number()
      .label("Initial Investment")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    annualSavings: yup
      .number()
      .label("Annual Cashflow")
      .min(-9999999999999)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    discountRate: yup
      .number()
      .label("Discount Rate")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    disposalCost: yup
      .number()
      .label("Discount Cost")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    emissionSavingsStartDate: yup
      .date()
      .label("Emission Savings Start Date")
      .required(),
    projectLifetime: yup
      .number()
      .label("Project Lifetime")
      .min(1)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .integer()
      .required(),
    annualCarbonEmissionReduction: yup
      .number()
      .label("Annual Carbon Emission Reduction")
      .min(0)
      .max(
        ((emissions && emissions.emissionsScopeOne) || 0) +
          ((emissions && emissions.emissionsScopeTwo) || 0) || 9999999999999,
        ((emissions && emissions.emissionsScopeOne) || 0) +
          ((emissions && emissions.emissionsScopeTwo) || 0)
          ? `Your CO2 savings is more than your scope 1 and scope 2 emissions`
          : `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
      )
      .required(),
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
  });

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    const address = autocomplete.getPlace().name;
    setProjectLocation(address);
    setCordinates({ lat, lng });
  };

  const createProject = ({
    name,
    location,
    status,
    type,
    description,
    annualSavings,
    projectLifetime,
    discountRate,
    disposalCost,
    initialInvestment,
    annualCarbonEmissionReduction,
    emissionSavingsStartDate,
    autoRenew,
    site,
    dataConfidence,
  }) => {
    setError(null);
    setIsLoading(true);

    const organizationPromise = post(
      `organizations/${organizationId}/abatement-projects`,
      {
        name: name,

        location: projectLocation,
        latitude: cordinates.lat,
        longitude: cordinates.lng,

        data_confidence: parseInt(dataConfidence),
        project_status: parseInt(status),
        project_type: parseInt(type),
        description: description,
        initial_investment: initialInvestment,
        annual_savings: annualSavings,
        project_lifetime: projectLifetime,
        discount_rate: discountRate,
        disposal_cost: disposalCost,
        annual_carbon_emission_reduction: annualCarbonEmissionReduction,
        emission_savings_start_date: moment(emissionSavingsStartDate).format(
          "MM/DD/YYYY"
        ),
        auto_renew: autoRenew,
        site_id: site,
      }
    );

    organizationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onAbatementProjectCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    if (show) {
      const emissionsPromise = get(`organizations/${organizationId}/emissions`);

      emissionsPromise.promise
        .then((response) => {
          setError(null);
          setIsLoading(false);
          setEmissions(response.data[0] || null);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(emissionsPromise);
    }
  }, [organizationId, show]);

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="left"
        show={visible}
        target={target.current}
        overlay={
          <Popover id="popover-basic">
            <Popover.Header as="h3">
              {" "}
              Quantity (units)
              <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
            </Popover.Header>
            <Popover.Body>
              <Form.Group controlId="quantity">
                <NumberFormat
                  name="quantity"
                  size="sm"
                  value={quantity}
                  customInput={Form.Control}
                  thousandSeparator={true}
                  onValueChange={(numberItem) => setQuantity(numberItem.value)}
                  isValid={quantity}
                />
              </Form.Group>
              <div className="d-grid gap-2">
                <Button
                  className="mt-3"
                  variant="primary"
                  size="sm"
                  onClick={handleShow}
                  disabled={!parseInt(quantity) > 0}
                >
                  select
                </Button>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <Button variant="outline-primary" size="sm" onClick={toggleOverlay}>
          select
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className={visible ? "d-none" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Abatement Project</Modal.Title>
        </Modal.Header>
        {!visible && (
          <Formik
            validationSchema={schema}
            onSubmit={(values) => createProject(values)}
            initialValues={{
              name: project.title || "",
              location: "",
              description: project.description || "",
              status: project.status || "",
              type: "",
              site: "",
              dataConfidence: project.dataConfidence || "",

              initialInvestment: project.initialInvestment * quantity || "",
              annualSavings: project.annualSavings * quantity,
              emissionSavingsStartDate: moment().format("YYYY-MM-DD"),
              projectLifetime: project.projectLifetime || 10,
              discountRate: project?.discountRate || 8,
              disposalCost: project?.disposalCost * quantity || 0,

              annualCarbonEmissionReduction:
                project?.cumulativeCarbonSavings * quantity || 0,
              autoRenew: 1,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={step}
                    onSelect={(k) => setStep(k)}
                    className="mb-3"
                  >
                    <Tab
                      eventKey="step-1"
                      title={
                        <>
                          General Information{" "}
                          {((errors.name && touched.name) ||
                            (errors.location && touched.location) ||
                            (errors.status && touched.status) ||
                            (errors.type && touched.type)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <Form.Group controlId="projectName">
                        <Form.Label>
                          Name
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.name}
                          isInvalid={errors.name && touched.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="site" className="my-3">
                        <Form.Label>
                          Site
                          <span className="text-muted ms-2">(Optional)</span>
                        </Form.Label>
                        <Form.Select
                          name="site"
                          value={values.site}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.site}
                          isInvalid={errors.site && touched.site}
                        >
                          <option value={0}>Select the project site</option>
                          {sites.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.site}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {isLoaded && (
                        <Autocomplete
                          onLoad={onLoad}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <Form.Group
                            controlId="projectLocation"
                            className="my-3"
                          >
                            <Form.Label>
                              Location{" "}
                              <sup className="ps-1 fs-3 top-0 text-muted">
                                *
                              </sup>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="location"
                              placeholder="Please enter location name"
                              value={
                                projectLocation === ""
                                  ? values.location
                                  : projectLocation
                              }
                              onChange={(e) => {
                                handleChange(e);
                                setProjectLocation("");
                              }}
                              onBlur={handleBlur}
                              isValid={values.location}
                              isInvalid={errors.location && touched.location}
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.location}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Autocomplete>
                      )}
                      <Form.Group controlId="projectStatus" className="my-3">
                        <Form.Label>
                          Status
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <Form.Select
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.status}
                          isInvalid={errors.status && touched.status}
                        >
                          <option value={0}>Select the project status</option>
                          {projectStatusMap.map(({ key, value }) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.status}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="projectType" className="my-3">
                        <Form.Label>
                          Type
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <Form.Select
                          name="type"
                          value={values.type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.type}
                          isInvalid={errors.type && touched.type}
                        >
                          <option value={0}>Select the project type</option>
                          {projectTypesMap.map(({ key, value }) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.type}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        controlId="projectDescription"
                        className="my-3"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.description}
                          isInvalid={errors.description && touched.description}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.description}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        controlId="projectDataConfidence"
                        className="my-3"
                      >
                        <Form.Label>Data Confidence</Form.Label>
                        <Form.Select
                          name="dataConfidence"
                          value={values.dataConfidence}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.dataConfidence}
                          isInvalid={
                            errors.dataConfidence && touched.dataConfidence
                          }
                        >
                          <option value={0}>
                            Select the project data confidence
                          </option>
                          {projectDataConfidenceMap.map(({ key, value }) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.dataConfidence}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Tab>
                    <Tab
                      eventKey="step-2"
                      title={
                        <>
                          NPV (Net Present Value)
                          {((errors.projectLifetime &&
                            touched.projectLifetime) ||
                            (errors.discountRate && touched.discountRate) ||
                            (errors.disposalCost && touched.disposalCost) ||
                            (errors.annualSavings && touched.annualSavings) ||
                            (errors.initialInvestment &&
                              touched.initialInvestment)) && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                        </>
                      }
                    >
                      <Form.Group
                        controlId="initialInvestment"
                        className="my-3"
                      >
                        <Form.Label>
                          Initial Investment ($)
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <NumberFormat
                          name="initialInvestment"
                          value={values.initialInvestment}
                          prefix={"$"}
                          customInput={Form.Control}
                          thousandSeparator={true}
                          onValueChange={(numberItem) => {
                            setFieldValue(
                              "initialInvestment",
                              numberItem.value
                            );
                          }}
                          onBlur={handleBlur}
                          isValid={values.initialInvestment}
                          isInvalid={
                            errors.initialInvestment &&
                            touched.initialInvestment
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.initialInvestment}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="annualSavings" className="my-3">
                        <Form.Label>
                          Annual Cashflow ($)
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <NumberFormat
                          name="annualSavings"
                          value={values.annualSavings}
                          prefix={"$"}
                          customInput={Form.Control}
                          thousandSeparator={true}
                          onValueChange={(numberItem) => {
                            setFieldValue("annualSavings", numberItem.value);
                          }}
                          onBlur={handleBlur}
                          isValid={values.annualSavings}
                          isInvalid={
                            errors.annualSavings && touched.annualSavings
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.annualSavings}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="discountRate" className="my-3">
                        <Form.Label>
                          Discount Rate (%)
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="discountRate"
                          value={values.discountRate}
                          onChange={handleChange}
                          onWheel={(e) => e.target.blur()}
                          onBlur={handleBlur}
                          isValid={values.discountRate}
                          isInvalid={
                            errors.discountRate && touched.discountRate
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.discountRate}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="disposalCost" className="my-3">
                        <Form.Label>
                          Disposal Cost ($)
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <NumberFormat
                          name="disposalCost"
                          value={values.disposalCost}
                          prefix={"$"}
                          customInput={Form.Control}
                          thousandSeparator={true}
                          onValueChange={(numberItem) => {
                            setFieldValue("disposalCost", numberItem.value);
                          }}
                          onBlur={handleBlur}
                          isValid={!errors.disposalCost}
                          isInvalid={
                            errors.disposalCost && touched.disposalCost
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.disposalCost}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="emissionSavingsStartDate"
                        className="my-3"
                      >
                        <Form.Label>
                          Emission Savings Start Date
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="emissionSavingsStartDate"
                          value={values.emissionSavingsStartDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.emissionSavingsStartDate}
                          isInvalid={
                            errors.emissionSavingsStartDate &&
                            touched.emissionSavingsStartDate
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.emissionSavingsStartDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="projectLifetime" className="my-3">
                        <Form.Label>
                          Project Lifetime (Yrs)
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="projectLifetime"
                          value={values.projectLifetime}
                          onChange={handleChange}
                          onWheel={(e) => e.target.blur()}
                          onBlur={handleBlur}
                          isValid={values.projectLifetime}
                          isInvalid={
                            errors.projectLifetime && touched.projectLifetime
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.projectLifetime}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="my-3" controlId="autoRenew">
                        <Form.Check
                          type="checkbox"
                          label="Auto renew project"
                          name="autoRenew"
                          value={values.autoRenew}
                          checked={!!values.autoRenew}
                          onChange={() => {
                            if (!!values.autoRenew)
                              setFieldValue("autoRenew", 0);
                            else setFieldValue("autoRenew", 1);
                          }}
                        />
                      </Form.Group>
                    </Tab>
                    <Tab
                      eventKey="step-3"
                      title={
                        <>
                          Lifetime Emissions Reduction
                          {errors.annualCarbonEmissionReduction &&
                            touched.annualCarbonEmissionReduction && (
                              <sup className="ps-1 fs-3 top-0 text-danger">
                                *
                              </sup>
                            )}
                        </>
                      }
                    >
                      <Form.Group
                        controlId="annualCarbonEmissionReduction"
                        className="my-3 mb-5"
                      >
                        <Form.Label>
                          Annual Emissions Reduction (tCO<sub>2</sub>e)
                          <sup className="ps-1 fs-3 top-0 text-muted">*</sup>
                        </Form.Label>
                        <NumberFormat
                          name="annualCarbonEmissionReduction"
                          value={values.annualCarbonEmissionReduction}
                          customInput={Form.Control}
                          thousandSeparator={true}
                          onValueChange={(numberItem) => {
                            setFieldValue(
                              "annualCarbonEmissionReduction",
                              numberItem.value
                            );
                          }}
                          onBlur={handleBlur}
                          isValid={values.annualCarbonEmissionReduction}
                          isInvalid={
                            errors.annualCarbonEmissionReduction &&
                            touched.annualCarbonEmissionReduction
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.annualCarbonEmissionReduction}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Tab>
                  </Tabs>

                  {error && <ErrorHandler error={error} />}
                  {isLoading && <Loader />}
                </Modal.Body>

                <Modal.Footer className="">
                  {step === "step-1" && (
                    <Button
                      variant="outline-dark"
                      size="sm"
                      className="px-4"
                      onClick={() => setStep("step-2")}
                    >
                      Next
                    </Button>
                  )}

                  {step === "step-2" && (
                    <>
                      <Button
                        variant="outline-dark"
                        size="sm"
                        onClick={() => setStep("step-1")}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outline-dark"
                        size="sm"
                        className="px-4"
                        onClick={() => setStep("step-3")}
                      >
                        Next
                      </Button>
                    </>
                  )}

                  {step === "step-3" && (
                    <>
                      <Button
                        variant="outline-dark"
                        className="float-start px-4"
                        size="sm"
                        onClick={() => setStep("step-2")}
                      >
                        Previous
                      </Button>
                      <Button type="submit" size="sm" disabled={!isValid}>
                        Create Project
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

AbatementProjectCreate.propTypes = {
  onAbatementProjectCreated: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default AbatementProjectCreate;
