import { ceil } from "lodash";

export function slugify(text) {
  text = text || "content";
  const a = "àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with ‘and’
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple — with single -
    .replace(/^-+/, ""); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
}

export function getURLHostName(URL) {
  //Remove protocol (http, ftp, etc.) and get hostname
  let hostName = URL.indexOf("//") > -1 ? URL.split("/")[2] : URL.split("/")[0];

  //Remove portNumber
  hostName = hostName.split(":")[0];
  //Remove "?"
  hostName = hostName.split("?")[0];

  return hostName;
}

export function getURLDomain(URL) {
  let domain = getURLHostName(URL),
    splitArr = domain.split("."),
    arrLen = splitArr.length;

  //Check for subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
    //If using Country Code Top Level Domain (ccTLD) (i.e. ".co.ke")
    if (
      splitArr[arrLen - 2].length === 2 &&
      splitArr[arrLen - 1].length === 2
    ) {
      domain = splitArr[arrLen - 3] + "." + domain;
    }
  }
  return domain;
}

export function isValidEmail(text = "") {
  let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  return pattern.test(text);
}

export function isValidPassword(text = "") {
  let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}/);
  return pattern.test(text);
}

export function getFormattedEmission(emission, precision = 0) {
  if (precision > 0) {
    return Intl.NumberFormat("en-us").format(ceil(emission, precision));
  }
  return Intl.NumberFormat("en-us").format(Math.ceil(emission));
}

export function getInternationalNumberFormatCompact(number, locale = "en-US") {
  return Intl.NumberFormat(locale, {
    notation: "compact",
  }).format(number);
}

export function countDecimals(value) {
  if (Math.floor(value) !== value) {
    return (
      value
        .toLocaleString("en-US", { maximumSignificantDigits: 20 })
        .split(".")[1].length || 0
    );
  }
  return 0;
}

export function fromSnakeToTitleCase(str) {
  return str.replace(/(^[a-z]|[-_][a-z])/gi, (match) => {
    return match.toUpperCase().replace("-", "").replace("_", "");
  });
}
